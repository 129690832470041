import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/public/blogs.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';
import { format } from 'date-fns';

const Blogs = () => {
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const { search } = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [searchBar, setSearchBar] = useState(search || ''); // Initialize with URL param if present

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noResults, setNoResults] = useState(false); // Track if no results found
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if more blogs are available
  const observer = useRef();

  const fetchBlogs = async (pageNumber) => {
    try {
      setLoading(true);
      const url = search 
        ? `${ServerApiDomain}/api/blogs-feed/search?query=${encodeURIComponent(search)}&page=${pageNumber}`
        : `${ServerApiDomain}/api/blogs-feed?page=${pageNumber}`;
      const response = await axios.get(url, {
        auth: {
          username: 'testuser',
          password: 'testpassword',
        },
      });
  
      // Check if the fetch was for search and there are no results
      if (search && response.data.length === 0) {
        setNoResults(true); // Set noResults only for search
      } else {
        setNoResults(false); // Reset no results flag if we have results or it's a normal fetch
      }
  
      setBlogs((prevBlogs) => [...prevBlogs, ...response.data]); // Append new blogs
      setHasMore(response.data.length > 0); // Check if there's more data
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  

  // Load the initial data based on the search query or page
  useEffect(() => {
    document.title = 'Blogs | Aadow';
    fetchBlogs(page);
  }, [page, search]); // Add search to dependency array

  // Observer callback function
  const lastBlogElementRef = useCallback((node) => {
    if (loading) return; // Do nothing if already loading
    if (observer.current) observer.current.disconnect(); // Disconnect the observer

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) { // Check if there's more to load
        setPage((prevPage) => prevPage + 1); // Increment the page number
      }
    });

    if (node) observer.current.observe(node); // Observe the last blog post element
  }, [loading, hasMore]);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from doing a full page reload
    setBlogs([]); // Clear the existing blogs
    setPage(0); // Reset the page number to 0 for a fresh start
    navigate(`/blogs/${searchBar}`); // Navigate to the new URL with the search input
  };

  // Reset search query if navigating back to the base /blogs page
  useEffect(() => {
    if (!search) {
      setSearchBar(''); // Clear the search bar when on the main blogs page
      setBlogs([]); // Clear current blogs list
      setPage(0); // Reset page to start fresh
      setNoResults(false); // Reset no results state
    }
  }, [search]); // Trigger whenever `search` changes

  return (
    <div>
      <TopBar />
      <div className="blogs-area">
        <div className="all-blogs-area">
          <div className="search-area">
            <div className="row align-items-center">
              <div className="col-12 col-md-12 col-lg-8 col-xl-8">
                <h3>
                  {search ? (
                    <p>Search Query: <b>{search}</b></p>
                  ) : (
                    <b><p>All Posts</p></b>
                  )}
                </h3>
              </div>
              <div className="col-12 col-md-12 col-lg-4 col-xl-4">
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="search"
                    autoComplete="off"
                    id="search"
                    value={searchBar} // Controlled input
                    onChange={(e) => setSearchBar(e.target.value)} // Update state when typing
                    className="blog-search" 
                    placeholder="Search Blogs & Articles"
                    required
                  />
                  <button type="submit" className="d-none">Search</button> {/* You can add a search button */}
                </form>
              </div>
            </div>
          </div>
          <div className="all-posts">
            <div className="row">
              {blogs.map((blog, index) => {
                const formattedDate = format(new Date(blog.datetime), 'MMM dd, yyyy');
                const isLastBlog = blogs.length === index + 1;

                return (
                  <div key={blog.id} ref={isLastBlog ? lastBlogElementRef : null} className="col-12 col-md-12 col-lg-3 col-xl-3 d-flex">
                    <div className="related-blog shadow-sm">
                      <Link to={`/blog/${blog.title.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase()}-${blog.id}`} className="text-decoration-none">
                        <img src={blog.poster} alt="" title="" className="related-blog-poster" />
                        <div className="related-blog-content-area">
                          <h6 className="related-blog-heading">{blog.title}</h6>
                          <h6 className="related-blog-subheading">{blog.preview}</h6>
                          <div className="pt-2 pb-2">
                            <h6 className="relative-blog-date d-inline">{formattedDate} by</h6>
                            <h6 className="relative-blog-author d-inline"> {blog.author}</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            {loading && hasMore && (
              <div className="d-block float-center text-center pt-4 pb-4">
                <div className="spinner-grow" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            {noResults && !loading && !blogs.length && (
              <div className="text-center">No results found for your search.</div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
