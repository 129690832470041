import React, { useState, useEffect, useRef } from 'react';
import NotificationRadioSlider from './../../../components/NotificationRadioSlider';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';

const TeamMembers = () => {
  const [emailNotifications, setEmailNotifications] = useState({
    newsUpdates: true,
    campaignUpdates: true,
  });

  const [smsNotifications, setSmsNotifications] = useState({
    newsUpdates: false,
    // Add other notifications as needed
  });

  const handleEmailChange = (name) => (event) => {
    setEmailNotifications({ ...emailNotifications, [name]: event.target.checked });
	alert(`Email Notification changed: ${name} - New Value: ${event.target.checked}`);
  };

  const handleSmsChange = (name) => (event) => {
    setSmsNotifications({ ...smsNotifications, [name]: event.target.checked });
	alert(`Sms Notification changed: ${name} - New Value: ${event.target.checked}`);
  };
  useEffect(() => {
  }, []);

  return (					
			<div className="main-area">
		  
			<div className="secondary-heading-area">
					<h4 className="secondary-heading">Notification Settings</h4>
					<h6 className="secondary-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
			</div>

			<div className="form-area">
				<div className="row">
					<div className="col-4 border-1 border-end">
						  <h4 className="secondary-heading">Email Notifications</h4>
						  <h6 className="secondary-subheading">Admin can add and remove users and manage organiation-level settings</h6>
					</div>
					<div className="col">
							<div className="notification-radio-area">
								<div className="row align-items-center">
								  <div className="col-8">
									  <div className="notification-control-area">
										  <h4 className="secondary-heading">News and Updates</h4>
										  <h6 className="secondary-subheading">News about products and feature update</h6>
									  </div>
								  </div>
								  <div className="col-4 text-end">
									 <NotificationRadioSlider
										checked={emailNotifications.newsUpdates}
										onChange={handleEmailChange('newsUpdates')}
									  />
								  </div>
								</div>  
							</div>  
							
							<div className="notification-radio-area">
								<div className="row align-items-center">
								  <div className="col-8">
									  <div className="notification-control-area">
										  <h4 className="secondary-heading">Campaigns Reports</h4>
										  <h6 className="secondary-subheading">News about products and feature update</h6>
									  </div>
								  </div>
								  <div className="col-4 text-end">
									<NotificationRadioSlider
										checked={emailNotifications.campaignUpdates}
										onChange={handleEmailChange('campaignUpdates')}
									  />
								  </div>
								</div>  
							</div>  
							
							<div className="notification-radio-area">
								<div className="row align-items-center">
								  <div className="col-8">
									  <div className="notification-control-area">
										  <h4 className="secondary-heading">News and Updates</h4>
										  <h6 className="secondary-subheading">News about products and feature update</h6>
									  </div>
								  </div>
								  <div className="col-4 text-end">
									<NotificationRadioSlider />
								  </div>
								</div>  
							</div>  
							
							<div className="notification-radio-area">
								<div className="row align-items-center">
								  <div className="col-8">
									  <div className="notification-control-area">
										  <h4 className="secondary-heading">News and Updates</h4>
										  <h6 className="secondary-subheading">News about products and feature update</h6>
									  </div>
								  </div>
								  <div className="col-4 text-end">
									<NotificationRadioSlider />
								  </div>
								</div>  
							</div>  
							


					</div>
				</div>
			</div>
			
			<div className="form-area">
				<div className="row">
					<div className="col-4 border-1 border-end">
						  <h4 className="secondary-heading">SMS Notifications</h4>
						  <h6 className="secondary-subheading">Admin can add and remove users and manage organiation-level settings</h6>
					</div>
					<div className="col">
							<div className="notification-radio-area">
								<div className="row align-items-center">
								  <div className="col-8">
									  <div className="notification-control-area">
										  <h4 className="secondary-heading">News and Updates</h4>
										  <h6 className="secondary-subheading">News about products and feature update</h6>
									  </div>
								  </div>
								  <div className="col-4 text-end">
									<NotificationRadioSlider
										checked={smsNotifications.newsUpdates}
										onChange={handleSmsChange('newsUpdates')}
									  />
								  </div>
								</div>  
							</div>  
							
							<div className="notification-radio-area">
								<div className="row align-items-center">
								  <div className="col-8">
									  <div className="notification-control-area">
										  <h4 className="secondary-heading">News and Updates</h4>
										  <h6 className="secondary-subheading">News about products and feature update</h6>
									  </div>
								  </div>
								  <div className="col-4 text-end">
									<NotificationRadioSlider />
								  </div>
								</div>  
							</div>  
							
							<div className="notification-radio-area">
								<div className="row align-items-center">
								  <div className="col-8">
									  <div className="notification-control-area">
										  <h4 className="secondary-heading">News and Updates</h4>
										  <h6 className="secondary-subheading">News about products and feature update</h6>
									  </div>
								  </div>
								  <div className="col-4 text-end">
									<NotificationRadioSlider />
								  </div>
								</div>  
							</div>  
							
							<div className="notification-radio-area">
								<div className="row align-items-center">
								  <div className="col-8">
									  <div className="notification-control-area">
										  <h4 className="secondary-heading">News and Updates</h4>
										  <h6 className="secondary-subheading">News about products and feature update</h6>
									  </div>
								  </div>
								  <div className="col-4 text-end">
									<NotificationRadioSlider />
								  </div>
								</div>  
							</div>  
							


					</div>
				</div>
			</div>
			
			
			
		  </div>
					
  );
};

export default TeamMembers;
