import { useEffect } from 'react';
import NProgress from 'nprogress';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Start NProgress when the route changes
    NProgress.start();
    
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // End NProgress when the route is fully loaded
    NProgress.done();
    
    // Cleanup function to ensure NProgress doesn't hang
    return () => {
      NProgress.done();
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;
