export function gtag_report_conversion(url) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url;
    }
  };
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      send_to: 'AW-11492318057/nPlFCPH08fYZEOm2--cq',
      value: 1.0,
      currency: 'INR',
      event_callback: callback,
    });
  }
  return false;
}
