import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/public/terms-policies.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';

const Terms = () => {
  useEffect(() => {
    document.title = 'Terms & Conditions | Aadow';
	  }, []);
 

  return (
    <div class="terms-body">
		<TopBar />
		<div class="top-area">
		  <div class="container-top text-center">
			<h1>TERMS & CONDITIONS</h1>
			<span>Update on 23 Jul 2023</span>
		  </div>
		</div>
		<div class="content-area"> 
		
		<div class="policy-content shadow-sm">
		  <h2>1. Acceptance of Terms</h2>
		  <p>By utilizing our media planning, media buying, and email solution services, you acknowledge that you have read, understood, and agreed to be bound by the terms and conditions outlined herein. These terms constitute a legally binding agreement between our marketing company and you, the client.</p>

		  <h2>2. Services</h2>
		  <p> We provide media planning, media buying, and email solution services to assist you in promoting your business. Our media planning services involve creating effective strategies to reach your target audience through various media channels. Media buying services encompass negotiating and purchasing ad space or time on your behalf. Our email solution services include managing and optimizing your email marketing campaigns.</p>

		  <h2>3. Account Registration</h2>
		  <p>To access certain features of our Services, you may be required to create an account. You must provide accurate and complete information during the registration process and keep your account credentials secure. You are solely responsible for all activities that occur under your account.</p>

		  <h2>4. User Conduct</h2>
		  <p>As a client, you are responsible for providing accurate and up-to-date information regarding your business, target audience, marketing goals, and any specific requirements. You must also ensure compliance with applicable laws, regulations, and industry standards.</p>

		  <h2>5. Intellectual Property</h2>
		  <p>Any intellectual property rights associated with our media planning strategies, creative materials, or email templates developed during the course of our engagement shall remain the property of our marketing company, unless otherwise agreed upon in writing. You are granted a limited, non-exclusive license to use these materials solely for the purpose of the agreed-upon services.</p>

		  <h2>6. Privacy</h2>
		  <p>Protecting your privacy is of utmost importance to us. We may collect certain personal information from you in order to provide our services effectively. This information may include but is not limited to your name, contact details, and marketing preferences. We will handle and safeguard this information in accordance with our Privacy Policy. By accepting these terms and conditions, you consent to the collection, use, and storage of your personal information as described in our Privacy Policy.</p>

		  <h2>7. Third-Party Links</h2>
		  <p>We cannot be held responsible or liable for the content, actions, or policies of any third-party websites or resources linked to or referenced in our services. Any interactions, transactions, or disputes that may occur between you and a third party are solely between you and that third party. Please exercise caution and use your own judgment when accessing or interacting with any third-party links. If you have any concerns or questions about a particular third-party website or resource, we recommend contacting the respective website or resource directly for more information</p>

		  <h2>8. Limitation of Liability</h2>
		  <p> Our marketing company shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of or relating to our services. In no event shall our liability exceed the total amount paid by you for the specific services provided.</p>


		  <h2>9. Modifications </h2>
		  <p>We reserve the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting the revised Terms on Aadow.com. Your continued use of our website or Services after any modifications constitute your acceptance of the revised Terms.</p>

		</div>
		 
		</div>
		<Footer />
    </div>
  );
};

export default Terms;
