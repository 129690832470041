import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../../css/public/pricing.css';
import '../../../../css/public/CustomSelect.css';
import { Select, MenuItem, FormControl, InputLabel, InputBase, Box, Typography } from '@mui/material';
import Cookies from 'js-cookie';

const EmailMarketingPlans = () => {
  
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  
 


  
    const [isBasicButtonDisabled, setisBasicButtonDisabled] = useState('');
    
    const CurrentPlanId = Cookies.get('CurrentPlanIdTransactional');
    
    useEffect(() => {
      if(CurrentPlanId === 'PRE001'){
        setisBasicButtonDisabled(true);
      }else{
        setisBasicButtonDisabled(false);
      }
    
      }, []);
  
  const [selectedPlanIdPremium, setselectedPlanIdPremium] = useState("PRE001");
  
  const CookieAccountId = Cookies.get('current_accountId');
  const SelectPrePlan = async () => {
	Cookies.set('selected_plan_id', selectedPlanIdPremium, { expires: 1 });
	Cookies.set('selected_plan_type', "EmailTransactional", { expires: 1 });
	
	window.location.href = `/checkout`;
  };

  const faqs = [
    {
      question: "Are there any setup or hidden fees?",
      answer: "No, Aadow has transparent pricing with no hidden charges or setup fees."
    },
    {
      question: "Do I need to sign a long-term contract?",
      answer: "No, Aadow offers flexible monthly and annual subscriptions without long-term commitments."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept major credit/debit cards and online payment systems for your convenience."
    },
    {
      question: "Are there discounts for annual subscriptions?",
      answer: "Yes, annual plans come with exclusive discounts compared to monthly billing."
    },
    {
      question: "How do I switch to a different currency for payments?",
      answer: "You can choose your preferred currency during checkout, depending on your region."
    },
    {
      question: "Will I be charged automatically at the end of my billing cycle?",
      answer: "No, subscriptions are not automatically renewed."
    },
    {
      question: "Does Aadow offer nonprofit or educational discounts?",
      answer: "Yes, we have special pricing for nonprofits and educational institutions. Reach out to us for eligibility details."
    },
    {
      question: "Are there charges for team member accounts?",
      answer: "Team member access is included in specific plans. Additional charges may apply for extra users depending on your plan."
    },
    {
      question: "Does Aadow offer custom pricing for large businesses or high-volume senders?",
      answer: "Yes, Aadow provides tailored pricing plans for enterprises or high-volume email requirements. Contact our sales team for more details."
    },
    {
      question: "What happens if I exceed my plan's email limit?",
      answer: "If you exceed your plan’s limit, you can purchase additional credits or upgrade to a higher plan."
    }
  ];
  
  const [currencyselectedOption, currencysetSelectedOption] = useState('INR');
  const [value, setValue] = useState(0); // initial value set to 0
  
  
    // Define the specific steps you want for the slider
    const steps = [2500, 5000, 10000, 25000, 50000, 75000, 100000, 200000, 500000];
    const maxValue = steps.length - 1; // max value for the slider

   
	
	const RangedCredits = steps[value].toLocaleString('en-IN');
    useEffect(() => {
      
    // Calculate percentage value for the slider thumb position
    const percentageValue = (value / maxValue) * 100;
    document.documentElement.style.setProperty('--value', `${percentageValue}%`);
  }, [value, maxValue]);
  
  const [PlanCreditsBasic, setPlanCreditsBasic] = useState('6,000');
  const [PlanPriceBasic, setPlanPriceBasic] = useState('0');
  const [PlanCreditsPremium, setPlanCreditsPremium] = useState('20,000');
  const [PlanPricePremium, setPlanPricePremium] = useState('999');
  const [CurrentCredit, setCurrentCredit] = useState('2,500');
  const [CurrentCurrency, setCurrentCurrency] = useState('');
  const [CurrentDuration, setCurrentDuration] = useState('');
  const [PlanPricePremiumINR, setPlanPricePremiumINR] = useState('');
  const [PlanPricePremiumUSD, setPlanPricePremiumUSD] = useState('');
  const [PlanPricePremiumGBP, setPlanPricePremiumGBP] = useState('');
  const [PlanCreditsUltimate, setPlanCreditsUltimate] = useState('2,500');
  const [PlanPriceUltimate, setPlanPriceUltimate] = useState('999');
  const [CurrencySymbol, setCurrencySymbold] = useState('₹ ');
  
  const handleChange = (event) => {
  const newValue = parseInt(event.target.value, 10);
  setValue(newValue);

  const newCredits = steps[newValue].toLocaleString('en-IN');
  let newCreditsWithoutComma = newCredits.replace(/,/g, '');

  setCurrentCredit(newCredits);

  if (newCreditsWithoutComma < 100001) {
    if(newCreditsWithoutComma === "20000"){
      if(currencyselectedOption === "INR"){
        setPlanPricePremium("$ 1,739");
        setPlanPricePremiumINR("1,739");
      } else if(currencyselectedOption === "USD"){
        setPlanPricePremium("$ 25");
        setPlanPricePremiumUSD("25");
      } else if(currencyselectedOption === "GBP"){
        setPlanPricePremium("$ 18");
        setPlanPricePremiumGBP("18");
      }
    }
    // Update premium pricing similarly for other ranges...
  }

  ChangePriceContent(newCredits, handlecurrencyselectedOption, handledurationselectedOption);
};

// Initialize the state for currency and duration
const [handlecurrencyselectedOption, sethandlecurrencyselectedOption] = useState("INR"); // Default to INR
const [handledurationselectedOption, sethandledurationselectedOption] = useState("Monthly"); // Default to Monthly


const handlecurrencyChange = (event) => {
  const selectedOption = event.target.value;
  sethandlecurrencyselectedOption(selectedOption);  // Update state for selected currency
  setCurrencySymbold("");
  // Call ChangePriceContent with updated currency and current values
  ChangePriceContent(CurrentCredit, selectedOption, handledurationselectedOption);
};

const handledurationChange = (event) => {
  const selectedDuration = event.target.value;
  sethandledurationselectedOption(selectedDuration);  // Update state for selected duration
  
  setCurrencySymbold("");
  // Call ChangePriceContent with updated duration and current values
  ChangePriceContent(CurrentCredit, handlecurrencyselectedOption, selectedDuration);
};


const ChangePriceContent = (credits, currency, duration) => {
  let newCreditsWithoutCommaa = credits.replace(/,/g, '');
  setCurrencySymbold("");
  if (credits === '2,500') {
    if(CurrentPlanId === 'PRE001'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    setselectedPlanIdPremium("PRE001");
    let ultimatePrice = currency === 'INR' ? 999 : 14;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}

if (credits === '5,000') {
    setselectedPlanIdPremium("PRE002");
    if(CurrentPlanId === 'PRE002'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    let ultimatePrice = currency === 'INR' ? 1899 : 24;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}

if (credits === '10,000') {
    setselectedPlanIdPremium("PRE003");
    if(CurrentPlanId === 'PRE003'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    let ultimatePrice = currency === 'INR' ? 3399 : 42;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}

if (credits === '25,000') {
    setselectedPlanIdPremium("PRE004");
    if(CurrentPlanId === 'PRE004'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    let ultimatePrice = currency === 'INR' ? 6499 : 78;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}

if (credits === '50,000') {
    setselectedPlanIdPremium("PRE005");
    if(CurrentPlanId === 'PRE005'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    let ultimatePrice = currency === 'INR' ? 11399 : 136;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}

if (credits === '75,000') {
    setselectedPlanIdPremium("PRE006");
    if(CurrentPlanId === 'PRE006'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    let ultimatePrice = currency === 'INR' ? 14249 : 168;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}

if (credits === '1,00,000') {
    setselectedPlanIdPremium("PRE007");
    if(CurrentPlanId === 'PRE007'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    let ultimatePrice = currency === 'INR' ? 15199 : 180;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}

if (credits === '2,00,000') {
    setselectedPlanIdPremium("PRE008");
    if(CurrentPlanId === 'PRE008'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    let ultimatePrice = currency === 'INR' ? 26599 : 316;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}

if (credits === '5,00,000') {
    setselectedPlanIdPremium("PRE009");
    if(CurrentPlanId === 'PRE009'){
      setisBasicButtonDisabled(true);
    }else{
      setisBasicButtonDisabled(false);
    }
    let ultimatePrice = currency === 'INR' ? 56999 : 676;
    setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
}



  setPlanCreditsUltimate(credits);
  
};


  const currencyoptions = [
    { value: 'INR', label: 'INR', imgSrc: 'iconss/flags/india.png' },
    { value: 'USD', label: 'USD', imgSrc: 'iconss/flags/united-states.png' },
  ]; 
  
  const durationoptions = [
    { value: 'Monthly', label: 'Monthly'},
    { value: 'Yearly', label: 'Yearly', discount: ' -10% off'},
  ];  
	  
 

  return (
    <div className="email-plans-body">
		
		<div className="filters-area">
			<div className="row">
				
				<div className="col-12 col-md-12 col-lg-10 col-xl-10">
					   <div className="slider-container">
							
							<input
								type="range"
								min="0"
								max={maxValue}
								value={value}
								className="range-slider"
								onChange={handleChange}
							/>
							 <div className="labels">
								<span className="current-value">{steps[value].toLocaleString('en-IN')}</span>
								<span className="max-value">{steps[maxValue].toLocaleString('en-IN')}</span>
							</div>
						</div>
				</div>
				<div className="col-12 col-md-12 col-lg-2 col-xl-2 float-end">
					<div className="row">
						<div className="col">
						

					<Select
					  style={{ height: '6.5vh'}} 
					  value={handlecurrencyselectedOption} 
					  onChange={handlecurrencyChange}
										disableUnderline
										fullWidth
										defaultValue="All"
									  >
										{currencyoptions.map((option) => (
						<MenuItem key={option.value} value={option.value}>
						  <Box className="custom-typo" display="flex" alignItems="center">
							<img className="custom-select-icon" src={option.imgSrc} alt={option.label}  />
							<Typography className="custom-select-typography">{option.label}</Typography>
						  </Box>
						</MenuItem>
					  ))}
				  </Select>

						</div>
						<div className="col-7 d-none">
							
							  <Select
					  			  style={{ height: '6.5vh'}}
								  value={handledurationselectedOption} 
								  onChange={handledurationChange}
													disableUnderline
													fullWidth
													defaultValue="All"
												  >
									{durationoptions.map((option) => (
								  <MenuItem key={option.value} value={option.value}>
									<Box display="flex" alignItems="center">
									  <Typography>{option.label}</Typography>
									  <span style={{ color: 'rgba(2,96,105)', fontWeight: '600', marginLeft: '0.5vw' }}>{option.discount}</span>
									</Box>
								  </MenuItem>
								))}
							  </Select>
							  
						</div>						
					</div>  
				</div>
			</div>
		</div>
		
		<div className="plans-column-area ">
			<div className="row d-flex horizontalrow">
				
				<div className="col d-flex">
					<div className="plan-column w-100">
						<h3 className="plan-column-heading">Premium Plan</h3>
						<h6 className="plan-column-description">Integrate email to your app or website</h6>
						<div className="credits-area">
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-envelope"></i> Email Credits</h6></div>
								<div className="col-5 right"><h6> {PlanCreditsUltimate}/mo</h6></div>
							</div>
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-credit-card"></i> Monthly Cost</h6></div>
								<div className="col-5 right"><h6>{CurrencySymbol}{PlanPriceUltimate}/mo</h6></div>
							</div>
						</div>
						
					<div className="features-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> RESTful APIs</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Email tracking and analysis</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Dynamic templates</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited log retention</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Email support</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Upto 5 APIs</h5>
					</div>
					
					<div class="button-area">
            <button
						disabled={isBasicButtonDisabled}
						onClick={SelectPrePlan}
						type="button"
						className="primary-outline-button"
					>
						Select Plan
					</button>
          </div>
					
					</div>
				</div>
				
				<div className="col d-flex">
					<div className="plan-column w-100">
						<h3 className="plan-column-heading">Custom Plan</h3>
						<h6 className="plan-column-description">Amplify your email program with extra support from our team</h6>
						<div className="credits-area">
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-envelope"></i> Email Credits</h6></div>
								<div className="col-5 right"><h6>5,00,000+</h6></div>
							</div>
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-credit-card"></i> Monthly Cost</h6></div>
								<div className="col-5 right"><h6> --------</h6></div>
							</div>
						</div>
						
					<div className="features-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> RESTful APIs</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Email tracking and analysis</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Dynamic templates</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited log retention</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Dynamic support</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited APIs</h5>
					</div>
					<div class="button-area"><a href="/contact" target="_blank" className="primary-outline-button d-block text-center text-decoration-none">Contact Sales</a></div>
					
					
					</div>
				</div>
				
			</div>
		</div>
		
		
		<div className="faqss-area">
			<h3 className="heading">Frequently asked questions</h3>
			<h6 className="description">Have doubts about our plan? Don't worry we got you cover</h6>
			
			<div className="accordion">
			  {faqs.map((faq, index) => (
				<div key={index} className="faq-item">
				  <div
					className={`faq-question ${activeIndex === index ? 'active' : ''}`}
					onClick={() => toggleFAQ(index)}
				  >
					{faq.question}
					<span className={`arrow ${activeIndex === index ? 'rotate' : ''}`}>
					  <i class="bi bi-chevron-down"></i>
					</span>
				  </div>
				  <div className={`faq-answer ${activeIndex === index ? 'open' : ''}`}>
					{faq.answer}
				  </div>
				</div>
			  ))}
		</div>
			
		</div>

		
    </div>
  );
};

export default EmailMarketingPlans;