import React, { useState, useEffect, useRef } from 'react';
import CountrySelect from './../../../components/CountrySelect';
import LanguageSelect from './../../../components/LanguageSelect';
import TimeZoneSelect from './../../../components/TimeZoneSelect';
import TimeFormatSelect from './../../../components/TimeFormatSelect';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const TeamMembers = () => {
  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [AddModalFooterArea, setAddModalFooterArea] = useState('true');
  const [AddModalContentArea, setAddModalContentArea] = useState('true');
  const [AddModalSuccessArea, setAddModalSuccessArea] = useState('false');
  const [Email, setEmail] = useState('');
  const [ErrorEmail, setErrorEmail] = useState('');
  const [ErrorRole, setErrorRole] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const EmailRef = useRef(null);
  
  const [roleselectedValue, setroleselectedValue] = React.useState('');
  const roleselecthandleChange = (event) => {
    setroleselectedValue(event.target.value);
	  
  };
  
  
  const [AddUserShowModal, setAddUserShowModal] = useState(false);
  const handleAddUserShowModal = () => setAddUserShowModal(true);
  const handleAddUserCloseModal = () => setAddUserShowModal(false);
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const AddNewUserModal = () => {
	  
	setErrorEmail("");
    setErrorRole("");
	setEmail("");
	setroleselectedValue("");
    setAddUserShowModal(true);
	setAddModalSuccessArea('false');
	setAddModalContentArea('true');
	setAddModalFooterArea('true');
  };
  
  const handleInviteMember = () => {
		setErrorEmail("");
		setErrorRole("");
		setEmailError(false);
	if (Email.trim() === '') {
		setErrorEmail("Please enter email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}else if (!emailRegex.test(Email)) {
		setErrorEmail("Please enter a valid email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}else if (roleselectedValue.trim() === '') {
		setErrorRole("Please select a role");
		return;
	}
	
	setAddModalSuccessArea('true');
	setAddModalContentArea('false');
	setAddModalFooterArea('false');
	
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  useEffect(() => {
  }, []);

  return (					
			<div className="main-area">
		  
			<div className="secondary-heading-area">
				<div className="row align-items-center">
					<div className="col-9">
						<h4 className="secondary-heading">Team Members</h4>
						<h6 className="secondary-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
					</div>
					<div className="col-3 text-end">
						<button type="button" className="primary-button ms-2" onClick={AddNewUserModal}>Add new Member</button>
					</div>
				</div>
			</div>

			<div className="form-area">
				<div className="row">
					<div className="col-4">
						  <h4 className="secondary-heading">Admin Users <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Admin can add and remove users and manage organiation-level settings</h6>
					</div>
					<div className="col-8">
						<table>
							<thead>
								<tr>
									<th>Name</th>
									<th>Date Added</th>
									<th>Last Active</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="td-first">
										<div className="row align-items-center">
											<div className="col-3">
												<img src="https://m.media-amazon.com/images/M/MV5BMTU2Njk0NTUyNl5BMl5BanBnXkFtZTcwODE0OTgyNw@@._V1_.jpg" className="member-icon" />
											</div>
											<div className="col-9">
												<h5 className="member-name">Blake Lively</h5>
												<h6 className="memeber-email">blake.lively@gmail.com</h6>
											</div>
										</div>			
									</td>
									<td className="td-second">Sep 2, 2024</td>
									<td className="td-third">Sep 10, 2024</td>
									<td><i className="bi bi-trash"></i> <i className="bi bi-pencil"></i></td>
								</tr>
								
								<tr>
									<td className="td-first">
										<div className="row align-items-center">
											<div className="col-3">
												<img src="https://m.media-amazon.com/images/M/MV5BMzg1MzQyYTktN2U3Ni00ZTQ2LThkMjEtODcxY2UyNmM5ZDBhXkEyXkFqcGdeQXVyMTExNzQ3MzAw._V1_.jpg" className="member-icon" />
											</div>
											<div className="col-9">
												<h5 className="member-name">Alicia Viaknder</h5>
												<h6 className="memeber-email">alicia.vikander@gmail.com</h6>
											</div>
										</div>			
									</td>
									<td className="td-second">Sep 2, 2024</td>
									<td className="td-third">Sep 10, 2024</td>
									<td><i className="bi bi-trash"></i> <i className="bi bi-pencil"></i></td>
								</tr>
								
								
								<tr>
									<td className="td-first">
										<div className="row align-items-center">
											<div className="col-3">
												<img src="https://m.media-amazon.com/images/M/MV5BMTU0MzE2NTI1NV5BMl5BanBnXkFtZTcwODcwMzMwOQ@@._V1_.jpg" className="member-icon" />
											</div>
											<div className="col-9">
												<h5 className="member-name">Natalie Dormer</h5>
												<h6 className="memeber-email">natalie.dormer@gmail.com</h6>
											</div>
										</div>			
									</td>
									<td className="td-second">Sep 2, 2024</td>
									<td className="td-third">Sep 10, 2024</td>
									<td><i className="bi bi-trash"></i> <i className="bi bi-pencil"></i></td>
								</tr>
								
								<tr>
									<td className="td-first">
										<div className="row align-items-center">
											<div className="col-3">
												<img src="https://m.media-amazon.com/images/M/MV5BOTA3MzQwNjkxNl5BMl5BanBnXkFtZTgwNzYxMDI4NzE@._V1_.jpg" className="member-icon" />
											</div>
											<div className="col-9">
												<h5 className="member-name">Bryce Dallas Howard</h5>
												<h6 className="memeber-email">bryce.dallas@gmail.com</h6>
											</div>
										</div>			
									</td>
									<td className="td-second">Sep 2, 2024</td>
									<td className="td-third">Sep 10, 2024</td>
									<td><i className="bi bi-trash"></i> <i className="bi bi-pencil"></i></td>
								</tr>
								
								
							</tbody>
						</table>
					</div>
				</div>
			</div>
			
			<div className="form-area">
				<div className="row">
					<div className="col-4">
						  <h4 className="secondary-heading">Account Users <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Admin can add and remove users and manage organiation-level settings</h6>
					</div>
					<div className="col-8">
						<table>
							<thead>
								<tr>
									<th>Name</th>
									<th>Date Added</th>
									<th>Last Active</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="td-first">
										<div className="row align-items-center">
											<div className="col-3">
												<img src="https://m.media-amazon.com/images/M/MV5BMTU2Njk0NTUyNl5BMl5BanBnXkFtZTcwODE0OTgyNw@@._V1_.jpg" className="member-icon" />
											</div>
											<div className="col-9">
												<h5 className="member-name">Blake Lively</h5>
												<h6 className="memeber-email">blake.lively@gmail.com</h6>
											</div>
										</div>			
									</td>
									<td className="td-second">Sep 2, 2024</td>
									<td className="td-third">Sep 10, 2024</td>
									<td><i className="bi bi-trash"></i> <i className="bi bi-pencil"></i></td>
								</tr>
								
								<tr>
									<td className="td-first">
										<div className="row align-items-center">
											<div className="col-3">
												<img src="https://m.media-amazon.com/images/M/MV5BMzg1MzQyYTktN2U3Ni00ZTQ2LThkMjEtODcxY2UyNmM5ZDBhXkEyXkFqcGdeQXVyMTExNzQ3MzAw._V1_.jpg" className="member-icon" />
											</div>
											<div className="col-9">
												<h5 className="member-name">Alicia Viaknder</h5>
												<h6 className="memeber-email">alicia.vikander@gmail.com</h6>
											</div>
										</div>			
									</td>
									<td className="td-second">Sep 2, 2024</td>
									<td className="td-third">Sep 10, 2024</td>
									<td><i className="bi bi-trash"></i> <i className="bi bi-pencil"></i></td>
								</tr>
								
								
								<tr>
									<td className="td-first">
										<div className="row align-items-center">
											<div className="col-3">
												<img src="https://m.media-amazon.com/images/M/MV5BMTU0MzE2NTI1NV5BMl5BanBnXkFtZTcwODcwMzMwOQ@@._V1_.jpg" className="member-icon" />
											</div>
											<div className="col-9">
												<h5 className="member-name">Natalie Dormer</h5>
												<h6 className="memeber-email">natalie.dormer@gmail.com</h6>
											</div>
										</div>			
									</td>
									<td className="td-second">Sep 2, 2024</td>
									<td className="td-third">Sep 10, 2024</td>
									<td><i className="bi bi-trash"></i> <i className="bi bi-pencil"></i></td>
								</tr>
								
								<tr>
									<td className="td-first">
										<div className="row align-items-center">
											<div className="col-3">
												<img src="https://m.media-amazon.com/images/M/MV5BOTA3MzQwNjkxNl5BMl5BanBnXkFtZTgwNzYxMDI4NzE@._V1_.jpg" className="member-icon" />
											</div>
											<div className="col-9">
												<h5 className="member-name">Bryce Dallas Howard</h5>
												<h6 className="memeber-email">bryce.dallas@gmail.com</h6>
											</div>
										</div>			
									</td>
									<td className="td-second">Sep 2, 2024</td>
									<td className="td-third">Sep 10, 2024</td>
									<td><i className="bi bi-trash"></i> <i className="bi bi-pencil"></i></td>
								</tr>
								
								
							</tbody>
						</table>
					</div>
				</div>
			</div>
			
			
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
		  
		  {AddUserShowModal && (
			<>
		   <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleAddUserCloseModal}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Invite a new Member</h5>
						  </div>
						  <div className="col-1">
							  
						  </div>	  
						</div>  
					</div>
					<div className="modal-body pb-4 px-4">
					
					{AddModalSuccessArea === 'true' && (
					<div id="step_three" className="right-content">
					  <img src="iconss/other/approved.png" className="success-icon" alt="Success" />
					  <h2 className="secondary-heading-modal text-center">Invitation Sent</h2>
					  <h2 className="secondary-description-modal text-center">Once memeber accept the invitaion you will be able to manage it in the list</h2>
					  <center><button type="button" className="primary-button float-center" onClick={handleAddUserCloseModal} >Okay</button></center>
					</div>
					)}
					
					{AddModalContentArea === 'true' && (
						
						<div className="col-12">
							
							<div className="row align-items-center">
							<div className="col-12">
								<label>Email Address<b className="text-danger">*</b></label>
								<input type="text" ref={EmailRef} className={`input-forms w-100 ${EmailError ? 'input-error' : ''}`}  value={Email} onChange={(e) => {
									  setEmail(e.target.value); // Call a custom handler with the value
									}} />
									
								{ErrorEmail && <div className="error-message">{ErrorEmail}</div>}
							</div>
							<div className="col-12">
								<label>Role<b className="text-danger">*</b></label>
								
								<Select 
									labelId="role-select-label" 
									id="role-sell-emails-select" 
									className="country-select w-100" 
									value={roleselectedValue} 
									onChange={roleselecthandleChange}
									renderValue={(selected) => (
										// Use a smaller typography or plain text for the selected value in the select box
										<Typography variant="body1">{selected}</Typography>
									)}
								>
									<MenuItem  key="Admin" value="Admin">
									<Box  display="flex" alignItems="center">
										<Typography>
											<h3 style={{ fontSize: '1.2vw', fontWeight: '600' }}>Admin</h3>
											<h6 style={{ fontSize: '1vw', fontWeight: '500', color: 'rgba(100, 100, 100)' }}>This role is ideal for the account owner or a trusted individual managing the entire platform</h6>
										</Typography>
									</Box>
								</MenuItem>
								<MenuItem key="Campaign Manager" value="Campaign Manager">
									<Box display="flex" alignItems="center">
										<Typography>
											<h3 style={{ fontSize: '1.2vw', fontWeight: '600' }}>Campaign Manager</h3>
											<h6 style={{ fontSize: '1vw', fontWeight: '500', color: 'rgba(100, 100, 100)' }}>This role is ideal for the account owner or a trusted individual managing the entire platform</h6>
										</Typography>
									</Box>
								</MenuItem>
									{/* Continue for other roles */}
								</Select>

								{ErrorRole && <div className="error-message">{ErrorRole}</div>}

								
								
							</div>
							</div>
						</div>
					)}	
					
					</div>
					{AddModalFooterArea === 'true' && (
					<div className="modal-footer">
						<button type="button" className="primary-outline-button me-2" onClick={handleAddUserCloseModal} data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="primary-button ms-2" onClick={handleInviteMember} >Invite</button>
					</div>
					)}
				  </div>
				</div>
			  </div>
			  </>
			)}
			
		  </div>
					
  );
};

export default TeamMembers;
