import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/private/settings.css';
import SideBar from './../components/SideBar';
import GeneralSettings from './components/settings/GeneralSettings';
import ProfileSettings from './components/settings/ProfileSettings';
import SecuritySettings from './components/settings/SecuritySettings';
import NotificationSettings from './components/settings/NotificationSettings';
import BusinessDetails from './components/settings/BusinessDetails';
import TeamMembers from './components/settings/TeamMembers';
import MyPlan from './components/settings/MyPlan';
import Payments from './components/settings/Payments';
// Import other settings components as needed

const Settings = () => {
  const [currentTab, setCurrentTab] = useState('general');

  useEffect(() => {
    document.title = 'Settings | Aadow';

    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); // Remove the '#' from the hash
      setCurrentTab(hash || 'general'); // Default to 'general' if the hash is empty
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // Call once to handle the initial load

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <SideBar>
      <div className="settings-content">
        <div className="settings-body">
          <div className="breadcrumb-area">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Settings</li>
              </ol>
            </nav>
          </div>

          <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-12">
                  <h1 className="main-heading">Settings</h1>

                  <div className="settings-tabs-area">
                    <a href="#" className={`settings-tab ${currentTab === 'general' ? 'active' : ''}`}>General</a>
                    <a href="#business-details" className={`settings-tab ${currentTab === 'business-details' ? 'active' : ''}`}>Business Details</a>
                    <a href="#my-plan" className={`settings-tab ${currentTab === 'my-plan' ? 'active' : ''}`}>My Plan</a>
                    <a href="#payments" className={`settings-tab ${currentTab === 'payments' ? 'active' : ''}`}>Payments</a>
                    <a href="#profile" className={`settings-tab ${currentTab === 'profile' ? 'active' : ''}`}>Profile</a>
                    <a href="#security" className={`settings-tab ${currentTab === 'security' ? 'active' : ''}`}>Security</a>
                    <a href="#notifications" className={`d-none settings-tab ${currentTab === 'notifications' ? 'active' : ''}`}>Notifications</a>
                    <a href="#team-members" className={`d-none settings-tab ${currentTab === 'team-members' ? 'active' : ''}`}>Team Members</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Conditional Rendering of the Settings Components */}
          {currentTab === 'general' && <GeneralSettings />}
          {currentTab === 'business-details' && <BusinessDetails />}
          {currentTab === 'my-plan' && <MyPlan />}
          {currentTab === 'profile' && <ProfileSettings />}
          {currentTab === 'security' && <SecuritySettings />}
          {currentTab === 'notifications' && <NotificationSettings />}
          {currentTab === 'team-members' && <TeamMembers />}
          {currentTab === 'payments' && <Payments />}
          {/* Add other settings components conditionally */}

        </div>
      </div>
    </SideBar>
  );
};

export default Settings;
