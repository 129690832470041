import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../../css/authentication/login.css';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from 'js-cookie';

const Login = () => {
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(3);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(new Array(5).fill(''));
  const [seconds, setSeconds] = useState(30);
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Resend Code in 30");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [currentStep, setCurrentStep] = useState('step_one'); // State to manage current step
  const [decodedToken, setDecodedToken] = useState(null); // State to store the decoded token
	
  useEffect(() => {
    
    document.title = 'Login | Aadow';
	  const CookieAccountId = Cookies.get('current_accountId');

    const urlParams = new URLSearchParams(window.location.search);
    const redirectTo = urlParams.get('redirect_to');

    if (CookieAccountId) {
		  if (redirectTo) {
        window.location.href = decodeURIComponent(redirectTo);
      } else {
        // Fallback if no redirect_to parameter exists
        window.location.href = '/dashboard';
      }
	  }



    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds(prevSeconds => {
          const newSeconds = prevSeconds - 1;
          setButtonText(`Resend Code in ${newSeconds}`);
          return newSeconds;
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsDisabled(false);
      setButtonText("Resend Code");
    }
  }, [seconds]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);

    // Focus on the next field
    if (element.nextSibling && value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [OtpSentEmail, setOtpSentEmail] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [loginbuttonDisabled, setLoginButtonDisabled] = useState(false);
  const [loginbuttonText, setLoginButtonText] = useState('Login');
  
  const handleChangeEmail = async () => {
	setCurrentStep('step_one');
	setLoginButtonDisabled(false);
	setLoginButtonText('Login');
  }
  
  
  const handleLogin = async () => {
		setEmailError(false);
		setPasswordError(false);
		setEmailErrorMessage('');
		setPasswordErrorMessage('');
		if (email.trim() === '') {
		  setEmailError(true);
		  setEmailErrorMessage('Please enter an email address');
		  return;
		}else if (!emailRegex.test(email)) {
		  setEmailErrorMessage('Please enter a valid email address.');
		  setEmailError(true);
		  return;
		}
		if (password.trim() === '') {
		  setPasswordError(true);
		  setPasswordErrorMessage('Please enter your password');
		  return;
		}
		setLoginButtonDisabled(true);
		setLoginButtonText('Logging in...');
		/// setCurrentStep('step_two');
		/// setOtpSentEmail(email.replace(/(\w)([\w.-]*)(\w)(@.+)/, (_, first, middle, last, domain) => first + '*'.repeat(middle.length) + last + domain));
		/// showSnackbar('OTP sent successfully!', 'success');
		/// setSeconds(30);
		/// setIsDisabled(true);
		handleLoginServer();
  };
  
const handleLoginServer = async (e) => {

    // Collecting login data
    const loginData = {
        email: email, // Your email input
        password: password, // Your password input
    };

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch(`${ServerApiDomain}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: JSON.stringify(loginData), // Send login data (email and password)
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const data = await response.json();
			if(data.twoFa === "1"){
				sendTwoFaOtp();
			}else{
				
				Cookies.set('current_Id', data.id, { expires: 30 });
				Cookies.set('current_accountId', data.accountId, { expires: 30 });
				Cookies.set('current_accountName', data.name, { expires: 30 });
				Cookies.set('current_accountEmail', data.email, { expires: 30 });
				
				setCurrentStep('step_three');
				const countdown = setInterval(() => {
					  setTimer(prevTimer => {
						if (prevTimer <= 1) {
						  clearInterval(countdown);

              const urlParams = new URLSearchParams(window.location.search);
              const redirectTo = urlParams.get('redirect_to');

						  if (redirectTo) {
                navigate(decodeURIComponent(redirectTo)); // Navigate to the original page
              } else {
                navigate('/dashboard'); // Default redirect if no redirect_to exists
              }

						}
						return prevTimer - 1;
					  });
					}, 1000);
			}
        } else {
            const errorText = await response.text(); // Get error message from server response
            console.error('Login failed:', errorText);
            console.error(`Login failed. Status: ${status}. Message: ${errorText}`); // Include server message
			setLoginButtonDisabled(false);
			setLoginButtonText('Login');
			setPasswordError(true);
		    setPasswordErrorMessage('Incorrect credentials.');
        }
    } catch (error) {
        console.error('Error during login:', error); // Log the entire error object
		
		showSnackbar('An error occurred. Please try again.', 'error');
		setLoginButtonDisabled(false);
		setLoginButtonText('Login');
    }
};


const sendTwoFaOtp = async (e) => {
		const requestData = {
			username: email, // The username input
			type: "2fa",         // The type input (e.g., 'email' or 'sms')
		};

		// Collecting Basic Auth credentials from inputs
		const basicAuthUsername = "testuser"; // This should be set from user input
		const basicAuthPassword = "testpassword"; // This should be set from user input

		// Base64 encode the Basic Auth credentials
		const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

		try {
			const response = await fetch(`${ServerApiDomain}/send-otp`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json', // Sending raw JSON data
					'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
				},
				body: JSON.stringify(requestData), // Send the username and type in JSON format
			});

			const status = response.status; // Get the response status

			if (response.ok) {
				const message = await response.text(); // Get plain text response
				setCurrentStep('step_two');
				setOtpSentEmail(email.replace(/(\w)([\w.-]*)(\w)(@.+)/, (_, first, middle, last, domain) => first + '*'.repeat(middle.length) + last + domain));
				showSnackbar('OTP sent successfully!', 'success');
				setSeconds(30);
				setIsDisabled(true);
			} else {
			
			showSnackbar('An error occurred. Please try again.', 'error');
			setLoginButtonDisabled(false);
			setLoginButtonText('Login');
			}
		} catch (error) {
			console.error('Error during OTP send:', error); // Log the entire error object
			
			showSnackbar('An error occurred. Please try again.', 'error');
			setLoginButtonDisabled(false);
			setLoginButtonText('Login');
		}
}

const handleOtpSubmit = async () => {
	  
    const cleanedOtp = String(otp).replace(/,/g, '');
	const otpLength = cleanedOtp.length;
    const requestData = {
        username: email, // The username input
        type: "2fa",         // The type input (e.g., 'email' or 'sms')
        otp: cleanedOtp,           // The OTP input
    };

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch(`${ServerApiDomain}/auth-otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Sending raw JSON data
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: JSON.stringify(requestData), // Send the username, type, and OTP in JSON format
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const data = await response.json(); // Parse JSON response
            const message = data.message; // Extract 'message' from the JSON response
            console.log('OTP validation message:', message); // Log the message
			handleCreateSession();
        } else {
            // Handle non-OK response
            const errorData = await response.json();
            const errorMessage = errorData.message; // Extract 'message' from the error response
            console.error('Error message:', errorMessage); // Log the error message
            setOtpError(true);
			setOtpErrorMessage("Invalid OTP");
			setotpverifyButtonDisabled(false);
			setotpverifyButtonText("Verify");
        }
    } catch (error) {
        console.error('Error during OTP validation:', error); // Log the entire error object
		
		setotpverifyButtonDisabled(false);
		otpverifybuttonText("Verify");
		showSnackbar('An error occurred. Please try again.', 'error');
		setLoginButtonDisabled(false);
		setotpverifyButtonText('Signup');
    }
};


const handleCreateSession = async () => {
    const sessionData = new URLSearchParams();
    sessionData.append('email', email); // Use 'email' instead of 'Email'

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch(`${ServerApiDomain}/api/session/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // Specify x-www-form-urlencoded
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: sessionData.toString(), // Convert form data to string
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const jsonResponse = await response.json(); // Parse the response as JSON
            console.log('Session creation response:', jsonResponse); // Log the JSON response
            //alert(`Response: ${JSON.stringify(jsonResponse)}`); // Show the full JSON response in an alert
            setCurrentStep("step_three");

            const countdown = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(countdown);
                        const urlParams = new URLSearchParams(window.location.search);
                        const redirectTo = urlParams.get('redirect_to');

                        if (redirectTo) {
                          navigate(decodeURIComponent(redirectTo)); // Navigate to the original page
                        } else {
                          navigate('/dashboard'); // Default redirect if no redirect_to exists
                        }
                    }
                    return prevTimer - 1;
                });
            }, 1000);

            // Show the message in your UI or handle it as needed
            showSnackbar("OTP Verified", 'success'); // Show success message
        } else {
            // Handle non-OK response
            const jsonErrorResponse = await response.json(); // Parse the error response as JSON
            console.error('Error message:', jsonErrorResponse); // Log the error message
            /// alert(`Error: ${JSON.stringify(jsonErrorResponse)}`); // Show the full error response in an alert
            showSnackbar('An error occurred. Please try again.', 'error');
            setotpverifyButtonDisabled(false);
            setotpverifyButtonText('Verify');
        }
    } catch (error) {
        console.error('Error during session creation:', error); // Log the entire error object
        /// alert(`Error: ${error.message}`); // Show error message in an alert
        showSnackbar('An error occurred. Please try again.', 'error');
        setotpverifyButtonDisabled(false);
        setotpverifyButtonText('Verify');
    }
};



  
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [otpError, setOtpError] = useState(false);
  
  const [otpverifybuttonDisabled, setotpverifyButtonDisabled] = useState(false);
  const [otpverifybuttonText, setotpverifyButtonText] = useState('Verify');
  
  const handleVerifyOtp = () => {
    const cleanedOtp = String(otp).replace(/,/g, '');
	const otpLength = cleanedOtp.length;
	
	setOtpError(false);
	setOtpErrorMessage("");
	if (cleanedOtp.trim() === '') {
		setOtpError(true);
		setOtpErrorMessage("Please enter OTP");
		return;
	}else if (otpLength < 5) {
		setOtpError(true);
		setOtpErrorMessage("The OTP must be at least 5 characters long");
		return;
	}
	setotpverifyButtonDisabled(true);
    setotpverifyButtonText('Verifying...');
	handleOtpSubmit();
	
  };

  const handleResendCode = () => {
    // Logic to resend the OTP code
    setSeconds(30);
    setIsDisabled(true);
	sendTwoFaOtp();
  };

  
  const handleGoogleLoginSuccess = (credentialResponse) => {
    const jwt = credentialResponse.credential;
    const decoded = JSON.parse(atob(jwt.split('.')[1])); // Decode the token
    setDecodedToken(decoded); // Store the decoded token in state
    setEmail(decoded.email); // Update email state if needed
    handleCreateGoogleSession(decoded); // Pass the token as an argument
  };

  const handleGoogleLoginFailure = () => {
    console.error('Google Login Failed');
  };

  const handleCreateGoogleSession = async (token) => {
    const sessionData = new URLSearchParams();
    sessionData.append('email', token.email);

    try {
      const response = await fetch(`${ServerApiDomain}/api/session/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: sessionData.toString(),
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        console.log('Session creation response:', jsonResponse);
        setCurrentStep('step_three');
        Cookies.set('current_Id', jsonResponse.id, { expires: 30 });
        Cookies.set('current_accountId', jsonResponse.accountId, { expires: 30 });
        Cookies.set('current_accountName', jsonResponse.name, { expires: 30 });
        Cookies.set('current_accountEmail', jsonResponse.email, { expires: 30 });
        const countdown = setInterval(() => {
          setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
              const urlParams = new URLSearchParams(window.location.search);
              const redirectTo = urlParams.get('redirect_to');

						  if (redirectTo) {
                navigate(decodeURIComponent(redirectTo)); // Navigate to the original page
              } else {
                navigate('/dashboard'); // Default redirect if no redirect_to exists
              }
          }
          return prevTimer - 1;
          });
        }, 1000);
      } else {
        const errorResponse = await response.json();
        console.error('Error message:', errorResponse);
        showSnackbar('No account is associated with this Gmail.', 'error');
      }
    } catch (error) {
      console.error('Error during session creation:', error);
      showSnackbar('An error occurred. Please try again.', 'error');
    }
  };

  const clientId = '216821187656-5o36d8jogtranv334s2bepo0hcmeo6k0.apps.googleusercontent.com';

  return (
    <div className="login-body">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6">
            <div className="left-area">
              <div className="top-section">
                <img className="logo" src="logos/aadow-logo-white.svg" alt="Aadow Logo" />
              </div>
              <div className="middle-section">
                <div><h1 className="main-heading">Welcome Back!</h1></div>
                <div><h6 className="main-description ">Log in to manage your campaigns and transactions effortlessly</h6></div>
                <div><a className="main-link d-none">Get Started</a></div>
              </div>
              <div className="bottom-section">
                <p className="footer-description"><i className="bi bi-c-circle me-1"></i> {currentYear} Copyright: Aadow by Horza Private Limited</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 col-xl-6">
            <div className="right-area">
              {currentStep === 'step_one' && (
                <div id="step_one" className="right-content">
                  <h2 className="secondary-heading">Login to Account</h2>
                  <h2 className="secondary-description mb-4">Get Access to your account and create campaigns</h2>
                  <GoogleOAuthProvider  clientId={clientId}>
                  
                    <GoogleLogin
                    className="social-login"
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginFailure}
                    />
                    <button className="social-login d-none">
                      <img src="iconss/logos/google.jpg" alt="Google Icon" className="social-login-icon" />
                      <span>Sign In with Google</span>
                    </button>
                    </GoogleOAuthProvider>

                  
                  
                  <button className="social-login d-none">
                    <img src="iconss/logos/apple.jpg" alt="Apple Icon" className="social-login-icon" />
                    <span>Sign In with Apple</span>
                  </button>
                  <div className="text-with-lines mt-4">
                    <span>Or</span>
                  </div>
                  <form>
                    <div className="form-area">
                      <div className="col-12">
                        <label>Email Address<b className="text-danger">*</b></label>
                        <input type="email" autocomplete="off" 
                        className={`input-form ${emailError ? 'input-error' : ''}`} 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              handleLogin();  // Trigger the login on Enter press
                          }
                        }}
                        />
						{emailErrorMessage && <div className="error-message">{emailErrorMessage}</div>}
					  </div>
                      <div className="col-12">
                        <label>Password<b className="text-danger">*</b></label>
                        <div className="password-container">
                          <input
						                autocomplete="off"
                            type={passwordVisible ? 'text' : 'password'}
                            className={`input-form ${passwordError ? 'input-error' : ''} pe-5`}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                  handleLogin();  // Trigger the login on Enter press
                              }
                            }}
                          />
                          <i className={`bi ${passwordVisible ? 'bi-eye-slash' : 'bi-eye'} password-icon`} onClick={togglePasswordVisibility}></i>
                        </div>
						            {passwordErrorMessage && <div className="error-message">{passwordErrorMessage}</div>}
                      </div>
                      <div className="col-12">
                        <h6 className="forgot-password-link"><Link to="/account-recovery">Forgot Password</Link></h6>
                      </div>
                      <div className="col-12">
                        <button type="button" disabled={loginbuttonDisabled} className="primary-button w-100" onClick={handleLogin}>{loginbuttonText}</button>
                      </div>
                      <div className="col-12">
                        <p className="login-signup-link">Don't have an account? <Link to="/signup">Sign Up</Link></p>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {currentStep === 'step_two' && (
                <div id="step_two" className="right-content">
                  <h2 className="secondary-heading">Welcome Back</h2>
                  <h2 className="secondary-description">We sent a code to <b>{OtpSentEmail}</b> <a onClick={handleChangeEmail} className="change-email">Edit</a></h2>
				  
                  <form>
                    <div className="form-area">
                      <div className="col-12 otp-container">
                        {otp.map((data, index) => (
                          <input
                            type="tel"
							className={`otp-input ${otpError ? 'otp-error' : ''}`}
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={e => handleOtpChange(e.target, index)}
                            onFocus={e => e.target.select()}
                            onKeyDown={e => handleKeyDown(e, index)}
                          />
                        ))}
                      </div>
					  {otpErrorMessage && <div className="error-message">{otpErrorMessage}</div>}
                      <div className="col-12">
                        <button type="button" disabled={otpverifybuttonDisabled} className="primary-button w-100" onClick={handleVerifyOtp}>{otpverifybuttonText}</button>
                      </div>
                      <div className="col-12">
                        <button type="button" id="resend_code"
                          disabled={isDisabled}
                          className="secondary-button w-100"
                          onClick={handleResendCode}
                        >
                          {buttonText}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {currentStep === 'step_three' && (
                <div id="step_three" className="right-content">
                  <img src="iconss/verify.png" className="success-icon" alt="Success" />
                  <h2 className="secondary-heading text-center">Loggedin Successfully</h2>
                  <h2 className="secondary-description text-center">We're redirecting you to your Dashboard.</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Login;
