import React, { useState, useEffect, useRef, useCallback } from 'react';
import CountrySelect from './../../../components/CountrySelect';
import LanguageSelect from './../../../components/LanguageSelect';
import TimeZoneSelect from './../../../components/TimeZoneSelect';
import TimeFormatSelect from './../../../components/TimeFormatSelect';
import axios from 'axios';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Cookies from 'js-cookie';


const Payments = () => {
    

    
    const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
    
    const API_URL = `${ServerApiDomain}/api/payments/transactions`;
    const AUTH = 'Basic ' + btoa('testuser:testpassword');
	const CookieAccountName = Cookies.get('current_accountName');
	const CookieAccountId = Cookies.get('current_accountId');
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [noData, setNoData] = useState(false);
    const loadedIds = useRef(new Set());

    const fetchData = useCallback(async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        try {
            const response = await fetch(`${API_URL}?username=${CookieAccountId}&page=${page}`, {
                headers: {
                    'Authorization': AUTH
                }
            });
            const result = await response.json();

            if (result.content.length > 0) {
                const newData = result.content.filter(item => !loadedIds.current.has(item.id));
                
                if (newData.length > 0) {
                    setData(prevData => [...prevData, ...newData]);
                    newData.forEach(item => loadedIds.current.add(item.id)); // Mark IDs as loaded
                    setPage(prevPage => prevPage + 1);
                }
            } else {
                setHasMore(false); // No more data available
            }

            // Check if no data was returned initially
            if (page === 0 && result.content.length === 0) {
                setNoData(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [loading, hasMore, page]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const observer = useRef();

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchData();
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, fetchData]);


  return (					
			<div className="main-area">
			<div className="secondary-heading-area">
					<h4 className="secondary-heading">Billing History</h4>
					<h6 className="secondary-subheading">View your transaction history and easily download invoices for your records</h6>
			</div>
			
			
			<div className="table-container">
			  
			   {noData ? (
							<center>
								<div className="empty-error-area">
									<img className="empty-error-image w-25" src="../../iconss/other/no-results.png"  />
									<h2 className="empty-error-heading">No Transactions Found</h2>
									<h6 className="empty-error-subheading" >It looks like there are no transactions associated with your account.</h6>
								</div>
							</center>
				) : (
					<>
						<table className="styled-table">
				<thead>
				  <tr>
					<th>Transaction ID</th>
					<th>Date</th>
					<th>Amount</th>
					<th>Inovice</th>
					<th>Payment Method</th>
					<th>Status</th>
					<th></th>
				  </tr>
				</thead>
				<tbody>
					
					 {data.map((item, index) => {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }).format(new Date(item.date));

    return (
        <tr key={item.id} ref={data.length === index + 1 ? lastElementRef : null}>
            <td>
                <div className="row align-items-center">
                    <div className="col-3">
                        <img src="iconss/other/pdf.png" className="transaction-icon" />
                    </div>
                    <div className="col-9">
                        <h5 className="transaction-id">{item.transactionId}</h5>
                        <p className="invoice-id">{item.invoiceId}</p>
                    </div>
                </div>
            </td>
            <td><h5 className="transaction-date">{formattedDate}</h5></td>
            <td><h5 className="transaction-amount">₹ {item.finalTotal}</h5></td>
            <td><h5 className="transaction-invoice-id">{item.invoiceId}</h5></td>
            <td><h5 className="transaction-method">{item.payMode}</h5></td>
            <td><span className={`${item.status}-status text-capitalize`}>{item.status}</span></td>
            <td>
                {item.invoice ? (
                    <a href={item.invoice} download className="download-invoice">
                        <i className="bi bi-download"></i>
                    </a>
                ) : (
                    <span></span>
                )}
            </td>
        </tr>
    );
})}
				</tbody>
			  </table>
					</>
				)}
			  {loading && <p>Loading more data...</p>}
			</div>	
			
				
			
			</div>
					
  );
};

export default Payments;
