import React from 'react';

const AccountIdChecker = () => {
  const currentYear = new Date().getFullYear();
  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"; // Set cookie path to root
  };

  const getCookieValue = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    
    return null; // Cookie not found
  };

  const handleLogin = () => {
    // Assuming you've got the account ID from your login logic
    const accountId = "123456";
    setCookie("account_id", accountId, 7); // Set cookie for 7 days

    // Get the cookie value to show what was set
    const cookieValue = getCookieValue("account_id");
    alert(`Account ID cookie set! Value: ${cookieValue}`);
  };

  return (
    <div>
      <h1>Account ID</h1>
      <button type="button" onClick={handleLogin}>Login</button> {/* Corrected here */}
    </div>
  );
};

export default AccountIdChecker;
