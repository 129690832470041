import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Select, MenuItem, InputBase } from '@mui/material';
import '../../css/private/campaign-overview.css';
import SideBar from './../components/SideBar';
import myImage from './bar.png';
// Import other settings components as needed

const CampaignOverview = () => {
	
 const [activeTab, setActiveTab] = useState('#'); // Set default to '#'
const { CampaignId } = useParams();
  const handleTabClick = (hash) => {
    setActiveTab(hash);
    window.location.hash = hash; // Update URL hash
  };

  useEffect(() => {
	  
    document.title = 'SMS Campaign | Aadow';
    const currentHash = window.location.hash;
    // If no hash in the URL, default to Overview
    if (!currentHash) {
      setActiveTab('#');
      window.location.hash = '#';
    } else {
      setActiveTab(currentHash);
    }
  }, []);
  
  return (
    <SideBar>
      <div className="campaign-overview-content">
        <div className="campaign-overview-body">
			  <div className="breadcrumb-area">
				<nav aria-label="breadcrumb">
				  <ol className="breadcrumb">
					<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
					<li className="breadcrumb-item"><Link to="/campaigns/sms">SMS Campaigns</Link></li>
					<li className="breadcrumb-item active" aria-current="page">{CampaignId}</li>
				  </ol>
				</nav>
			  </div>
			  
		  <div className="top-area">
			  <div className="row align-items-center">
				<div className="col-6">
					<h2 className="campaign-heading">{CampaignId} <span className="campaign-status scheduled-status"><i className="bi bi-dot"></i> Scheduled</span></h2>
				</div>
				<div className="col-6 text-end">
					<button type="button" className="secondary-button me-3">Duplicate Campaign</button>
					<button type="button" className="primary-button">Download Report</button>
				</div>
			  </div>
          </div>
		  
		 <div className="tab-area">
      <a
        href="#"
        onClick={() => handleTabClick('#')}
        className={activeTab === '#' ? 'active' : ''}
      >
        Overview
      </a>
      <a
        href="#AbTest"
        onClick={() => handleTabClick('#AbTest')}
        className={activeTab === '#AbTest' ? 'active' : ''}
      >
        A/B Test
      </a>
      <a
        href="#Delivered"
        onClick={() => handleTabClick('#Delivered')}
        className={activeTab === '#Delivered' ? 'active' : ''}
      >
        Delivered
      </a>
      <a
        href="#Opened"
        onClick={() => handleTabClick('#Opened')}
        className={activeTab === '#Opened' ? 'active' : ''}
      >
        Opened
      </a>
      <a
        href="#Clicked"
        onClick={() => handleTabClick('#Clicked')}
        className={activeTab === '#Clicked' ? 'active' : ''}
      >
        Clicked
      </a>
      <a
        href="#Unsubscribed"
        onClick={() => handleTabClick('#Unsubscribed')}
        className={activeTab === '#Unsubscribed' ? 'active' : ''}
      >
        Unsubscribed
      </a>
      <a
        href="#NonDelivered"
        onClick={() => handleTabClick('#NonDelivered')}
        className={activeTab === '#NonDelivered' ? 'active' : ''}
      >
        Non-Delivered
      </a>
    </div>

		  
		   {activeTab === '#AbTest' && (
		   <div className="tab-content-area">
			<div className="top-table-area">
			  <div className="row align-items-center">
				<div className="col-10 pb-2">
				  <h6 className="top-table-heading"> Summary of your test</h6>
				</div>
				<div className="col pb-2">
					<Select className="input-form" disableUnderline fullWidth defaultValue="10" input={<InputBase />}>
						<MenuItem value={10}>Numbers</MenuItem>
						<MenuItem value={10}>Percentage</MenuItem>
					</Select>
				</div>
			  </div>
			  
			  <div className="table-area py-4 px-0 border-top border-1 ">
          <div className="row">
		  
			<div className="col-8 ">
				<h6  className="ab-metric-heading fw-bold mb-4">Rules for splitting and selecting the winner</h6>
				<div className="row px-2 my-4">
				
				<div className="col border border-1 p-4 text-center">
				<h6 className="ab-metric-col-heading">50%</h6>
				<h5 className="ab-metric-col-label">Version A</h5>
				<p className="d-none">(Approx.  recipients)</p>
				</div>
				
				
				<div className="col border border-1 p-4 text-center">
				<h6 className="ab-metric-col-heading">50%</h6>
				<h5 className="ab-metric-col-label">Version B</h5>
				<p className="d-none">(Approx.  recipients)</p>
				</div>
				
				
				
				
				</div>
			</div>
			
		  
			<div className="col-12"></div>
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Openers</h6>
				<div className="version-a">
				<div className="version-metric-heading winner_text">Version A <b className="float-end"><span  className="percentage-ab-metric">20%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div Style="Width:50%" className="actual_progress progress_winner_bg"></div></div>
				
				<div className="version-metric-heading ">Version B <b className="float-end"><span className="percentage-ab-metric">15%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg "><div  className="actual_progress "></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Clicks</h6>
				<div className="version-a">
				<div className="version-metric-heading winner_text">Version A <b className="float-end"><span className="percentage-ab-metric">70%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress progress_winner_bg"></div></div>
				
				<div className="version-metric-heading ">Version B <b className="float-end"><span className="percentage-ab-metric">35%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg "><div className="actual_progress "></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Unsubscribed</h6>
				<div className="version-a">
				<div className="version-metric-heading ">Version A <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress "></div></div>
				
				<div className="version-metric-heading ">Version B <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg "><div  className="actual_progress "></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Hard Bounced</h6>
				<div className="version-a">
				<div className="version-metric-heading">Version A <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				
				<div className="version-metric-heading">Version B <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Soft Bounced</h6>
				<div className="version-a">
				<div className="version-metric-heading">Version A <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				
				<div className="version-metric-heading">Version B <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Complained</h6>
				<div className="version-a">
				<div className="version-metric-heading">Version A <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				
				<div className="version-metric-heading">Version B <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				</div>
			</div>
			
			
			
			
		  </div>
        </div>
			  
			</div>
		   </div>
		   )}
		  
		  {activeTab === '#Delivered' && (
		  <div className="tab-content-area">
			<div className="table-area">
		  <table>
							<thead>
								<tr>
									<th>To</th>
									<th>Date & Time</th>
								</tr>
							</thead>
							<tbody>
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">mkulkarni@polygenta.com</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">Sep 11, 2024 01:30 pm</h6>
									</td>
								</tr>
								
								
							</tbody>
						</table>
						
						<div className="pagination-area">
							<div className="row">
								<div className="col-2">
									<button type="button"><i className="bi bi-arrow-left me-2"></i> Previous</button>
								</div>
								<div className="col-8 text-center pagination-numbers">
									<button className="">1</button>
									<button className="">2</button>
									<button className="">3</button>
									<button className="">.....</button>
									<button className="active">4</button>
									<button className="">5</button>
									<button className="">6</button>
								</div>
								<div className="col-2 text-end"> 
									<button type="button"> Next <i className="bi bi-arrow-right ms-2"></i></button>
								</div>
							</div>
						</div>
						
						</div>
		  </div>
		  )}
		  
		  {activeTab === '#' && (
		  <div className="tab-content-area">
		  <div className="metrics-col-area">
			<div className="row">
				<div className="col-3">
					<div className="metrics-col">
						<div className="metrics-col-head-area">
							<h2 className="metrics-col-label">Delivered</h2>
							<h6 className="metrics-col-heading">4,482 <span>(98.6%)</span></h6>
						</div>
						<img src={myImage} />
						
					</div>
				</div>
				
				<div className="col-3">
					<div className="metrics-col">
						<div className="metrics-col-head-area">
							<h2 className="metrics-col-label">Clicked</h2>
							<h6 className="metrics-col-heading text-success">1,218 <span>(23.87%)</span></h6>
						</div>
						<img src={myImage} />
						
					</div>
				</div>
				
				
			</div>
		  </div>
		  
		  
		  <div className="basic-info-area">
		  <div className="row align-items-center">
			<div className="col-7">
				
				<div className="info-area">
					<h6 className="info-label">Sent on</h6>
					<h6 className="info">Wed Sep 11 2024 at 11:34 AM to 4080 contacts</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">Subject</h6>
					<h6 className="info">Exclusive Machinery Deals!</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">Contact List</h6>
					<h6 className="info">List 1,Himanshu List,Cleaned-List-Sanjay-1</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">Preview Text</h6>
					<h6 className="info">Don’t miss out on this unique opportunity to upgrade your equipment</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">From</h6>
					<h6 className="info">Himanshu Garg himanshu.gargwazir.in</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">Reply to</h6>
					<h6 className="info">himanshu.gargwazir.in</h6>
				</div>
				
				
			</div>
			
			<div className="col-5">
				<img src="https://app.aadow.com/email-thumbnails/469534-email-thumbnail.png" className="email-preview" />
			</div>
			
		  </div>
		  </div>
		  <div className="table-area">
		  <table>
							<thead>
								<tr>
									<th>List</th>
									<th>Delivered</th>
									<th>Clicked</th>
									<th>Non-Delivered</th>
								</tr>
							</thead>
							<tbody>
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">List 1,Himanshu List,Cleaned-List-Sanjay-1</h6>
									<h6 className="memeber-email">4080 contacts</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">45.49%</h6>
									<h6 className="memeber-email">2049</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">24.12%</h6>
									<h6 className="memeber-email">1031</h6>
									</td>
									
									<td className="td-first">
									<h6 className="domain-email-name">6.3%</h6>
									<h6 className="memeber-email">234</h6>
									</td>
									
								</tr>
								
								
							</tbody>
						</table>
						</div>
		  
      </div>
	  
		  )}
      </div>
      </div>
	  <br/><br/><br/><br/><br/><br/>
    </SideBar>
  );
};

export default CampaignOverview;