import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/public/faq.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';
import { format } from 'date-fns';

const FAQ = () => {
	
  
  useEffect(() => {
    document.title = 'FAQs | Aadow';
	  }, []);
	
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const { BlogTitle } = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [searchBar, setSearchBar] = useState(''); // Initialize with URL param if present
	
  
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from doing a full page reload
    navigate(`/faqs/${searchBar}`); // Navigate to the new URL with the search input
  };
  
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const blogId = BlogTitle.split('-').pop();
    const username = 'testuser';
    const password = 'testpassword';
    const encodedCredentials = btoa(`${username}:${password}`);

    const fetchArticle = async () => {
      try {
        const response = await fetch(`${ServerApiDomain}/api/Faq-feed/article/${blogId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setArticle(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [BlogTitle]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Ensure article is not null before accessing its properties
  if (!article) {
    return <div>No article found</div>;
  }

  const formattedDate = format(new Date(article.date), 'MMM dd, yyyy');

  return (
    <div class="faq-area">
	<TopBar />
	  <div class="top-area">
      <div class="container-search">
        <h1>Frequently Asked Questions</h1>
        <h4>Advice and answers from the Customer Success Team</h4>
			<form onSubmit={handleSubmit}>
              <input
                type="text"
                name="search"
                autoComplete="off"
                id="search"
                value={searchBar} // Controlled input
                onChange={(e) => setSearchBar(e.target.value)} // Update state when typing
                className="search"
                placeholder="Search for FAQs...."
                required
              />
              <button type="submit" className="d-none">Search</button> {/* You can add a search button */}
            </form>
      </div>
    </div>
    <div class="result-area"> 

					
					<nav aria-label="breadcrumb">
					  <ol class="breadcrumb">
						<li class="breadcrumb-item"><a class="text-dark" href="/">Home</a></li>
						<li class="breadcrumb-item"><Link to="/faqs" class="text-dark">FAQs</Link></li>
						<li class="breadcrumb-item active" aria-current="page">{article.title}</li>
					  </ol>
					</nav>
					
					 
					<div class="faq-container shadow-sm">
					<div class="row pb-0 align-items-center">
                      <div class="col-12">
                        <h6 class="faq-date d-inline"><i class="bi bi-calendar-date"></i> {formattedDate}</h6>
                        <h6 class="author-name d-inline"><i class="bi bi-person"></i> {article.author}</h6>
                      </div>
                    </div>
					
					<h1 class="faq-title"> {article.title}</h1>
					<div class="faq-start-area mb-2 pt-0"> 
						<div dangerouslySetInnerHTML={{ __html: article.content }} />
					</div>
					</div>
					<div class="comment-section shadow-sm d-none">
						<div id="disqus_thread"></div><noscript>Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a></noscript>
						<script id="dsq-count-scr" src="//aadow-com.disqus.com/count.js" async></script>
					</div>
					
					<div id="contact_desktop" class="contact-support-area shadow-sm bg-white mt-4 mb-4">
				<div class="row align-items-center">
					<div class="col-1">
						<img src="https://app.aadow.com/images/support-executive-avatar.webp" alt="Contact Aadow" title="Contact Aadow" class="support-avatar" />
					</div>
					<div class="col-8 pt-2">
						<h4 class="contact-support-heading">Still Have Questions?</h4>
						<h4 class="contact-support-subheading">Can't find the answer you're looking for? Chat to our friendly Team.</h4>
					</div>
					<div class="col-3">
						<a href="contact" className="contact-support-button text-decoration-none float-end shadow-sm">Get in touch</a>
					</div>
				</div>
			</div>
			
			<div id="contact_mobile" class="contact-support-area shadow-sm text-center bg-white  mt-4 mb-4">
				<div class="row align-items-center">
					<div class="col-12">
						<img src="https://app.aadow.com/images/support-executive-avatar.webp" alt="Contact Aadow" title="Contact Aadow" class="support-avatar" />
					</div>
					<div class="col-12 pt-2">
						<h4 class="contact-support-heading">Still Have Questions?</h4>
						<h4 class="contact-support-subheading">Can't find the answer you're looking for? Chat to our friendly Team.</h4>
					</div>
					<div class="col-12">
						
						<a href="contact" className="contact-support-button text-decoration-none shadow-sm">Get in touch</a>
					</div>
				</div>
			</div>
	
    </div>
	<Footer />
    </div>
  );
};

export default FAQ;