import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import CountrySelect from './../../../components/CountrySelect';
import LanguageSelect from './../../../components/LanguageSelect';
import TimeZoneSelect from './../../../components/TimeZoneSelect';
import TimeFormatSelect from './../../../components/TimeFormatSelect';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import Cookies from 'js-cookie';

const MyPlan = () => {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState('email-marketing');
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountId = Cookies.get('current_accountId');
  const CurrentPlanName = Cookies.get('CurrentPlanName');
  const [PlanName, setPlanName] = useState("------");
  const [TotalMonthlyEmails, setTotalMonthlyEmails] = useState("------");
  const [TotalTransactionalEmails, setTotalTransactionalEmails] = useState("------");
  const [UsedMonthlyEmails, setUsedMonthlyEmails] = useState("------");
  const [UsedTransactionalEmails, setUsedTransactionalEmails] = useState("------");
  const [RenewalDate, setRenewalDate] = useState("------");
  const [RenewalDateTransactional, setRenewalDateTransactional] = useState("------");
  const [UsageResetDate, setUsageResetDate] = useState("------");
  const [UsageResetDateTransactional, setUsageResetDateTransactional] = useState("------");
  const [PlanId, setPlanId] = useState("------");
  const [CombinedUsedCredits, setCombinedUsedCredits] = useState("------");
  const [CombinedTotalCredits, setCombinedTotalCredits] = useState("------");
  const [TotalmarketingPercentage, setTotalmarketingPercentage] = useState("0");
  const [TotalapiPercentage, setTotalapiPercentage] = useState("0");
  
  useEffect(() => {
  const current_username = CookieAccountId;
  const username = 'testuser';
  const password = 'testpassword';
  const encodedCredentials = btoa(`${username}:${password}`);
  
  const fetchMyPlanInfo = async () => {
			try {
			  const response = await fetch(`${ServerApiDomain}/api/myplan/username/${current_username}`, {
				method: 'GET',
				headers: {
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }

			  
			  const data = await response.json();

			// Access the first item in the array
			if (data.length > 0) {
			  setPlanName(data[0].planName);

			  if(data[0].planName !== null && data[0].planName !== ''){
				setCurrentTab('email-marketing');
			  }else if(data[0].totalTransactionalCredits !== null && data[0].totalTransactionalCredits !== ''){
				setCurrentTab('email-transactional');
			  }

			  setTotalMonthlyEmails(data[0].totalEmails.toLocaleString());
			  setTotalTransactionalEmails(data[0].totalTransactionalCredits);
			  setRenewalDate(format(new Date(data[0].expirationDate), 'MMM dd, yyyy'));
			  setRenewalDateTransactional(format(new Date(data[0].expirationDateTransactional), 'MMM dd, yyyy'));
			  setUsedMonthlyEmails(data[0].usedEmails.toLocaleString());
			  
			  if(data[0].usedTransactionalCredits !== null){
			  setUsedTransactionalEmails(data[0].usedTransactionalCredits.toLocaleString());
			  }else{
			  setUsedTransactionalEmails(0);
			  }

			  setUsageResetDate(format(new Date(data[0].resetData), 'MMM dd, yyyy'));
			  setUsageResetDateTransactional(format(new Date(data[0].resetDataTransactional), 'MMM dd, yyyy'));

			  const UsedtransactionalCredits = Number(data[0].usedTransactionalCredits);
			  const UsedTotalEmail = Number(data[0].usedEmails);
			  const CombinedTotalUsedEmails = UsedtransactionalCredits + UsedTotalEmail;
			  setCombinedUsedCredits(CombinedTotalUsedEmails.toLocaleString());
			  
			  
			  const TotaltransactionalCredits = Number(data[0].totalTransactionalCredits);
			  const TotalEmail = Number(data[0].totalEmails);
			  const CombinedTotalEmails = TotaltransactionalCredits + TotalEmail;
			  setCombinedTotalCredits(CombinedTotalEmails.toLocaleString());
			  
			  const marketingUsed = 5000;
			  const apiUsed = 2500;

			  // Calculate the percentages
			  const marketingPercentage = (UsedTotalEmail / CombinedTotalEmails) * 100;
			  const apiPercentage = (UsedtransactionalCredits / CombinedTotalEmails) * 100;
			  setTotalapiPercentage(apiPercentage);
			  setTotalmarketingPercentage(marketingPercentage);
			  
			} else {
			  alert("No data found.");
			}
			  setLoading(false);  // Set loading to false once data is fetched
			} catch (error) {
			  setError("Fetch error:", error);
			  setLoading(false);
			}
		};
	  fetchMyPlanInfo();
	  
	  
  }, []);
  
  
   
   
   if (loading) {
	  return <div>Loading...</div>; // Display loading message
   }

   if (error) {
   }

  return (					
			<div className="main-area">
			<div className="secondary-heading-area">
					<h4 className="secondary-heading">Plan Details</h4>
					<h6 className="secondary-subheading">Here you manage and view your account usage, plan and more</h6>
			</div>
			
			<div className="row">
				<div className="col-9">
					<div className="main-usage-area">
						
						
						
						
						<div className="usage-metric-area mt-0">
							<div className="row mb-4">
								<div className="col-6">
									<label className="plan-usage-label">Plan Usage</label>
								</div>
								<div className="col-6 text-end">
									<Link to="/pricing" className="primary-button text-decoration-none ms-2">Change Plan</Link>
								</div>
							</div>
							
							
							<div className="row">
								<div className="col-6">
									<label className="secondary-plan-usage-label">Orders</label>
								</div>
								<div className="col-6">
									<label className="secondary-plan-usage-label text-end"><span className="credit-used-out-of">{CombinedUsedCredits} of {CombinedTotalCredits} credits used</span></label>
								</div>
							</div>
							
							<div className="plan-usage-meter">
								<span style={{ width: `${TotalmarketingPercentage}%` }} className="marketing-usage"></span>
								<span style={{ width: `${TotalapiPercentage}%` }} className="api-usage"></span>
							</div>
							
							<div className="meter-examplain-area">
								<h6 className="meter-examplain-label"><i className="bi bi-dot icon-marketing-usage"></i> <b>{UsedMonthlyEmails}</b> Marketing Credits</h6>
								<h6 className="meter-examplain-label"><i className="bi bi-dot icon-api-usage"></i> <b>{UsedTransactionalEmails}</b> API Credits</h6>
							</div>
							
							
						</div>
						
							<div className="row align-items-center">
								<div className="col-12">

								<div className="settings-tabs-area">
									
								{PlanName !== null && PlanName !== "" && (
								<a
									style={{ cursor: 'pointer' }}
									className={`settings-tab ${currentTab === 'email-marketing' ? 'active' : ''}`}
									onClick={() => setCurrentTab('email-marketing')}
								>
									Email Marketing Plan
								</a>
								)}
								{TotalTransactionalEmails !== null && TotalTransactionalEmails !== "" && (
									<a
										style={{ cursor: 'pointer' }}
										className={`settings-tab ${currentTab === 'email-transactional' ? 'active' : ''}`}
										onClick={() => setCurrentTab('email-transactional')}
									>
										Email Transactional Plan
									</a>
								)}
								</div>
								</div>
							</div>

							<div style={{ borderBottom: '1.5px solid rgb(226, 226, 226)', margin: '15px 0 30px 0px' }}></div>


							<div className="tab-content">
							{currentTab === 'email-marketing' && (
								<>
									<div className="main-usage-top-area">
									{PlanName !== null && PlanName !== "" && (
										<div className="row">
											<div className="col-3">
												<h4 className="primary-label-my-plan">Marketing Plan</h4>
												<h4 className="current-plan-label-my-plan">{PlanName}</h4>
											</div>
											<div className="col-3">
												<h4 className="primary-label-my-plan">Marketing Emails</h4>
												{(CurrentPlanName === 'Basic Plan' || CurrentPlanName === 'Basic') ? (
												<h4 className="current-plan-label-my-plan">{TotalMonthlyEmails} Emails/day</h4>
												) : (
												<h4 className="current-plan-label-my-plan">{TotalMonthlyEmails} Emails/mo</h4>
												)}
											</div>

											
											<div className="col-3">
												<h4 className="primary-label-my-plan">Usage Reset</h4>
												<h4 className="current-plan-label-my-plan">{UsageResetDate}</h4>
											</div>
											
											<div className="col-3">
												<h4 className="primary-label-my-plan">Renewal Date</h4>
												<h4 className="current-plan-label-my-plan">{RenewalDate}</h4>
											</div>
											
											
										</div>
										)}
										
									</div>

									
									<div style={{ borderBottom: '1.5px solid rgb(226, 226, 226)', margin: '15px 0 30px 0px' }}></div>
									
									<div className="feature-area">
										<h6 className="feature-area-label">Plan Benefits: </h6>
										
											<div className="row">
											
											
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points">{PlanName === 'Basic' || PlanName === 'Basic Plan' || PlanName === 'Pro' || PlanName === 'Ultimate' || PlanName === 'Pro Plan' || PlanName === 'Ultimate Plan' ? (<i className="bi bi-check-circle-fill"></i>) : ( <i className="bi bi-check-circle-fill text-secondary"></i> )} {TotalMonthlyEmails} Marketing Credits</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points">{PlanName === 'Basic' || PlanName === 'Basic Plan' || PlanName === 'Pro' || PlanName === 'Ultimate' || PlanName === 'Pro Plan' || PlanName === 'Ultimate Plan' ? (<i className="bi bi-check-circle-fill"></i>) : ( <i className="bi bi-check-circle-fill text-secondary"></i> )} Drag & Drop Email Editor</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points">{PlanName === 'Basic' || PlanName === 'Basic Plan' || PlanName === 'Pro' || PlanName === 'Ultimate' || PlanName === 'Pro Plan' || PlanName === 'Ultimate Plan' ? (<i className="bi bi-check-circle-fill"></i>) : ( <i className="bi bi-check-circle-fill text-secondary"></i> )} Unlimited Contacts</h6>
											</div>
											
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points">{PlanName === 'Pro' || PlanName === 'Ultimate' || PlanName === 'Pro Plan' || PlanName === 'Ultimate Plan' ? (<i className="bi bi-check-circle-fill"></i>) : ( <i className="bi bi-check-circle-fill text-secondary"></i> )} Bulk List Upload</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points">{PlanName === 'Pro' || PlanName === 'Ultimate' || PlanName === 'Pro Plan' || PlanName === 'Ultimate Plan' ? (<i className="bi bi-check-circle-fill"></i>) : ( <i className="bi bi-check-circle-fill text-secondary"></i> )} No Daily Limits</h6>
											</div>
											
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points">{PlanName === 'Ultimate' || PlanName === 'Ultimate Plan' ? (<i className="bi bi-check-circle-fill"></i>) : ( <i className="bi bi-check-circle-fill text-secondary"></i> )} AI Integration</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points">{PlanName === 'Ultimate' || PlanName === 'Ultimate Plan' ? (<i className="bi bi-check-circle-fill"></i>) : ( <i className="bi bi-check-circle-fill text-secondary"></i> )} AMP Email</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points">{PlanName === 'Ultimate' || PlanName === 'Ultimate Plan' ? (<i className="bi bi-check-circle-fill"></i>) : ( <i className="bi bi-check-circle-fill text-secondary"></i> )} 3 Sector Email</h6>
											</div>
											
										</div>
										
									</div>
								
								</>
							)}
							{currentTab === 'email-transactional' && (
								<>
								<div className="main-usage-top-area">
									{TotalTransactionalEmails !== null && TotalTransactionalEmails !== "" && (
											<div className="row mt-4">
												<div className="col-3">
													<h4 className="primary-label-my-plan">Transactional Plan</h4>
													<h4 className="current-plan-label-my-plan">Premium</h4>
												</div>
												<div className="col-3">
													<h4 className="primary-label-my-plan">Transactional Emails</h4>
													
													<h4 className="current-plan-label-my-plan">{TotalTransactionalEmails} Emails/mo</h4>
												</div>
												
												
												<div className="col-3">
													<h4 className="primary-label-my-plan">Renewal Date</h4>
													<h4 className="current-plan-label-my-plan">{RenewalDateTransactional}</h4>
												</div>
											</div>
									)}
								</div>

								
								<div style={{ borderBottom: '1.5px solid rgb(226, 226, 226)', margin: '15px 0 30px 0px' }}></div>
								
								<div className="feature-area">
									<h6 className="feature-area-label">Plan Benefits: </h6>
									
										<div className="row">
										{TotalTransactionalEmails !== null && TotalTransactionalEmails !== "" && (
											<>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points"><i className="bi bi-check-circle-fill"></i> {TotalTransactionalEmails} Transactional Credits</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points"><i className="bi bi-check-circle-fill"></i> RESTful APIs</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points"><i className="bi bi-check-circle-fill"></i> Dynamic templates</h6>
											</div>
											
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points"><i className="bi bi-check-circle-fill"></i> Unlimited log retention</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points"><i className="bi bi-check-circle-fill"></i> Email support</h6>
											</div>
											<div className="col-4">
												<h6 style={{ fontSize: '16px' }} className="feature-points"><i className="bi bi-check-circle-fill"></i> Upto 10 APIs</h6>
											</div>
											</>
										)}
										
										
										
									</div>
									
								</div>
							
							</>
							)}
							</div>	

						
						
					</div>
				</div>
				
				<div className="col-3 d-none">
					<div className="payments-area">
						<div className="payments-top-area">
							<h4 className="payments-heading">Payments</h4>
						</div>
						
						<div className="transaction-list">
							<div className="payment-list">
								<h4 className="payment-list-heading">Subscription for Ultimate Plan</h4>
								<h4 className="payment-list-subheading">March 22, 2021</h4>
								<a className="invoice-link">View Inovice</a>
							</div>
							
							<div className="payment-list">
								<h4 className="payment-list-heading">Subscription for Ultimate Plan</h4>
								<h4 className="payment-list-subheading">March 22, 2021</h4>
								<a className="invoice-link">View Inovice</a>
							</div>
							
							
							<div className="payment-list">
								<h4 className="payment-list-heading">Subscription for Ultimate Plan</h4>
								<h4 className="payment-list-subheading">March 22, 2021</h4>
								<a className="invoice-link">View Inovice</a>
							</div>
							
							
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			</div>
					
  );
};

export default MyPlan;
