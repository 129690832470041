import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/public/job.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';

const Job = () => {
  useEffect(() => {
    document.title = 'Job | Aadow';
	  }, []);
 

  return (
    <div class="job-area">
	<TopBar />
		<div class="top-area bg-light p-5 ps-3 pe-3">
		  <div class="p-5 ps-3 pe-3 pb-1 pt-1">
		  <div class="row align-items-center">
		  <div class="col-12 col-md-12 col-lg-8 col-xl-8">
		  <div class="row align-items-center">
			<div class="col-12 col-md-12 col-lg-3 col-xl-3 "><img class="job-icon rounded shadow-sm ms-2 me-2" src="https://media.licdn.com/dms/image/D560BAQERoeJoPyRZ5A/company-logo_200_200/0/1693733141214/aadow_logo?e=2147483647&v=beta&t=K5c8ZmQNelN4LtOym8Gc9ajlOSfaDWQNxRCiV6qbMaQ"/></div>
			<div class="col-12 col-md-12 col-lg-9 col-xl-9 ">
			<h3 class="fw-bold job-title">REACT.JS Web Developer</h3>
			<h6 class="d-none job-info-bulletes text-secondary align-items-center me-4"><i  class="bi bi-lock"></i><b class="fw-normal"> Technology</b></h6>
			<h6 class="d-none job-info-bulletes text-secondary align-items-center me-4"><i  class="fi-rr-marker"></i><b class="fw-normal"> Bangalore</b></h6>
			<h6 class="d-none job-info-bulletes text-secondary align-items-center me-4"><i  class="fi-rr-money"></i><b class="fw-normal"> Salary</b></h6>
			<div class="pt-3 pb-3">
			<span class="fw-normal job-category rounded-pill ">Full-Time</span>
			<span class="fw-normal bg-warning text-dark job-needed  rounded-pill">Bangalore, Karnataka</span>
			<span class="fw-normal bg-success text-light job-needed rounded-pill">Technology</span>
			</div>
			</div>
			</div>
		  </div>
		  <div class="col-12 col-md-12 col-lg-4 col-xl-4 float-center align-center text-center">
		  <h6 class="d-none">Application ends: <b class="text-danger">23 July 2024</b></h6>
		  <a type="button" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="mt-2 text-decoration-none text-white apply-button rounded shadow-sm">Apply Now</a>
		  </div>
		  <div class="col-4">
		  </div>
		  </div>
		  </div>
		  </div>
			
			
			<div class="main-content ">
			<div class="row">
			<div class="col-12 col-md-12 col-lg-8 col-xl-8">
			<div class="job-description">
			<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
			<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
			<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
			</div>
			<div class="mt-5 mb-5">
			<h6 class="d-inline share-title me-3">Share this Job: </h6>
			<a class="facebook text-white text-decoration-none ps-4 pe-4" data-toggle="tooltip" data-original-title="Facebook" href="https://www.facebook.com/sharer/sharer.php?u=https://app.aadow.com/job.php?id=">
						<i class="bi bi-facebook me-1"></i>
						Facebook			</a>
						<a class="twitter text-white text-decoration-none ps-4 pe-4" data-toggle="tooltip" data-original-title="Facebook" href="https://twitter.com/share?url=https://app.aadow.com/job.php?id=" target="_blank" title="Share on Twitter">
						<i class="bi bi-twitter-x me-1"></i>
						 X (Twitter)			</a>
						
						<a class="linkedin text-white text-decoration-none ps-4 pe-4" data-toggle="tooltip" data-original-title="Facebook" href="https://www.linkedin.com/shareArticle?url=https://app.aadow.com/job.php?id=" target="_blank" title="Share on LinkedIn">
						<i class="bi bi-linkedin me-1"></i>
						LinkedIn			</a>
		 
									
			</div>
			</div>
			
			<div class="col-12 col-md-12 col-lg-4 col-xl-4">
			<div class="ms-5 d-none p-4 mb-4 bg-light rounded">
			<h4 class="fs-5">Job Details</h4>
			<div class="row pt-3 mb-3 align-items-center">
			<div class="col-2">
			<i class="fi-rr-calendar fs-3"></i>
			</div>
			<div class="col-10">
			<h5 class="right-title">Date Posted</h5>
			<h6 class="text-secondary right-subtitle">23 July 2024</h6>
			</div>
			</div>
			<div class="row pt-3 mb-3 align-items-center">
			<div class="col-2">
			<i class="fi-rr-marker fs-3"></i>
			</div>
			<div class="col-10">
			<h5 class="right-title">Location</h5>
			<h6 class="text-secondary right-subtitle">Bangalore, Karnataka</h6>
			</div>
			</div>
			<div class="row pt-3 mb-3 align-items-center">
			<div class="col-2">
			<i class="fi-rr-money fs-3"></i>
			</div>
			<div class="col-10">
			<h5 class="right-title">Offered Salary:</h5>
			<h6 class="text-secondary right-subtitle">25 Lakh</h6>
			</div>
			</div>
			<div class="row pt-3 mb-3 align-items-center">
			<div class="col-2">
			<i class="fi-rr-user-time fs-3"></i>
			</div>
			<div class="col-10">
			<h5 class="right-title">Experience:</h5>
			<h6 class="text-secondary right-subtitle">3 Year(s)</h6>
			</div>
			</div>
			<div class="row pt-3 mb-3 align-items-center">
			<div class="col-2">
			<i class="fi-rr-graduation-cap fs-3"></i>
			</div>
			<div class="col-10">
			<h5 class="right-title">Qualification:</h5>
			<h6 class="text-secondary right-subtitle">Bachelors</h6>
			</div>
			</div>
			</div>
			
			<div class="extra-job-info-area">
			<h4 class="extra-job-info-area-heading">Posted By</h4>
			
			<div class="row pt-3 mb-3 align-items-center">
			<div class="col-3">
			<img src="https://www.aadow.com/logo/aadow-logo-full.svg" class="poster-icon rounded-circle shadow-sm"/>
			</div>
			<div class="col-9">
			<h5 class="right-title ">Aadow</h5>
			<h6 class="text-secondary right-subtitle">by HORZA PVT LTD</h6>
			</div>
			</div>
			<div class="right-info-area mt-4 d-none">
			
			<div class="row mb-3 align-items-center">
			<div class="col-4">
			<h5 class="right-title">Location:</h5>
			</div>
			<div class="col-8">
			<h5 class="right-title text-end text-secondary">Bangalore, Karnataka</h5>
			</div>
			</div>
			
			<div class="row mb-3 align-items-center">
			<div class="col-6">
			<h5 class="right-title">Email:</h5>
			</div>
			<div class="col-6">
			<h5 class="right-title text-end text-secondary">hr@horza.in</h5>
			</div>
			</div>
			
			
			</div>
			</div>
			</div>
			</div>
			</div>
			<Footer />
	</div>
  );
};

export default Job;
