import React, { useState, useEffect, useRef } from 'react';
import CountrySelect from './../../../components/CountrySelect';
import LanguageSelect from './../../../components/LanguageSelect';
import TimeZoneSelect from './../../../components/TimeZoneSelect';
import TimeFormatSelect from './../../../components/TimeFormatSelect';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Cookies from 'js-cookie';

import Backdrop from "@mui/material/Backdrop";


const GeneralSettings = () => {
  
    const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieId = Cookies.get('current_Id');
  const [FullPageloading, setFullPageloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [NameSaveArea, setNameSaveArea] = useState('');
  const [EmailOtpArea, setEmailOtpArea] = useState('');
  const [PhoneOtpArea, setPhoneOtpArea] = useState('');
  
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [Otp, setOtp] = useState('');
  
  
  const [NameError, setNameError] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [PhoneError, setPhoneError] = useState(false);
  const [OtpError, setOtpError] = useState(false);
  
  const [ErrorName, setErrorName] = useState('');
  const [ErrorEmail, setErrorEmail] = useState('');
  const [ErrorPhone, setErrorPhone] = useState('');
  const [ModalTitle, setModalTitle] = useState('');
  const [ErrorOtp, setErrorOtp] = useState('');
  
  const [OtpSentTo, setOtpSentTo] = useState('');
  
  const NameRef = useRef(null);
  const EmailRef = useRef(null);
  const PhoneRef = useRef(null);
  const OtpRef = useRef(null);
	
  const handleNameChange = (value) => {
	  setNameSaveArea("true");
  };	
  
  const handleEmailChange = (value) => {
	  setEmailOtpArea("true");
  };	
  
  const handlePhoneChange = (value) => {
	  setPhoneOtpArea("true");
  };	
  
  
  const handleCancelName = async () => {
	  setNameSaveArea("false");
  };
  const handleCancelEmail = async () => {
	  setEmailOtpArea("false");
  };
  const handleCancelPhone = async () => {
	  setPhoneOtpArea("false");
  };
  const handleOtpChange = async () => {
  };
  
  
  const handleSaveName = async () => {
		setErrorName("");
		setNameError(false);
	if (Name.trim() === '') {
		setErrorName("Please enter name");
		setNameError(true);
		NameRef.current.focus();
		return;
	}
	SaveName();
  };
  
  const handleSendEmailOtp = async () => {
	    setErrorEmail("");
		setEmailError(false);
	if (Email.trim() === '') {
		setErrorEmail("Please enter email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}else if (!emailRegex.test(Email)) {
		setErrorEmail("Please enter a valid email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}
	
	
	sendEmailOtp();
	
  };
  
  const handleValidateOtp = async () => {
	    setErrorOtp("");
		setOtpError(false);
	if (Otp.trim() === '') {
		setErrorOtp("Please enter an OTP");
		setOtpError(true);
		OtpRef.current.focus();
		return;
	}
	
	ValidateOtp();
	
  };
  
  const handleSendPhoneOtp = async () => {
	    setErrorPhone("");
		setPhoneError(false);
	if (Phone.trim() === '') {
		setErrorPhone("Please enter a phone number");
		setPhoneError(true);
		PhoneRef.current.focus();
		return;
	}
	
	setFullPageloading(true);
	setFullPageloading(false);
	
	setModalTitle("Verify Phone Number");
	setOtpSentTo(Phone);
	setShowModal(true)
	setOtp("");
  };
  
  useEffect(() => {
	  fetchProfileSettings();
  }, []);
  
  
  const current_username = CookieAccountId;
  const account_id = CookieId;
  const username = 'testuser';
  const password = 'testpassword';
  const encodedCredentials = btoa(`${username}:${password}`);

  const sendEmailOtp = async () => {
	setFullPageloading(true);
		const requestData = {
			username: Email,
			type: "email",
		};
        try {
            const response = await fetch(`${ServerApiDomain}/send-otp`, {
                method: 'POST',
                headers: {
				  'Content-Type': 'application/json', // Sending raw JSON data
				  'Authorization': `Basic ${encodedCredentials}`,
                },
				body: JSON.stringify(requestData),
            });
			
            if (response.ok) {
				setOtpSentTo(Email.replace(/(\w)([\w.-]*)(\w)(@.+)/, (_, first, middle, last, domain) => first + '*'.repeat(middle.length) + last + domain));
				setShowModal(true)
				setModalTitle("Verify Email Address");
				setOtp("");
				setFullPageloading(false);
            } else {
                console.error('Failed to send OTP');
				setFullPageloading(false);
            }
        } catch (error) {
            console.error('Error:', error);
			setFullPageloading(false);
        }
    };
	
	
	const ValidateOtp = async () => {
		setFullPageloading(true);
		const requestData = {
			username: Email,
			type: "email",
			otp: Otp,
		};
        try {
            const response = await fetch(`${ServerApiDomain}/auth-otp`, {
                method: 'POST',
                headers: {
				  'Content-Type': 'application/json', // Sending raw JSON data
				  'Authorization': `Basic ${encodedCredentials}`,
                },
				body: JSON.stringify(requestData),
            });
			
            if (response.ok) {
				//////setShowModal(false)
				//////setEmailOtpArea("false")
				//////setPhoneOtpArea("false")
				//////showSnackbar('Profile Info Saved', 'success');
				updateEmailAddress();
				
            } else {
				setErrorOtp("Invalid OTP");
				setOtpError(true);
				OtpRef.current.focus();
				setFullPageloading(false);
            }
        } catch (error) {
            console.error('Error:', error);
			setFullPageloading(false);
        }
    };
	
	const updateEmailAddress = async () => {
		const requestData = {
			email: Email,
		};
        try {
            const response = await fetch(`${ServerApiDomain}/api/profiles/update/${account_id}`, {
                method: 'PUT',
                headers: {
				  'Content-Type': 'application/json', // Sending raw JSON data
				  'Authorization': `Basic ${encodedCredentials}`,
                },
				body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const newApi = await response.json(); 
				setShowModal(false)
				setEmailOtpArea("false")
				setPhoneOtpArea("false")
				showSnackbar('Profile Info Saved', 'success');
				
				setFullPageloading(false);
            } else {
				
				setFullPageloading(false);
                console.error('Failed to add the API');
            }
        } catch (error) {
			
			setFullPageloading(false);
            console.error('Error:', error);
        }
    };
	
	const SaveName = async () => {
		
		setFullPageloading(true);
		const requestData = {
			name: Name,
		};
        try {
            const response = await fetch(`${ServerApiDomain}/api/profiles/update/${account_id}`, {
                method: 'PUT',
                headers: {
				  'Content-Type': 'application/json', // Sending raw JSON data
				  'Authorization': `Basic ${encodedCredentials}`,
                },
				body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const newApi = await response.json(); 
				
				setNameSaveArea("false");
				showSnackbar('Profile Info Saved', 'success');
				
				setFullPageloading(false);
            } else {
                console.error('Failed to add the API');
				setFullPageloading(false);
            }
        } catch (error) {
            console.error('Error:', error);
			setFullPageloading(false);
        }
    };
  
  const fetchProfileSettings = async () => {
			try {
			  const response = await fetch(`${ServerApiDomain}/api/profiles/${account_id}`, {
				method: 'GET',
				headers: {
				  'Authorization': `Basic ${encodedCredentials}`,
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }

			
			  const data = await response.json();
			  setName(data.name);
			  setEmail(data.email);
			  setPhone(data.phone);
			  setLoading(false);  // Set loading to false once data is fetched
			  
			} catch (error) {
			  setError("Fetch error:", error);
			  setLoading(false);
			}
   };
   
   if (loading) {
	  return <div>Loading...</div>; // Display loading message
   }

   if (error) {
   }
  
  return (					
			<div className="main-area">
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="secondary-heading-area">
					<h4 className="secondary-heading">Profile Settings</h4>
					<h6 className="secondary-subheading">Manage your profile by updating your name, phone, and email</h6>
			</div>

			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Name <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter your display name</h6>
					</div>
					<div className="col-5">
					
							<input type="text" ref={NameRef} className={`input-forms-settings w-75 ${NameError ? 'input-error' : ''}`} value={Name} onChange={(e) => {
								  setName(e.target.value);
								  handleNameChange(e.target.value); // Call a custom handler with the value
								}} placeHolder="Name" />
								{ErrorName && <div className="error-message">{ErrorName}</div>}
					</div>
					{NameSaveArea === 'true' && (
					<div className="col-3 text-end">
						<button type="button" onClick={handleCancelName} className="primary-outline-button-settings me-2">Cancel</button>
						<button type="button" onClick={handleSaveName} className="primary-button-settings ms-2">Save Changes</button>
					</div>
					)}
				</div>
			</div>
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Email Address <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter your email address</h6>
					</div>
					<div className="col-5">
							<input type="text" ref={EmailRef} className={`input-forms-settings w-75 ${EmailError ? 'input-error' : ''}`} value={Email} onChange={(e) => {
								  setEmail(e.target.value);
								  handleEmailChange(e.target.value); // Call a custom handler with the value
								}} placeHolder="Email Address" />
								{ErrorEmail && <div className="error-message">{ErrorEmail}</div>}
					</div>
					{EmailOtpArea === 'true' && (
					<div className="col-3 text-end">
						<button type="button" onClick={handleCancelEmail} className="primary-outline-button-settings me-2">Cancel</button>
						<button type="button" onClick={handleSendEmailOtp} className="primary-button-settings ms-2">Send OTP</button>
					</div>
					)}
				</div>
			</div>
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Phone Number <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter your phone number</h6>
					</div>
					<div className="col-5">
								<input type="tel" ref={PhoneRef} className={`input-forms-settings w-75 ${PhoneError ? 'input-error' : ''}`} value={Phone} onChange={(e) => {
								  setPhone(e.target.value);
								  handlePhoneChange(e.target.value); // Call a custom handler with the value
								}} placeHolder="Phone Number" />
								{ErrorPhone && <div className="error-message">{ErrorPhone}</div>}
					</div>
					{PhoneOtpArea === 'true' && (
					<div className="col-3 text-end">
						<button type="button" onClick={handleCancelPhone} className="primary-outline-button-settings me-2">Cancel</button>
						<button type="button" onClick={handleSendPhoneOtp} className="primary-button-settings ms-2">Send OTP</button>
					</div>
					)}
				</div>
			</div>

			
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
		  {showModal && (
			<>
		   <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleCloseModal}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">{ModalTitle}</h5>
						  </div>
						  <div className="col-1">
							  
						  </div>	  
						</div>  
					</div>
					<div className="modal-body py-4 px-4">
						
						
						<div className="col-12">
							<p className="otp-disclaimer">We've sent an otp on <b>{OtpSentTo}</b></p>
							
							<div className="row align-items-center">
							<div className="col-12">
							<input type="tel" ref={OtpRef} className={`input-forms-settings w-100 ${OtpError ? 'input-error' : ''}`} value={Otp} onChange={(e) => {
								  setOtp(e.target.value);
								  handleOtpChange(e.target.value); // Call a custom handler with the value
								}} placeHolder="Enter OTP"  />
								{ErrorOtp && <div className="error-message">{ErrorOtp}</div>}
							</div>
							</div>
						</div>
						
					</div>
					<div className="modal-footer">
						<button type="button" className="primary-outline-button-settings me-2" onClick={handleCloseModal} data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="primary-button-settings ms-2" onClick={handleValidateOtp}>Validate</button>
					</div>
					
				  </div>
				</div>
			  </div>
			  </>
			)}
		  
		  </div>
					
  );
};

export default GeneralSettings;
