import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/public/about.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const About = () => {


  useEffect(() => {
    document.title = 'About Aadow - Innovating Email Marketing for Smarter Campaigns';
	  }, []);
 

  return (
  <>
    <HelmetProvider>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>About Aadow - Innovating Email Marketing for Smarter Campaigns</title>
        <meta name="title" content="About Aadow: Innovating Email Marketing for Smarter Campaigns" />
        <meta
          name="description"
          content="Discover Aadow's mission to revolutionize email marketing with cutting-edge solutions. Learn about our journey, values, and dedication to empowering businesses with smarter email campaigns."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.aadow.com/about-us" />
        <meta property="og:title" content="About Aadow - Innovating Email Marketing for Smarter Campaigns" />
        <meta
          property="og:description"
          content="Discover Aadow's mission to revolutionize email marketing with cutting-edge solutions. Learn about our journey, values, and dedication to empowering businesses with smarter email campaigns."
        />
        <meta property="og:image" content="https://app.aadow.com/images/about-us-banner-2.webp" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.aadow.com/about-us" />
        <meta name="twitter:title" content="About Aadow - Innovating Email Marketing for Smarter Campaigns" />
        <meta
          name="twitter:description"
          content="Discover Aadow's mission to revolutionize email marketing with cutting-edge solutions. Learn about our journey, values, and dedication to empowering businesses with smarter email campaigns."
        />
        <meta name="twitter:image" content="https://app.aadow.com/images/about-us-banner-2.webp" />
      </Helmet>
    </HelmetProvider>
  <div class="about-body">
	  <TopBar />
      <div class="top-content">
        <div class="row ">
          <div class="col-12 col-md-12 col-lg-6 col-xl-6">
            <h1 class="heading">Building Brands, Amplifying Messages: <span>Aadow Unplugged</span></h1>
          </div>
          <div class="col-12 col-md-12 col-lg-6 col-xl-6 text-align-bottom">
            <p class="description ">We harness the latest trends and technologies to craft captivating experiences that Inspire, Engage, and Ultimately Empower brands to reach new heights.</p>
          </div>
        </div>
      </div>
	  <div class="main-banner mt-3"></div>
      <div class="med-content">
        <div class="row mb-5">
          <div class="col-12 col-md-12 col-lg-6 col-xl-6">
            <h2 class="heading">Unleashing the Power of Aadow!</h2>
          </div>
          <div class="col-12 col-md-12 col-lg-6 col-xl-6">
            <h4 class="subheading">Unlock the full potential of your Marketing Initiatives, Experience the difference of our Comprehensive Solutions.</h4>
            <p class="description">Founded in 2021, Aadow has swiftly emerged as a leading provider of Email Marketing Solutions, Media planning, and Media Buying Services. We believe in a holistic approach to Marketing and Media. Our team combines deep industry knowledge with cutting-edge technology to develop tailored strategies for each client. By leveraging Data-Driven Insights, Market Trends, and Creative Ingenuity, we craft compelling campaigns that generate tangible results.At Aadow, we specialize in email marketing services that go beyond just sending emails. Our platform empowers businesses to create personalized, engaging campaigns that captivate audiences and drive conversions. With advanced automation, segmentation, and analytics, we help businesses harness the full potential of email marketing.</p>
          </div>
        </div>
        <div class="row align-items-center mb-5 mt-5">
          <div class="col-12 col-md-12 col-lg-6 col-xl-6 ">
            <div class="founder-image-container">
              <img class="founder-image" alt="Ashwin Pachori" title="Ashwin Pachori" src="https://app.aadow.com/images/ceo-avatar-2.webp" />
              <div class="overlay shadow-sm">
                <h4 class="founder-name">Ashwin Pachori</h4>
                <h6 class="founder-post">Co-Foudner & CEO of Aadow.com</h6>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-6 col-xl-6">
            <h4 class="subheading-large">"With a relentless pursuit of Innovation, Strategic Thinking, and a keen eye for Emerging Trends, we are your partner in navigating the dynamic world of Advertising."</h4>
          </div>
        </div>
      </div>
      <div class="job-content">
        <div class="job-content-area">
          <div class="row mt-5 mb-5">
            <div class="col-12 col-md-12 col-lg-6 col-xl-6">
              <h2 class="heading">Join the League of Advertising Innovators</h2>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-6">
              <p class="description ">At Aadow, we believe in attracting the brightest minds who possess skills that surpass our own. We seek Game-Changers, Boundary-Pushers, and Visionaries who are eager to shape the future of Advertising. If you're ready to ignite innovation, challenge conventions, and make a lasting impact, explore our job opportunities today. Let's change the world together.</p>
			  
			  <Link to="/careers?ref=about-us-open-positions" class="link">
				Explore Opportunities <i class="bi bi-chevron-right"></i>
			  </Link>
            
			</div>
          </div>
        </div>
      </div>
      <div class="contact-content">
        <div class="row align-items-center">
          <div class="col-12 col-md-12 col-lg-5 col-xl-5">
            <h2 class="heading">Curiosity Sparked? Our team is happy to assist you</h2>
            <p class="description">Ask Us Anything and Let's Find the Answers Together!</p>
            <div class="contact-button">
				<Link to="/contact?ref=about-us" class="button text-decoration-none text-dark">Drop Us a Line</Link>
			</div>
          </div>
          <div class="col-12 col-md-12 col-lg-2 col-xl-2"></div>
          <div class="col-12 col-md-12 col-lg-5 col-xl-5 contact-right-area">
            <div class="contact-image-container">
              <img class="contact-image shadow-sm" alt="Contact Aadow" title="Contact Aadow" src="https://app.aadow.com/images/about-us--contact-banner.webp" />
              <div class="overlay d-none"></div>
              <div class="overlay-top"></div>
            </div>
          </div>
        </div>
      </div>
	  <Footer/>
	</div>
  </>
  );
};

export default About;
