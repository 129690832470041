import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import 'nprogress/nprogress.css'; // Import NProgress CSS here

import ScrollToTop from './ScrollToTop';

import Login from './pages/authentication/Login';
import Signup from './pages/authentication/Signup';
import AccountRecovery from './pages/authentication/AccountRecovery';
import CompleteSetup from './pages/authentication/CompleteSetup';
import AccountIdChecker from './pages/authentication/AccountIdChecker';

import Logout from './pages/components/Logout';
import SetManuallySession from './pages/SetManuallySession';
import Contacts from './pages/private/Contacts';
import RazorpayCheckout from './pages/private/RazorpayCheckout';
import Dashboard from './pages/private/Dashboard';
import Checkout from './pages/private/Checkout';
import Settings from './pages/private/Settings';
import EmailCampaigns from './pages/private/EmailCampaigns';
import EmailCampaignOverview from './pages/private/EmailCampaignOverview';
import SmsCampaignOverview from './pages/private/SmsCampaignOverview';
import SmsCampaigns from './pages/private/SmsCampaigns';
import AudienceOverview from './pages/private/AudienceOverview';
import ImportContactsCsvUpload from './pages/private/ImportContactsCsvUpload';
import ImportContactsCopyPaste from './pages/private/ImportContactsCopyPaste';
import TemplateLibrary from './pages/private/TemplateLibrary';
import EmailTemplates from './pages/private/EmailTemplates';
import SenderAuthentication from './pages/private/SenderAuthentication';
import DomainAuthentication from './pages/private/DomainAuthentication';
import EmailApi from './pages/private/EmailApi';
import SmsApi from './pages/private/SmsApi';
import CreateEmailCampaign from './pages/private/CreateEmailCampaign';
import CreateEmailTemplateCodeEditor from './pages/private/CreateEmailTemplateCodeEditor';
import CreateEmailTemplateTextEditor from './pages/private/CreateEmailTemplateTextEditor';
import CreateEmailTemplateDragDropEditor from './pages/private/CreateEmailTemplateDragDropEditor';
import PreviewEmailTemplate from './pages/private/PreviewEmailTemplate';
import SelectPlan from './pages/public/SelectPlan';

import Home from './pages/public/Home';
import NavBar from './pages/public/NavBar';
import Pricing from './pages/public/Pricing';
import About from './pages/public/About';
import Contact from './pages/public/Contact';
import Blog from './pages/public/Blog';
import Blogs from './pages/public/Blogs';
import FAQ from './pages/public/FAQ';
import FAQs from './pages/public/FAQs';
import Careers from './pages/public/Careers';
import Job from './pages/public/Job';
import Terms from './pages/public/Terms';
import PrivacyPolicy from './pages/public/PrivacyPolicy';
import RefundPolicy from './pages/public/RefundPolicy';
import ImageSelect from './pages/public/ImageSelect';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <UTMTracker />
      <Routes>
        {/* Authentication Pages Start */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/account-recovery" element={<AccountRecovery />} />
        <Route path="/complete-setup" element={<CompleteSetup />} />
        <Route path="/session" element={<AccountIdChecker />} />
        <Route path="/set-manually-session" element={<SetManuallySession />} />
        {/* Authentication Pages End */}
        
        {/* Private Pages Start */}
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/razorpay-checkout" element={<RazorpayCheckout />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/campaigns/email" element={<EmailCampaigns />} />
        <Route path="/campaigns/email/:CampaignId" element={<EmailCampaignOverview />} />
        <Route path="/campaigns/sms/:CampaignId" element={<SmsCampaignOverview />} />
        <Route path="/sender-authentication" element={<SenderAuthentication />} />
        <Route path="/sender-authentication/:Domain" element={<DomainAuthentication />} />
        <Route path="/campaigns/sms" element={<SmsCampaigns />} />
        <Route path="/audience-overview" element={<AudienceOverview />} />
        <Route path="/import-contacts/csv-upload" element={<ImportContactsCsvUpload />} />
        <Route path="/import-contacts/copy-paste" element={<ImportContactsCopyPaste />} />
        <Route path="/email-templates/template-library" element={<TemplateLibrary />} />
        <Route path="/email-templates/template-library/:Search" element={<TemplateLibrary />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/api/email" element={<EmailApi />} />
        <Route path="/api/sms" element={<SmsApi />} />
        <Route path="/create/email-campaign/:CampaignId" element={<CreateEmailCampaign />} />
        <Route path="/create/email-template/code-editor/:TemplateId" element={<CreateEmailTemplateCodeEditor />} />
        <Route path="/create/email-template/text-editor/:TemplateId" element={<CreateEmailTemplateTextEditor />} />
        <Route path="/create/email-template/drag-drop/:TemplateId" element={<CreateEmailTemplateDragDropEditor />} />
        <Route path="/select-plan" element={<SelectPlan />} />
        <Route path="/preview/email-template/:TemplateId" element={<PreviewEmailTemplate />} />
        {/* Private Pages End */}
        
        {/* Public Pages Start */}
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:BlogTitle" element={<Blog />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:search" element={<Blogs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/faq/:BlogTitle" element={<FAQ />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/faqs/:search" element={<FAQs />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:search" element={<Careers />} />
        <Route path="/job/:JobTitle" element={<Job />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/nav-bar" element={<NavBar />} />
        {/* Public Pages End */}
      </Routes>
    </Router>
  );
}

function UTMTracker() {
  const location = useLocation();
  const [ip, setIp] = useState('');

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        Cookies.set('current_ip', data.ip, { expires: 7 });
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
    const searchParams = new URLSearchParams(location.search);
    const utm = searchParams.get('utm');
    const source = searchParams.get('source');
    if (utm) {
      Cookies.set('current_utm', utm, { expires: 7 });
      Cookies.set('current_source', source, { expires: 7 });
    }
  }, [location.search]);

  return null;
}

export default App;
