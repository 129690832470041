import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import '../../css/public/blog.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';
import { format } from 'date-fns';

const Blog = () => {
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const { BlogTitle } = useParams();
  const currentUrl = window.location.href;
  const [currentBlogId, setCurrentBlogId] = useState("");
  const [currentBlogCategory, setCurrentBlogCategory] = useState("");
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const excludeBlogId = '4'; // Update with the actual blog ID you want to exclude
  const [blogs, setBlogs] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  useEffect(() => {
    const blogId = BlogTitle.split('-').pop();
    setCurrentBlogId(blogId);
    const username = 'testuser';
    const password = 'testpassword';
    const encodedCredentials = btoa(`${username}:${password}`);

    const fetchArticle = async () => {
      try {
        const response = await fetch(`${ServerApiDomain}/api/blogs-feed/article/${blogId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Basic ${encodedCredentials}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Set the article first
        setArticle(data);
        // Now set the category
        setCurrentBlogCategory(data.category); // Use data.category instead of article.category
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [BlogTitle]);


   const handleCopyLink = () => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Use the Clipboard API to copy the URL
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
		showSnackbar('Link copied to clipboard!', 'success');
      })
      .catch(err => {
        alert('Failed to copy: ', err);
      });
  };
   
   
  useEffect(() => {
    // Only fetch blogs if currentBlogCategory is set
    if (currentBlogCategory) {
      const fetchBlogs = async () => {
        try {
          const response = await axios.get(`${ServerApiDomain}/api/blogs-feed/search?query=${currentBlogCategory}`, {
            auth: {
              username: 'testuser',  // Replace with your username
              password: 'testpassword'   // Replace with your password
            }
          });
          setBlogs(response.data); // Assuming the response is an array of blogs
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchBlogs();
    }
  }, [currentBlogCategory]); // Dependency added to trigger when currentBlogCategory changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Ensure article is not null before accessing its properties
  if (!article) {
    return <div>No article found</div>;
  }

  const formattedDate = format(new Date(article.datetime), 'MMM dd, yyyy');
  const filteredBlogs = blogs.filter(blog => blog.id !== excludeBlogId);
  
  const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  
  return (
    <div>
      <TopBar />
      <div className="blog-area">
        <div className="bg-light p-5">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-9 col-xl-9 ">
              <div className="blog-area">
                {/* Ensure article is fully loaded before accessing properties */}
                <img src={article.poster} alt="" title="" className="blog-poster shadow-sm" />
                <div className="blog-content-area">
                  <div className="blog-content shadow-sm">
                    <div className="row">
                      <div class="col-12 col-md-12 col-lg-1 col-xl-1 desk-social-media">
						<div class="share-link-area">
						<a 
						  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`} 
						  className="sharer-links" 
						  target="_blank" 
						  rel="noopener noreferrer"
						>
						  <i className="bi bi-facebook"></i>
						</a>
						<a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`} href="#" class="sharer-links" target="_blank"><i class="bi bi-twitter-x"></i></a>
						<a href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentUrl)}`} target="_blank" class="sharer-links"><i class="bi bi-linkedin"></i></a>
						<a href={`https://web.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`} target="_blank" class="sharer-links"><i class="bi bi-whatsapp" data-action="share/whatsapp/share"></i></a>
						<a 
						  id="copy-link" 
						  className="sharer-links" 
						  onClick={handleCopyLink}
						  style={{ cursor: 'pointer' }} // Optional: Change cursor to pointer
						>
						  <i className="bi bi-link"></i>
						</a>
						</div>
					</div>	
                      <div className="col-12 col-md-12 col-lg-11 col-xl-11 ps-4">
                        <div className="row align-items-center">
                          <div className="col-12 col-md-12 col-lg-8 col-xl-8">
                            <h6 className="blog-date d-inline-block"><i className="bi bi-calendar-date"></i> {formattedDate}</h6>
                            <h6 className="author-name d-inline-block"><i className="bi bi-person"></i> {article.author}</h6>
                            <h6 className="blog-category mob-cat"><span> {article.category}</span></h6>
                          </div>
                          <div className="col-4 desk-cat">
                            <h6 className="blog-category d-inline float-end"><span> {article.category}</span></h6>
                          </div>
                        </div>
                        <h1 className="blog-title">{article.title}</h1>
                        <div className="blog-start-area mt-2 mb-2 pt-2">
                          <div dangerouslySetInnerHTML={{ __html: article.blog }} />
                        </div>
                        <div className="blog-author-area">
                          <div className="row align-items-center">
                            <div className="col-3">
                              <img src="https://www.aadow.com/icons/user-avatar.png" alt="" title="" className="auth-image-logo shadow-sm" />
                            </div>
                            <div className="col-9 pt-2">
                              <h4 className="auth-name"> {article.author}</h4>
                              <h4 className="auth-bio d-none">@ Aadow</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="comment-section shadow-sm">
                    <div id="disqus_thread"></div><noscript>Please enable JavaScript to view the <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a></noscript>
                    <script id="dsq-count-scr" src="//aadow-com.disqus.com/count.js" async></script>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-3 col-xl-3 popular-post-area">
              <h5 className="mb-4 popular-post">Popular Posts</h5>
              
			  
			  {filteredBlogs.slice(0, 4).map(blog => ( 
				  <div className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex">
                    <div className="related-blog shadow-sm">
                      <Link to={`/blog/${blog.title.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase()}-${blog.id}`} className="text-decoration-none">
                        <img src={blog.poster} alt="" title="" className="related-blog-poster" />
                        <div className="related-blog-content-area">
                          <h6 className="related-blog-heading">{blog.title}</h6>
                          <h6 className="related-blog-subheading">{blog.preview}</h6>
                          <div className="pt-2 pb-2">
                            <h6 className="relative-blog-date d-inline">{formatDateTime(blog.datetime)} by</h6>
                            <h6 className="relative-blog-author d-inline"> {blog.author}</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
				))}
			  
			  
			  
            </div>
          </div>
        </div>
        <div className="blog-footer-area"></div>
      </div>
	  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Blog;
