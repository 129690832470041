import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../../css/public/pricing.css';
import Cookies from 'js-cookie';
import { Select, MenuItem, FormControl, InputLabel, InputBase, Box, Typography } from '@mui/material';
import axios from 'axios';


const EmailMarketingPlans = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const [selectedPlanIdPro, setselectedPlanIdPro] = useState("PRO001");

  const [selectedPlanIdUltimate, setselectedPlanIdUltimate] = useState("ULT001");

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  
  
  const CookieAccountId = Cookies.get('current_accountId');
  const CurrentPlanId = Cookies.get('CurrentPlanId');
  

  const SelectProPlan = async () => {
	Cookies.set('selected_plan_id', selectedPlanIdPro, { expires: 1 });
	Cookies.set('selected_plan_type', "EmailMarketing", { expires: 1 });
	const CookieSelectedPlanId = Cookies.get('selected_plan_id');
	window.location.href = `/checkout`;
  };
  
  const SelectUltimatePlan = async () => {
	Cookies.set('selected_plan_id', selectedPlanIdUltimate, { expires: 1 });
	Cookies.set('selected_plan_type', "EmailMarketing", { expires: 1 });
	const CookieSelectedPlanId = Cookies.get('selected_plan_id');
	window.location.href = `/checkout`;
  };

  useEffect(() => {
	if(CurrentPlanId === "BASIC00")	{
		setisBasicButtonDisabled(true);
	}else if(CurrentPlanId === "PRO001")	{
		setisProButtonDisabled(true);
	}else if(CurrentPlanId === "ULT001")	{
		setisUltimateButtonDisabled(true);
	}
	
	if (CurrentPlanId !== "" && CurrentPlanId !== "null" && CurrentPlanId !== undefined) {
		setisBasicButtonDisabled(true);
	}
	

  }, []);


  const faqs = [
    {
      question: "Are there any setup or hidden fees?",
      answer: "No, Aadow has transparent pricing with no hidden charges or setup fees."
    },
    {
      question: "Do I need to sign a long-term contract?",
      answer: "No, Aadow offers flexible monthly and annual subscriptions without long-term commitments."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept major credit/debit cards and online payment systems for your convenience."
    },
    {
      question: "Are there discounts for annual subscriptions?",
      answer: "Yes, annual plans come with exclusive discounts compared to monthly billing."
    },
    {
      question: "How do I switch to a different currency for payments?",
      answer: "You can choose your preferred currency during checkout, depending on your region."
    },
    {
      question: "Will I be charged automatically at the end of my billing cycle?",
      answer: "No, subscriptions are not automatically renewed."
    },
    {
      question: "Does Aadow offer nonprofit or educational discounts?",
      answer: "Yes, we have special pricing for nonprofits and educational institutions. Reach out to us for eligibility details."
    },
    {
      question: "Are there charges for team member accounts?",
      answer: "Team member access is included in specific plans. Additional charges may apply for extra users depending on your plan."
    },
    {
      question: "Does Aadow offer custom pricing for large businesses or high-volume senders?",
      answer: "Yes, Aadow provides tailored pricing plans for enterprises or high-volume email requirements. Contact our sales team for more details."
    },
    {
      question: "What happens if I exceed my plan's email limit?",
      answer: "If you exceed your plan’s limit, you can purchase additional credits or upgrade to a higher plan."
    }
  ];
  
  const [currencyselectedOption, currencysetSelectedOption] = useState('INR');
  const [value, setValue] = useState(0); // initial value set to 0
  
  
    // Define the specific steps you want for the slider
    const steps = [20000, 40000, 60000, 80000, 100000, 150000, 200000, 250000];
    const maxValue = steps.length - 1; // max value for the slider

   
	
	const RangedCredits = steps[value].toLocaleString('en-IN');
    useEffect(() => {
    // Calculate percentage value for the slider thumb position
    const percentageValue = (value / maxValue) * 100;
    document.documentElement.style.setProperty('--value', `${percentageValue}%`);
  }, [value, maxValue]);
  
  
  const [isBasicButtonDisabled, setisBasicButtonDisabled] = useState('');
  const [isProButtonDisabled, setisProButtonDisabled] = useState('');  
  const [isUltimateButtonDisabled, setisUltimateButtonDisabled] = useState('');


  const [PlanCreditsBasic, setPlanCreditsBasic] = useState('6,000');
  const [PlanPriceBasic, setPlanPriceBasic] = useState('0');
  const [PlanCreditsPremium, setPlanCreditsPremium] = useState('20,000');
  const [PlanPricePremium, setPlanPricePremium] = useState('2,699');
  const [CurrentCredit, setCurrentCredit] = useState('20,000');
  const [CurrentCurrency, setCurrentCurrency] = useState('INR');
  const [CurrentDuration, setCurrentDuration] = useState('Monthly');
  const [PlanPricePremiumINR, setPlanPricePremiumINR] = useState('2,699');
  const [PlanPricePremiumUSD, setPlanPricePremiumUSD] = useState('34');
  const [PlanPricePremiumGBP, setPlanPricePremiumGBP] = useState('');
  const [PlanCreditsUltimate, setPlanCreditsUltimate] = useState('20,000');
  const [PlanPriceUltimate, setPlanPriceUltimate] = useState('4,699');
  const [CurrencySymbol, setCurrencySymbold] = useState('₹ ');
  
  const handleChange = (event) => {
  const newValue = parseInt(event.target.value, 10);
  setValue(newValue);

  const newCredits = steps[newValue].toLocaleString('en-IN');
  let newCreditsWithoutComma = newCredits.replace(/,/g, '');

  setCurrentCredit(newCredits);

  if (newCreditsWithoutComma < 100001) {
    if(newCreditsWithoutComma === "20000"){
      if(currencyselectedOption === "INR"){
        setPlanPricePremium("$ 1,739");
        setPlanPricePremiumINR("1,739");
      } else if(currencyselectedOption === "USD"){
        setPlanPricePremium("$ 25");
        setPlanPricePremiumUSD("25");
      } else if(currencyselectedOption === "GBP"){
        setPlanPricePremium("$ 18");
        setPlanPricePremiumGBP("18");
      }
    }
    // Update premium pricing similarly for other ranges...
  }

  ChangePriceContent(newCredits, handlecurrencyselectedOption, handledurationselectedOption);
};

// Initialize the state for currency and duration
const [handlecurrencyselectedOption, sethandlecurrencyselectedOption] = useState("INR"); // Default to INR
const [handledurationselectedOption, sethandledurationselectedOption] = useState("Monthly"); // Default to Monthly


const handlecurrencyChange = (event) => {
  const selectedOption = event.target.value;
  sethandlecurrencyselectedOption(selectedOption); // Update state
  // Use the updated value directly
  setCurrencySymbold("");
  ChangePriceContent(CurrentCredit, selectedOption, handledurationselectedOption);
};

const handledurationChange = (event) => {
  const selectedDuration = event.target.value;
  sethandledurationselectedOption(selectedDuration); // Update state
  // Use the updated value directly
  setCurrencySymbold("");
  // Use the updated value directly
  ChangePriceContent(CurrentCredit, handlecurrencyselectedOption, selectedDuration);
};



const ChangePriceContent = (credits, currency, duration) => {
  let newCreditsWithoutCommaa = credits.replace(/,/g, '');
  setCurrencySymbold("");
  if (newCreditsWithoutCommaa < 100001) {	
    setPlanCreditsPremium(credits);

	if(CurrentPlanId !== "" && CurrentPlanId !== null){
		setisBasicButtonDisabled(true);
	}

	setisProButtonDisabled(false);
	setisUltimateButtonDisabled(false);

    let premiumPrice = 0;
    let ultimatePrice = 0;

    // Assign base prices (monthly prices)
    if (credits === '20,000') {

		if (CurrentPlanId !== "" && CurrentPlanId !== undefined) {
			setisBasicButtonDisabled(true);
		}else{
			setisBasicButtonDisabled(false);
		}

		setselectedPlanIdPro("PRO001");
		setselectedPlanIdUltimate("ULT001");

		if(CurrentPlanId === "PRO001"){
			setisProButtonDisabled(true);
		}else if(CurrentPlanId === "ULT001"){
			setisUltimateButtonDisabled(true);
		}

		let premiumPrice = currency === 'INR' ? 2699 : 32;
		let ultimatePrice = currency === 'INR' ? 4699 : 58;
		if (duration === "Yearly") {
			premiumPrice *= 0.9;
			ultimatePrice *= 0.9;
		}
		setPlanPricePremium(`${currency === 'INR' ? '₹' : '$'} ${Number(premiumPrice.toFixed(0)).toLocaleString('en-IN')}`);
		setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
	}
	
	if (credits === '40,000') {
		setselectedPlanIdPro("PRO002");
		setselectedPlanIdUltimate("ULT002");
		if(CurrentPlanId === "PRO002"){
			setisProButtonDisabled(true);
		}else if(CurrentPlanId === "ULT002"){
			setisUltimateButtonDisabled(true);
		}
		let premiumPrice = currency === 'INR' ? 5099 : 60;
		let ultimatePrice = currency === 'INR' ? 6999 : 85;
		if (duration === "Yearly") {
			premiumPrice *= 0.9;
			ultimatePrice *= 0.9;
		}
		setPlanPricePremium(`${currency === 'INR' ? '₹' : '$'} ${Number(premiumPrice.toFixed(0)).toLocaleString('en-IN')}`);
		setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
	}
	
	if (credits === '60,000') {
		setselectedPlanIdPro("PRO003");
		setselectedPlanIdUltimate("ULT003");
		if(CurrentPlanId === "PRO003"){
			setisProButtonDisabled(true);
		}else if(CurrentPlanId === "ULT003"){
			setisUltimateButtonDisabled(true);
		}
		let premiumPrice = currency === 'INR' ? 7599 : 90;
		let ultimatePrice = currency === 'INR' ? 9499 : 115;
		if (duration === "Yearly") {
			premiumPrice *= 0.9;
			ultimatePrice *= 0.9;
		}
		setPlanPricePremium(`${currency === 'INR' ? '₹' : '$'} ${Number(premiumPrice.toFixed(0)).toLocaleString('en-IN')}`);
		setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
	}
	
	if (credits === '80,000') {
		setselectedPlanIdPro("PRO004");
		setselectedPlanIdUltimate("ULT004");
		if(CurrentPlanId === "PRO004"){
			setisProButtonDisabled(true);
		}else if(CurrentPlanId === "ULT004"){
			setisUltimateButtonDisabled(true);
		}
		let premiumPrice = currency === 'INR' ? 9999 : 118;
		let ultimatePrice = currency === 'INR' ? 11899 : 143;
		if (duration === "Yearly") {
			premiumPrice *= 0.9;
			ultimatePrice *= 0.9;
		}
		setPlanPricePremium(`${currency === 'INR' ? '₹' : '$'} ${Number(premiumPrice.toFixed(0)).toLocaleString('en-IN')}`);
		setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
	}
	
	if (credits === '1,00,000') {
		setselectedPlanIdPro("PRO005");
		setselectedPlanIdUltimate("ULT005");
		if(CurrentPlanId === "PRO005"){
			setisProButtonDisabled(true);
		}else if(CurrentPlanId === "ULT005"){
			setisUltimateButtonDisabled(true);
		}
		let premiumPrice = currency === 'INR' ? 12099 : 144;
		let ultimatePrice = currency === 'INR' ? 13999 : 170;
		if (duration === "Yearly") {
			premiumPrice *= 0.9;
			ultimatePrice *= 0.9;
		}
		setPlanPricePremium(`${currency === 'INR' ? '₹' : '$'} ${Number(premiumPrice.toFixed(0)).toLocaleString('en-IN')}`);
		setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
	}

    
} else {
	setselectedPlanIdPro("PRO005");
    // Logic for when credits exceed 100,000
    setselectedPlanIdPro("PRO005");
	setisProButtonDisabled(true);
    if (currency === 'INR') {
        setPlanPricePremium("₹ 12,099");
    } else if (currency === 'USD') {
        setPlanPricePremium("$ 144");
    }

    if (credits === '1,50,000') {
        setselectedPlanIdUltimate("ULT006");
		if(CurrentPlanId === "ULT006"){
			setisUltimateButtonDisabled(true);
		}
        let ultimatePrice = currency === 'INR' ? 18999 : 225;
        if (duration === "Yearly") {
            ultimatePrice *= 0.9;
        }
        setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
    }

    if (credits === '2,00,000') {
        setselectedPlanIdUltimate("ULT007");
		if(CurrentPlanId === "ULT007"){
			setisUltimateButtonDisabled(true);
		}
        let ultimatePrice = currency === 'INR' ? 23999 : 285;
        if (duration === "Yearly") {
            ultimatePrice *= 0.9;
        }
        setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
    }

    if (credits === '2,50,000') {
        setselectedPlanIdUltimate("ULT008");
		if(CurrentPlanId === "ULT008"){
			setisUltimateButtonDisabled(true);
		}
        let ultimatePrice = currency === 'INR' ? 29999 : 355;
        if (duration === "Yearly") {
            ultimatePrice *= 0.9;
        }
        setPlanPriceUltimate(`${currency === 'INR' ? '₹' : '$'} ${Number(ultimatePrice.toFixed(0)).toLocaleString('en-IN')}`);
    }
}

  setPlanCreditsUltimate(credits);
  
};


  const currencyoptions = [
    { value: 'INR', label: 'INR', imgSrc: 'iconss/flags/india.png' },
    { value: 'USD', label: 'USD', imgSrc: 'iconss/flags/united-states.png' },
  ]; 
  
  const durationoptions = [
    { value: 'Monthly', label: 'Monthly'},
    { value: 'Yearly', label: 'Yearly', discount: ' -10% off'},
  ];  
  
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
	  
  const handleFreeAcountSubmit = async () => {
		

	const today = new Date(); // Today's date
	// Format date as "YYYY-MM-DDTHH:mm:ss.sssZ" (ISO 8601 format)
	const formatDate = (date) => {
	return date.toISOString();
	};

	// Generate resetData (tomorrow's date in YYYYMMDD format)
	
	today.setDate(today.getDate() + 1);  // Add one day to today's date

	const resetData = today.toISOString();

	// Generate purchaseDate (today's date in ISO format)
	const purchaseDate = formatDate(today);

	// Generate expirationDate (one year from today in ISO format)
	const expirationDate = formatDate(
	new Date(today.setFullYear(today.getFullYear() + 1))
	);

	const apiUrl = `${ServerApiDomain}/api/myplan/insertOrUpdate`;
	const data = {
	username: CookieAccountId,
	planId: "BASIC00",
	planName: "Basic Plan",
	paymentMonthly: 0,
	usedSubscribers: 0,
	totalSubscribers: 0,
	usedEmails: 0,
	totalEmails: 300,
	smsCredits: 0,
	usedUsers: 0,
	totalUsers: 0,
	resetData: resetData,
	purchaseDate: purchaseDate,
	expirationDate: expirationDate,
	status: "active",
	};

	try {
	  const response = await axios.post(apiUrl, data, {
		headers: {
		  "Content-Type": "application/json",
		},
	  });
	  console.log("API Response:"+ response.data);
	  window.location.href = `/dashboard`;
	} catch (error) {
	  console.log("Error while sending request:"+ error);
	}
  };
  

  return (
    <div className="email-plans-body">
		
		<div className="filters-area">
			<div className="row">
				
				<div className="col-12 col-md-12 col-lg-9 col-xl-9">
					   <div className="slider-container">
							
							<input
								type="range"
								min="0"
								max={maxValue}
								value={value}
								className="range-slider"
								onChange={handleChange}
							/>
							 <div className="labels">
								<span className="current-value">{steps[value].toLocaleString('en-IN')}</span>
								<span className="max-value">{steps[maxValue].toLocaleString('en-IN')}</span>
							</div>
						</div>
				</div>
				<div className="col-12 col-md-12 col-lg-3 col-xl-3 float-end">
					<div className="row">
						<div className="col-5">
						

					<Select
					  style={{ height: '6.5vh'}}
					  value={handlecurrencyselectedOption} 
					  onChange={handlecurrencyChange}
										disableUnderline
										fullWidth
										defaultValue="All"
									  >
										{currencyoptions.map((option) => (
						<MenuItem key={option.value} value={option.value}>
						  <Box className="custom-typo" display="flex" alignItems="center">
							<img className="custom-select-icon" src={option.imgSrc} alt={option.label}  />
							<Typography className="custom-select-typography">{option.label}</Typography>
						  </Box>
						</MenuItem>
					  ))}
				  </Select>
						</div>
						<div className="col-7">
							
							  <Select
					  style={{ height: '6.5vh'}}
								  className="select-box" 
								  value={handledurationselectedOption} 
								  onChange={handledurationChange}
													disableUnderline
													fullWidth
													defaultValue="All"
												  >
									{durationoptions.map((option) => (
								  <MenuItem key={option.value} value={option.value}>
									<Box className="custom-typo" display="flex" alignItems="center">
									  <Typography className="custom-select-typography">{option.label}</Typography>
									  <span style={{ color: 'rgba(2,96,105)', fontWeight: '600', marginLeft: '0.5vw' }}>{option.discount}</span>
									</Box>
								  </MenuItem>
								))}
							  </Select>
							  
						</div>						
					</div>  
				</div>
			</div>
		</div>
		
		<div className="plans-column-area ">
			<div className="row d-flex horizontalrow">
				
				<div className="col-12 col-md-12 col-lg col-xl d-flex">
					<div className="plan-column">
						<h3 className="plan-column-heading">Basic Plan</h3>
						<h6 className="plan-column-description">Experience the Basics, No Credit Card Required</h6>
						<div className="credits-area">
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-envelope"></i> Email Credits</h6></div>
								<div className="col-5 right"><h6> {PlanCreditsBasic}/mo</h6></div>
							</div>
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-credit-card"></i> Monthly Cost</h6></div>
								<div className="col-5 right"><h6>{PlanPriceBasic}/mo</h6></div>
							</div>
						</div>
						
					<div className="features-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 6,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Customizable Email Templates</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 200 Daily Sending Limit</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> No Contact Limits</h5>
					</div>
					
					<div class="button-area">
					<button
						disabled={isBasicButtonDisabled}
						onClick={handleFreeAcountSubmit}
						type="button"
						className="primary-outline-button"
					>
						Select Plan
					</button>
					</div>
					
					</div>
				</div>
				
				<div className="col-12 col-md-12 col-lg col-xl d-flex">
					<div className="plan-column">
						<h3 className="plan-column-heading">Pro Plan</h3>
						<h6 className="plan-column-description">Take Your Business to the Next Level with Pro</h6>
						<div className="credits-area">
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-envelope"></i> Email Credits</h6></div>
								<div className="col-5 right"><h6> {PlanCreditsPremium}/mo</h6></div>
							</div>
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-credit-card"></i> Monthly Cost</h6></div>
								<div className="col-5 right"><h6><span>{CurrencySymbol}</span>{PlanPricePremium}/mo</h6></div>
							</div>
						</div>
						
					<div className="features-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Everything in Basic</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 20,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Send Time Optimization</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Branding-Free Emails(No Logo)</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> AI Content Generator</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited Daily Sending</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Dedicated Priority Support</h5>
					</div>
					
					<div class="button-area"><button disabled={isProButtonDisabled} type="button" onClick={SelectProPlan} className="primary-outline-button">Select Plan</button></div>
					
					</div>
				</div>
				
				<div className="col-12 col-md-12 col-lg col-xl popular d-flex">
					<div className="plan-column">
						<h3 className="plan-column-heading">Ultimate Plan <span>Popular</span></h3>
						<h6 className="plan-column-description">Transform Your Operations with Ultimate Efficiency</h6>
						<div className="credits-area">
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-envelope"></i> Email Credits</h6></div>
								<div className="col-5 right"><h6> {PlanCreditsUltimate}/mo</h6></div>
							</div>
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-credit-card"></i> Monthly Cost</h6></div>
								<div className="col-5 right"><h6>{CurrencySymbol}{PlanPriceUltimate}/mo</h6></div>
							</div>
						</div>
						
					<div className="features-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Everything in Pro</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 20,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Team Collaboration</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> A/B Testing</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Advanced Stats</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Bulk List Uploading</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited Daily Sending</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Real-Time Assistance</h5>
					</div>
					
					<div class="button-area"><button type="button" disabled={isUltimateButtonDisabled} onClick={SelectUltimatePlan} className="primary-outline-button">Select Plan</button></div>
					
					</div>
				</div>
				
				<div className="col-12 col-md-12 col-lg col-xl d-flex">
					<div className="plan-column">
						<h3 className="plan-column-heading">Custom Plan</h3>
						<h6 className="plan-column-description">Get a Personalized Plan That Works for You</h6>
						<div className="credits-area">
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-envelope"></i> Email Credits</h6></div>
								<div className="col-5 right"><h6>250,000+</h6></div>
							</div>
							<div className="row align-items-center">
								<div className="col-7 left"><h6><i className="bi bi-credit-card"></i> Monthly Cost</h6></div>
								<div className="col-5 right"><h6> ------</h6></div>
							</div>
						</div>
						
					<div className="features-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Everything in Ultimate</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Robust Security Measures</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Comprehensive Integration Capabilities</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Multi-Account Administration</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Personalized Setup Experience</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Dedicated Account Assistance</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited Daily Sending</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Scalable Subscription Plans</h5>
					</div>
					
					<div class="button-area"><a href="/contact" target="_blank" className="primary-outline-button d-block text-center text-decoration-none">Contact Sales</a></div>
					
					</div>
				</div>
				
			</div>
		</div>
		
		<div className="feature-comparision-area">
			<h3 className="heading">Transparency at its Best!</h3>
			<h6 className="description">Explore the features and benefits of each package side by side to make an informed decision. <a target="_blank" className="a-link" href="/contact">We're here to help.</a></h6>
			<div className="table-container">
			  <table>
				<thead>
				  <tr>
					<th>Features</th>
					<th>Basic</th>
					<th>Pro</th>
					<th>Business</th>
					<th>Custom</th>
				  </tr>
				</thead>
				<tbody>
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Send marketing campaigns easily"> Email Campaigns</span></td>
						<td class="fourth_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Create email campaigns and newsletters using our easy drag-and-drop editor"> Drag & Drop Editor</span></td>
						<td class="fourth_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Effortlessly create campaigns with AI features"> AI Content Generator</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Choose from over 100 email templates, then customize them according to your convenience"> Email Templates				</span></td>
						<td class="fourth_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Personalize your email by including the recipient's first name, birthday, and more"> Email Personalization				</span></td>
						<td class="fourth_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Send unlimited emails per day"> No daily sending limit				</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Import and manage as many contacts as you want."> Unlimited contacts & details</span></td>
						<td class="fourth_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Contact our customer care team using the support email"> Email support</span></td>
						<td class="fourth_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fourth_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Remove Aadow Branding Logo from Campaigns"> Branding Free Email</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Optimize your emails to send campaigns at the best time for each individual users with our AI feature to improve performance"> Send Time Optimization</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="second_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Launch A/B testing on content to optimize campaign performance"> A/B Testing</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Effortlessly upload contacts by selecting a folder containing multiple files">Bulk List Upload</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Analyze and manage email campaign reports for 'Open' and 'Click' with advanced statistics"> Advanced open & click stats</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Attach documents and images in emails"> Attachements</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Create separate logins and privileges for your team members"> Multi-user access</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  
					  
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Create separate logins and privileges for your team members"> Phone and live chat support</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-check-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
					  
					  <tr class="tr">
						<td class="first_td" >
						<span data-toggle="tooltip" data-placement="right" title="Get an account manager to receive help with creating, analyzing, and more about campaigns">Dedicated Account Manager</span></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="fourth_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="third_td"><i class="bi bi-x-circle-fill"></i></td>
						<td class="fifth_td"><i class="bi bi-check-circle-fill"></i></td>
					  </tr>
	  
				</tbody>
			  </table>
			</div>
		
		</div>
		
		
		<div className="faqss-area">
			<h3 className="heading">Frequently asked questions</h3>
			<h6 className="description">Have doubts about our plan? Don't worry we got you cover</h6>
			
			<div className="accordion">
			  {faqs.map((faq, index) => (
				<div key={index} className="faq-item">
				  <div
					className={`faq-question ${activeIndex === index ? 'active' : ''}`}
					onClick={() => toggleFAQ(index)}
				  >
					{faq.question}
					<span className={`arrow ${activeIndex === index ? 'rotate' : ''}`}>
					  <i class="bi bi-chevron-down"></i>
					</span>
				  </div>
				  <div className={`faq-answer ${activeIndex === index ? 'open' : ''}`}>
					{faq.answer}
				  </div>
				</div>
			  ))}
		</div>
			
		</div>

		
    </div>
  );
};

export default EmailMarketingPlans;