import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const SetManuallySession = () => {
	
  useEffect(() => {
    Cookies.set('current_Id', "37", { expires: 30 });
    Cookies.set('current_accountId', "713200DQBN", { expires: 30 });
    Cookies.set('current_accountName', "Eshan Pachori", { expires: 30 });
    Cookies.set('current_accountEmail', "eshupachori777@gmail.com", { expires: 30 });
    Cookies.set('CurrentPlanId', "BASIC00", { expires: 30 });
  }, []);


  return (
    <div className="login-body">
      

    </div>
  );
};

export default SetManuallySession;
