import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from 'js-cookie';



const TeamMembers = () => {
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountId = Cookies.get('current_accountId');
  
  const API_URL = `${ServerApiDomain}/api/senders_domain/list`;
  const AUTH = 'Basic ' + btoa('testuser:testpassword');
	
  const [FullPageloading, setFullPageloading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [verifyDomainLink, setVerifyDomainLink] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
	const current_username = CookieAccountId;
	const username = 'testuser';
	const password = 'testpassword';
	const encodedCredentials = btoa(`${username}:${password}`);
	
	const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
	
	const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
        setSelectedId(null);
    };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorDomain("");
	setDomain("");
    setDomainError(false);
    setInputApiArea("true");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [Domain, setDomain] = useState('');
  const [DomainError, setDomainError] = useState(false);
  const [ErrorDomain, setErrorDomain] = useState('');
  const [DomainAdded, setDomainAdded] = useState('');
  const [DomainSentTo, setDomainSentTo] = useState('');
  const DomainRef = useRef(null);
  
  const handleAddSender = () =>{
	    setErrorDomain("");
		setDomainError(false);
	if (Domain.trim() === '') {
		setErrorDomain("Please enter a domain");
		setDomainError(true);
		DomainRef.current.focus();
		return;
	}
	handleAddDomain();
  };
  
  const [InputApiArea, setInputApiArea] = useState('true');
  
  
  const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [noData, setNoData] = useState(false);
    const loadedIds = useRef(new Set());

    const fetchData = useCallback(async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        try {
            const response = await fetch(`${API_URL}?username=${CookieAccountId}&page=${page}`, {
                headers: {
                    'Authorization': AUTH
                }
            });
            const result = await response.json();

            if (result.content.length > 0) {
                const newData = result.content.filter(item => !loadedIds.current.has(item.id));
                
                if (newData.length > 0) {
                    setData(prevData => [...prevData, ...newData]);
                    newData.forEach(item => loadedIds.current.add(item.id)); // Mark IDs as loaded
                    setPage(prevPage => prevPage + 1);
                }
            } else {
                setHasMore(false); // No more data available
            }

            // Check if no data was returned initially
            if (page === 0 && result.content.length === 0) {
                setNoData(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [loading, hasMore, page]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const observer = useRef();

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchData();
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, fetchData]);
	
	
	const handleAddDomain = async () => {
		// Prepare the domain data
		const authenticateId = Math.floor(Math.random() * 1000000);
		const domainData = new URLSearchParams();
		domainData.append('username', current_username);
		domainData.append('domain', Domain);
		domainData.append('authenticateId', authenticateId);

		try {
			const response = await fetch(`${ServerApiDomain}/api/senders_domain/generate`, {
				method: 'POST',
				headers: {
					'Authorization': `Basic ${encodedCredentials}`,
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: domainData, // Send the formatted body
			});

			if (response.ok) {
				const result = await response.json();
				showSnackbar('Domain added successfully!', 'success');
				setDomainAdded(Domain);
				setInputApiArea("false");
				setData(prevData => {
					const updatedData = [...prevData, result]; // Assuming result contains the new domain info
					setNoData(updatedData.length === 0); // Check if the data is empty
					return updatedData;
				});
				setDomain('');
				setVerifyDomainLink(`/sender-authentication/${authenticateId}`);
			} else {
				console.error('Failed to add the domain');
				showSnackbar('Failed to add the domain.', 'error');
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};
	
	const handleDelete = async () => {
		try {
			const response = await fetch(`${ServerApiDomain}/api/senders_domain/delete?id=${selectedId}&username=${current_username}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Basic ${encodedCredentials}`,
				},
			});

			if (response.ok) {
            setData(prevData => {
                const updatedData = prevData.filter(item => item.id !== selectedId);
                setNoData(updatedData.length === 0); // Check if the data is empty
                return updatedData;
            });
            showSnackbar('Domain Removed!', 'success');
        } else {
            alert('Failed to delete the Domain');
        }
		} catch (error) {
			alert('Error:', error);
		} finally {
			handleCloseDeleteModal();
		}
	};

  return (					
			<div className="main-area">
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className="secondary-heading-area border-bottom-0 p-0">
				
			</div>

			<div className="form-area">
				<div className="row">
					<div className="col-4">
						  <h4 className="secondary-heading">Domain Authentication <b className="text-danger"></b></h4>
						  <h6 className="secondary-subheading">Improve deliverability by proving to inbox providers that you own the domain you're sending from. <a href="" className="d-none">Learn more</a></h6>
						  <button type="button" onClick={HandleShowModal} className="primary-button mt-3">Add new Domain</button>
					</div>
					<div className="col-8">
						
								
								{noData ? (
									<center>
										<div className="empty-error-area">
											<img className="empty-error-image w-25 mb-4" src="../../iconss/other/no-results.png"  />
											<h2 className="empty-error-heading">No Domains Found</h2>
											<h6 className="empty-error-subheading" >We couldn't find any domain authentication records associated with your account.</h6>
										</div>
									</center>
								) : (
									<>
										<table>
											<thead>
												<tr>
													<th>Domain</th>
													<th>Verification Status</th>
													<th>Date Added</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
											{data.map((item, index) => {
											const formattedDate = new Intl.DateTimeFormat('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}).format(new Date(item.datetime));

											return (
												<tr key={item.id} ref={data.length === index + 1 ? lastElementRef : null}>
													<td className="td-first"><h6 className="domain-email-name">{item.domain}</h6></td>
													<td><span className={`verfication-status ${item.status}-status text-capitalize`}>{item.status}</span></td>
													<td className="td-third">{formattedDate}</td>
													<td><a href="#" className="download-invoice" onClick={() => handleClickOpen(item.id)}><i className="bi bi-trash"></i></a> <Link to={`/sender-authentication/${item.authenticateId}`}><i className="bi bi-pencil"></i></Link></td>
												</tr>
												);
											})}
											
											</tbody>
										</table>
										{loading && <p>Loading more data...</p>}
									</>
								)}
								
								
								
								
					</div>
				</div>
			</div>
			
			<Dialog open={open} onClose={handleCloseDeleteModal}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this Domain? This action cannot be undone.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDeleteModal} className="text-secondary">
							Cancel
						</Button>
						<Button onClick={handleDelete} className="text-danger">
							Delete
						</Button>
					</DialogActions>
			</Dialog>
			
			{show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Add a new Domain</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '1.1vw', color: 'rgba(100, 100, 100)', cursor: 'pointer', textAlign: 'right'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body pb-4 pt-0 px-4">
					{InputApiArea === 'true' && (
					<div className="row">
						<div className="col-12">
							<label>Domain <b className="text-danger">*</b></label>
							<input type="text" ref={DomainRef} className={`input-forms w-100 ${DomainError ? 'input-error' : ''}`} value={Domain} onChange={(e) => {
								  setDomain(e.target.value);
								}} />
							{ErrorDomain && <div className="error-message">{ErrorDomain}</div>}
						</div>
						
					</div>	
					)}
					{InputApiArea === 'false' && (	
						<div className="col-12">
							<div id="step_three" className="right-content">
							  <img src="iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">Domain Added!</h2>
							  <h2 className="secondary-description-modal text-center"><b>{DomainAdded}</b> added as a domain.</h2>
							  <center className="my-2 mt-4">
							  <Link to={verifyDomainLink} className="primary-button float-center text-decoration-none mx-2">Verify Domain</Link>
							  <button type="button" className="secondary-button float-center mx-2" onClick={handleClose} >Do it later</button></center>
							</div>
						</div>
					)}
						
					</div>
					
					{InputApiArea === 'true' && (
					<div className="modal-footer ">
						<button type="button" className="secondary-button" onClick={handleClose} data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="primary-button" onClick={handleAddSender}>Add Domain</button>
					</div>
					)}
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
			
		  </div>
					
  );
};

export default TeamMembers;
