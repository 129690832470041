import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/public/home.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';
import myImage from './original-12484d8005a15a7369c7e03fcb451758.png';

import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = () => {

	
	
  useEffect(() => {
    document.title = 'Aadow — Powerful and Advanced Email Marketing Software & Platform';

    const options = {
      threshold: 0.7 // Trigger shrinking when 70% of the stack item is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('shrink'); // Expand the current item
        } else {
          entry.target.classList.add('shrink'); // Shrink the previous item
        }
      });
    }, options);

    document.querySelectorAll('.stack-item').forEach((item) => {
      observer.observe(item);
    });

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);
  
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  
  
  const faqs = [
    {
      question: "How does Aadow improve email deliverability?",
      answer: "Aadow’s SpamScore feature detects potential spam risks before sending, helping your emails reach inboxes instead of spam folders."
    },
    {
      question: "What are AMP Emails?",
      answer: "AMP Emails are interactive emails that let recipients perform actions like filling forms or booking slots directly within the email."
    },
    {
      question: "Does Aadow offer a free plan?",
      answer: "Yes, Aadow offers a free plan that lets you start sending email campaigns without any upfront cost."
    },
    {
      question: "Can Aadow integrate with my CRM?",
      answer: "Yes, Aadow integrates with popular CRMs to streamline your email marketing efforts."
    },
    {
      question: "What kind of businesses can use Aadow?",
      answer: "Aadow is suitable for all businesses, from startups to enterprises, across various industries."
    }
  ];

  return (
	<>
		<HelmetProvider>
			<Helmet>
			{/* Basic SEO */}
			<title>Aadow — Powerful and Advanced Email Marketing Software & Platform</title>
			<meta
				name="title"
				content="Aadow — Powerful and Advanced Email Marketing Software & Platform"
			/>
			<meta
				name="description"
				content="Boost your email campaigns with Aadow, the advanced email marketing software designed for businesses of all sizes. Create, automate, and track impactful campaigns with ease. Try Aadow today for better engagement and results."
			/>
			<meta
				name="keywords"
				content="Email Marketing Platform, Email Marketing Software, Free Email Marketing, Free Email Marketing Credits, Email Campaigns, Best Email Campaign Tools, Advanced Email Marketing Solutions, Automated Email Marketing, Email Campaign Management Software, Email Automation Platform, AMP Email, AMP Mail, Business Email Campaign Software, Top Email Marketing Platforms 2024, Email Campaign Tracking Tools, Free Email Marketing Tool, Mailchimp, Mailjet, Brevo, Zoho Email, Sendgrid, Mailgun, Mailmodo, Alternatives of Mailchimp, Alternatives of Mailjet, Alternatives of Zoho Email, Alternatives of Sendgrid, Alternative of, Alternatives of Mailgun, Alternative of Yamm, Mail Sender, Bulk Email Sending, Transactional Email, Marketing Email, Promotional Email, Newsletter Email, OTP Email"
			/>

			{/* Open Graph / Facebook */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content="https://www.aadow.com" />
			<meta
				property="og:title"
				content="Aadow — Powerful and Advanced Email Marketing Software & Platform"
			/>
			<meta
				property="og:description"
				content="Boost your email campaigns with Aadow, the advanced email marketing software designed for businesses of all sizes. Create, automate, and track impactful campaigns with ease. Try Aadow today for better engagement and results."
			/>
			<meta
				property="og:image"
				content="https://www.aadow.com/imagess/Sync.png"
			/>

			{/* Twitter */}
			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content="https://www.aadow.com" />
			<meta
				property="twitter:title"
				content="Aadow — Powerful and Advanced Email Marketing Software & Platform"
			/>
			<meta
				property="twitter:description"
				content="Boost your email campaigns with Aadow, the advanced email marketing software designed for businesses of all sizes. Create, automate, and track impactful campaigns with ease. Try Aadow today for better engagement and results."
			/>
			<meta
				property="twitter:image"
				content="https://www.aadow.com/imagess/Sync.png"
			/>
			</Helmet>
		
		</HelmetProvider>
    <div className="home-body">
      <TopBar />
      <div 
	  className="top-area" 
	  style={{
		backgroundImage: 'url(/imagess/top-bg-final.png)',
		backgroundSize: 'cover', // Optional: Adjust the background size
		backgroundPosition: 'center', // Optional: Center the background image
	  }}
	  >
        <h1 className="main-heading">Emails That Do More: One Click, Endless Possibilities</h1>
        <h6 className="main-subheading">
          Watch Your Campaigns Talk, Engage, and Convert with Aadow.
        </h6>
        <div className="button-area text-center">
          <Link to="/signup"><button className="primary-button">Get Started</button></Link>
          <Link to="/Pricing"><button type="button" className="secondary-button">See Pricing</button></Link>
          <p>Get Started for free. No credit card required</p>
        </div>
        <div className="blocks-container">
		  <div className="block block1">
			<img src="imagess/working-home-hero.jpg"></img>
		  </div>
          <div className="block block2">
		  	<i className='bi bi-send-check'></i>
			<h2 className='heading-block'>10000+</h2>
			<p className='subheading-block'>Campaigns sends every minute</p>
		  </div>
          <div className="block block3">
			
			<i className='bi bi-palette'></i>
		  	<h2 className='heading-block'>40,000+</h2>
			<h2 className='description-block'>Designed Email Templates</h2>
		  </div>
          <div className="block block4">
		  	<i className='bi bi-stars'></i>
		  	<h2 className='heading-block'>AIM</h2>
			<p className='subheading-block'>Unlock the Power of AMP Emails</p>
			
		  </div>
          <div className="block block5">
		  	<i className='bi bi-speedometer2'></i>
			<h1 className='heading-block'>Target the Best Prospects with Precision Segmentation</h1>
		  </div>
        </div>
      </div>
		
	
	  <img src="imagess/Sync.png" className="w-100" />	
		
	 <div className="info-area">	
		  <h2 className="stack-container-heading">The Ultimate Interactive Email Marketing Platform</h2>
		  <div className="stack-container">
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/1.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Supercharge Your Email Marketing with AI</h2>
				  <h6 className="infoblock-subheading">Seamlessly Generate Compelling Content with Intelligent Assistance</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> AI-Enhanced Subject Lines</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Enhanced Pre-Headers</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Effortless Email Content</h6>
				  </div>
				</div>
			  </div>
			</div>

			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/2.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Craft Stunning Templates with Ease</h2>
				  <h6 className="infoblock-subheading">Unleash Your Creativity with Our Comprehensive Design Tools</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Drag-and-Drop Simplicity</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Custom HTML Design</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> WYSIWYG Flexibility</h6>
				  </div>
				</div>
			  </div>
			</div>

			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/3.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Optimize Your Campaigns with Spam Indicator Insights</h2>
				  <h6 className="infoblock-subheading">Ensure Your Emails Land in the Inbox, Not the Spam Folder</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Real-Time Spam Check</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Actionable Insights</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Campaign Confidence</h6>
				  </div>
				</div>
			  </div>
			</div>

			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/4.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading"> Product/Brand Showcase with AIM</h2>
				  <h6 className="infoblock-subheading">Create Interactive Carousels to Highlight Your Brand with Aadow Interactive Mail</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Dynamic Product Displays</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Instant Website Experience</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Enhanced User Engagement</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/8.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Gamify Your Emails</h2>
				  <div className="info-block-bullets">
				  <h6 className="infoblock-subheading">Add Fun and Engagement with Interactive Gamify AIM</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Spin the Wheel</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Scratch the Coupon</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Quiz Challanges</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/6.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Collect Feedback and Surveys</h2>
				  <h6 className="infoblock-subheading">Gather Valuable Insights through your email campaigns</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Instant Feedback Collection</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Real-Time Responses</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Enhanced User Experience</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/7.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Lead Collection Made Easy with One Click</h2>
				  <h6 className="infoblock-subheading">Capture Leads Instantly with Built-In Forms</h6>
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Embedded Forms</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Instant Data Collection</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Increase Conversion Rates</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			<div className="stack-item">
			  <div style={{ backgroundImage: "url('images/home/5.webp')" }} className="info-block">
				<div className="info-block-content-area">
				  <h2 className="infoblock-heading">Uncover Your Most Engaged Audience with Precision</h2>
				  <h6 className="infoblock-subheading">Target Your Campaigns with Confidence Using Aadow's Audience Insights</h6>
				  
				  <div className="info-block-bullets">
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Identify True Interest</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Data-Driven Targeting</h6>
					<h6 className="info-block-bullet"><i className="bi bi-check-circle"></i> Enhanced Campaign Effectiveness</h6>
				  </div>
				</div>
			  </div>
			</div>
			
			
		  </div>
      </div>
	  
	  
	  

	  <div className="pricing-area">
		<h2 className="pricing-area-heading">Tailored Plan for your Business Needs</h2>
		<h2 className="pricing-area-subheading">Rest assured that every email will reach an inbox.</h2>
		<div className="home-pricing-area">
		<div className="row horizontalrow">
			<div className="col-12 col-md-12 col-lg-4 col-xl-4 col-price d-flex">
				<div className="pricing-col">
					<h2 className="pricing-col-heading">Basic Plan</h2>
					<h6 className="pricing-col-subheading">Experience the Basics, No Credit Card Required</h6>
					<h3 className="pricing-col-price">₹ 0 <span>/month</span></h3>
					<button type="button" className="primary-button">Get Started</button>
					
					<div className="divider-container">
					  <hr className="line" />
					  <span className="divider-text">Features</span>
					  <hr className="line" />
					</div>
					
					
					<div className="bullet-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 6,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Customizable Email Templates</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 200 Daily Sending Limit</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> No Contact Limits</h5>
					</div>
					
				</div>
			</div>
			
			<div className="col-12 col-md-12 col-lg-4 col-xl-4 col-price d-flex">
				<div className="pricing-col">
					<h2 className="pricing-col-heading">Pro Plan</h2>
					<h6 className="pricing-col-subheading">Take Your Business to the Next Level with Pro Plan</h6>
					<h3 className="pricing-col-price">₹ 2,429 <span>/month</span></h3>
					<button type="button" className="primary-button">Get Started</button>
					
					<div className="divider-container">
					  <hr className="line" />
					  <span className="divider-text">Features</span>
					  <hr className="line" />
					</div>
					
					<div className="bullet-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Everything in Basic</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 20,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Send Time Optimization</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Branding-Free Emails(No Logo)</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> AI Content Generator</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited Daily Sending</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Dedicated Priority Support</h5>
					</div>
					
				</div>
			</div>
			
			<div className="col-12 col-md-12 col-lg-4 col-xl-4 col-price d-flex">
				<div className="pricing-col">
					<h2 className="pricing-col-heading">Ultimate Plan</h2>
					<h6 className="pricing-col-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
					<h3 className="pricing-col-price">₹ 4,229 <span>/month</span></h3>
					<button type="button" className="primary-button">Get Started</button>
					
					<div className="divider-container">
					  <hr className="line" />
					  <span className="divider-text">Features</span>
					  <hr className="line" />
					</div>
					
					
					<div className="bullet-area">
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Everything in Pro</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> 20,000 Monthly Email Quota</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Team Collaboration</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> A/B Testing</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Advanced Stats</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Bulk List Uploading</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Unlimited Daily Sending</h5>
						<h5 className="feature-list"><i class="bi bi-check-circle-fill"></i> Real-Time Assistance</h5>
					</div>
					
				</div>
			</div>
			
			
			</div>
			<div className="col-12">
				<div className="custom-plan-area">
					<h2 className="custom-plan-heading">Looking for more pricing info</h2>
					<h2 className="custom-plan-subheading">Understand the Value of Our Services. See How Our Plans Fit Your Budget</h2>
					<div className="text-center">
					<Link to="/pricing"><button type="button" className="primary-button">See full pricing</button></Link>
					<Link to="/signup"><button type="button" className="secondary-button">Get Custom Plan</button></Link>
					</div>
				</div>
			</div>
			
			
			
		</div>
		
	  </div>
	  
	  <div className="faqss-area pt-5">
			<h3 className="heading">Frequently asked questions</h3>
			<h6 className="description">Have doubts about our plan? Don't worry we got you cover</h6>
			
			<div className="accordion">
			  {faqs.map((faq, index) => (
				<div key={index} className="faq-item">
				  <div
					className={`faq-question ${activeIndex === index ? 'active' : ''}`}
					onClick={() => toggleFAQ(index)}
				  >
					{faq.question}
					<span className={`arrow ${activeIndex === index ? 'rotate' : ''}`}>
					  <i class="bi bi-chevron-down"></i>
					</span>
				  </div>
				  <div className={`faq-answer ${activeIndex === index ? 'open' : ''}`}>
					{faq.answer}
				  </div>
				</div>
			  ))}
		</div>
			
	  </div>
	  <div className="pricing-area bg-light">
		<h2 className="pricing-area-heading">Accelerate your email journey</h2>
		<h2 className="pricing-area-subheading">Explore practical guides, free courses and case studies. Find all you need to build your success journey.</h2>
		<div className="home-pricing-area">
		
		<div className="row horizontalrow pt-5">
			<div className="col-12 col-md-12 col-lg col-xl col-price d-flex">
					<div className="home-blog shadow-sm">
                      <Link to={`/blog/email-automation-best-practices-for-higher-conversion-rates-29`} className="text-decoration-none">
                        <img src="https://app.aadow.com/blog_poster/automation.png" alt="" title="" className="home-blog-poster" />
                        <div className="home-blog-content-area">
                          <h6 className="home-blog-heading">Email Automation Best Practices for Higher Conversion Rates</h6>
                          <h6 className="home-blog-subheading">Email automation is a powerful tool that allows businesses to streamline their communication, nurture leads, and drive conversions. In this blog post, we will explore best practices for leveraging email automation to achieve higher conversion rates. By implementing these practices, you can create personalized and timely interactions that resonate with your audience and ultimately lead to increased conversions.</h6>
                          <div className="pt-2 pb-2">
                            <h6 className="home-blog-date d-inline">05 Dec 2024 by</h6>
                            <h6 className="home-blog-author d-inline"> Usha Singh</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
			</div>
			<div className="col-12 col-md-12 col-lg col-xl col-price d-flex">
					<div className="home-blog shadow-sm">
                      <Link to={`/blog/categorizing-data-for-effective-email-marketing-aadows-threetier-approach-72`} className="text-decoration-none">
                        <img src="/images/home/audience-segmentation.jpg" alt="" title="" className="home-blog-poster" />
                        <div className="home-blog-content-area">
                          <h6 className="home-blog-heading">Categorizing Data for Effective Email Marketing: Aadow's Three-Tier Approach						  </h6>
                          <h6 className="home-blog-subheading">In the realm of email marketing, precision is key. Sending the right message to the right audience can make the difference between a successful campaign and a missed opportunity. Aadow, a cutting-edge email marketing platform, has recognized this need for accuracy and developed a feature that categorizes data into three distinct tiers. This categorization streamlines your email marketing efforts, ensuring that your message reaches the most receptive audience.						  </h6>
                          <div className="pt-2 pb-2">
                            <h6 className="home-blog-date d-inline">14 Nov 2024 by</h6>
                            <h6 className="home-blog-author d-inline"> Aiko Tanaka</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
			</div>
			<div className="col-12 col-md-12 col-lg col-xl col-price d-flex d-none">
					<div className="home-blog shadow-sm">
                      <Link to={`/blog/securing-email-trust-the-power-of-domain-signatures-in-modern-email-marketing-70`} className="text-decoration-none">
                        <img src="/images/home/DomainSignatures.jpg" alt="" title="" className="home-blog-poster" />
                        <div className="home-blog-content-area">
                          <h6 className="home-blog-heading">Securing Email Trust: The Power of Domain Signatures in Modern Email Marketing</h6>
                          <h6 className="home-blog-subheading">In the fast-paced digital landscape, email marketing remains a cornerstone of effective communication and customer engagement. However, with the growing concerns surrounding cyber security, spam, and phishing attacks, maintaining the authenticity and credibility of email communications has become more crucial than ever. This is where the concept of "Domain Signature" steps in, playing a pivotal role in ensuring that emails are not only delivered successfully but also perceived as trustworthy by recipients.</h6>
                          <div className="pt-2 pb-2">
                            <h6 className="home-blog-date d-inline">18 Nov 2024 by</h6>
                            <h6 className="home-blog-author d-inline"> Ganesh Iyer</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
			</div>
			<div className="col-12 col-md-12 col-lg col-xl col-price d-flex">
					<div className="home-blog shadow-sm">
                      <Link to={`/blog/spamguard-optimization-suite-your-shield-against-email-spam-74`} className="text-decoration-none">
                        <img src="/images/home/spam-meter.jpg" alt="" title="" className="home-blog-poster" />
                        <div className="home-blog-content-area">
                          <h6 className="home-blog-heading">SpamGuard Optimization Suite: Your Shield Against Email Spam</h6>
                          <h6 className="home-blog-subheading">Email marketing is a powerful tool, but only if your messages land where they belong—your audience’s inbox. Spam emails cost businesses billions annually in lost revenue and trust, and being flagged as spam can be a nightmare for any marketer. Enter SpamGuard Optimization Suite by Aadow, your ultimate defense against spam filters and a game-changer for email marketing success.</h6>
                          <div className="pt-2 pb-2">
                            <h6 className="home-blog-date d-inline">29 Nov 2024 by</h6>
                            <h6 className="home-blog-author d-inline"> Rudra Kumar</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
			</div>
			<div className="col-12 col-md-12 col-lg col-xl col-price d-flex">
					<div className="home-blog shadow-sm ">
                      <Link to={`/blog/amp-email-the-future-of-email-marketing-73`} className="text-decoration-none">
                        <img src="/images/home/amp-mail.jpg" alt="" title="" className="home-blog-poster" />
                        <div className="home-blog-content-area">
                          <h6 className="home-blog-heading">AMP Email: The Future of Email Marketing</h6>
                          <h6 className="home-blog-subheading">In the ever-evolving landscape of email marketing, AMP (Accelerated Mobile Pages) is redefining the way businesses interact with their audience. AMP emails bring a new level of interactivity and engagement directly to users’ inboxes, making static emails a thing of the past. Let’s dive into what AMP is, how it works, and why it should be the future of your email marketing strategy.						  </h6>
                          <div className="pt-2 pb-2">
                            <h6 className="home-blog-date d-inline">06 Dec 2024 by</h6>
                            <h6 className="home-blog-author d-inline"> Deepika M</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
			</div>	
		</div>
			
			
			
		</div>
		
	  </div>
	  <div className="contact-us-section">
					<h2 className="contact-us-section-heading">Revolutionize Your Email Marketing</h2>
					<h2 className="contact-us-section-subheading">Launch Smarter, Faster, and Better Campaigns. Start Your Journey with Us Today</h2>
					<div className="text-center">
					<Link to="/contact"><button type="button" className="primary-button">Connect with us</button></Link>
					</div>
	  </div>
	  
	  
	  <Footer/>
    </div>
	</>
  );
};

export default Home;
