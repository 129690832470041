import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../../../css/public/pricing.css';
import { Select, MenuItem, FormControl, InputLabel, Box, Typography } from '@mui/material';
import EmailMarketingPlans from './EmailMarketingPlans';
import EmailTransactionalPlans from './EmailTransactionalPlans';

const EmailPlans = () => {
  const [activePlan, setActivePlan] = useState('marketing');

  return (
    <div className="email-plans-body">
      <div className="filters-area">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-3 col-xl-3"></div>
          <div className="col-12 col-md-12 col-lg-6 col-xl-6">
            <div className="product-type-tab">
              <div className="row">
                <div className="col">
                  <a 
                    onClick={() => setActivePlan('marketing')} 
                    className={`${activePlan === 'marketing' ? 'active' : ''}`}
                  >
                    Marketing
                  </a>
                </div>
                <div className="col">  
                  <a 
                    onClick={() => setActivePlan('transactional')} 
                    className={`${activePlan === 'transactional' ? 'active' : ''}`}
                  >
                    Transactional
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {activePlan === 'marketing' && <EmailMarketingPlans />}
      {activePlan === 'transactional' && <EmailTransactionalPlans />}
    </div>
  );
};

export default EmailPlans;
