import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/public/refund-policy.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';

const RefundPolicy = () => {
  useEffect(() => {
    document.title = 'Refund Policy | Aadow';
	  }, []);
 

  return (
    <div class="refund-policy-body">
	<TopBar/> 
	<div class="top-area">
      <div class="container-top text-center">
        <h1>REFUND POLICY</h1>
		<span>Update on 23 Jul 2023</span>
      </div>
    </div>
    <div class="content-area"> 
	
	<div class="policy-content shadow-sm">
	
	  <p>At Aadow, we strive to provide high-quality services and ensure customer satisfaction. However, please carefully review our refund policy before making any payment. Our refund policy is as follows:</p>

	  <h2>Payment Failure:</h2>
	  <p>In the event of a payment failure during the transaction process, we will promptly initiate a refund. You will be reimbursed for the full amount paid through the same payment method used for the original transaction.</p>

	  <h2>No Refunds for Services Rendered:</h2>
	  <p>Once payment is successfully made and our services are rendered, we do not generally offer refunds. We allocate significant time, effort, and resources to fulfill our obligations and provide the agreed-upon services.</p>

	  <h2>Exceptional Circumstances:</h2>
	  <p>In exceptional cases, such as a breach of contract on our part or failure to deliver the agreed-upon services, we may consider refund requests on a case-by-case basis. Our decision to grant a refund in such circumstances will be at our sole discretion.</p>

	  <h2>Non-Refundable Fees:</h2>
	  <p>Please note that certain fees and expenses associated with third-party services, licenses, or materials procured on your behalf may be non-refundable. Any such non-refundable fees will be clearly communicated to you before the purchase or engagement of those services.</p>

	  <h2>Process for Refunds:</h2>
	  <p>If you are eligible for a refund as per the terms mentioned above, please contact our customer support team within a reasonable time-frame to initiate the refund process. Once the necessary transaction details and documentation are provided and verified, the refund will be processed, and you can expect the refunded amount to be credited to your account within 7-14 working days.</p>
	  
	  <p>
		 Please note that monthly plans are not eligible for refunds, and this policy applies exclusively to annual/yearly plans.
	  </p>

	  <p>It is important to note that this refund policy applies specifically to Aadow's services and may not cover third-party products or services that you may have engaged with separately. We recommend reviewing this refund policy thoroughly before engaging our services or making any payments. If you have any questions or require further clarification, please contact our customer support team, who will be happy to assist you. Please note that this refund policy is subject to change at our discretion. Any updates or modifications to the refund policy will be reflected on our website or communicated directly to you.</p>
	 

	</div>
	 
	</div>
	<Footer/>
    </div>
  );
};

export default RefundPolicy;
