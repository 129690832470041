import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Select, MenuItem, InputBase } from '@mui/material';
import '../../css/private/campaign-overview.css';
import SideBar from './../components/SideBar';
import myImage from './bar.png';
import { format } from 'date-fns';
import axios from 'axios';
import Cookies from 'js-cookie';

// Import other settings components as needed

const CampaignOverview = () => {
	
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const [activeTab, setActiveTab] = useState('#'); // Set default to '#'
  const [EmptyError, setEmptyError] = useState("no"); // Set default to '#'
  const { CampaignId } = useParams();
  
  const CookietId = Cookies.get('current_Id');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountEmail = Cookies.get('current_accountEmail');
  const CurrentPlanName = Cookies.get('CurrentPlanName');
  const handleTabClick = (hash) => {
    setActiveTab(hash);
    fetchContacts(currentPage, hash.substring(1)); // Remove the '#' character
    window.location.hash = hash; // Update URL hash
  };

  useEffect(() => {
	  fetchEmailCampaign();
  }, []);
  
  useEffect(() => {
    document.title = 'Email Campaign | Aadow';
    const currentHash = window.location.hash;
    // If no hash in the URL, default to Overview
    if (!currentHash) {
      setActiveTab('#');
      window.location.hash = '#';
    } else {
      setActiveTab(currentHash);
    }
  }, []);
  
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const current_username = 'pachorieshan';
  
  
  const [ScheduledDate, setScheduledDate] = useState("------");
  const [campaignName, setcampaignName] = useState("------");
  const [totalDelivered, settotalDelivered] = useState("------");
  const [totalOpened, settotalOpened] = useState("------");
  const [totalClicked, settotalClicked] = useState("------");
  const [totalUnsubscribed, settotalUnsubscribed] = useState("------");
  const [sentOnSentTo, setsentOnSentTo] = useState("------");
  const [Subject, setSubject] = useState("------");
  const [ContactLists, setContactLists] = useState("------");
  const [Preview, setPreview] = useState("------");
  const [FromEmail, setFromEmail] = useState("------");
  const [FromName, setFromName] = useState("------");
  const [ReplyTo, setReplyTo] = useState("------");
  const [TotalContacts, setTotalContacts] = useState("------");
  const [TotalSoftBouced, setTotalSoftBouced] = useState("------");
  const [TotalHardBouced, setTotalHardBouced] = useState("------");
  const [EmailTemplateThumbnail, setEmailTemplateThumbnail] = useState("------");
  const [Status, setStatus] = useState("------");


  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [listId, setListId] = useState('');
  const [status, setstatus] = useState('');
  const [segment, setSegment] = useState('');
  const [folderId, setFolderId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const pageSize = 8; // Number of items per page

   const fetchContacts = async (page, activeTab) => {
    try {
      const url = `${ServerApiDomain}/scheduled-email-contacts/${activeTab}?username=${CookieAccountId}&campaignId=${CampaignId}&${activeTab}=1&page=${page}`;
      
      const response = await axios.get(url, {
        
      });

      setContacts(response.data.content);
      setTotalPages(response.data.totalPages);
      setError(null); // Clear any previous error
    } catch (err) {
      setError('Failed to fetch contacts. Please try again later.');
      setContacts([]);
      setTotalPages(0);
    }
  };

  useEffect(() => {
    fetchContacts(currentPage, activeTab.substring(1)); // Remove the '#' character
  }, [currentPage, activeTab]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  
  const fetchEmailCampaign = async () => {
			try {
			  const response = await fetch(`${ServerApiDomain}/api/campaigns/${CookieAccountId}/campaign/${CampaignId}`, {
				method: 'GET',
				headers: {
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				  
				throw new Error('Network response was not ok');
			  }

			  
			  
			  const data = await response.json();
			  if (data.status !== "sent") {
					window.location.href = `/create/email-campaign/${CampaignId}`;
			  }
			  if (data.replyEmail !== null && data.replyEmail !== "") {
				setReplyTo(data.replyEmail);
			  }else{
				setReplyTo(data.fromEmail);
			  }
			  setSubject(data.subject);
			  setFromName(data.fromName);
			  setFromEmail(data.fromEmail);
			  settotalUnsubscribed(data.unsubscribed);
			  settotalClicked(data.clicked);
			  settotalOpened(data.opened);
			  settotalDelivered(data.delivered);
			  setStatus(data.status);
			  setcampaignName(data.campaignName);
			  setTotalContacts(data.totalContacts);
			  setTotalSoftBouced(data.softBounced);
			  setTotalHardBouced(data.hardBounced);
			  setPreview(data.preview);
			  setEmailTemplateThumbnail(`https://www.aadow.com/emailthumbnail/${data.emailTemplate}.png`)

			  const formattedDate = format(new Date(data.scheduledDateTime), 'MMM dd, yyyy \'at\' hh:mm a');

				
				const formatDateTime = (scheduledDateTime) => {
					const date = new Date(scheduledDateTime);
					return date.toLocaleString('en-US', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					});
				};
			  
			  setScheduledDate(formattedDate);
			  setContactLists(data.contactLists);

			  
			  setLoading(false);  // Set loading to false once data is fetched
			  
			} catch (error) {
			  setEmptyError("yes");
			  setError("Fetch error:", error);
			  setLoading(false);
			}
  };
   
   
   if (loading) {
	  return <div>Loading...</div>; // Display loading message
   }

   if (error) {
   }
  
   const formatDateTimeTwo = (datetime) => {
	const dateOptions = {
	  day: "numeric",
	  month: "short",
	  year: "numeric",
	};
	const timeOptions = {
	  hour: "numeric",
	  minute: "numeric",
	  hour12: true,
	};
  
	const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(new Date(datetime));
	const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(new Date(datetime));
  
	return `${formattedDate} at ${formattedTime}`;
  };
  
  
  return (
    <SideBar>
      <div className="campaign-overview-content">
		{EmptyError === 'yes' && (
							<center>
								<div className="empty-error-area">
									<img className="empty-error-image w-25" src="../../iconss/other/no-results.png"  />
									<h2 className="empty-error-heading">No Data Found</h2>
									<h6 className="empty-error-subheading" >It looks like there are no data associated with this campaign.</h6>
								</div>
							</center>
		)}
		{EmptyError === 'no' && (
        <div className="campaign-overview-body">
			  <div className="breadcrumb-area">
				<nav aria-label="breadcrumb">
				  <ol className="breadcrumb">
					<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
					<li className="breadcrumb-item"><Link to="/campaigns/email">Email Campaigns</Link></li>
					<li className="breadcrumb-item active" aria-current="page">{campaignName}</li>
				  </ol>
				</nav>
			  </div>
			  
		  <div className="top-area bg-white">
			  <div className="row align-items-center">
				<div className="col-6">
					<h2 className="campaign-heading">{campaignName} <span className={`campaign-status ${Status}-status text-capitalize`}><i className="bi bi-dot"></i> {Status}</span></h2>
				</div>
				{(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') ? (
				<div className="col-6 text-end">
					<a href={`https://app.aadow.com/download-campaign-report.php?username=${CookieAccountId}&campaign_id=${CampaignId}`} target='_blank' className="primary-button text-decoration-none fs-6">Download Report</a>
				</div>
				):(
					<></>
				)}
			  </div>
          </div>
		  
		 <div className="tab-area">
      <a
        href="#"
        onClick={() => handleTabClick('#')}
        className={activeTab === '#' ? 'active' : ''}
      >
        Overview
      </a>
      <a
        href="#AbTest"
        onClick={() => handleTabClick('#AbTest')}
        className={activeTab === '#AbTest' ? 'active' : 'd-none'}
      >
        A/B Test
      </a>
      <a
        href="#delivered"
        onClick={() => handleTabClick('#delivered')}
        className={activeTab === '#delivered' ? 'active' : ''}
      >
        Delivered
      </a>
      <a
        href="#opened"
        onClick={() => handleTabClick('#opened')}
        className={activeTab === '#opened' ? 'active' : ''}
      >
        Opened
      </a>
      <a
        href="#clicked"
        onClick={() => handleTabClick('#clicked')}
        className={activeTab === '#clicked' ? 'active' : ''}
      >
        Clicked
      </a>
      <a
        href="#unsubscribed"
        onClick={() => handleTabClick('#unsubscribed')}
        className={activeTab === '#unsubscribed' ? 'active' : ''}
      >
        Unsubscribed
      </a>
      <a
        href="#hardBounced"
        onClick={() => handleTabClick('#hardBounced')}
        className={activeTab === '#hardBounced' ? 'active' : ''}
      >
        Hard-Bounced
      </a>
      <a
        href="#softBounced"
        onClick={() => handleTabClick('#softBounced')}
        className={activeTab === '#softBounced' ? 'active' : ''}
      >
        Soft-Bounced
      </a>
    </div>

		  
		   {activeTab === '#AbTest' && (
		   <div className="tab-content-area">
			<div className="top-table-area">
			  <div className="row align-items-center">
				<div className="col-10 pb-2">
				  <h6 className="top-table-heading"> Summary of your test</h6>
				</div>
				<div className="col pb-2">
					<Select className="input-form" disableUnderline fullWidth defaultValue="10" input={<InputBase />}>
						<MenuItem value={10}>Numbers</MenuItem>
						<MenuItem value={10}>Percentage</MenuItem>
					</Select>
				</div>
			  </div>
			  
			  <div className="table-area py-4 px-0 border-top border-1 ">
          <div className="row">
		  
			<div className="col-8 ">
				<h6  className="ab-metric-heading fw-bold mb-4">Rules for splitting and selecting the winner</h6>
				<div className="row px-2 my-4">
				
				<div className="col border border-1 p-4 text-center">
				<h6 className="ab-metric-col-heading">50%</h6>
				<h5 className="ab-metric-col-label">Version A</h5>
				<p className="d-none">(Approx.  recipients)</p>
				</div>
				
				
				<div className="col border border-1 p-4 text-center">
				<h6 className="ab-metric-col-heading">50%</h6>
				<h5 className="ab-metric-col-label">Version B</h5>
				<p className="d-none">(Approx.  recipients)</p>
				</div>
				
				
				
				
				</div>
			</div>
			
		  
			<div className="col-12"></div>
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Openers</h6>
				<div className="version-a">
				<div className="version-metric-heading winner_text">Version A <b className="float-end"><span  className="percentage-ab-metric">20%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div Style="Width:50%" className="actual_progress progress_winner_bg"></div></div>
				
				<div className="version-metric-heading ">Version B <b className="float-end"><span className="percentage-ab-metric">15%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg "><div  className="actual_progress "></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Clicks</h6>
				<div className="version-a">
				<div className="version-metric-heading winner_text">Version A <b className="float-end"><span className="percentage-ab-metric">70%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress progress_winner_bg"></div></div>
				
				<div className="version-metric-heading ">Version B <b className="float-end"><span className="percentage-ab-metric">35%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg "><div className="actual_progress "></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Unsubscribed</h6>
				<div className="version-a">
				<div className="version-metric-heading ">Version A <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress "></div></div>
				
				<div className="version-metric-heading ">Version B <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg "><div  className="actual_progress "></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Hard Bounced</h6>
				<div className="version-a">
				<div className="version-metric-heading">Version A <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				
				<div className="version-metric-heading">Version B <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Soft Bounced</h6>
				<div className="version-a">
				<div className="version-metric-heading">Version A <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				
				<div className="version-metric-heading">Version B <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				</div>
			</div>
			
			<div className="col-4 p-4">
				<h6  className="ab-metric-heading fw-bold mb-3">Complained</h6>
				<div className="version-a">
				<div className="version-metric-heading">Version A <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				
				<div className="version-metric-heading">Version B <b className="float-end"><span className="percentage-ab-metric">%</span><span className="d-none number-ab-metric"></span></b></div>
				<div className="progress_bg mb-4"><div  className="actual_progress"></div></div>
				</div>
			</div>
			
			
			
			
		  </div>
        </div>
			  
			</div>
		   </div>
		   )}
		  
		  {(activeTab === '#delivered' || activeTab === '#opened' || activeTab === '#clicked' || activeTab === '#unsubscribed' || activeTab === '#softBounced' || activeTab === '#hardBounced') && (
		  <div className="tab-content-area">
							  <div className="table-contact-area">
	{contacts.length === 0 && !error ? (
									<center>
										<div className="empty-error-area">
											<img className="empty-error-image w-25 mb-4" src="../../iconss/other/no-results.png"  />
											<h2 className="empty-error-heading">No Data found.</h2>
										</div>
									</center>
      ) : (
	  <div>
	<table className='table-area'>
      <thead>
        <tr>
			<th>To</th>
			<th>Date & Time</th>
        </tr>
      </thead>
      <tbody>
		  {contacts.map(contact => {
			console.log("Contact:", contact);
			return (
				<tr key={contact.id}>
				<td className="td-first">
					<h6 className="domain-email-name">{contact.email}</h6>
				</td>
				<td className="td-first">
				{(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') ? (
				<>
				{activeTab === '#delivered' && (
				  <h6 className="domain-email-name">
					{contact.datetimeDelivered ? formatDateTimeTwo(contact.datetimeDelivered) : '-------------'}
				  </h6>
				)}
				{activeTab === '#opened' && (
				  <h6 className="domain-email-name">
					{contact.datetimeOpened ? formatDateTimeTwo(contact.datetimeOpened) : '-------------'}
				  </h6>
				)}
				{activeTab === '#clicked' && (
				  <h6 className="domain-email-name">
					{contact.datetimeClicked ? formatDateTimeTwo(contact.datetimeClicked) : '-------------'}
				  </h6>
				)}
				{activeTab === '#unsubscribed' && (
				  <h6 className="domain-email-name">
					{contact.dateTimeUnsubscribed ? formatDateTimeTwo(contact.dateTimeUnsubscribed) : '-------------'}
				  </h6>
				)}
				{activeTab === '#softBounced' && (
				  <h6 className="domain-email-name">
					{contact.dateTimeSoftBounced ? formatDateTimeTwo(contact.dateTimeSoftBounced) : '-------------'}
				  </h6>
				)}
				{activeTab === '#hardBounced' && (
				  <h6 className="domain-email-name">
					{contact.dateTimeHardBounced ? formatDateTimeTwo(contact.dateTimeHardBounced) : '-------------'}
				  </h6>
				)}
			  </>
				) : (
					<h1>
					<span
					  style={{
						backgroundColor: "#f1f1f1",
						borderRadius: "5px",
						fontSize: "16px",
						padding: "10px 25px",
						userSelect: "none",
					  }}
					>
					  <b
						style={{
						  color: "transparent",
						  fontSize: "16px",
						  textShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
						}}
					  >
						12 Oct 2023, 12:34 PM
					  </b>
					</span>
					<a
					  style={{
						color: "transparent",
						fontSize: "16px",
						textShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
					  }}
					  target='_blank'
					  href="/select-plan"
					  className="ms-3 secondary-button text-decoration-none button-primary-filled bg-warning text-dark border-0 text-center m-2"
					>
					  Upgrade to View Date & Time
					</a>
				  </h1>
				  
				)}
				</td>
				</tr>
			);
			})}
      </tbody>
    </table>
			
			<div className="pagination-area p-5">
				<div className="row">
					<div className="col-2">
						<button onClick={handlePrevPage} disabled={currentPage === 0} type="button"><i className="bi bi-arrow-left me-2" ></i> Previous</button>
						
					</div>
					<div className="col-8 text-center pagination-numbers">
					{Array.from({ length: totalPages }, (_, index) => (
							<button
							  key={index}
							  onClick={() => handlePageClick(index)}
							  disabled={index === currentPage}
							  type="button"
							  className={`{index === currentPage ? 'active' : ''}`}
							>
							  {index + 1}
							</button>
					))}	
					</div>
					<div className="col-2 text-end"> 
						<button type="button" onClick={handleNextPage} disabled={currentPage === totalPages - 1}> Next <i className="bi bi-arrow-right ms-2"></i></button>
					</div>
				</div>
			</div>
			</div>
			)}
			
		  </div>
			
		  </div>
		  )}
		  
		  {activeTab === '#' && (
		  <div className="tab-content-area">
		  <div className="metrics-col-area">
			<div className="row">
				<div className="col-3">
					<div className="metrics-col">
						<div className="metrics-col-head-area">
							<h2 className="metrics-col-label">Delivered</h2>
							<h6 className="metrics-col-heading">{totalDelivered} <span>({totalDelivered > 0 ? ((totalDelivered / totalDelivered) * 100).toFixed(1) : 0}%)</span></h6>
						</div>
						<img src={myImage} />
						
					</div>
				</div>
				
				<div className="col-3">
					<div className="metrics-col">
						<div className="metrics-col-head-area">
							<h2 className="metrics-col-label">Opened</h2>
							<h6 className="metrics-col-heading text-primary">{totalOpened} <span>({totalDelivered > 0 ? ((totalOpened / totalDelivered) * 100).toFixed(1) : 0}%)</span></h6>
						</div>
						<img src={myImage} />
						
					</div>
				</div>
				
				<div className="col-3">
					<div className="metrics-col">
						<div className="metrics-col-head-area">
							<h2 className="metrics-col-label">Clicked</h2>
							<h6 className="metrics-col-heading text-success">{totalClicked} <span>({totalDelivered > 0 ? ((totalClicked / totalDelivered) * 100).toFixed(1) : 0}%)</span></h6>
						</div>
						<img src={myImage} />
						
					</div>
				</div>
				
				<div className="col-3">
					<div className="metrics-col">
						<div className="metrics-col-head-area">
							<h2 className="metrics-col-label">Unsubscribed</h2>
							<h6 className="metrics-col-heading text-danger">{totalUnsubscribed} <span>({totalDelivered > 0 ? ((totalUnsubscribed / totalDelivered) * 100).toFixed(1) : 0}%)</span></h6>
						</div>
						<img src={myImage} />
						
					</div>
				</div>
				
				
			</div>
		  </div>
		  
		  
		  <div className="basic-info-area">
		  <div className="row align-items-center">
			<div className="col-7">
				
				<div className="info-area">
					<h6 className="info-label">Sent on</h6>
					<h6 className="info">{ScheduledDate} to {TotalContacts} contacts</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">Subject</h6>
					<h6 className="info">{Subject}</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">Contact List</h6>
					<h6 className="info">{ContactLists}</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">Preview Text</h6>
					<h6 className="info">{Preview}</h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">From</h6>
					<h6 className="info">{FromName} <i class="bi bi-chevron-left"></i>{FromEmail}<i class="bi bi-chevron-right"></i></h6>
				</div>
				
				<div className="info-area">
					<h6 className="info-label">Reply to</h6>
					<h6 className="info">{ReplyTo}</h6>
				</div>
				
				
			</div>
			
			<div className="col-5">
				<img src={`${EmailTemplateThumbnail}`} className="email-preview" />
			</div>
			
		  </div>
		  </div>
		  <div className="table-area">
		  <table>
							<thead>
								<tr>
									<th>List</th>
									<th>Delivered</th>
									<th>Soft Bounced</th>
									<th>Hard Bounced</th>
									<th>Trackable Opened Rate</th>
									<th>Clicked</th>
									<th>Unsubscribed</th>
								</tr>
							</thead>
							<tbody>
								
								<tr>
									<td className="td-first">
									<h6 className="domain-email-name">{ContactLists}</h6>
									<h6 className="memeber-email">{TotalContacts}</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">({totalDelivered > 0 ? ((totalDelivered / totalDelivered) * 100).toFixed(1) : 0}%)</h6>
									<h6 className="memeber-email">{totalDelivered}</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">({totalDelivered > 0 ? ((TotalSoftBouced / totalDelivered) * 100).toFixed(1) : 0}%)</h6>
									<h6 className="memeber-email">{TotalSoftBouced}</h6>
									</td>
									
									<td className="td-first">
									<h6 className="domain-email-name">({totalDelivered > 0 ? ((TotalHardBouced / totalDelivered) * 100).toFixed(1) : 0}%)</h6>
									<h6 className="memeber-email">{TotalHardBouced}</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">({totalDelivered > 0 ? ((totalOpened / totalDelivered) * 100).toFixed(1) : 0}%)</h6>
									<h6 className="memeber-email">{totalOpened}</h6>
									</td>
									<td className="td-first">
									<h6 className="domain-email-name">({totalDelivered > 0 ? ((totalClicked / totalDelivered) * 100).toFixed(1) : 0}%)</h6>
									<h6 className="memeber-email">{totalClicked}</h6>
									</td>
									
									<td className="td-first">
									<h6 className="domain-email-name">({totalDelivered > 0 ? ((totalUnsubscribed / totalDelivered) * 100).toFixed(1) : 0}%)</h6>
									<h6 className="memeber-email">{totalUnsubscribed}</h6>
									</td>
									
								</tr>
								
								
							</tbody>
						</table>
						</div>
		  
      </div>
	  
		  )}
      </div>
	  )}
      </div>
	  <br/><br/><br/><br/><br/><br/>
    </SideBar>
  );
};

export default CampaignOverview;