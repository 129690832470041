import React from 'react';
import { Select, MenuItem, Box, Typography } from '@mui/material';

const TimeFormatSelect = ({ timeformatselectedOption, timeformathandleChange }) => {
  const timeformatoptions = [
	{ value: '12 Hours', label: '12 Hours' },
	{ value: '24 Hours', label: '24 Hours' }
  ]; 

  return (
    <Select
      labelId="timeformat-select-label"
      id="timeformat-select"
      className="select-box w-100"
      value={timeformatselectedOption} // Use value from props
      onChange={(event) => timeformathandleChange(event.target.value)} // Call function from props
    >
      {timeformatoptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Box display="flex" alignItems="center">
            <Typography>{option.label}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default TimeFormatSelect;
