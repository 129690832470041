import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/private/sender-authentication.css';
import SideBar from './../components/SideBar';
import Domains from './components/sender-authentication/domains';
import SingleSenders from './components/sender-authentication/SingleSenders';
// Import other settings components as needed

const SenderAuthentication
 = () => {
  const [currentTab, setCurrentTab] = useState('domain');

  useEffect(() => {
    document.title = 'Sender Authentication | Aadow';

    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); // Remove the '#' from the hash
      setCurrentTab(hash || 'domain'); // Default to 'general' if the hash is empty
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // Call once to handle the initial load

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <SideBar>
      <div className="settings-content">
        <div className="settings-body">
          <div className="breadcrumb-area">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Sender Authentication</li>
              </ol>
            </nav>
          </div>

          <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-12">
                  <h1 className="main-heading">Sender Authentication</h1>

                  <div className="settings-tabs-area">
                    <a href="#" className={`settings-tab ${currentTab === 'domain' ? 'active' : ''}`}>Domain</a>
                    <a href="#single-sender" className={`settings-tab ${currentTab === 'single-sender' ? 'active' : ''}`}>Single Sender</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Conditional Rendering of the Settings Components */}
          {currentTab === 'domain' && <Domains />}
          {currentTab === 'single-sender' && <SingleSenders />}
          {/* Add other settings components conditionally */}

        </div>
      </div>
    </SideBar>
  );
};

export default SenderAuthentication;