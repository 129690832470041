import React, { useState, useEffect, useRef } from 'react';
import '../../css/private/import-contacts.css';
import { Link } from 'react-router-dom';
import SideBar from './../components/SideBar';
import axios from 'axios'; // or use fetch if you prefer
import { Select, MenuItem, FormControl, InputBase, InputLabel, Checkbox, FormControlLabel } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from 'js-cookie';

const ImportContactsCopyPaste = () => {
	
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const [selectedListOption, setSelectedListOption] = useState("");
  
  const [selectedFolderOption, setSelectedFolderOption] = useState("");

  
	const [FullPageloading, setFullPageloading] = useState(false);
  
  const CookietId = Cookies.get('current_Id');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountEmail = Cookies.get('current_accountEmail');
  useEffect(() => {
    
    document.title = 'Import Contacts | Aadow';
  }, []);
  const [NewListName, setNewListName] = useState('');
  const [NewListNameError, setNewListNameError] = useState(false);
  const NewListNameRef = useRef(null);

  
  const [NewFolderName, setNewFolderName] = useState('');
  const [NewFolderNameError, setNewFolderNameError] = useState(false);
  const NewFolderNameRef = useRef(null);
	
  const [currentStep, setCurrentStep] = useState(0); // Step state
  const [responseMessage, setresponseMessage] = useState(0); // Step state
  const [ResponseSuccessMessage, setResponseSuccessMessage] = useState(""); // Step state
  const [checked, setChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1); // Pre-selected value
  const [textareaValue, setTextareaValue] = useState(""); // State for textarea value
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [errorList, seterrorList] = useState(""); // State for error message
  const [totalContactsInList, setTotalContactsInList] = useState(0);
  const [contactsWillBeProcessed, setContactsWillBeProcessed] = useState(0);
  const [contactsWillBeSkipped, setContactsWillBeSkipped] = useState(0);

  const options = [
    { value: 1, label: "First Name", image: "../iconss/other/cells.png" },
    { value: 2, label: "Last Name", image: "../iconss/other/cells.png" },
    { value: 3, label: "Email", image: "../iconss/other/cells.png" },
  ];

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setChecked(event.target.checked);
  };
  
  
  const handleImport = async (event) => {
  

  seterrorList("");


  setChecked(true);

  if (GetFolderId.trim() === '') {
    seterrorList("Please Select a Folder");
    return;
  }
  if (GetFolderId.trim() === 'new-folder') {
    if (NewFolderName.trim() === '') {
      seterrorList("Please enter a Folder Name");
      return;
    }else{
      setFinalFolderId(NewFolderName);
      setFinalFolderName(NewFolderName);
    }
    if (NewListName.trim() === '') {
        seterrorList("Please enter a List Name");
        return;
    }else{
      setFinalListId(GetListId);
      setFinalListName(GetListName);
    }
    
  setChecked(false);
  }else{
    
    setChecked(true);
    setFinalFolderId(GetFolderId);
    setFinalFolderName(GetFolderName);

    if (GetListId.trim() === '') {
      seterrorList("Please Select a List");
      return;
    }
  
    if (GetListId.trim() === 'new-list') {
      setChecked(false);
      if (NewListName.trim() === '') {
        seterrorList("Please enter a List Name");
        return;
      }else{
        setFinalListId(NewListName);
        setFinalListName(NewListName);
      }
    }else{
      setFinalListId(GetListId);
      setFinalListName(GetListName);
    }
  }

  
  // Validate if at least one email is valid
  if (!validateEmails(textareaValue)) {
    setErrorMessage("Please enter at least one valid email address.");
    return; // Prevent proceeding if no valid email is found
  }
  handleImportSubmit();
  };
  
  const handleImportSubmit = async (event) => {

  
    setCurrentStep(3);
    setresponseMessage(0);
    
  const generateRandomIdForList = () => {
    return Math.floor(10000000 + Math.random() * 90000000); // Generates a number between 10000000 and 99999999
  };

  
  const generateRandomIdForFolder = () => {
    return Math.floor(100000000 + Math.random() * 900000000); // Generates a number between 10000000 and 99999999
  };
  const emailArray = textareaValue.split(/\s+/).filter(email => email);
  
  let LastListName = GetListName;
  let LastListId = GetListId;
  let LastFolderId = GetFolderId;
  let LastFolderName = GetFolderName;
   
  if (GetFolderId.trim() === 'new-folder') {
    LastListName = NewListName;
    LastListId = generateRandomIdForList();
    LastFolderId = generateRandomIdForFolder();
    LastFolderName = NewFolderName;
  }

  if (GetListId.trim() === 'new-list') {
    LastListName = NewListName;
    LastListId = generateRandomIdForList();
  }


  const body = new URLSearchParams({
    username: CookieAccountId,
    noduplicate: checked,
    listname: LastListName, // Use new list name if creating a new list
    listid: LastListId,
    folderid: LastFolderId,
    folder: LastFolderName,
    status: "active",
  });

  // Add email addresses to the body
  emailArray.forEach(email => {
    body.append('emails', email);
  });

  try {
    const response = await fetch(`${ServerApiDomain}/import-contacts/copy-paste`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: body.toString(),
    });

    const data = await response.text();
    setresponseMessage(1);
    setResponseSuccessMessage(data);
    setCurrentStep(3); // Move to the success step
  } catch (error) {
    alert('Error importing contacts: ' + error.message);
  }
};

  const handleTextareaChange = (event) => {
    const value = event.target.value;
    setTextareaValue(value);

    const emailArray = value.split(/\s+/).filter(email => email); // Split and filter empty strings
    setTotalContactsInList(emailArray.length);

    const emailCounts = {};
    const validEmails = [];

    emailArray.forEach(email => {
      if (validateEmail(email)) {
        if (!emailCounts[email]) {
          emailCounts[email] = 1; // Count unique valid emails
          validEmails.push(email);
        } else {
          emailCounts[email] += 1; // Count duplicates
        }
      }
    });

    setContactsWillBeProcessed(validEmails.length);
    setContactsWillBeSkipped(emailArray.length - validEmails.length + Object.keys(emailCounts).length - validEmails.length);

    if (value) {
      setErrorMessage(""); // Clear error message if textarea is filled
    }
  };

  // Function to validate email addresses
  const validateEmails = (emails) => {
    const emailArray = emails.split(/\s+/); // Split emails by whitespace
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for validating email addresses

    return emailArray.some(email => emailRegex.test(email)); // Check if at least one valid email exists
  };

  const handleNext = () => {
    if (currentStep === 0) {
      // Validate textarea before going to the next step
      if (!textareaValue.trim()) {
        setErrorMessage("Please fill out the email addresses before proceeding.");
        return; // Prevent moving to the next step if textarea is empty
      }

      // Validate if at least one email is valid
      if (!validateEmails(textareaValue)) {
        setErrorMessage("Please enter at least one valid email address.");
        return; // Prevent moving to the next step if no valid email is found
      }
    }
	
	if (currentStep === 1) {
      // Validate textarea before going to the next step
      if (!textareaValue.trim()) {
        setErrorMessage("Please fill out the email addresses before proceeding.");
        return; // Prevent moving to the next step if textarea is empty
      }

    }
	
    if (currentStep < 2) setCurrentStep(currentStep + 1); // Go to the next step
  };

  const handlePrev = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1); // Go to the previous step
  };

  
  
  const [username, setUsername] = useState('pachorieshan');
  const [listName, setListName] = useState('listname');
  const [listId, setListId] = useState('listid');
  const [folderId, setFolderId] = useState('folderid');
  const [folder, setFolder] = useState('folderName');
  const [emails, setEmails] = useState(['panddanbdka@gmail.com', 'dakn@nkda.dkan', 'afknskan@nkda.dakn']);
  const [message, setMessage] = useState('');


  
  const [GetListId, setGetListId] = useState('');
  const [GetListName, setGetListName] = useState('');
  const [GetFolderId, setGetFolderId] = useState('');
  const [GetFolderName, setGetFolderName] = useState('');

  
  const [FinalListId, setFinalListId] = useState('');
  const [FinalListName, setFinalListName] = useState('');
  const [FinalFolderId, setFinalFolderId] = useState('');
  const [FinalFolderName, setFinalFolderName] = useState('');

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  
  
  
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for validating email addresses
    return emailRegex.test(email);
  };


  const [folders, setFolders] = useState([]); // To store the folder list
  const [lists, setLists] = useState([]); // To store the lists based on the selected folder

  useEffect(() => {
    axios
      .get(`${ServerApiDomain}/api/email-contacts/distinct-lists?username=${CookieAccountId}`)
      .then((response) => {
        setFolders(response.data); // Save the folder data in state
      })
      .catch((error) => {
        console.error('Error fetching folders:', error);
      });
  }, []);
  
  // Handle folder selection
  const handleFolderSelectChange = (event) => {
    const selectedFolderId = event.target.value; // Get the folderid
    
    // Find the folder with the selected folderid
    const selectedFolder = folders.find(folder => folder.folderid === selectedFolderId);
    
    // Get the foldername of the selected folder
    const selectedFolderName = selectedFolder ? selectedFolder.folder : null;
  
    setSelectedFolderOption(selectedFolderId); // Set the selected folderid
    
    // Show an alert with both the folderid and the foldername
    console.log(`Selected Folder ID: ${selectedFolderId}, Folder Name: ${selectedFolderName}`);
  
    // Filter the lists based on the selected folderid
    const filteredLists = folders.filter(
      (folder) => folder.folderid === selectedFolderId // Match by folderid
    );
    setLists(filteredLists); // Update the list options based on selected folder
    setGetFolderId(selectedFolderId);
    setGetFolderName(selectedFolderName);
    setSelectedListOption(''); // Reset the list selection when folder changes
  };
  
  // Handle list selection
  const handleListSelectChange = (event) => {
    const selectedListId = event.target.value; // Get the listid
    
    // Find the list with the selected listid
    const selectedList = lists.find(list => list.listid === selectedListId);
    
    // Get the listname of the selected list
    const selectedListName = selectedList ? selectedList.listname : null;
  
    setSelectedListOption(selectedListId); // Set the selected listid
    
    // Show an alert with both the listid and the listname
    console.log(`Selected List ID: ${selectedListId}, List Name: ${selectedListName}`);
    
    setGetListId(selectedListId);
    setGetListName(selectedListName);
  };


  return (
    <SideBar>
      <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
      <div className="import-contacts">
        <div className="import-contacts-body">
          <div className="breadcrumb-area"> 
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link>Import Contacts</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Copy-Paste</li>
              </ol>
            </nav>
          </div>

          {/* Step 1: Copy-Paste Area */}
          {currentStep === 0 && (
            <div className="step-one mt-0">
              <div className="header-area mt-0">
                <div className="top-area mt-0">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h1 className="main-heading">Import Contacts: Copy-Paste</h1>
                      <h6 className="main-subheading">Easily import bulk email addresses by copying and pasting them below</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="import-area">
                <div className="copy-paste-area">
                  <textarea
                    className=""
                    placeholder="email1@example.com
email2@example.com
email3@example.com
email4@example.com
email5@example.com
email6@example.com
email7@example.com
email8@example.com
email9@example.com
email10@example.com"
                    value={textareaValue}
                    onChange={handleTextareaChange} // Handle textarea input
                  ></textarea>
                  {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Show error message */}
                </div>

                <h6 className="uploader-label">
                  <a href="https://www.aadow.com/faqs/import%20contacts" target='_blank' className='text-dark'>Learn More</a> about importings contacts
                </h6>

                <div className="footer-area text-end">	
                  <button type="button" className="primary-button ms-2" onClick={handleNext}>Next</button>
                </div>
              </div>
            </div>
          )}

          {/* Step 2: Map Properties */}
		  {/*
          {currentStep === 5 && (
            <div className="step-two d-none">
              <div className="mid-area">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h1 className="main-heading">Map Properties</h1>
                    <h6 className="main-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                  </div>
                </div>

                <div className="mapping-area">
                  <div className="row">
                    <div className="col-4">
                      <label>Columns in your file</label>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-4">
                      <label>Properties in Aadow</label>
                    </div>
                  </div>

                  {Array.from({ length: 4 }, (_, index) => (
                    <div className="row" key={index}>
                      <div className="col-4">
                        <FormControl fullWidth variant="outlined">
                          <Select
                            labelId="custom-select-label"
                            value={selectedValue}
                            onChange={handleChange}
                            className="custom-select"
                          >
                            {options.map((option) => (
                              <MenuItem key={option.value} value={option.value} className="custom-menu-item">
                                <img src={option.image} alt={option.label} className="menu-item-icon" />
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-1">
                        <h6 className="right-arrow"><i className="bi bi-arrow-right text-center"></i></h6>
                      </div>
                      <div className="col-4">
                        <FormControl fullWidth variant="outlined">
                          <Select
                            labelId="custom-select-label"
                            value={selectedValue}
                            onChange={handleChange}
                            className="custom-select"
                          >
                            {options.map((option) => (
                              <MenuItem key={option.value} value={option.value} className="custom-menu-item">
                                <img src={option.image} alt={option.label} className="menu-item-icon" />
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ))}

                  <div className="footer-area text-end">
                    <button type="button" className="primary-outline-button me-2" onClick={handlePrev}>Prev</button>
                    <button type="button" className="primary-button ms-2" onClick={handleNext}>Next</button>
                  </div>
                </div>
              </div>
            </div>
          )}
		  */}
          {/* Step 3: Final Import */}
          {currentStep === 1 && (
            <div className="step-three">
              <div className="mid-area">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h1 className="main-heading">Import Contacts: Copy-Paste</h1>
                      <h6 className="main-subheading">Easily import bulk email addresses by copying and pasting them below</h6>
                  </div>
                </div>

                <div className="final-import">
                  <div className="total-contacts-area">
                    <div className="row">
                      <div className="col-4">
                        <h2 className="import-contacts-heading">{totalContactsInList}</h2>
                        <h6 className="import-contacts-subheading">Total Contacts in your list</h6>
                      </div>
                      <div className="col-4">
                        <h2 className="import-contacts-heading">{contactsWillBeProcessed}</h2>
                        <h6 className="import-contacts-subheading">Contacts will be Processed</h6>
                      </div>
                      <div className="col-4">
                        <h2 className="import-contacts-heading">{contactsWillBeSkipped}</h2>
                        <h6 className="import-contacts-subheading">Contacts will be skipped</h6>
                      </div>
                    </div>
                  </div>
				  
				          <div className="row">
                    <div className="col-12 mt-3">
                      <h6 className="listname-heading">Name your import<b className="text-danger">*</b></h6>
                      <h6 className="listname-subheading">This is the name that would show up when filtering contacts by search</h6>
                      
                      <div className='row'>
                        <div className='col-3'>
                        <label>Folder<span className='text-danger'>*</span></label>
                        <Select
                          className="input-form"
                          sx={{ fontSize: '4vw' }}
                          disableUnderline
                          fullWidth
                          style={{height: '2.25vw', marginTop: "0"}}
                          value={selectedFolderOption}
                          onChange={handleFolderSelectChange}
                          input={<InputBase />}
                        >
                          <MenuItem value="" selected>Select a Folder....</MenuItem>
                          {/* Dynamically populate folder options with folderid as value */}
                          {[
                            ...new Map(folders.map((folder) => [folder.folderid, folder])).values() // Ensure unique folderid
                          ].map((folder) => (
                            <MenuItem key={folder.folderid} value={folder.folderid}>
                              {folder.folder} {/* Display folder name, but value is folderid */}
                            </MenuItem>
                          ))}
                          <MenuItem value="new-folder">Create new Folder</MenuItem>
                        </Select>
                        </div>

                        {selectedFolderOption !== "new-folder" && (
                        <div className='col-3'>
                        <label>List<span className='text-danger'>*</span></label>
                        <Select
                          className="input-form"
                          sx={{ fontSize: '4vw' }}
                          disableUnderline
                          fullWidth
                          style={{height: '2.25vw', marginTop: "0"}}
                          value={selectedListOption}
                          onChange={handleListSelectChange}
                          input={<InputBase />}
                        >
                          <MenuItem value="" selected>Select a List....</MenuItem>
                          {/* Dynamically populate list options with listid as value */}
                          {[
                            ...new Map(lists.map((list) => [list.listid, list])).values() // Ensure unique listid
                          ].map((list) => (
                            <MenuItem key={list.listid} value={list.listid}>
                              {list.listname} {/* Display list name, but value is listid */}
                            </MenuItem>
                          ))}
                          <MenuItem value="new-list">Create new List</MenuItem>
                        </Select>
                        </div> 
                        )}

                        {selectedFolderOption === "new-folder" && (
                          <div className='col-3'>
                        <label>Folder<span className='text-danger'>*</span></label>
                          <input type="text" ref={NewFolderNameRef} className={`input-form w-100 py-2 ${NewFolderNameError ? 'input-error' : ''}`} value={NewFolderName} onChange={(e) => {
                                setNewFolderName(e.target.value);
                              }} placeHolder="New Folder Name*" />
                          </div>    
                          )}

                        

                        {(selectedFolderOption === "new-folder" || selectedListOption === "new-list") && (
                          <div className='col-3'>
                        <label>Listname<span className='text-danger'>*</span></label>
                          <input type="text" ref={NewListNameRef} className={`input-form w-100 py-2 ${NewListNameError ? 'input-error' : ''}`} value={NewListName} onChange={(e) => {
                                setNewListName(e.target.value);
                              }} placeHolder="New List Name*" />
                          </div>    
                          )}    



                        <div className='col-12'>
                          {(selectedFolderOption === "new-folder" || selectedListOption === "new-list") && (
                          <div className="d-block">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={checked}
                                            onChange={(e) => setChecked(e.target.checked)}
                                            color="primary"
                                          />
                                        }
                                        label="Do not import duplicate contacts"
                                        className="custom-label" // Apply the custom CSS class
                                      />
                                    </div>
                          )}
                        </div>
                      </div>
                      
                      {errorList && <p className="error-message">{errorList}</p>}

					  
                    </div>
                  </div>

                  <div className="footer-area text-end">
                    <button type="button" className="primary-outline-button me-2" onClick={handlePrev}>Prev</button>
                    <button type="button" className="primary-button ms-2" onClick={handleImport}>Import</button>
                  </div>
                </div>
              </div>
            </div>
          )}
		  
		  
		  {currentStep === 3 && (
            <div className="step-three">
              <div className="mid-area">
				{responseMessage === 0 && (
						<div className="col-12">
							<div id="step_three" className="right-content success-content">
							  <img src="../iconss/other/sand-clock.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">We're adding your contacts</h2>
							  <h2 className="secondary-description-modal text-center">Depending on the number of contacts you've uploaded, this may take several minutes. You can move on to your next tasks in the mean time.</h2>
							  <center><Link to="/audience-overview" className="primary-button text-decoration-none float-center d-none" >Okay</Link></center>
							</div>
						</div>
						
				)}
				
				{responseMessage === 1 && (
						<div className="col-12">
							<div id="step_three" className="right-content success-content">
							  <img src="../iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">{ResponseSuccessMessage}</h2>
							  <center><Link to="/audience-overview" className="primary-button text-decoration-none float-center mt-3 d-inline-block" >Okay, Got it!</Link></center>
							</div>
						</div>
						
				)}
              </div>
            </div>
          )}
		  
		  
        </div>
      </div>
    </SideBar>
  );
};

export default ImportContactsCopyPaste;
