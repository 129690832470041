import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/PreviewEmailTemplate.css';
import Cookies from 'js-cookie';



const PreviewPage = () => {
    
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const [viewMode, setViewMode] = useState("desktop"); // "desktop" or "mobile"
  const [htmlFileUrl, sethtmlFileUrl] = useState(""); // "desktop" or "mobile"
  
  const [loading, setLoading] = useState(false);
  const [Error, setError] = useState(null);

  const CookieAccountId = Cookies.get('current_accountId');
  const { TemplateId } = useParams();

  
  useEffect(() => {
    
    document.title = 'Preview Email Template | Aadow';
    const fetchEmailCampaign = async () => {
            try {
                // Fetch email template data
                const response = await fetch(
                    `${ServerApiDomain}/api/emailtemplates/${TemplateId}/${CookieAccountId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                // Log the data to verify structure
                console.log('API Response:', data);

                // Ensure `data.content` exists and process the path
               
                
                


                if(data.content !== ""){
                    const getEmailTemplatePath = (path) => {
                        // Remove everything before 'emailtemplates' and replace backslashes with forward slashes
                        const updatedPath = path.replace(/^.*emailtemplates/, '/emailtemplates').replace(/\\/g, '/');
                        return updatedPath;
                    };
                    
                    // Example usage:
                    const filePath = data.content;
                    const templatePath = getEmailTemplatePath(filePath);
                    try {
                        const response = await fetch(templatePath);
                        if (!response.ok) {
                            throw new Error('Failed to fetch the HTML file');
                        }
                        
                        sethtmlFileUrl(templatePath);
                        
                    } catch (error) {
                        console.error('Error fetching HTML:', error);
                    }
                    }else{
                    }



            } catch (error) {
                console.error('Fetch error:', error);
                setError('TRUE');
                setLoading(false);
            }
        };

        fetchEmailCampaign();
    }, [TemplateId, CookieAccountId]);

  return (
    <div className="preview-mail-area">
        {Error === 'TRUE' && (
            <center>
                <div className="empty-error-area">
                    <img className="empty-error-image w-25" src="../../iconss/other/no-results.png"  />
                    <h2 className="empty-error-heading">No Template Found</h2>
                    <h6 className="empty-error-subheading" >It looks like there are no template associated with this id.</h6>
                </div>
            </center>
        )}
        {Error !== 'TRUE' && (
        <div className="preview-pro-container">
        {/* Header */}
        <div className="header">
        <h1>Preview Your Template</h1>
        </div>

        {/* Tabs */}
        <div className="tabs">
        <button
            className={viewMode === "desktop" ? "active" : ""}
            onClick={() => setViewMode("desktop")}
        >
            <span className="tab-icon">🖥</span> Desktop
        </button>
        <button
            className={viewMode === "mobile" ? "active" : ""}
            onClick={() => setViewMode("mobile")}
        >
            <span className="tab-icon">📱</span> Mobile
        </button>
        </div>

        {/* Preview Area */}
        <div className="preview-area">
        {viewMode === "desktop" && (
            <div className="desktop-frame">
            <div className="browser-bar">
                <span className="dot red"></span>
                <span className="dot yellow"></span>
                <span className="dot green"></span>
                <div className="url-placeholder">https://www.aadow.com/</div>
            </div>
            <iframe
                src={htmlFileUrl}
                title="Desktop Preview"
                className="desktop-preview"
                sandbox="allow-scripts allow-same-origin"
            />
            </div>
        )}
        {viewMode === "mobile" && (
            <div className="mobile-frame">
            <div className="phone-mockup">
                <iframe
                src={htmlFileUrl}
                title="Mobile Preview"
                className="mobile-preview"
                sandbox="allow-scripts allow-same-origin"
                />
            </div>
            </div>
        )}
        </div>
    </div>
        )}
  </div>
  );
};

export default PreviewPage;
