import React, { useState, useEffect } from 'react';

const RazorpayCheckout = () => {
    const [amount, setAmount] = useState(0);
    const [razorpayLoaded, setRazorpayLoaded] = useState(false);

    useEffect(() => {
        const loadRazorpayScript = () => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => setRazorpayLoaded(true);
            document.body.appendChild(script);
        };

        loadRazorpayScript();
    }, []);

    const handlePayment = () => {
        if (!razorpayLoaded) {
            alert('Razorpay SDK not loaded yet.');
            return;
        }

        const options = {
            key: 'rzp_test_a9Y2LPAou4n5tp', // Replace with your Razorpay key ID
            amount: amount * 100, // Amount is in paise
            currency: 'INR',
            name: 'Your Company Name',
            description: 'Test Transaction',
            handler: function (response) {
                // Handle success here
                alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
                console.log(response);
            },
            prefill: {
                name: 'Customer Name',
                email: 'customer@example.com',
                contact: '9999999999',
            },
            theme: {
                color: '#000000',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Razorpay Checkout</h2>
            <div>
                <label>
                    Amount (INR):
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        min="1"
                    />
                </label>
            </div>
            <button onClick={handlePayment} disabled={amount <= 0}>
                Pay Now
            </button>
        </div>
    );
};

export default RazorpayCheckout;
