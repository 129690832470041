import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate, useHistory } from 'react-router-dom';
import '../../css/private/email-campaigns.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Cookies from 'js-cookie';
import ReactSpeedometer from "react-d3-speedometer";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

  const EmailCampaigns = () => {
	
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
	const [FullPageloading, setFullPageloading] = useState(false);
	const CurrentPlanName = Cookies.get('CurrentPlanName');
  
	const getCurrentDateTime = () => {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
		const day = String(now.getDate()).padStart(2, '0');
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');
		const seconds = String(now.getSeconds()).padStart(2, '0');
	  
		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
	  };

  const CookietId = Cookies.get('current_Id');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountEmail = Cookies.get('current_accountEmail');
   // Initialize with the current hash
  
  const [showDropdown, setShowDropdown] = useState(null); // Track which dropdown is open
  const dropdownRefs = useRef([]); // Array to hold refs for multiple dropdowns

  const toggleDropdown = (index) => {
    setShowDropdown(showDropdown === index ? null : index); // Toggle dropdown based on index
  };
  
  const navigate = useNavigate();

  const [showAbOptions, setShowAbOptions] = useState(false);

  const closeDropdown = () => {
    setShowDropdown(null);
  };

  const apiUrl = `${ServerApiDomain}/api/campaigns/add`; // API URL

  
  const handleCreateABTestCampaign = () => {
    setShowAbOptions(!showAbOptions); // Toggle the visibility
  };

  const handleCreateTemplateAbCampaign = async () => {
	setFullPageloading(true);
	const currentDateTime = getCurrentDateTime();
    // Data to send in the request body
    const campaignData = {
		"scheduledDateTime": currentDateTime,
		"username": CookieAccountId,
		"campaignName": "Untitled Campaign",
		"campaignLabel": "",
		"status": "draft",
		"subject": "",
		"fromName": "",
		"fromEmail": "",
		"replyEmail": "",
		"delivered": "0",
		"opened": "0",
		"clicked": "0",
		"unsubscribed": "0",
		"softBounced": "0",
		"hardBounced": "0",
		"preview": "",
		"emailTemplateB": "",
		"subjectB": "",
		"emailTemplate": "",
		"contactLists": "",
		"totalContacts": "",
		"totalContactListsId": "",
		"attachments": "",
		"segments": "",
		"archived": "",
		"timeZone": "",
		"scheduledDateTimeB": "",
		"testGroupSize": "",
		"testDuration": "",
		"testDurationType": "",
		"ab": "true",
		"abType": "template",
		"winingRule": ""
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });

      // Check if the response is okay
      if (!response.ok) {
        throw new Error('Failed to create campaign');
      }

      const data = await response.json();
	  navigate('/create/email-campaign/' + data.campaignId);

    } catch (error) {
      alert('Error:'+ error);
      // Handle error, maybe show a notification to the user
    }
  };


  const handleCreateSubjectAbCampaign = async () => {
	setFullPageloading(true);
	const currentDateTime = getCurrentDateTime();
    // Data to send in the request body
    const campaignData = {
		"scheduledDateTime": currentDateTime,
		"username": CookieAccountId,
		"campaignName": "Untitled Campaign",
		"campaignLabel": "",
		"status": "draft",
		"subject": "",
		"fromName": "",
		"fromEmail": "",
		"replyEmail": "",
		"delivered": "0",
		"opened": "0",
		"clicked": "0",
		"unsubscribed": "0",
		"softBounced": "0",
		"hardBounced": "0",
		"preview": "",
		"emailTemplateB": "",
		"subjectB": "",
		"emailTemplate": "",
		"contactLists": "",
		"totalContacts": "",
		"totalContactListsId": "",
		"attachments": "",
		"segments": "",
		"archived": "",
		"timeZone": "",
		"scheduledDateTimeB": "",
		"testGroupSize": "",
		"testDuration": "",
		"testDurationType": "",
		"ab": "true",
		"abType": "subject",
		"winingRule": ""
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });

      // Check if the response is okay
      if (!response.ok) {
        throw new Error('Failed to create campaign');
      }

      const data = await response.json();
	  navigate('/create/email-campaign/' + data.campaignId);

    } catch (error) {
      alert('Error:'+ error);
      // Handle error, maybe show a notification to the user
    }
  };

  const handleCreateRegularCampaign = async () => {
	setFullPageloading(true);
	const currentDateTime = getCurrentDateTime();
    // Data to send in the request body
    const campaignData = {
		"scheduledDateTime": currentDateTime,
		"username": CookieAccountId,
		"campaignName": "Untitled Campaign",
		"campaignLabel": "",
		"status": "draft",
		"subject": "",
		"fromName": "",
		"fromEmail": "",
		"replyEmail": "",
		"delivered": "0",
		"opened": "0",
		"clicked": "0",
		"unsubscribed": "0",
		"preview": "",
		"emailTemplateB": "",
		"softBounced": "0",
		"hardBounced": "0",
		"subjectB": "",
		"emailTemplate": "",
		"contactLists": "",
		"totalContacts": "",
		"totalContactListsId": "",
		"attachments": "",
		"segments": "",
		"archived": "",
		"timeZone": "",
		"scheduledDateTimeB": "",
		"testGroupSize": "",
		"testDuration": "",
		"testDurationType": "",
		"ab": "",
		"abType": "",
		"winingRule": ""
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });

      // Check if the response is okay
      if (!response.ok) {
        throw new Error('Failed to create campaign');
      }

      const data = await response.json();
	  navigate('/create/email-campaign/' + data.campaignId);

    } catch (error) {
      alert('Error:'+ error);
      // Handle error, maybe show a notification to the user
    }
  };

  const handleDuplicateCampaign = async (item) => { 
	const currentDateTime = getCurrentDateTime();
	// Data to send in the request body, now with the extra fields from 'item'
	const campaignData = {
	  scheduledDateTime: currentDateTime,
	  username: CookieAccountId,
	  campaignName: "Copy - " + item.campaignName,
	  campaignLabel: "",
	  status: "draft",
	  subject: item.subject,            // Additional field
	  fromName: item.fromName,          // Additional field
	  fromEmail: item.fromEmail,        // Additional field
	  replyEmail: item.replyEmail,      // Additional field
	  delivered: "0",        // Additional field
	  opened: "0",              // Additional field
	  clicked: "0",            // Additional field
	  unsubscribed: "0",  // Additional field
	  preview: item.preview,            // Additional field
	  emailTemplateB: item.emailTemplateB, // Additional field
	  softBounced: "0",    // Additional field
	  hardBounced: "0",    // Additional field
	  subjectB: item.subjectB,          // Additional field
	  emailTemplate: item.emailTemplate, // Additional field
	  contactLists: item.contactLists,   // Additional field
	  totalContacts: item.totalContacts, // Additional field
	  totalContactListsId: item.totalContactListsId, // Additional field
	  attachments: item.attachments,      // Additional field
	  segments: item.segments,            // Additional field
	  archived: item.archived,            // Additional field
	  timeZone: item.timeZone,            // Additional field
	  scheduledDateTimeB: "2024-10-05T10:00:00",
	  testGroupSize: item.testGroupSize, // Additional field
	  testDuration: item.testDuration,   // Additional field
	  testDurationType: item.testDurationType, // Additional field
	  ab: item.ab,                       // Additional field
	  abType: item.abType,               // Additional field
	  winingRule: item.winingRule        // Additional field
	};
  
	try {
	  const response = await fetch(apiUrl, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/json',
		},
		body: JSON.stringify(campaignData),
	  });
  
	  // Check if the response is okay
	  if (!response.ok) {
		throw new Error('Failed to create campaign');
	  }
  
	  const data = await response.json();
	  navigate(`/create/email-campaign/${data.campaignId}`); // Navigate to the new campaign page
  
	} catch (error) {
	  alert('Error: ' + error);
	  // Handle error, maybe show a notification to the user
	}
  };
  

  // Close dropdown when clicking outside of it
  useEffect(() => {
    document.title = 'Email Campaigns | Aadow';

    const handleClickOutside = (event) => {
      if (dropdownRefs.current.some(ref => ref && !ref.contains(event.target))) {
        setShowDropdown(null);
      }
    };

    
    // Set the active tab based on the current hash
    const handleHashChange = () => {
      setActiveTab(window.location.hash || '#All'); // Default to '#All' if no hash exists
    };

    // On mount, set the active tab
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  
  const [triggeredBy, setTriggeredBy] = useState('All');
  const [duration, setDuration] = useState('All');
  const [campaignType, setCampaignType] = useState('All');
  const [sortOrder, setSortOrder] = useState('All');
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [verifyDomainLink, setVerifyDomainLink] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
	const current_username = 'pachorieshan';
	const username = 'testuser';
	const password = 'testpassword';
	const encodedCredentials = btoa(`${username}:${password}`);
	
	const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
	
	const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
		closeDropdown();
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
        setSelectedId(null);
    };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorDomain("");
	setDomain("");
    setDomainError(false);
    setInputApiArea("true");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [Domain, setDomain] = useState('');
  const [DomainError, setDomainError] = useState(false);
  const [ErrorDomain, setErrorDomain] = useState('');
  const [DomainAdded, setDomainAdded] = useState('');
  const [DomainSentTo, setDomainSentTo] = useState('');
  const DomainRef = useRef(null);
  
  
  
  const [InputApiArea, setInputApiArea] = useState('true');
  
  
  const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [noData, setNoData] = useState(false);
    const loadedIds = useRef(new Set());

    
	const [activeTab, setActiveTab] = useState(window.location.hash || '#All');
    // ... other state variables

    const fetchData = useCallback(async () => {
		if (loading || !hasMore) return;
	
		setLoading(true);
		const AUTH = 'Basic ' + btoa('testuser:testpassword');
		let url;
	
		try {
			// Determine which URL to use based on filters
			if (
				triggeredBy === 'All' &&
				duration === 'All' &&
				campaignType === 'All' &&
				sortOrder === 'All' &&
				activeTab === '#All'
			) {
				// Use the default URL when all filters are "All"
				url = `${ServerApiDomain}/api/campaigns/username/${CookieAccountId}?page=${page}`;
			} else {
				// Use the filtered URL otherwise
				url = `${ServerApiDomain}/api/campaigns/filtered?username=${CookieAccountId}&page=${page}`;
	
				// Append filters to the URL
				if (activeTab !== '#All') {
					const status = activeTab.replace('#', '').toLowerCase();
					url += `&status=${status}`;
				}
				if (triggeredBy !== 'All') {
					url += `&fromEmail=${triggeredBy}`;
				}
				if (duration !== 'All') {
					url += `&duration=${duration}`;
				}
				if (campaignType !== 'All') {
					url += `&campaignLabel=${campaignType}`;
				}
				if (sortOrder !== 'All') {
					url += `&sortOrder=${sortOrder}`;
				}
			}
			
	
			const response = await fetch(url, {
				headers: {
					'Authorization': AUTH,
				},
			});
	
			const result = await response.json();
	
			if (result.content.length > 0) {
				const newData = result.content.filter(item => !loadedIds.current.has(item.campaignId));
	
				if (newData.length > 0) {
					setData(prevData => [...prevData, ...newData]);
					newData.forEach(item => loadedIds.current.add(item.campaignId)); // Mark IDs as loaded
					setPage(prevPage => prevPage + 1);
				}
			} else {
				setHasMore(false); // No more data available
			}
	
			// Check if no data was returned initially
			if (page === 0 && result.content.length === 0) {
				setNoData(true);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	}, [loading, hasMore, page, activeTab, triggeredBy, duration, campaignType, sortOrder]);
	

    // useEffect for fetching data
    useEffect(() => {
        fetchData();
    }, [fetchData, activeTab]); // Now it works because fetchData is defined

	const handleTriggeredByChange = (event) => {
		setTriggeredBy(event.target.value);
		setPage(0); // Reset page number
		setData([]); // Clear existing data
		setHasMore(true); // Reset hasMore to true to allow fetching new data
		loadedIds.current.clear(); // Clear loaded IDs for new fetch
		setNoData(false);
		fetchData(); // Fetch new data based on the selected filter
	};

	const handleDurationChange = (event) => {
		setDuration(event.target.value);
		setPage(0); // Reset page number
        setData([]); // Clear existing data
        setHasMore(true); // Reset hasMore to true to allow fetching new data
        loadedIds.current.clear(); // Clear loaded IDs for new fetch
        setNoData(false);
	};

	const handleCampaignTypeChange = (event) => {
		setCampaignType(event.target.value);
		setPage(0); // Reset page number
        setData([]); // Clear existing data
        setHasMore(true); // Reset hasMore to true to allow fetching new data
        loadedIds.current.clear(); // Clear loaded IDs for new fetch
        setNoData(false);
	};

	const handleSortOrderChange = (event) => {
		setSortOrder(event.target.value);
		setPage(0); // Reset page number
        setData([]); // Clear existing data
        setHasMore(true); // Reset hasMore to true to allow fetching new data
        loadedIds.current.clear(); // Clear loaded IDs for new fetch
        setNoData(false);
	};
    // Function to handle tab click
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setPage(0); // Reset page number
        setData([]); // Clear existing data
        setHasMore(true); // Reset hasMore to true to allow fetching new data
        loadedIds.current.clear(); // Clear loaded IDs for new fetch
        setNoData(false);
    };

    

    const observer = useRef();

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchData();
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, fetchData]);
	
	
	
	const handleDelete = async () => {
		try {
			const response = await fetch(`${ServerApiDomain}/api/campaigns/delete/${CookieAccountId}/campaign/${selectedId}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Basic ${encodedCredentials}`,
				},
			});

			if (response.ok) {
            setData(prevData => {
                const updatedData = prevData.filter(item => item.campaignId !== selectedId);
                setNoData(updatedData.length === 0); // Check if the data is empty
                return updatedData;
            });
            showSnackbar('Email Campaign Removed!', 'success');
        } else {
            alert('Failed to delete the Email Campaign');
        }
		} catch (error) {
			alert('Error:', error);
		} finally {
			handleCloseDeleteModal();
		}
	};

	const [emails, setEmails] = useState([]);
	useEffect(() => {
			const fetchEmails = async () => {
				try {
					const response = await fetch(`${ServerApiDomain}/api/senders_email/emails?username=${CookieAccountId}&status=active`);
					const data = await response.json();
					setEmails(data);
				} catch (error) {
					console.error('Error fetching emails:', error);
				}
			};

			fetchEmails();
		}, [username]);

  return (
    <SideBar>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
      <div className="page-content ">
        <div className="email-campaigns-body">
		<div className="breadcrumb-area"> 
			 <nav aria-label="breadcrumb">
			  <ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
				<li className="breadcrumb-item"><Link>Campaigns</Link></li>
				<li className="breadcrumb-item active" aria-current="page">Email</li>
			  </ol>
			</nav>
		</div>	
          <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-8">
                  <h1 className="main-heading">Email Campaigns</h1>
                  <h6 className="main-subheading">Manage Your Email Campaigns: Scheduled, Sent, Draft, and Archived at Your Fingertips</h6>
                </div>
                <div className="col-4">
                  <button type="button" style={{fontSize: '0.8vw'}} onClick={handleShow} className="primary-button float-end">Create Campaign</button>
                </div>
              </div>
            </div>
          </div>

		 
		  
          
          <div className="filter-area">
            <div className="row">
              <div className="col-3">
                <label>Triggered By</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-envelope-at"></i>
				  <Select
						className="input-form"
						disableUnderline
						fullWidth
						style={{marginTop: '0', height: '1.5vw', border: 'none'}}
						value={triggeredBy}
						onChange={handleTriggeredByChange}
						input={<InputBase />}
					>
						<MenuItem value="All">All</MenuItem>
						{emails.map((email, index) => (
							<MenuItem key={index} value={email.email}>
								{email.email}
							</MenuItem>
						))}
					</Select>

                </div>
              </div>
              
              <div className="col-3">
                <label>Duration</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-clock"></i>
                  <Select
						className="input-form"
						disableUnderline
						fullWidth
						style={{marginTop: '0', height: '1.5vw', border: 'none'}}
						value={duration}
						onChange={handleDurationChange}
						input={<InputBase />}
					>
						<MenuItem value="All">All Time</MenuItem>
						<MenuItem value="today">Today</MenuItem>
						<MenuItem value="yesterday">Yesterday</MenuItem>
						<MenuItem value="last7days">Last 7 Days</MenuItem>
						<MenuItem value="last30days">Last 30 Days</MenuItem>
						<MenuItem value="last6months">Last 6 Months</MenuItem>
						<MenuItem value="last12months">Last 12 Months</MenuItem>
					</Select>
                </div>
              </div>
              <div className="col-3">
                <label>Campaign Type</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-tag"></i>
                  <Select
						className="input-form"
						disableUnderline
						fullWidth
						value={campaignType}
						style={{marginTop: '0', height: '1.5vw', border: 'none'}}
						onChange={handleCampaignTypeChange}
						input={<InputBase />}
					>
						<MenuItem value="All">All</MenuItem>
						<MenuItem value="Newsletter">Newsletter</MenuItem>
						<MenuItem value="Promotional">Promotional</MenuItem>
						<MenuItem value="Transactional">Transactional</MenuItem>
						<MenuItem value="Automated">Automated</MenuItem>
						<MenuItem value="Event">Event</MenuItem>
						<MenuItem value="Survey">Survey</MenuItem>
						<MenuItem value="Announcement">Announcement</MenuItem>
						<MenuItem value="Others">Others</MenuItem>
					</Select>
                </div>
              </div>
              
              <div className="col-3">
                <label>Sort</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-filter"></i>
                  <Select
						className="input-form"
						disableUnderline
						fullWidth
						value={sortOrder}
						style={{marginTop: '0', height: '1.5vw', border: 'none'}}
						onChange={handleSortOrderChange}
						input={<InputBase />}
					>
						<MenuItem value="All">All</MenuItem>
						<MenuItem value="latest">Date: Latest</MenuItem>
						<MenuItem value="oldest">Date: Oldest</MenuItem>
					</Select>
                </div>
              </div>
          </div>
            </div>
          
           <div className="tab-area">
            <a href="#All" className="campaign-status-tab-campaign-page" onClick={() => handleTabClick('#All')}>
              <span className={activeTab === '#All' ? 'active' : ''}>
                All Campaigns <span className="number d-none">24</span>
              </span>
            </a>
            <a href="#Sent" className="campaign-status-tab-campaign-page" onClick={() => handleTabClick('#Sent')}>
              <span className={activeTab === '#Sent' ? 'active' : ''}>
                Sent <span className="number d-none">10</span>
              </span>
            </a>
            <a href="#Scheduled" className="campaign-status-tab-campaign-page" onClick={() => handleTabClick('#Scheduled')}>
              <span className={activeTab === '#Scheduled' ? 'active' : ''}>
                Scheduled <span className="number d-none">15</span>
              </span>
            </a>
            <a href="#Draft" className="campaign-status-tab-campaign-page" onClick={() => handleTabClick('#Draft')}>
              <span className={activeTab === '#Draft' ? 'active' : ''}>
                Draft <span className="number d-none">12</span>
              </span>
            </a>
            <a href="#Archived" className="campaign-status-tab-campaign-page" onClick={() => handleTabClick('#Archived')}>
              <span className={activeTab === '#Archived' ? 'active' : ''}>
                Archived <span className="number d-none">2</span>
              </span>
            </a>
          </div>
          
        </div>  
        
      </div>
      
      <div className="email-campaigns-main-area">
        <div className="row">
          <div className="col-8">
            <h2 className="secondary-heading d-none">24 Campaigns</h2>
          </div>
          <div className="col-4">
            
          </div>
        </div>  
        
		{noData ? (
									<center>
										<div className="empty-error-area">
											<img className="empty-error-image w-25 mb-4" src="../../iconss/other/no-results.png"  />
											<h2 className="empty-error-heading">No Email Campaigns Found</h2>
											<h6 className="empty-error-subheading" >We couldn't find any email campaigns associated with your account.</h6>
										</div>
									</center>
								) : (
									<>
										<div className="row">
											{data.map((item, index) => {
											
											const formattedDate = new Intl.DateTimeFormat('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric',
												hour: 'numeric', // for hours
												minute: 'numeric', // for minutes
												hour12: true
											}).format(new Date(item.scheduledDateTime));

											const formattedDraftDate = new Intl.DateTimeFormat('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric',
												hour: 'numeric', // for hours
												minute: 'numeric', // for minutes
												hour12: true
											}).format(new Date(item.timeStamp));
											
											

											return (
												<div key={item.campaignId} ref={data.length === index + 1 ? lastElementRef : null} className="col-12">
													
													<div className="list-area">
													  <div className="campaign-top-area">
														<div className="row align-items-center">
														  <div className="col-1">
														  <Link  to={item.status === "sent" ? `/campaigns/email/${item.campaignId}` : `/create/email-campaign/${item.campaignId}`}>
															<a href="" className="campaign-tag-icon communication"><i className="bi bi-tag"></i></a>
														  </Link>
														  </div>
														  <div className="col-8">
														  <Link className="text-decoration-none" to={item.status === "sent" ? `/campaigns/email/${item.campaignId}` : `/create/email-campaign/${item.campaignId}`}>
															<h2 className="campaign-title-campaign-page">{item.campaignName}</h2>
															<h6 className="campaign-description-campaign-page">#{item.campaignId} · <b className="text-capitalize">{item.status}</b> on {item.status === "draft" ? formattedDate : formattedDate}															</h6>
														  </Link>
														  </div>
														  <div className="col-2 text-end">
														  <Link className="text-decoration-none" to={item.status === "sent" ? `/campaigns/email/${item.campaignId}` : `/create/email-campaign/${item.campaignId}`}>
															<span className={`campaign-status-campaign-page ${item.status}-status text-capitalize`}><i className="bi bi-dot"></i> {item.status}</span>
														  </Link>
														  </div>
														  <div className="col-1 text-start">
															<div className="dropdown-container" ref={(el) => dropdownRefs.current[index] = el}>
															  <button className="dropdown-toggle" type="button" onClick={() => toggleDropdown(index)}>
																<i className="bi bi-three-dots"></i>
															  </button>
															  {showDropdown === index && (
																  <ul className="dropdown-menu show">
																	<li className='drop-down-menu-campaign-page'><a onClick={() => handleDuplicateCampaign(item)}>Duplicate</a></li>
																	{item.status === 'sent' ? (
																	<li className='drop-down-menu-campaign-page'><a href={`/campaigns/email/${item.campaignId}`} >View Report</a></li>
																	) : (
																	<li className='drop-down-menu-campaign-page'><a to={item.status === "sent" ? `/campaigns/email/${item.campaignId}` : `/create/email-campaign/${item.campaignId}`} >Edit</a></li>
																	)}
																	<div className="line-break"></div>
																	<li className='drop-down-menu-campaign-page'><a href="#" onClick={() => handleClickOpen(item.campaignId)} className="text-danger">Delete</a></li>
																  </ul>
																)}
															</div>
														  </div>
														</div>
													  </div>
													  
													  <div className="campaign-bottom-area">
													  <Link className="text-decoration-none" to={item.status === "sent" ? `/campaigns/email/${item.campaignId}` : `/create/email-campaign/${item.campaignId}`}>
														<div className="row">
														  
														  <div className="col-3">
															  <div className="campaign-metric-column border-0">
																<h2 className="metric-heading-campaign-page text-dark">
																  {item.delivered} <span>({item.delivered > 0 ? ((item.delivered / item.delivered) * 100).toFixed(1) : 0}%)</span>
																</h2>
																<h6 className="metric-subheading-campaign-page">Delivered</h6>
															  </div>
															</div>

															<div className="col-3">
															  <div className="campaign-metric-column">
																<h2 className="metric-heading-campaign-page text-primary">
																  {item.opened} <span>({item.delivered > 0 ? ((item.opened / item.delivered) * 100).toFixed(1) : 0}%)</span>
																</h2>
																<h6 className="metric-subheading-campaign-page">Opened</h6>
															  </div>
															</div>

															<div className="col-3">
															  <div className="campaign-metric-column">
																<h2 className="metric-heading-campaign-page text-success">
																  {item.clicked} <span>({item.delivered > 0 ? ((item.clicked / item.delivered) * 100).toFixed(1) : 0}%)</span>
																</h2>
																<h6 className="metric-subheading-campaign-page">Clicked</h6>
															  </div>
															</div>

															<div className="col-3">
															  <div className="campaign-metric-column">
																<h2 className="metric-heading-campaign-page text-danger">
																  {item.unsubscribed} <span>({item.delivered > 0 ? ((item.unsubscribed / item.delivered) * 100).toFixed(1) : 0}%)</span>
																</h2>
																<h6 className="metric-subheading-campaign-page">Unsubscribed</h6>
															  </div>
															</div>

														  
														  
														  
														</div>
														</Link>
													  </div>
													  
													</div>
												</div>
												
												);
											})}
											
										</div>
										{loading && <p>Loading more data...</p>}
									</>
								)}
		
        
        
        
      </div>
       {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row align-items-center w-100">
						  <div className="col-11">
							<h5 className="modal-title">Create a new Campaign</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>

					<div className="modal-body py-4 px-4">
						
							<Link className="text-decoration-none" to="#" onClick={handleCreateRegularCampaign}>
							<div
								style={{
								border: '0.1vw solid rgba(220, 220, 220)',
								cursor: 'pointer',
								margin: '1vw 0 0vw 0vw',
								borderRadius: '0.6vw',
								padding: '1vw 0.8vw',
								}}
								className="create-campaign-type-area"
							>
								<div className="row align-items-center">
								<div className="col-2">
									<img
									style={{
										width: '4.5vw',
										height: '4.5vw',
										borderRadius: '0.6vw',
										background: 'rgba(230, 230, 230)',
										padding: '1.25vw',
										float: 'center',
										display: 'block',
									}}
									src="https://app.aadow.com/imgs/email-marketing.png"
									className="create-campaign-type-icon"
									/>
								</div>
								<div className="col-10">
									<div style={{ paddingLeft: '2vw', paddingTop: '1vw' }}>
									<h4
										style={{ fontSize: '0.9vw', fontWeight: '600' }}
										className="create-campaign-type-heading text-dark"
									>
										Create Regular Campaign
									</h4>
									<h6
										style={{
										fontSize: '0.7vw',
										lineHeight: '1.2vw',
										fontWeight: '400',
										color: 'rgba(120, 120, 120)',
										}}
										className="create-campaign-type-description"
									>
										Engage subscribers with news, promotions, and events to keep them interested and informed
									</h6>
									</div>
								</div>
								</div>
							</div>
							</Link>
							{(CurrentPlanName === 'Ultimate Plan' || CurrentPlanName === 'Ultimate') && (
						<div
							style={{
							cursor: 'pointer',
							margin: '1vw 0 0vw 0vw',
							borderRadius: '0.6vw',
							padding: '1vw 0.8vw',
							border: showAbOptions ? '0.1vw solid rgba(2,96,105)' : '0.1vw solid rgba(220, 220, 220)',
							background: showAbOptions ? 'rgba(2,96,105, 0.02)' : 'none',
							}}
							className="create-campaign-type-area"
							onClick={handleCreateABTestCampaign}
						>
							<div className="row align-items-center">
							<div className="col-2">
								<img
								style={{
									width: '4.5vw',
									height: '4.5vw',
									borderRadius: '0.6vw',
									background: 'rgba(230, 230, 230)',
									padding: '1.25vw',
									float: 'center',
									display: 'block',
								}}
								src="https://app.aadow.com/imgs/ab-testing.png"
								className="create-campaign-type-icon"
								/>
							</div>
							<div className="col-10">
								<div style={{ paddingLeft: '2vw', paddingTop: '1vw' }}>
								<h4
									style={{ fontSize: '0.9vw', fontWeight: '600' }}
									className="create-campaign-type-heading"
								>
									Create AB Test Campaign
								</h4>
								<h6
									style={{
									fontSize: '0.7vw',
									lineHeight: '1.2vw',
									fontWeight: '400',
									color: 'rgba(120, 120, 120)',
									}}
									className="create-campaign-type-description"
								>
									Engage your subscribers with A/B testing to optimize your campaigns. Test subject lines, templates to improve performance.
								</h6>
								</div>
							</div>
							</div>
						</div>
							)}

						{showAbOptions && (
							<div className="row">
							<div className="col-1"></div>
							<div className="col-5">
								<div
								onClick={handleCreateSubjectAbCampaign}
								style={{
									border: '0.1vw solid rgba(220, 220, 220)',
									cursor: 'pointer',
									margin: '1vw 0 0vw 0vw',
									borderRadius: '0.6vw',
									padding: '1vw 0.8vw',
								}}
								className="create-campaign-type-area"
								>
								<div className="row align-items-center">
									<div style={{ padding: '0 2vw' }} className="col-12">
									<img
										style={{
										width: '100%',
										height: '4.5vw',
										borderRadius: '0.6vw',
										objectFit: 'contain',
										background: 'rgba(230, 230, 230)',
										padding: '1.25vw',
										float: 'center',
										display: 'block',
										}}
										src="/iconss/copy-writing.png"
										className="create-campaign-type-icon"
									/>
									</div>
									<div className="col-12">
									<div style={{ paddingTop: '1vw' }}>
										<h4
										style={{ fontSize: '0.9vw', fontWeight: '600' }}
										className="create-campaign-type-heading text-center"
										>
										2 Subject Lines
										</h4>
									</div>
									</div>
								</div>
								</div>
							</div>
							<div className="col-5">
								<div
								onClick={handleCreateTemplateAbCampaign}
								style={{
									border: '0.1vw solid rgba(220, 220, 220)',
									cursor: 'pointer',
									margin: '1vw 0 0vw 0vw',
									borderRadius: '0.6vw',
									padding: '1vw 0.8vw',
								}}
								className="create-campaign-type-area"
								>
								<div className="row align-items-center">
									<div style={{ padding: '0 2vw' }} className="col-12">
									<img
										style={{
										width: '100%',
										height: '4.5vw',
										borderRadius: '0.6vw',
										objectFit: 'contain',
										background: 'rgba(230, 230, 230)',
										padding: '1.25vw',
										float: 'center',
										display: 'block',
										}}
										src="/iconss/graphic-design.png"
										className="create-campaign-type-icon"
									/>
									</div>
									<div className="col-12">
									<div style={{ paddingTop: '1vw' }}>
										<h4
										style={{ fontSize: '0.9vw', fontWeight: '600' }}
										className="create-campaign-type-heading text-center"
										>
										2 Templates
										</h4>
									</div>
									</div>
								</div>
								</div>
							</div>
							</div>
						)}
						</div>
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  
		  <Dialog open={open} onClose={handleCloseDeleteModal}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this campaign? This action cannot be undone.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDeleteModal} className="text-secondary">
							Cancel
						</Button>
						<Button onClick={handleDelete} className="text-danger">
							Delete
						</Button>
					</DialogActions>
			</Dialog>
			
			<Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
			</Snackbar>
		  
    </SideBar>
  );
};

export default EmailCampaigns;
