import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/components/footer.css';

const CompleteSetup = () => {
  
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-body shadow">
		<div className="row">
			<div className="col-12 col-md-12 col-lg-6 col-xl-6">
				<div className="description-area">
					<img className="footer-logo" alt="" title="" src="logos/aadow-logo-white.svg" />
					<h6 className="footer-description">Consider your budget and growth plans when evaluating the pricing structure of different email marketing services.</h6>
				</div>	
			</div>
			
			<div className="col-6 col-md-6 col-lg-2 col-xl-2">
				<h6 className="link-label">Useful Link</h6>
				<Link to="/about-us" className="footer-link">About Us</Link>
				<Link to="/careers" className="footer-link">Careers</Link>
				<Link to="/terms" className="footer-link">Terms & Conditions</Link>
				<Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
				<Link to="/refund-Policy" className="footer-link">Refund Policy</Link>
			</div>
			
			<div className="col-6 col-md-6 col-lg-2 col-xl-2 hide-phone">
				<h6 className="link-label">Account</h6>
				<Link to="/login" className="footer-link">Login</Link>
				<Link to="/signup" className="footer-link">Sign Up</Link>
				<Link to="/account-recovery" className="footer-link">Account Recovery</Link>
				<Link to="/email-Campaigns" className="footer-link">Campaigns</Link>
				<Link to="/sender-authentication" className="footer-link">Sender Authentication</Link>
			</div>
			
			
			<div className="col-6 col-md-6 col-lg-2 col-xl-2">
				<h6 className="link-label">Help & Support</h6>
				<Link to="/contact" className="footer-link">Contact Us</Link>
				<Link to="/faqs" className="footer-link">FAQs</Link>
				<Link to="/blogs" className="footer-link">Blogs</Link>
				<Link to="/pricing" className="footer-link">Pricing</Link>
				<Link to="/account-recovery" className="footer-link">Account Recovery</Link>
			</div>
			
		</div>
		<div className="bottom-footer">
			<div className="row">
				<div className="col-12 col-md-12 col-lg-6 col-xl-6 order-2 order-md-2 order-lg-1 order-xl-1">
					<h6 className="copyright">© {currentYear} Copyright: Aadow by <a href="https://www.horza.in" target="_blank" className="text-decoration-none text-white">Horza Private Limited</a></h6>
				</div>
				<div className="col-12 col-md-12 col-lg-6 col-xl-6 order-1 order-md-1 order-lg-2 order-xl-2">
					<div className="social-media-links">
						<a href="https://www.facebook.com/officialaadow/" target="_blank" className="social-media-link bi bi-facebook"></a>
						<a href="https://x.com/aadow_com" target="_blank" className="social-media-link bi bi-twitter-x"></a>
						<a href="https://www.youtube.com/c/Aadow" target="_blank" className="social-media-link bi bi-youtube"></a>
						<a href="https://www.instagram.com/officialaadow/" target="_blank" className="social-media-link bi bi-instagram"></a>
						<a href="https://www.linkedin.com/company/aadow" target="_blank" className="social-media-link bi bi-linkedin"></a>
					</div>
				</div>
			</div>
		</div>
    </div>
  );
};

export default CompleteSetup;