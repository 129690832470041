import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/sms-campaigns.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase } from '@mui/material';

const SmsCampaigns = () => {
 const [activeTab, setActiveTab] = useState(window.location.hash || '#All'); // Initialize with the current hash
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDropdown, setShowDropdown] = useState(null); // Track which dropdown is open
  const dropdownRefs = useRef([]); // Array to hold refs for multiple dropdowns

  const toggleDropdown = (index) => {
    setShowDropdown(showDropdown === index ? null : index); // Toggle dropdown based on index
  };

  const closeDropdown = () => {
    setShowDropdown(null);
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    document.title = 'SMS Campaigns | Aadow';

    const handleClickOutside = (event) => {
      if (dropdownRefs.current.some(ref => ref && !ref.contains(event.target))) {
        setShowDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    // Set the active tab based on the current hash
    const handleHashChange = () => {
      setActiveTab(window.location.hash || '#All'); // Default to '#All' if no hash exists
    };

    // On mount, set the active tab
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <SideBar>
      <div className="page-content ">
        <div className="email-campaigns-body">
		<div className="breadcrumb-area"> 
			 <nav aria-label="breadcrumb">
			  <ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
				<li className="breadcrumb-item"><Link>Campaigns</Link></li>
				<li className="breadcrumb-item active" aria-current="page">SMS</li>
			  </ol>
			</nav>
		</div>	
          <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-8">
                  <h1 className="main-heading">SMS Campaigns</h1>
                  <h6 className="main-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                </div>
                <div className="col-4">
                  <Link to="/create/sms-campaign" className="primary-button text-decoration-none float-end">Create Campaign</Link>
                </div>
              </div>
            </div>
          </div>

		 
		  
          
          <div className="filter-area">
            <div className="row">
              <div className="col-3">
                <label>Triggered By</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-envelope-at"></i>
                   <Select
					className="input-form"
					sx={{ fontSize: '4vw' }}
					disableUnderline
					fullWidth
					defaultValue="All"
					input={<InputBase />}
				  >
					<MenuItem value="All">All</MenuItem>
					{/* Email addresses wrapped in quotes */}
					<MenuItem value="eshanpachori@aadow.com">eshanpachori@aadow.com</MenuItem>
					<MenuItem value="ashwinpachori@aadow.com">ashwinpachori@aadow.com</MenuItem>
					<MenuItem value="contact@aadow.com">contact@aadow.com</MenuItem>
				  </Select>
                </div>
              </div>
              
              <div className="col-3">
                <label>Duration</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-clock"></i>
                  <Select className="input-form" disableUnderline fullWidth defaultValue="All" input={<InputBase />}>
                    <MenuItem value="All">All Time</MenuItem>
                    <MenuItem value={10}>Today</MenuItem>
                    <MenuItem value={10}>Yesterday</MenuItem>
                    <MenuItem value={20}>Last 7 Days</MenuItem>
                    <MenuItem value={30}>Last 30 Days</MenuItem>
                    <MenuItem value={30}>Last 6 Months</MenuItem>
                    <MenuItem value={30}>Last 12 Months</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-3">
                <label>Campaign Type</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-tag"></i>
                  <Select className="input-form" disableUnderline fullWidth defaultValue="All" input={<InputBase />}>
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value={10}>Newsletter</MenuItem>
                    <MenuItem value={20}>Promotional</MenuItem>
                    <MenuItem value={30}>Transactional</MenuItem>
                    <MenuItem value={30}>Automated</MenuItem>
                    <MenuItem value={30}>Event Invitations</MenuItem>
                    <MenuItem value={30}>Survey or Feedback Requests</MenuItem>
                    <MenuItem value={30}>Announcement</MenuItem>
                    <MenuItem value={30}>Others</MenuItem>
                  </Select>
                </div>
              </div>
              
              <div className="col-3">
                <label>Sort</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-filter"></i>
                  <Select className="input-form" disableUnderline fullWidth defaultValue="All" input={<InputBase />}>
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value={20}>Send Date: Newest</MenuItem>
                    <MenuItem value={30}>Send Date: Oldest</MenuItem>
                    <MenuItem value={20}>Open Rate: Highest First</MenuItem>
                    <MenuItem value={30}>Open Rate: Lowest First</MenuItem>
                  </Select>
                </div>
              </div>
          </div>
            </div>
          
           <div className="tab-area">
            <a href="#All" className="campaign-status-tab" onClick={() => handleTabClick('#All')}>
              <span className={activeTab === '#All' ? 'active' : ''}>
                All Campaigns <span className="number">24</span>
              </span>
            </a>
            <a href="#Sent" className="campaign-status-tab" onClick={() => handleTabClick('#Sent')}>
              <span className={activeTab === '#Sent' ? 'active' : ''}>
                Sent <span className="number">10</span>
              </span>
            </a>
            <a href="#Scheduled" className="campaign-status-tab" onClick={() => handleTabClick('#Scheduled')}>
              <span className={activeTab === '#Scheduled' ? 'active' : ''}>
                Scheduled <span className="number">15</span>
              </span>
            </a>
            <a href="#Draft" className="campaign-status-tab" onClick={() => handleTabClick('#Draft')}>
              <span className={activeTab === '#Draft' ? 'active' : ''}>
                Draft <span className="number">12</span>
              </span>
            </a>
            <a href="#Archived" className="campaign-status-tab" onClick={() => handleTabClick('#Archived')}>
              <span className={activeTab === '#Archived' ? 'active' : ''}>
                Archived <span className="number">2</span>
              </span>
            </a>
          </div>
          
        </div>  
        
      </div>
      
      <div className="email-campaigns-main-area">
        <div className="row">
          <div className="col-8">
            <h2 className="secondary-heading">24 Campaigns</h2>
          </div>
          <div className="col-4">
            
          </div>
        </div>  
        
		
        <div className="list-area">
          <div className="campaign-top-area">
            <div className="row align-items-center">
              <div className="col-1">
			  
                <Link className="text-decoration-none" to="/sms/campaign-overview" className="campaign-tag-icon survey"><i className="bi bi-question-circle "></i></Link>
              </div>
              <div className="col-8">
				<Link className="text-decoration-none" to="/sms/campaign-overview">
                <h2 className="campaign-title">Special offer for Loyal Customers till 15th August</h2>
                <h6 className="campaign-description">#426678 · <b>Sent</b> on Aug 30, 2024 12:15 PM</h6>
				</Link>
              </div>
              <div className="col-2 text-end">
                <span className="campaign-status sent-status"><i className="bi bi-dot"></i> Sent</span>
              </div>
              <div className="col-1 text-start">
                <div className="dropdown-container" ref={(el) => dropdownRefs.current[0] = el}>
                  <button className="dropdown-toggle" type="button" onClick={() => toggleDropdown(0)}>
                    <i className="bi bi-three-dots"></i>
                  </button>
                  {showDropdown === 0 && (
                    <ul className="dropdown-menu show">
                      <li><Link to="/sms/campaign-overview" >View Report</Link></li>
                      <li><a href="#" >Duplicate</a></li>
                      <li><a href="#" >Preview</a></li>
                      <div className="line-break"></div>
                      <li><a href="#">Archive</a></li>
                      <li><a href="#" className="text-danger">Delete</a></li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          
		<Link className="text-decoration-none" to="/sms/campaign-overview">
          <div className="campaign-bottom-area">
            <div className="row">
              <div className="col-6">
                <div className="campaign-metric-column border-0">
                  <h2 className="metric-heading">4,482 <span>(98.6%)</span></h2>
                  <h6 className="metric-subheading">Delivered</h6>
                </div>
              </div>
              
              <div className="col-6">
                <div className="campaign-metric-column">
                  <h2 className="metric-heading text-success">1,063 <span>(24.41%)</span></h2>
                  <h6 className="metric-subheading">Clicked</h6>
                </div>
              </div>
              
            </div>
          </div>
		  </Link>
        </div>
		
		
		
        <div className="list-area">
          <div className="campaign-top-area">
            <div className="row align-items-center">
              <div className="col-1">
                <a href="" className="campaign-tag-icon discount"><i className="bi bi-chat-dots"></i></a>
              </div>
              <div className="col-8">
                <h2 className="campaign-title">Promotional Email Blast for New Products</h2>
                <h6 className="campaign-description">#573849 · <b>Draft</b> on Aug 30, 2024 01:45 PM</h6>
              </div>
              <div className="col-2 text-end">
                <span className="campaign-status draft-status"><i className="bi bi-dot"></i> Draft</span>
              </div>
              <div className="col-1 text-start">
                <div className="dropdown-container" ref={(el) => dropdownRefs.current[1] = el}>
                  <button className="dropdown-toggle" type="button" onClick={() => toggleDropdown(1)}>
                    <i className="bi bi-three-dots"></i>
                  </button>
                  {showDropdown === 1 && (
                    <ul className="dropdown-menu show">
                      <li><a href="#" onClick={closeDropdown}>Edit</a></li>
                      <li><a href="#" onClick={closeDropdown}>Duplicate</a></li>
                      <li><a href="#" onClick={closeDropdown}>View Report</a></li>
                      <div className="line-break"></div>
                      <li><a href="#" onClick={closeDropdown} className="text-danger">Delete</a></li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          <div className="campaign-bottom-area">
            <div className="row">
              <div className="col-3">
                <div className="campaign-metric-column border-0">
                  <h2 className="metric-heading">3,782 <span>(90.2%)</span></h2>
                  <h6 className="metric-subheading">Delivered</h6>
                </div>
              </div>
              
              <div className="col-3">
                <div className="campaign-metric-column">
                  <h2 className="metric-heading text-primary">1,848 <span>(48.92%)</span></h2>
                  <h6 className="metric-subheading">Opened</h6>
                </div>
              </div>
              
              <div className="col-3">
                <div className="campaign-metric-column">
                  <h2 className="metric-heading text-success">983 <span>(25.98%)</span></h2>
                  <h6 className="metric-subheading">Clicked</h6>
                </div>
              </div>
              
              <div className="col-3">
                <div className="campaign-metric-column">
                  <h2 className="metric-heading text-danger">250 <span>(10.2%)</span></h2>
                  <h6 className="metric-subheading">Unsubscribed</h6>
                </div>
              </div>
              
            </div>
          </div>
          
        </div>
        
        <div className="list-area">
          <div className="campaign-top-area">
            <div className="row align-items-center">
              <div className="col-1">
                <a href="" className="campaign-tag-icon communication"><i className="bi bi-tag"></i></a>
              </div>
              <div className="col-8">
                <h2 className="campaign-title">End-of-Season Sale Announcement</h2>
                <h6 className="campaign-description">#678934 · <b>Scheduled</b> on Aug 30, 2024 02:30 PM</h6>
              </div>
              <div className="col-2 text-end">
                <span className="campaign-status scheduled-status"><i className="bi bi-dot"></i> Scheduled</span>
              </div>
              <div className="col-1 text-start">
                <div className="dropdown-container" ref={(el) => dropdownRefs.current[2] = el}>
                  <button className="dropdown-toggle" type="button" onClick={() => toggleDropdown(2)}>
                    <i className="bi bi-three-dots"></i>
                  </button>
                  {showDropdown === 2 && (
                    <ul className="dropdown-menu show">
                      <li><a href="#" onClick={closeDropdown}>Edit</a></li>
                      <li><a href="#" onClick={closeDropdown}>Duplicate</a></li>
                      <li><a href="#" onClick={closeDropdown}>View Report</a></li>
                      <div className="line-break"></div>
                      <li><a href="#" onClick={closeDropdown} className="text-danger">Delete</a></li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          <div className="campaign-bottom-area">
            <div className="row">
              <div className="col-3">
                <div className="campaign-metric-column border-0">
                  <h2 className="metric-heading">5,000 <span>(95.4%)</span></h2>
                  <h6 className="metric-subheading">Delivered</h6>
                </div>
              </div>
              
              <div className="col-3">
                <div className="campaign-metric-column">
                  <h2 className="metric-heading text-primary">2,500 <span>(50.0%)</span></h2>
                  <h6 className="metric-subheading">Opened</h6>
                </div>
              </div>
              
              <div className="col-3">
                <div className="campaign-metric-column">
                  <h2 className="metric-heading text-success">1,200 <span>(24.0%)</span></h2>
                  <h6 className="metric-subheading">Clicked</h6>
                </div>
              </div>
              
              <div className="col-3">
                <div className="campaign-metric-column">
                  <h2 className="metric-heading text-danger">350 <span>(7.0%)</span></h2>
                  <h6 className="metric-subheading">Unsubscribed</h6>
                </div>
              </div>
              
            </div>
          </div>
          
        </div>
        
      </div>
       {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Create a new Campaign</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body py-4 px-4">
						<div style={{border: '0.1vw solid rgba(225, 225, 225)', cursor: 'pointer', margin: '0vw 0 1vw 0vw', borderRadius: '0.6vw', padding: '0.5vw 1vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '5vw', height: '5vw',borderRadius: '0.6vw', background: 'rgba(250, 250, 250)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/email-marketing.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '1vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1.2vw', fontWeight: '600'}} className="create-campaign-type-heading">Create Regular Campaign</h4>
									<h6 style={{fontSize: '0.9vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Engage subscribers with news, promotions, and events to keep them interested and informed</h6>
									</div>
								</div>

							</div>
						</div>
						
						<div style={{border: '0.1vw solid rgba(220, 220, 220)',cursor: 'pointer', margin: '2vw 0 0vw 0vw', borderRadius: '0.6vw', padding: '0.5vw 1vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '5vw', height: '5vw',borderRadius: '0.6vw', background: 'rgba(250, 250, 250)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/ab-testing.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '1vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1.2vw', fontWeight: '600'}} className="create-campaign-type-heading">Create AB Test Campaign</h4>
									<h6 style={{fontSize: '0.9vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Engage subscribers with news, promotions, and events to keep them interested and informed</h6>
									</div>
								</div>

							</div>
						</div>
					</div>
					
				  </div>
				</div>
			  </div>
			</>
		  )}
    </SideBar>
  );
};

export default SmsCampaigns;
