import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import '../../css/private/create-email-template-code-editor.css';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import Cookies from 'js-cookie';


import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const CreateEmailTemplateCodeEditor = () => {
  
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get('campaign_id');
  const template = searchParams.get('template');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [TemplateName, setTemplateName] = useState('Untitled Template');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [FullPageloading, setFullPageloading] = useState(false);
  
  const { TemplateId } = useParams();
  
  
  const CookietId = Cookies.get('current_Id');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountEmail = Cookies.get('current_accountEmail');

  const [templates, setTemplates] = useState([]);
  
  const [templateUrl, settemplateUrl] = useState();
  const [Error, setError] = useState(null);

    useEffect(() => {
        const fetchEmailCampaign = async () => {
          setFullPageloading(true);
            try {
                const response = await fetch(
                    `${ServerApiDomain}/api/emailtemplates/${TemplateId}/${CookieAccountId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.type === 'text-editor') {
                  let url = `/create/email-template/text-editor/${TemplateId}?`;
                  if (campaignId) {
                      url += `campaign_id=${campaignId}&`;
                  }

                  // Add template if it exists
                  if (template) {
                      url += `template=${template}&`;
                  }

                  // Remove trailing '&' or '?' if no parameters were added
                  url = url.endsWith('&') ? url.slice(0, -1) : url;
                  url = url.endsWith('?') ? url.slice(0, -1) : url;

                  // Redirect to the constructed URL
                  window.location.href = url;
                }else if (data.type === 'drag-drop') {
                let url = `/create/email-template/drag-drop/${TemplateId}?`;
                  if (campaignId) {
                      url += `campaign_id=${campaignId}&`;
                  }

                  // Add template if it exists
                  if (template) {
                      url += `template=${template}&`;
                  }

                  // Remove trailing '&' or '?' if no parameters were added
                  url = url.endsWith('&') ? url.slice(0, -1) : url;
                  url = url.endsWith('?') ? url.slice(0, -1) : url;

                  // Redirect to the constructed URL
                  window.location.href = url;
                }
                
                
                if(data.content !== ""){
                  const getEmailTemplatePath = (path) => {
                      // Remove everything before 'emailtemplates' and replace backslashes with forward slashes
                      const updatedPath = path.replace(/^.*emailtemplates/, '/emailtemplates').replace(/\\/g, '/');
                      return updatedPath;
                  };
                  
                  // Example usage:
                  const filePath = data.content;
                  setTemplateName(data.templateName);
                  settemplatePath(filePath);
                  const templatePath = getEmailTemplatePath(filePath);
                  try {
                      const response = await fetch(templatePath);
                      if (!response.ok) {
                          throw new Error('Failed to fetch the HTML file');
                      }
                      
                      setFullPageloading(false);
                      const text = await response.text();
                      if(filePath !== "" || filePath !== null){
                          setHtmlCode(text);
                      }
                      
                  } catch (error) {
                      console.error('Error fetching HTML:', error);
                      setFullPageloading(false);
                  }
                  }else{
                    setFullPageloading(false);
                  }
            } catch (error) {
              setFullPageloading(false);
                console.log('Fetch error:', error);
                setError('TRUE');
                setLoading(false);
            }
        };

        fetchEmailCampaign();
    }, []);


  
  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorEmail("");
	setEmail("");
    setEmailError(false);
    setInputApiArea("true");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [ErrorName, setErrorName] = useState('');
  const [EmailSentTo, setEmailSentTo] = useState('');
  const NameRef = useRef(null);
  
  const [Email, setEmail] = useState('');
  const [MailSubject, setMailSubject] = useState('');
  const [MailPreview, setMailPreview] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const [ErrorEmail, setErrorEmail] = useState('');
  const EmailRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [responseMessage, setResponseMessage] = useState('');
  
  const [loading, setLoading] = useState(false);

  const handleAddSender = async () => { // Add "async" here
      setErrorEmail("");
      
      setFullPageloading(true);
      setEmailError(false);

      if (Email.trim() === '') {
          setErrorEmail("Please enter a sender email address");
          setEmailError(true);
          EmailRef.current.focus();
          return;
      } else if (!emailRegex.test(Email)) {
          setErrorEmail("Please enter a valid email address");
          setEmailError(true);
          EmailRef.current.focus();
          return;
      }
          let templatePathUrl = templatePath;

            // Extract the desired portion and replace backslashes with forward slashes
            let relativePath = "";
            const match = templatePathUrl.match(/\/emailtemplates\/.*/);
            if (match) {
                relativePath = match[0]; // Extract the desired part of the path
            } else {
                console.error("Invalid template path: '/emailtemplates/' not found in templatePathUrl", templatePathUrl);
                setFullPageloading(false);
                return;
            }

            const templateUrl = "https://www.aadow.com" + relativePath;
      setLoading(true); // Show loading state
      setResponseMessage(''); // Clear previous response

      const requestData = {
          username: CookieAccountId,
          template_id: TemplateId,
          email: Email,
          subject: MailSubject,
          preview_text: MailPreview,
          template_url: templateUrl,
      };

      try {
          const response = await fetch(`${ServerApiDomain}/api/email/send-test`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams(requestData).toString(),
          });

          const result = await response.text(); // Assuming your API returns a plain text response
          showSnackbar('Test Mail Sent!', "success");
          setEmail("");
          setMailPreview("");
          setMailSubject("");
          setShow(false);
          
          setFullPageloading(false);
      } catch (error) {
          console.error('Error sending email:', error);
          showSnackbar('Failed to send email: ', "error");
          
          setFullPageloading(false);
      } finally {
        
        setFullPageloading(false);
          setLoading(false); // Hide loading state
      }
  };

  
  const [InputApiArea, setInputApiArea] = useState('true');
  const [htmlCode, setHtmlCode] = useState('Hello there...');

  // Close dropdown when clicking outside of it
  useEffect(() => {
    document.title = 'Email Template - Code Editor | Aadow';
  }, []);
  const handleGoBack = () => {
    
    window.history.go(-1);
  };

  const [templatePath, settemplatePath] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const getQueryParameter = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  const removeMultipleParameters = () => {
    const url = new URL(window.location);
    url.searchParams.delete('temp');  // Remove 'temp'
    url.searchParams.delete('foo');   // Remove 'foo'
    
    window.history.replaceState({}, '', url);  // Update the URL without reloading the page
  };

  useEffect(() => {
    // Get the 'temp' query parameter value
    const tempValue = getQueryParameter('temp');
    const redirectValue = getQueryParameter('redirect');
    generateThumbnail();
    // If 'temp' is 'update', show the snackbar
    if (tempValue === 'update') {
      showSnackbar('Template saved successfully', 'success');
      removeMultipleParameters();
    }else if (tempValue === 'test') {
      HandleShowModal();
      removeMultipleParameters();
    }else if (tempValue === 'preview') {
      window.open(`/preview/email-template/${TemplateId}`, '_blank');
      removeMultipleParameters();
    }
    
    if (redirectValue === "campaign"){
      window.location.href = `/create/email-campaign/${campaignId}`;
    }
    
  }, []);





  const handleUpdate = async (e) => {
    e.preventDefault();
    const url = `${ServerApiDomain}/api/emailtemplates/update/${CookieAccountId}/${TemplateId}`;
    const currentDateTime = new Date().toISOString();
    const data = {
      templateName: TemplateName,
      subject: "",
      previewText: "",
      content: "",
      thumbnail: `https://www.aadow.com/emailthumbnail/${TemplateId}.png`,
      status: "active",
      score: "",
      datetime: currentDateTime,
      timestamp: currentDateTime,
    };
  
    try {
      console.log('Update URL:', url);
      console.log('Request Data:', data);
  
      const res = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
  
      if (res.ok) {
        console.log('Update successful.');
        if (campaignId) {
          await generateThumbnail();
          await handleUpdateInfo();
        } else {
          await uploadTemplate();
        }
        showSnackbar('Template updated and uploaded successfully!', 'success');
      } else {
        const errorText = await res.text();
        console.error('Update failed:', errorText);
        showSnackbar(`Update failed: ${res.statusText}`, 'error');
      }
    } catch (error) {
      console.error('Error during update:', error);
      showSnackbar('An error occurred while updating the template.', 'error');
    }
  };
  

  const handleUpdateInfo = async () => {  // no need for the 'event' parameter here
    
    const CurrentUrl = new URL(window.location);
    CurrentUrl.searchParams.set('redirect', 'campaign');
    window.history.pushState({}, '', CurrentUrl);

    let updatedData;

    // Set updatedData based on the template condition
    if (template === "b") {
        updatedData = {
            "username": CookieAccountId, 
            "emailTemplateB": TemplateId
        };
    } else {
        updatedData = {
            "username": CookieAccountId, 
            "emailTemplate": TemplateId
        };
    }
    

    try {
        const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${campaignId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        });

        if (response.ok) {
          
            await generateThumbnail();
            await uploadTemplate();
        } else {
            console.log(`Failed to update campaign: ${response.statusText}`);
        }
    } catch (error) {
        console.log(`Error while updating campaign: ${error.message}`);
    }
  };

  const uploadTemplate = async () => {
    const url = `${ServerApiDomain}/api/emailtemplates/upload`;
    const data = {
        username: CookieAccountId,
        templateId: TemplateId,
        content: htmlCode, // HTML content
    };

    try {
          const res = await fetch(url, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          });

          if (res.ok) {
              const jsonResponse = await res.json();
              console.log('Upload successful:', jsonResponse.message);
              showSnackbar('Template saved successfully', 'success');
              window.location.reload();
          } else {
              const errorResponse = await res.text();
              console.error('Upload failed:', errorResponse);
              showSnackbar(`Upload failed: ${res.statusText}`, 'error');
          }
      } catch (error) {
          console.error('Error uploading template:', error);
          showSnackbar('An error occurred while uploading the template.', 'error');
      }
  };

 
  
  const handleUpdateTest = async (e) => {
    // Prevent default form behavior (if button is in a form)
    e.preventDefault();
    const CurrentUrl = new URL(window.location);
    CurrentUrl.searchParams.set('temp', 'test');  // Adds or updates the 'temp' parameter
    window.history.pushState({}, '', CurrentUrl);

    const url = `${ServerApiDomain}/api/emailtemplates/update/${CookieAccountId}/${TemplateId}`;
    const currentDateTime = new Date().toISOString();
    const data = {
        templateName: TemplateName,
        subject: "",
        previewText: "",
        content: "",
        thumbnail: `https://www.aadow.com/emailthumbnail/${TemplateId}.png`,
        status: "active",
        score: "",
        datetime: currentDateTime,
        timestamp: currentDateTime,
    };

    try {
        const res = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (res.ok) {
            await generateThumbnail();
            await uploadTemplate(); // Call the upload function
            showSnackbar('Template updated and uploaded successfully!', 'success');
        } else {
            console.error('Update failed:', res.statusText);
            showSnackbar(`Update failed: ${res.statusText}`, 'error');
        }
    } catch (error) {
        console.error('Error during update:', error);
        showSnackbar('An error occurred while updating the template.', 'error');
    }
  };

  
  const handleUpdatePreview = async (e) => {
    // Prevent default form behavior (if button is in a form)
    e.preventDefault();
    const CurrentUrl = new URL(window.location);
    CurrentUrl.searchParams.set('temp', 'preview');  // Adds or updates the 'temp' parameter
    window.history.pushState({}, '', CurrentUrl);

    const url = `${ServerApiDomain}/api/emailtemplates/update/${CookieAccountId}/${TemplateId}`;
    const currentDateTime = new Date().toISOString();
    const data = {
        templateName: TemplateName,
        subject: "",
        previewText: "",
        content: "",
        thumbnail: `https://www.aadow.com/emailthumbnail/${TemplateId}.png`,
        status: "active",
        score: "",
        datetime: currentDateTime,
        timestamp: currentDateTime,
    };

    try {
        const res = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (res.ok) {
            await generateThumbnail();
            await uploadTemplate(); // Call the upload function
            showSnackbar('Template updated and uploaded successfully!', 'success');
        } else {
            console.error('Update failed:', res.statusText);
            showSnackbar(`Update failed: ${res.statusText}`, 'error');
        }
    } catch (error) {
        console.error('Error during update:', error);
        showSnackbar('An error occurred while updating the template.', 'error');
    }
  };

  
  const generateThumbnail = async () => {
    const url = 'https://www.aadow.com:8080/generate-thumbnail';
  
    // Manually encode the URL and outputPath parameters
    const encodedUrl = encodeURIComponent(`https://aadow.com/emailtemplates/${CookieAccountId}/${TemplateId}/${TemplateId}.html`);
    const encodedOutputPath = encodeURIComponent(`/var/www/react/emailthumbnail/${TemplateId}.png`);
  
    const params = {
      url: encodedUrl,
      outputPath: encodedOutputPath,
    };
  
    try {
      console.log(`Thumbnail URL: ${url}`);
      console.log(`Thumbnail Params: ${JSON.stringify(params)}`);
  
      // Axios will send the encoded parameters
      const res = await axios.get(url, { params });
  
      if (res.status === 200) {
        console.log('Thumbnail generated successfully.');
      } else {
        console.log(`Thumbnail generation failed: ${res.statusText}`);
      }
    } catch (error) {
      console.log(`Error generating thumbnail: ${error}`);
    }
  };
  
  
  

  
  
  
  

  return (
    <div>
      <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
		</Backdrop>
      {Error === 'TRUE' && (
            <center>
                <div className="empty-error-area">
                    <img className="empty-error-image w-25" src="../../../iconss/other/no-results.png"  />
                    <h2 className="empty-error-heading">No Template Found</h2>
                    <h6 className="empty-error-subheading" >It looks like there are no template associated with this id.</h6>
                </div>
            </center>
      )}
      {Error !== 'TRUE' && (
      <div>
      <div className="page-content">
        <div className="create-code-editor-template-body">
			<header className="header-area">
				<div className="row align-items-center">
					<div className="col-5">
						<button onClick={handleGoBack} className="secondary-outline-button">Back</button>
					</div>
					
					<div className="col-2 text-center">
						    <input type="text" className="template-title" value={TemplateName} onChange={(e) => {
								  setTemplateName(e.target.value)
                }} />
					</div>
					<div className="col-5 text-end">
						<button type="button" onClick={handleUpdatePreview} className="secondary-outline-button text-decoration-none me-2">Preview Email</button>
						<button type="button" onClick={handleUpdateTest} className="secondary-outline-button me-2">Send Test Email</button>
						<button type="button" onClick={(e) => handleUpdate(e)} className="primary-button ms-3">
    Save Template
</button>

					</div>
				</div>
			</header>
			
			<div className="editor-area">
				<div className="col-6">
				</div>
				<div>
				</div>
				
				<div className="row" style={{ display: 'flex', height: '87vh' }}>
        {/* Left Side - Code Input */}
        <div style={{ flex: 1, padding: '20px', borderRight: '1px solid #ccc', overflowY: 'auto' }}>
          <textarea
            value={htmlCode}
            onChange={(e) => setHtmlCode(e.target.value)}
            style={{
              width: '100%',
              height: '100%',
              fontFamily: 'monospace',
              fontSize: '14px',
              padding: '10px',
              border: '1px solid #ddd',
              background: "#1C2C3C",
              color: "yellow",
              borderRadius: '5px',
              resize: 'none',
            }}
          />
        </div>

        {/* Right Side - Live Preview */}
        <div style={{ flex: 1, padding: '20px', backgroundColor: '#ccc', overflowY: 'auto' }}>
          <iframe
            srcDoc={htmlCode}
            title="Live Preview"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              border: 'none',
              backgroundColor: 'white',
            }}
          />
        </div>
      </div>
			</div>
			
			
		</div>	
      </div>
	  {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Send Test Email</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body pb-4 pt-0 px-4">
					{InputApiArea === 'true' && (
					<div className="row">
						
						
						<div className="col-12">
							<label  className='label-modal-form'>Sender Email<b className="text-danger">*</b></label>
							<input type="text" ref={EmailRef} className={`input-forms-modal w-100 ${EmailError ? 'input-error' : ''}`} value={Email} onChange={(e) => {
								  setEmail(e.target.value);
								}} />
							{ErrorEmail && <div className="error-message">{ErrorEmail}</div>}
						</div>
            <div className="col-12">
							<label className='label-modal-form'>Subject</label>
							<input type="text" className={`input-forms-modal w-100`} value={MailSubject} onChange={(e) => {
								  setMailSubject(e.target.value);
								}} />
						</div>
            <div className="col-12">
							<label  className='label-modal-form'>Preview Text</label>
							<input type="text" className={`input-forms-modal w-100`} value={MailPreview} onChange={(e) => {
								  setMailPreview(e.target.value);
								}} />
						</div>

					</div>	
					)}
					{InputApiArea === 'false' && (	
						<div className="col-12">
							<div id="step_three" className="right-content">
							  <img src="../../iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">Test Mail Sent!</h2>
							  <h2 className="secondary-description-modal text-center">Test mail sent to <b>{EmailSentTo}</b></h2>
							  <center><button type="button" className="primary-button float-center" onClick={handleClose} >Okay</button></center>
							</div>
						</div>
					)}
						
					</div>
					
					{InputApiArea === 'true' && (
					<div className="modal-footer ">
						<button type="button" style={{fontSize: '0.8vw'}} className="secondary-button" onClick={handleClose} data-bs-dismiss="modal">Cancel</button>
						<button type="button" style={{fontSize: '0.8vw'}}  className="primary-button" disabled={loading} onClick={handleAddSender}>{loading ? 'Sending...' : 'Send Test Email'}</button>
					</div>
					)}
					
				  </div>
				</div>
			  </div>
			</>
		  )}
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
    </div>
      )}
    </div>
  );
};

export default CreateEmailTemplateCodeEditor;