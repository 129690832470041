import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/public/careers.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';
import { format } from 'date-fns';

const Careers = () => {
  // Get the 'id' parameter from the URL
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const { search } = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [searchBar, setSearchBar] = useState(search || ''); // Initialize with URL param if present

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noResults, setNoResults] = useState(false); // Track if no results found
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if more blogs are available
  const observer = useRef();

  const fetchBlogs = async (pageNumber) => {
		try {
			setLoading(true);
			const url = search 
				? `${ServerApiDomain}/api/careers-feed/search?query=${encodeURIComponent(search)}&page=${pageNumber}`
				: `${ServerApiDomain}/api/careers-feed?page=${pageNumber}`;

			const response = await axios.get(url, {
				auth: {
					username: 'testuser',
					password: 'testpassword',
				},
			});

			// Check if the fetch was for search and there are no results
			if (search && response.data.length === 0) {
				setNoResults(true); // Set noResults only for search
			} else {
				setNoResults(false); // Reset no results flag if we have results or it's a normal fetch
			}

			setBlogs((prevBlogs) => [...prevBlogs, ...response.data]); // Append new blogs
			setHasMore(response.data.length > 0); // Check if there's more data
		} catch (err) {
			setError(err);
		} finally {
			setLoading(false);
		}
	};


  // Load the initial data
  useEffect(() => {
	  
    document.title = 'Careers | Aadow';
    fetchBlogs(page);
  }, [page, search]); // Add search to dependency array

  // Observer callback function
  const lastBlogElementRef = useCallback((node) => {
    if (loading) return; // Do nothing if already loading
    if (observer.current) observer.current.disconnect(); // Disconnect the observer

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) { // Check if there's more to load
        setPage((prevPage) => prevPage + 1); // Increment the page number
      }
    });

    if (node) observer.current.observe(node); // Observe the last blog post element
  }, [loading, hasMore]);

  const handleSubmit = (e) => {
	  e.preventDefault(); // Prevent the form from doing a full page reload
	  setBlogs([]); // Clear the existing blogs
	  setPage(0); // Reset the page number to 0 for a fresh start
	  navigate(`/faqs/${searchBar}`); // Navigate to the new URL with the search input
	};
  

  return (
    <div class="careers-area">
		<TopBar />
		<div class="top-area container "><span class="span-alert-hiring">&#8226; We're Hiring</span>
		  <h1 class="main-heading">Be a part of our Mission</h1>
		  <h5 class="main-subheading">Discover exciting career opportunities in our dynamic and inclusive environment. Join our talented team and make a meaningful impact!</h5>
		  <div class="jobs-category-area d-none"> 
				<a><span class="span-alert-active">All</span></a> 
				<a><span class="span-alert">Internship</span></a>
				<a><span class="span-alert">Full-Time</span></a>
				<a><span class="span-alert">Part-Time</span></a>
				<a><span class="span-alert">Freelancing</span></a>
		</div>
		  <div class="jobs-list">
		 {blogs.map((blog, index) => {
                const formattedDate = format(new Date(blog.timestamp), 'MMM dd, yyyy');
                const isLastBlog = blogs.length === index + 1;

                return (
		  
				  <div class="job">
				  <div class="row align-items-center">
				  <div class="col-md-8">
				  <Link to={`/job/${blog.title.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase()}-${blog.id}`}>
				  <h5 class="job-heading text-dark">{blog.title}</h5>
				  <h6 class="job-subheading text-dark">{blog.shortDesc}</h6>
				  <div class="job-key-points-area">
				  <a ><span class="span-alert"><i class="bi bi-geo-alt"></i> {blog.location}</span></a>
				  <a><span class="span-alert"><i class="bi bi-clock"></i> {blog.jobType}</span></a>
				  </div>
				  </Link>
				  </div>
				  <div class="col-md-4">
				  <Link to={`/job/${blog.title.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase()}-${blog.id}`} class="apply-button"><i class="bi bi-box-arrow-up-right"></i> Apply</Link>
				  </div>
				  </div>
				  </div>
		 
		  
                );
              })}
			  
			  {loading && hasMore && (
              <div className="d-block float-center text-center pt-4 pb-4">
                <div className="spinner-grow" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            {noResults && !loading && <div className="text-center">No results found for your search.</div>}
		  
		  
		  </div>
		</div>
		<Footer />
	</div>
  );
};

export default Careers;
