import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate properly
import { Select, MenuItem, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../../css/authentication/complete-setup.css';
import '../../css/public/CustomSelect.css';
import CountrySelect from './../components/CountrySelect';
import Cookies from 'js-cookie';


const CompleteSetup = () => {
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const CookieAccountId = Cookies.get('current_accountId');
  const [accountId, setAccountId] = useState(null);
  const [timer, setTimer] = useState(3);
  const navigate = useNavigate(); // Call useNavigate at the top level
  const [currentStep, setCurrentStep] = useState('step_one');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [countryselectedOption, countrysetSelectedOption] = useState(''); // Move state to parent
  
  const handleChange = () => {
	   
  };

   
  
		
	  useEffect(() => {
		document.title = 'Complete Setup | Aadow';
		const cookieValue = getCookieValue('account_id');
		setAccountId(cookieValue);
	  }, []);
	  
   const getCookieValue = (name) => {
	  const cookieString = document.cookie;
	  const cookies = cookieString ? cookieString.split('; ') : [];
	  for (let cookie of cookies) {
		const [cookieName, cookieValue] = cookie.split('=');
		if (cookieName === name) {
		  return decodeURIComponent(cookieValue);
		}
	  }
	  return null; // Cookie not found
	};

  
  const [TeamSizeselectedValue, setTeamSizeselectedValue] = React.useState('');
  const TeamSizehandleChange = (event) => {
    setTeamSizeselectedValue(event.target.value);
  };
  
  const [TotalEmailselectedValue, setTotalEmailsselectedValue] = React.useState('');
  const TotalEmailshandleChange = (event) => {
    setTotalEmailsselectedValue(event.target.value);
  };

  
  const [BusinessCategorySelected, setBusinessCategorySelected] = React.useState('');
  const BusinessCategoryHandleChange = (event) => {
    setBusinessCategorySelected(event.target.value);
  };

  
  const [OnlineSellselectedValue, setOnlineSellselectedValue] = React.useState('');
  const OnlineSellhandleChange = (event) => {
    setOnlineSellselectedValue(event.target.value);
  };
  
  const [CountrySelectedValue, setCountrySelectedValue] = React.useState('');
  const CountryhandleChange = (event) => {
    setCountrySelectedValue(event.target.value);
  };
  
  
  const [ErrorOneMessage, setErrorOneMessage] = useState('');
  const [ContinueOneButtonDisabled, setContinueOneButtonDisabled] = useState(false);
  const [ContinueOneText, setContinueOneText] = useState('Continue');
  
  
  const handleContinueOne = async () => {
	setErrorOneMessage("");
	if (TeamSizeselectedValue.trim() === '') {
		setErrorOneMessage("Please select team size");
		return;
	}else if (TotalEmailselectedValue.trim() === '') {
		setErrorOneMessage("Please select emails you have");
		return;
	}else if (OnlineSellselectedValue.trim() === '') {
		setErrorOneMessage("Please select do you sell online");
		return;
	}
	
	
	setContinueOneButtonDisabled(true);
    setContinueOneText('Verifying...');
	setCurrentStep('step_two');
	
  };
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  const [CompanyName, setCompanyName] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [Address, setAddress] = useState('');
  const [City, setCity] = useState('');
  const [State, setState] = useState('');
  const [Zip, setZip] = useState('');
  
  const [ErrorTwoMessage, setErrorTwoMessage] = useState('');
  const [ContinueTwoButtonDisabled, setContinueTwoButtonDisabled] = useState(false);
  const [ContinueTwoText, setContinueTwoText] = useState('Continue');
  
  
  const [CompanyNameError, setCompanyNameError] = useState(false);
  const [PhoneNumberError, setPhoneNumberError] = useState(false);
  const [AddressError, setAddressError] = useState(false);
  const [CityError, setCityError] = useState(false);
  const [StateError, setStateError] = useState(false);
  const [ZipError, setZipError] = useState(false);
  
  const handleContinueTwo = async () => {
	setErrorTwoMessage("");
	
	setCompanyNameError(false);
	setPhoneNumberError(false);
	setAddressError(false);
	setCityError(false);
	setStateError(false);
	setZipError(false);
	
	if (CompanyName.trim() === '') {
		setErrorTwoMessage("Please enter company name");
		setCompanyNameError(true);
		return;
	}else if (BusinessCategorySelected.trim() === '') {
		setErrorTwoMessage("Please select a business category");
		return;
	}else if (Address.trim() === '') {
		setErrorTwoMessage("Please enter address");
		setAddressError(true);
		return;
	}else if (City.trim() === '') {
		setErrorTwoMessage("Please enter city");
		setCityError(true);
		return;
	}else if (State.trim() === '') {
		setErrorTwoMessage("Please enter state");
		setStateError(true);
		return;
	}else if (Zip.trim() === '') {
		setErrorTwoMessage("Please enter postal code");
		setZipError(true);
		return;
	}else if (countryselectedOption.trim() === '') {
		setErrorTwoMessage("Please select country");
		return;
	}
	
	
	setContinueTwoButtonDisabled(true);
    setContinueTwoText('Saving...');
	handleSaveBusinessInfo();
	
	
  };
  
  const handleSaveBusinessInfo = async () => {
    const url = `${ServerApiDomain}/api/business/save`;
    const username = 'testuser';
    const password = 'testpassword';

    const basicAuth = 'Basic ' + btoa(username + ':' + password);

    const businessInfo = {
        username: CookieAccountId,
        teamSize: TeamSizeselectedValue,
        totalEmails: TotalEmailselectedValue,
        onlineSell: OnlineSellselectedValue,
        companyName: CompanyName,
        businessCategory: BusinessCategorySelected,
        address: Address,
        city: City,
        state: State,
        zip: Zip,
        country: countryselectedOption
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': basicAuth
            },
            body: JSON.stringify(businessInfo)
        });

        if (!response.ok) {
            // Log response status and text for debugging
            const errorText = await response.text();
            console.error(`Error: ${response.status} - ${response.statusText}`);
            console.error('Response body:', errorText);

            alert(`Error: ${response.status} - ${response.statusText}`);
            showSnackbar('An error occurred while saving. Please try again.', 'error');
            setContinueTwoButtonDisabled(false);
            setContinueTwoText('Continue');
            return;
        }

        const data = await response.json();
        showSnackbar('Business info saved, Redirecting....', 'success');

        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    navigate('/dashboard');
                }
                return prevTimer - 1;
            });
        }, 1000);
    } catch (error) {
        console.error('Fetch error:', error);  // Log the exact error

        console.error(`Error: ${error.message || 'Unknown error occurred'}`);
        showSnackbar('An error occurred. Please try again.', 'error');
        
        setContinueTwoButtonDisabled(false);
        setContinueTwoText('Continue');
    }
};





  

  return (
    <div className="complete-setup-body">
		<div className="row align-items-center">
			<div className="col-12 col-md-12 col-lg-6 col-xl-6">
				<div className="left-body">
					<div className="top-area">
						<h1 className="main-heading">Complete Account Setup</h1>
						<h6 className="main-subheading">Let's set up your profile in less than 2 minutes</h6>
					</div>
					
					{currentStep === 'step_one' && (
					<div id="step_one" className="steps-area ">
						<div className="form-area">
							<label>Team Size<b className="text-danger">*</b></label>
							<Select labelId="team-size-select-label" id="team-size-select" className="select-box w-100" value={TeamSizeselectedValue} onChange={TeamSizehandleChange}>
									
									  <MenuItem key="1-5" value="1-5" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">1-5</Typography>
										</Box>
									  </MenuItem>
									  <MenuItem key="5-25" value="5-25" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">5-25</Typography>
										</Box>
									  </MenuItem>
									   <MenuItem key="25-50" value="25-50" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">25-50</Typography>
										</Box>
									  </MenuItem>
									   <MenuItem key="51-250" value="51-250" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">51-250</Typography>
										</Box>
									  </MenuItem>
									   <MenuItem key="250+" value="250+" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">250+</Typography>
										</Box>
									  </MenuItem>
							</Select>
						</div>
						
						<div className="form-area">
							<label>How many emails do you have<b className="text-danger">*</b></label>
							<Select labelId="total-emails-select-label" id="total-emails-select" className="select-box w-100" value={TotalEmailselectedValue} onChange={TotalEmailshandleChange}>
									 <MenuItem key="0-300" value="0-300" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">0-300</Typography>
										</Box>
									  </MenuItem>
									  <MenuItem key="500-1000" value="500-1000" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">500-1000</Typography>
										</Box>
									  </MenuItem>
									  <MenuItem key="1000-2500" value="1000-2500" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">1000-2500</Typography>
										</Box>
									  </MenuItem>
									   <MenuItem key="2500-10000" value="2500-10000" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">2500-10000</Typography>
										</Box>
									  </MenuItem>
									   <MenuItem key="10000-25000" value="10000-25000" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">10000-25000</Typography>
										</Box>
									  </MenuItem>
									   <MenuItem key="25000-50000" value="25000-50000" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">25000-50000</Typography>
										</Box>
									  </MenuItem>
									  <MenuItem key="100000+" value="100000+" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">100000+</Typography>
										</Box>
									  </MenuItem>
							</Select>
						</div>
						
						<div className="form-area">
							<label>Do you sell online<b className="text-danger">*</b></label>
							<Select labelId="online-sell-select-label" id="online-sell-emails-select" className="select-box w-100" value={OnlineSellselectedValue} onChange={OnlineSellhandleChange}>
									 <MenuItem key="Yes" value="Yes" className="select-box-item">
										<Box display="flex" alignItems="center">
										  <Typography className="select-box-typography">Yes</Typography>
										</Box>
									  </MenuItem>
									  <MenuItem key="No" value="No" className="select-box-item">
										<Box display="flex" alignItems="center" className="select-box-box">
										  <Typography className="select-box-typography">No</Typography>
										</Box>
									  </MenuItem>
							</Select>
						</div>
						
						{ErrorOneMessage && <div className="error-message">{ErrorOneMessage}</div>}
						<div className="form-area">
							<button type="button" disabled={ContinueOneButtonDisabled} className="primary-button w-100" onClick={handleContinueOne}>{ContinueOneText}</button>
						</div>
						
						
					</div>
					)}
					
					{currentStep === 'step_two' && (
					<div id="step_two" className="steps-area">
					
						<div className="row">
						<div className="col-6">
						<div className="form-area">
							<label>Company Name<b className="text-danger">*</b></label>
							<input type="text" className={`input-form ${CompanyNameError ? 'input-error' : ''}`} 
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleContinueTwo();  // Trigger the login on Enter press
								}
							  }}
							value={CompanyName} onChange={(e) => setCompanyName(e.target.value)}></input>
						</div>
						</div>
						<div className="col-6">
						<div className="form-area">
							<label className='pb-1 mb-2'>Business Category<b className="text-danger">*</b></label>
							<Select labelId="business-category-select-label" id="business-category-select" className="select-box w-100" value={BusinessCategorySelected} onChange={BusinessCategoryHandleChange}>
							<MenuItem key="Retail" value="Retail">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Retail</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Technology" value="Technology">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Technology</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Healthcare" value="Healthcare">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Healthcare</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Finance" value="Finance">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Finance</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Manufacturing" value="Manufacturing">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Manufacturing</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Education" value="Education">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Education</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Food and Beverage" value="Food and Beverage">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Food and Beverage</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Real Estate" value="Real Estate">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Real Estate</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Entertainment" value="Entertainment">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Entertainment</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Automotive" value="Automotive">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Automotive</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Consulting" value="Consulting">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Consulting</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="E-commerce" value="E-commerce">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">E-commerce</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Hospitality" value="Hospitality">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Hospitality</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Transportation" value="Transportation">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Transportation</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Construction" value="Construction">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Construction</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Legal" value="Legal">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Legal</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Marketing and Advertising" value="Marketing and Advertising">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Marketing and Advertising</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Non-profit" value="Non-profit">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Non-profit</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Energy" value="Energy">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Energy</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Agriculture" value="Agriculture">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Agriculture</Typography>
							</Box>
							</MenuItem>
							<MenuItem key="Others" value="Others">
							<Box display="flex" alignItems="center">
								<Typography className="custom-select-typography">Others</Typography>
							</Box>
							</MenuItem>
							</Select>
						</div>
						</div>
						
						
						<div className="col-12">
						<div className="form-area">
							<label>Address<b className="text-danger">*</b></label>
							<input type="text" 
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleContinueTwo();  // Trigger the login on Enter press
								}
							  }}
							className={`input-form ${AddressError ? 'input-error' : ''}`} value={Address} onChange={(e) => setAddress(e.target.value)}></input>
						</div>
						</div>
						
						
						<div className="col-6">
						<div className="form-area">
							<label>City<b className="text-danger">*</b></label>
							<input type="text" 
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleContinueTwo();  // Trigger the login on Enter press
								}
							  }}
							className={`input-form ${CityError ? 'input-error' : ''}`} value={City} onChange={(e) => setCity(e.target.value)}></input>
						</div>
						</div>
						
						
						<div className="col-6">
						<div className="form-area">
							<label>State<b className="text-danger">*</b></label>
							<input type="text" onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleContinueTwo();  // Trigger the login on Enter press
								}
							  }}
							  className={`input-form ${StateError ? 'input-error' : ''}`} value={State} onChange={(e) => setState(e.target.value)}></input>
						</div>
						</div>
						
						
						<div className="col-6">
						<div className="form-area">
							<label>Postal Code<b className="text-danger">*</b></label>
							<input type="text" 
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleContinueTwo();  // Trigger the login on Enter press
								}
							  }}
							  className={`input-form ${ZipError ? 'input-error' : ''}`} value={Zip} onChange={(e) => setZip(e.target.value)}></input>
						</div>
						</div>
						
						
						<div className="col-6">
							<div className="form-area">
								<label className="country-label">Country<b className="text-danger">*</b></label>
								
								<CountrySelect
								countryselectedOption={countryselectedOption}
								countryhandleChange={(value) => {
								  countrysetSelectedOption(value);
								  handleChange();
								}}
							  />
								
								
								
							</div>
						</div>
						
						{ErrorTwoMessage && <div className="error-message">{ErrorTwoMessage}</div>}
						<div className="form-area">
							<button type="button" disabled={ContinueTwoButtonDisabled} className="primary-button w-100" onClick={handleContinueTwo}>{ContinueTwoText}</button>
						</div>
						
						</div>
					</div>
					
					)}
					
					
					
				</div>
			</div>
			<div className="col-12 col-md-12 col-lg-6 col-xl-6">
				<div className="right-body">
					<img src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?q=80&w=1376&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
				</div>
			</div>
		</div>
		
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
      </Snackbar>
    </div>
  );
};

export default CompleteSetup;
