import React, { useState, useEffect, useRef} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { useSearchParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../../css/private/create-email-template-drag-drop-editor.css';
import { Select, MenuItem, Box, Typography } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid'; // Import a library to generate unique IDs
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const CreateEmailTemplateDragDropEditor = () => {

  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
    const [searchParams] = useSearchParams();
    const campaignId = searchParams.get('campaign_id');
    const template = searchParams.get('template');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [TemplateName, setTemplateName] = useState('Untitled Template');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [FullPageloading, setFullPageloading] = useState(false);
    
    const { TemplateId } = useParams();
    const CookietId = Cookies.get('current_Id');
      const CookieAccountId = Cookies.get('current_accountId');
      const CookieAccountName = Cookies.get('current_accountName');
      const CookieAccountEmail = Cookies.get('current_accountEmail');
    
      const [templates, setTemplates] = useState([]);
      
      const [templateUrl, settemplateUrl] = useState();
      const [Error, setError] = useState(null);

  useEffect(() => {
          const fetchEmailCampaign = async () => {
            setFullPageloading(true);
              try {
                  const response = await fetch(
                      `${ServerApiDomain}/api/emailtemplates/${TemplateId}/${CookieAccountId}`,
                      {
                          method: 'GET',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                      }
                  );
  
                  if (!response.ok) {
                      throw new Error('Network response was not ok');
                  }
  
                  const data = await response.json();
  
                  if (data.type === 'text-editor') {
                    let url = `/create/email-template/text-editor/${TemplateId}?`;
                    if (campaignId) {
                        url += `campaign_id=${campaignId}&`;
                    }
  
                    // Add template if it exists
                    if (template) {
                        url += `template=${template}&`;
                    }
  
                    // Remove trailing '&' or '?' if no parameters were added
                    url = url.endsWith('&') ? url.slice(0, -1) : url;
                    url = url.endsWith('?') ? url.slice(0, -1) : url;
  
                    // Redirect to the constructed URL
                    window.location.href = url;
                  }else if (data.type === 'code-editor') {
                  let url = `/create/email-template/code-editor/${TemplateId}?`;
                    if (campaignId) {
                        url += `campaign_id=${campaignId}&`;
                    }
  
                    // Add template if it exists
                    if (template) {
                        url += `template=${template}&`;
                    }
  
                    // Remove trailing '&' or '?' if no parameters were added
                    url = url.endsWith('&') ? url.slice(0, -1) : url;
                    url = url.endsWith('?') ? url.slice(0, -1) : url;
  
                    // Redirect to the constructed URL
                    window.location.href = url;
                  }
                  
                  
                  if(data.content !== ""){



                    

                    
                    const getEmailTemplatePath = (path) => {
                        // Remove everything before 'emailtemplates' and replace backslashes with forward slashes
                        const updatedPath = path.replace(/^.*emailtemplates/, '/emailtemplates').replace(/\\/g, '/');
                        return updatedPath;
                    };
                    
                    // Example usage:
                    const filePath = data.content;
                    setTemplateName(data.templateName);
                    settemplatePath(filePath);
                    const templatePath = getEmailTemplatePath(filePath);
                    fetch(templatePath)
                    .then(response => response.text())
                    .then(data => {
                    const parsedData = parseHTMLToData(data);
                    setDroppedElements(parsedData);
                      console.log(parsedData);
                    })
                    try {
                        const response = await fetch(templatePath);
                        if (!response.ok) {
                            throw new Error('Failed to fetch the HTML file');
                        }
                        
                        setFullPageloading(false);
                        const text = await response.text();
                        if(filePath !== "" || filePath !== null){
                            setHtmlCode(text);
                        }
                        
                    } catch (error) {
                        console.error('Error fetching HTML:', error);
                        setFullPageloading(false);
                    }
                    }else{
                      setFullPageloading(false);
                    }
              } catch (error) {
                setFullPageloading(false);
                  console.log('Fetch error:', error);
                  setError('TRUE');
                  setLoading(false);
              }
          };
  
          fetchEmailCampaign();
      }, []);
      
      const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };
      
      const HandleShowModal = () => {
        
        setShow(true);
      setErrorEmail("");
      setEmail("");
        setEmailError(false);
        setInputApiArea("true");
      };
    
      const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };  

      const [Name, setName] = useState('');
        const [NameError, setNameError] = useState(false);
        const [ErrorName, setErrorName] = useState('');
        const [EmailSentTo, setEmailSentTo] = useState('');
        const NameRef = useRef(null);

        const [Email, setEmail] = useState('');
          const [MailSubject, setMailSubject] = useState('');
          const [MailPreview, setMailPreview] = useState('');
          const [EmailError, setEmailError] = useState(false);
          const [ErrorEmail, setErrorEmail] = useState('');
          const EmailRef = useRef(null);
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
          const [responseMessage, setResponseMessage] = useState('');
          
          const [loading, setLoading] = useState(false);
          
          
          
          
          
          const handleAddSender = async () => { // Add "async" here
            setErrorEmail("");
            
            setFullPageloading(true);
            setEmailError(false);
      
            if (Email.trim() === '') {
                setErrorEmail("Please enter a sender email address");
                setEmailError(true);
                EmailRef.current.focus();
                return;
            } else if (!emailRegex.test(Email)) {
                setErrorEmail("Please enter a valid email address");
                setEmailError(true);
                EmailRef.current.focus();
                return;
            }

            let templatePathUrl = templatePath;

            // Extract the desired portion and replace backslashes with forward slashes
            let relativePath = "";
            const match = templatePathUrl.match(/\/emailtemplates\/.*/);
            if (match) {
                relativePath = match[0]; // Extract the desired part of the path
            } else {
                console.error("Invalid template path: '/emailtemplates/' not found in templatePathUrl", templatePathUrl);
                setFullPageloading(false);
                return;
            }

            const templateUrl = "https://www.aadow.com" + relativePath;


            setLoading(true); // Show loading state
            setResponseMessage(''); // Clear previous response
      
            const requestData = {
                username: CookieAccountId,
                template_id: TemplateId,
                email: Email,
                subject: MailSubject,
                preview_text: MailPreview,
                template_url: templateUrl,
            };
      
            try {
                const response = await fetch(`${ServerApiDomain}/api/email/send-test`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams(requestData).toString(),
                });
      
                const result = await response.text(); // Assuming your API returns a plain text response
                showSnackbar('Test Mail Sent!', "success");
                setEmail("");
                setMailPreview("");
                setMailSubject("");
                setShow(false);
                
                setFullPageloading(false);
            } catch (error) {
                console.error('Error sending email:', error);
                showSnackbar('Failed to send email: ', "error");
                
                setFullPageloading(false);
            } finally {
              
              setFullPageloading(false);
                setLoading(false); // Hide loading state
            }
        };


        
        
        const [InputApiArea, setInputApiArea] = useState('true');
          const [htmlCode, setHtmlCode] = useState('Hello there...');
        
          // Close dropdown when clicking outside of it
          useEffect(() => {
            document.title = 'Email Template - Code Editor | Aadow';
          }, []);

          const handleGoBack = () => {
            window.history.go(-1);
            };
          
            const [templatePath, settemplatePath] = useState('');
            const [successMessage, setSuccessMessage] = useState('');
            const [errorMessage, setErrorMessage] = useState('');
   
    const getQueryParameter = (param) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };
  
    const removeMultipleParameters = () => {
      const url = new URL(window.location);
      url.searchParams.delete('temp');  // Remove 'temp'
      url.searchParams.delete('foo');   // Remove 'foo'
      
      window.history.replaceState({}, '', url);  // Update the URL without reloading the page
    };
  
    useEffect(() => {
      // Get the 'temp' query parameter value
      const tempValue = getQueryParameter('temp');
      const redirectValue = getQueryParameter('redirect');
      generateThumbnail();
      // If 'temp' is 'update', show the snackbar
      if (tempValue === 'update') {
        showSnackbar('Template saved successfully', 'success');
        removeMultipleParameters();
      }else if (tempValue === 'test') {
        HandleShowModal();
        removeMultipleParameters();
      }else if (tempValue === 'preview') {
        window.open(`/preview/email-template/${TemplateId}`, '_blank');
        removeMultipleParameters();
      }
      
      if (redirectValue === "campaign"){
        window.location.href = `/create/email-campaign/${campaignId}`;
      }
      
    }, []);
  
  
  
  
    const handleUpdate = async (e) => {
      // Prevent default form behavior (if button is in a form)
      e.preventDefault();
      const CurrentUrl = new URL(window.location);
      CurrentUrl.searchParams.set('temp', 'update');
      window.history.pushState({}, '', CurrentUrl);
  
      const url = `${ServerApiDomain}/api/emailtemplates/update/${CookieAccountId}/${TemplateId}`;
      const currentDateTime = new Date().toISOString();
      const data = {
          templateName: TemplateName,
          subject: "",
          previewText: "",
          content: "",
          thumbnail: `https://www.aadow.com/emailthumbnail/${TemplateId}.png`,
          status: "active",
          score: "",
          datetime: currentDateTime,
          timestamp: currentDateTime,
      };
  
      try {
          const res = await fetch(url, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          });
  
          if (res.ok) {
                if(campaignId){
                  await  handleUpdateInfo();
                }else{
                 await uploadTemplate();
                }
              showSnackbar('Template updated and uploaded successfully!', 'success');
          } else {
              console.error('Update failed:', res.statusText);
              showSnackbar(`Update failed: ${res.statusText}`, 'error');
          }
      } catch (error) {
          console.error('Error during update:', error);
          showSnackbar('An error occurred while updating the template.', 'error');
      }
    };
  
    const handleUpdateInfo = async () => {  // no need for the 'event' parameter here
      
      const CurrentUrl = new URL(window.location);
      CurrentUrl.searchParams.set('redirect', 'campaign');
      window.history.pushState({}, '', CurrentUrl);
  
      let updatedData;
  
      // Set updatedData based on the template condition
      if (template === "b") {
          updatedData = {
              "username": CookieAccountId, 
              "emailTemplateB": TemplateId
          };
      } else {
          updatedData = {
              "username": CookieAccountId, 
              "emailTemplate": TemplateId
          };
      }
      
  
      try {
          const response = await fetch(`${ServerApiDomain}/api/campaigns/update/${CookieAccountId}/campaign/${campaignId}`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(updatedData)
          });
  
          if (response.ok) {
              await uploadTemplate();
          } else {
              console.log(`Failed to update campaign: ${response.statusText}`);
          }
      } catch (error) {
          console.log(`Error while updating campaign: ${error.message}`);
      }
    };
  
    const uploadTemplate = async () => {
      const url = `${ServerApiDomain}/api/emailtemplates/upload`;
      const data = {
          username: CookieAccountId,
          templateId: TemplateId,
          content: generateHTML(), // HTML content
      };
  
      try {
            const res = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
  
            if (res.ok) {
                const jsonResponse = await res.json();
                console.log('Upload successful:', jsonResponse.message);
                showSnackbar('Template saved successfully', 'success');
                window.location.reload();
            } else {
                const errorResponse = await res.text();
                console.error('Upload failed:', errorResponse);
                showSnackbar(`Upload failed: ${res.statusText}`, 'error');
            }
        } catch (error) {
            console.error('Error uploading template:', error);
            showSnackbar('An error occurred while uploading the template.', 'error');
        }
    };
  
   
    
    const handleUpdateTest = async (e) => {
      // Prevent default form behavior (if button is in a form)
      e.preventDefault();
      const CurrentUrl = new URL(window.location);
      CurrentUrl.searchParams.set('temp', 'test');  // Adds or updates the 'temp' parameter
      window.history.pushState({}, '', CurrentUrl);
  
      const url = `${ServerApiDomain}/api/emailtemplates/update/${CookieAccountId}/${TemplateId}`;
      const currentDateTime = new Date().toISOString();
      const data = {
          templateName: TemplateName,
          subject: "",
          previewText: "",
          content: "",
          thumbnail: `https://www.aadow.com/emailthumbnail/${TemplateId}.png`,
          status: "active",
          score: "",
          datetime: currentDateTime,
          timestamp: currentDateTime,
      };
  
      try {
          const res = await fetch(url, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          });
  
          if (res.ok) {
              await uploadTemplate(); // Call the upload function
              showSnackbar('Template updated and uploaded successfully!', 'success');
          } else {
              console.error('Update failed:', res.statusText);
              showSnackbar(`Update failed: ${res.statusText}`, 'error');
          }
      } catch (error) {
          console.error('Error during update:', error);
          showSnackbar('An error occurred while updating the template.', 'error');
      }
    };
  
    
    const handleUpdatePreview = async (e) => {
      // Prevent default form behavior (if button is in a form)
      e.preventDefault();
      const CurrentUrl = new URL(window.location);
      CurrentUrl.searchParams.set('temp', 'preview');  // Adds or updates the 'temp' parameter
      window.history.pushState({}, '', CurrentUrl);
  
      const url = `${ServerApiDomain}/api/emailtemplates/update/${CookieAccountId}/${TemplateId}`;
      const currentDateTime = new Date().toISOString();
      const data = {
          templateName: TemplateName,
          subject: "",
          previewText: "",
          content: "",
          thumbnail: `https://www.aadow.com/emailthumbnail/${TemplateId}.png`,
          status: "active",
          score: "",
          datetime: currentDateTime,
          timestamp: currentDateTime,
      };
  
      try {
          const res = await fetch(url, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          });
  
          if (res.ok) {
              await uploadTemplate(); // Call the upload function
              showSnackbar('Template updated and uploaded successfully!', 'success');
          } else {
              console.error('Update failed:', res.statusText);
              showSnackbar(`Update failed: ${res.statusText}`, 'error');
          }
      } catch (error) {
          console.error('Error during update:', error);
          showSnackbar('An error occurred while updating the template.', 'error');
      }
    };        
    
    const generateThumbnail = async () => {
      const url = 'https://www.aadow.com:8080/generate-thumbnail';
    
      // Manually encode the URL and outputPath parameters
      const encodedUrl = encodeURIComponent(`https://aadow.com/emailtemplates/${CookieAccountId}/${TemplateId}/${TemplateId}.html`);
      const encodedOutputPath = encodeURIComponent(`/var/www/react/emailthumbnail/${TemplateId}.png`);
    
      const params = {
        url: encodedUrl,
        outputPath: encodedOutputPath,
      };
    
      try {
        console.log(`Thumbnail URL: ${url}`);
        console.log(`Thumbnail Params: ${JSON.stringify(params)}`);
    
        // Axios will send the encoded parameters
        const res = await axios.get(url, { params });
    
        if (res.status === 200) {
          console.log('Thumbnail generated successfully.');
        } else {
          console.log(`Thumbnail generation failed: ${res.statusText}`);
        }
      } catch (error) {
        console.log(`Error generating thumbnail: ${error}`);
      }
    };
    

  const [draggedLayoutIndex, setDraggedLayoutIndex] = useState(null);

  const handleDragStartLayout = (event, index) => {
    setDraggedLayoutIndex(index); // Store the index of the layout being dragged
  };
  
  const handleDragOverLayout = (event) => {
    event.preventDefault(); // Allow drop
  };
  
  const handleDropLayout = (event, targetIndex) => {
    event.preventDefault();
  
    if (draggedLayoutIndex !== null && draggedLayoutIndex !== targetIndex) {
      setDroppedElements((prev) => {
        const updatedElements = [...prev];
        const [draggedLayout] = updatedElements.splice(draggedLayoutIndex, 1); // Remove dragged layout
        updatedElements.splice(targetIndex, 0, draggedLayout); // Insert it at the new position
        return updatedElements;
      });
    }
  
    setDraggedLayoutIndex(null); // Reset the dragged index
  };

  const [socialLinks, setSocialLinks] = useState({
    facebook: 'https://www.facbook.com/',
    x: 'https://www.x.com/',
    instagram: 'https://www.instagram.com/',
    linkedin: 'https://www.linkedin.com/',
    youtube: 'https://www.youtube.com/',
    pinterest: 'https://www.pinterest.com/',
  });
  
  // Sample HTML to preload

  
  
  const [droppedElements, setDroppedElements] = useState([]);
  const [selectedLayoutIndex, setSelectedLayoutIndex] = useState(null);
  const [styleProperties, setStyleProperties] = useState({
    backgroundColor: '',
    padding: '',
    border: '',
  });
  const [selectedElement, setSelectedElement] = useState(null);

  //

  

  const sampleHTML = ``;


  const parseInlineStyles = (styleString) => {
    if (!styleString) return {};
    return styleString
      .split(';')
      .filter(Boolean)
      .reduce((acc, style) => {
        const [key, value] = style.split(':').map((s) => s.trim());
        if (key && value) {
          acc[key.replace(/-([a-z])/g, (_, char) => char.toUpperCase())] = value;
        }
        return acc;
      }, {});
  };

  const parseHTMLToData = (html) => {
    const container = document.createElement('div');
    container.innerHTML = html;

    const targetDivs = container.querySelectorAll('div[style*="display: flex"][style*="gap: 10px;"]');

    if (!targetDivs.length) {
      console.warn('No matching target divs found');
      return [];
    }

    const parseElement = (element) => {
      const elementId = uuidv4();
      const styleString = element.getAttribute('style') || '';
      const style = parseInlineStyles(styleString);

      switch (element.tagName.toUpperCase()) {
        case 'H1':
          return { id: elementId, type: 'heading', content: element.textContent, style };
        case 'P':
          return { id: elementId, type: 'text', content: element.textContent, style };
        case 'IMG':
          return {
            id: elementId,
            type: 'image',
            src: element.getAttribute('src') || '',
            alt: element.getAttribute('alt') || '',
            style,
          };
        case 'A':
          // Handle nested elements inside links (e.g., images)
          const nestedChildren = Array.from(element.children).map(parseElement);
          return {
            id: elementId,
            type: 'link',
            href: element.getAttribute('href') || '#',
            content: element.textContent || '',
            style,
            children: nestedChildren,
          };
        case 'DIV':
          // Recursive parsing for nested containers
          const nestedElements = Array.from(element.children).map(parseElement);
          return {
            id: elementId,
            type: 'container',
            children: nestedElements,
            style,
          };
        case 'HR':
          return { id: elementId, type: 'divider', style };
        case 'SOCIAL':
          const socialLinks = Array.from(element.children).map(parseElement).filter(Boolean);
          return {
            id: elementId,
            type: 'social',
            children: socialLinks,
            style,
          };
        default:
          console.warn(`Unhandled element type: ${element.tagName}`);
          return null;
      }
    };

    const layouts = Array.from(targetDivs).map((targetDiv) => {
      const columns = Array.from(targetDiv.children).map((column) =>
        Array.from(column.children).map(parseElement).filter(Boolean)
      );

      return {
        type: 'layout',
        columns,
        style: parseInlineStyles(targetDiv.getAttribute('style') || ''),
      };
    });

    return layouts;
  };

  
  
  
  
  

  const handleDragStart = (event, element) => {
    event.dataTransfer.setData('element', element);
  };

  const handleDrop = (event, parentIndex = null, columnIndex = null) => {
    event.preventDefault();
    const element = event.dataTransfer.getData('element');
  
    if (!element) {
      console.error('No element data found during drop.');
      return;
    }
  
    setDroppedElements((prev) => {
      const updatedElements = [...prev];
  
      if (parentIndex !== null && columnIndex !== null) {
        const updatedColumns = [...updatedElements[parentIndex].columns];
        updatedColumns[columnIndex] = [
          ...updatedColumns[columnIndex],
          {
            id: uuidv4(),
            type: element,
            content: element === 'text' ? 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.' : 'New Element',
            ...(element === 'footer' && { content: '© 2024 [Your Company Name]. All rights reserved. [Your Company Name], [Your Address Line 1], [City, State, ZIP Code]' }),
            ...(element === 'heading' && { content: 'Some title here' }),
            ...(element === 'divider' && { content: '' }),
            ...(element === 'spacer' && { content: '' }),
            ...(element === 'link' && { href: '', content: 'Click Me' }),
            ...(element === 'image' && { src: 'https://app.aadow.com/imgs/placeholder.webp' }),
            ...(element === 'logo' && { src: 'https://app.aadow.com/imgs/logo-placeholder.jpg' }),
          },
        ];
        updatedElements[parentIndex] = { ...updatedElements[parentIndex], columns: updatedColumns };
      } else if (element.includes('-column')) {
        // Adding a new layout
        const numColumns = parseInt(element.split('-')[0], 10);
        const columns = Array(numColumns).fill([]); // Initialize empty columns
        updatedElements.push({ type: 'layout', columns, style: {} });
      }
  
      return updatedElements;
    });
  };  
  

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const removeElement = (parentIndex, columnIndex, elementId) => {
    setDroppedElements((prev) => {
      const updatedElements = [...prev];
      const updatedColumns = [...updatedElements[parentIndex].columns];
      updatedColumns[columnIndex] = updatedColumns[columnIndex].filter((el) => el.id !== elementId);
      updatedElements[parentIndex] = { ...updatedElements[parentIndex], columns: updatedColumns };
      return updatedElements;
    });
    setSelectedElement(null);
  };

  const removeLayout = (parentIndex) => {
    setDroppedElements((prev) => {
      return prev.filter((_, index) => index !== parentIndex);
    });
    setSelectedLayoutIndex(null);
  };

  const moveLayout = (index, direction) => {
    setDroppedElements((prev) => {
      const updatedElements = [...prev];
      const targetIndex = direction === 'up' ? index - 1 : index + 1;

      if (targetIndex >= 0 && targetIndex < updatedElements.length) {
        const temp = updatedElements[index];
        updatedElements[index] = updatedElements[targetIndex];
        updatedElements[targetIndex] = temp;
      }
      setSelectedLayoutIndex(null);
      return updatedElements;
    });
  };

  const handleStyleChange = (property, value) => {
    setStyleProperties((prev) => ({ ...prev, [property]: value }));
    applyStyles();
  };

  const applyStyles = () => {
    if (selectedLayoutIndex !== null) {
      setDroppedElements((prev) => {
        const updatedElements = [...prev];
        updatedElements[selectedLayoutIndex].style = { ...styleProperties };
        return updatedElements;
      });
    }
  };

  const applyStylesClosed = () => {
    if (selectedLayoutIndex !== null) {
      setDroppedElements((prev) => {
        const updatedElements = [...prev];
        updatedElements[selectedLayoutIndex].style = { ...styleProperties };
        setSelectedLayoutIndex(null);
        return updatedElements;
      });
    }
  };

  const handleEditLayout = (index) => {
    setSelectedElement(null);
    setSelectedLayoutIndex(index);
    setStyleProperties(droppedElements[index]?.style || { backgroundColor: '', padding: '', border: '' });
  };

  const handleEditElement = (parentIndex, columnIndex, elementId) => {
    const element = droppedElements[parentIndex].columns[columnIndex].find((el) => el.id === elementId);
    setSelectedElement({ parentIndex, columnIndex, element });
    setSelectedLayoutIndex(null);
  };

  const handleElementContentChange = (event) => {
    const updatedElement = { ...selectedElement.element, content: event.target.value };
    setSelectedElement({ ...selectedElement, element: updatedElement });

    setDroppedElements((prev) => {
      const updatedElements = [...prev];
      updatedElements[selectedElement.parentIndex].columns[selectedElement.columnIndex] = updatedElements[selectedElement.parentIndex].columns[selectedElement.columnIndex].map((el) =>
        el.id === selectedElement.element.id ? updatedElement : el
      );
      return updatedElements;
    });
  };

  const handleElementStyleChange = (property, value) => {
    if (selectedElement) {
      const updatedElement = {
        ...selectedElement.element,
        style: {
          ...selectedElement.element.style,
          [property]: value, // Update the specific style property
        },
      };
  
      setSelectedElement({ ...selectedElement, element: updatedElement });
  
      setDroppedElements((prev) => {
        const updatedElements = [...prev];
        updatedElements[selectedElement.parentIndex].columns[selectedElement.columnIndex] =
          updatedElements[selectedElement.parentIndex].columns[selectedElement.columnIndex].map((el) =>
            el.id === selectedElement.element.id ? updatedElement : el
          );
        return updatedElements;
      });
    }
  };
  

  const applyElementStyles = () => {
    if (selectedElement) {
      setDroppedElements((prev) => {
        const updatedElements = prev.map((layout, parentIndex) => {
          if (parentIndex === selectedElement.parentIndex) {
            const updatedColumns = layout.columns.map((column, columnIndex) => {
              if (columnIndex === selectedElement.columnIndex) {
                return column.map((el) => {
                  if (el.id === selectedElement.element.id) {
                    return {
                      ...el,
                      style: { ...selectedElement.element.style }, // Ensure the new style is applied
                    };
                  }
                  return el;
                });
              }
              return column;
            });
            return { ...layout, columns: updatedColumns };
          }
          return layout;
        });
        return updatedElements;
      });
    }
  };
  const applyElementStylesClosed = () => {
    if (selectedElement) {
      setDroppedElements((prev) => {
        const updatedElements = prev.map((layout, parentIndex) => {
          if (parentIndex === selectedElement.parentIndex) {
            const updatedColumns = layout.columns.map((column, columnIndex) => {
              if (columnIndex === selectedElement.columnIndex) {
                return column.map((el) => {
                  if (el.id === selectedElement.element.id) {
                    return {
                      ...el,
                      style: { ...selectedElement.element.style }, // Ensure the new style is applied
                    };
                  }
                  return el;
                });
              }
              return column;
            });
            return { ...layout, columns: updatedColumns };
          }
          return layout;
        });
        return updatedElements;
      });
    }
    setSelectedElement(null);
  };
  
  const generateHTML = () => {
    return droppedElements
      .map((item) => {
        if (item.type === 'layout') {
          const layoutStyle = Object.keys(item.style || {})
            .map((key) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${item.style[key]}`)
            .join('; ');
  
          return `
          <div style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f1f1f1;"><table  width="100%" border="0" cellpadding="0"cellspacing="0" style="background-color: #f1f1f1;padding: 0 0;"><tr><td align="center" style="padding: 0; margin: 0;"><table class="main-table" style=""><div style="background: #fff;width:600px;border-spacing: 0;border-collapse: collapse;text-align:left;">
          <div style="display: flex; gap: 10px; ${layoutStyle}">${item.columns
            .map(
              (column) =>
                `<div style="font-family: Verdana;flex: 1; padding: 10px;">${column
                  .map((el) => {
                    const elementStyle = Object.keys(el.style || {})
                      .map((key) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${el.style[key]}`)
                      .join('; ');
  
                    switch (el.type) {
                      case 'heading':
                        
                        const predefinedStylesheading = {
                                            fontSize: '24px',
                                            marginTop:'10px',
                                            marginBottom:'10px',
                                            display: 'block',
                        };

                        const combinedStylesheading = {
                          ...predefinedStylesheading,
                          ...el.style, // Merge predefined and dynamic styles
                        };
                        const styleHeading = Object.entries(combinedStylesheading)
                        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                        .join('; ');

                        return `<h1 style="${styleHeading}">${el.content}</h1>`;

                      case 'text':
                        const predefinedStylestext = {
                          fontSize: '14px',
                                            marginTop:'10px',
                                            marginBottom:'10px',
                                            display: 'block',
                        };

                        const combinedStylestext = {
                          ...predefinedStylestext,
                          ...el.style, // Merge predefined and dynamic styles
                        };
                        const styleText = Object.entries(combinedStylestext)
                        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                        .join('; ');
                        
                        return `<p style="${styleText}">${el.content}</p>`;

                      case 'footer':
                        const predefinedStylesfooter = {
                                            fontSize: '14px',
                                            marginTop:'10px',
                                            marginBottom:'10px',
                                            textAlign:'center',
                                            display: 'block',
                        };

                        const combinedStylesfooter = {
                          ...predefinedStylesfooter,
                          ...el.style, // Merge predefined and dynamic styles
                        };
                        const styleFooter = Object.entries(combinedStylesfooter)
                        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                        .join('; ');
                        
                        return `<p style="${styleFooter}">${el.content}</p>`;

                      case 'divider':
                        const predefinedStylesdivider = {
                                            border: 'none',
                                            borderTop: '1px solid #ddd',
                                            margin: '20px 0'
                        };

                        const combinedStylesdivider = {
                          ...predefinedStylesdivider,
                          ...el.style, // Merge predefined and dynamic styles
                        };
                        const styleDivider = Object.entries(combinedStylesdivider)
                        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                        .join('; ');
                        
                        return `<hr style="${styleDivider}" />`; 
                        
                      case 'spacer':
                        const predefinedStylesspacer = {
                                            height: '20px',
                        };

                        const combinedStylesspacer = {
                          ...predefinedStylesspacer,
                          ...el.style, // Merge predefined and dynamic styles
                        };
                        const styleSpacer = Object.entries(combinedStylesspacer)
                        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                        .join('; ');
                        
                        return `<div style="${styleSpacer}"></div>`;   

                      case 'logo':

                        const predefinedStyleslogo = {
                          width: '25%',
                          margin: '10px auto',
                          display: 'block',
                        };

                        const combinedStyleslogo = {
                          ...predefinedStyleslogo,
                          ...el.style, // Merge predefined and dynamic styles
                        };
                        const styleLogo = Object.entries(combinedStyleslogo)
                        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                        .join('; ');

                        const logoElement = `<img src="${el.src}" alt="Logo" style="${styleLogo}" />`;
                        return el.href ? `<a href="${el.href}" target="_blank" style="${styleLogo}">${logoElement}</a>` : logoElement;  

                      case 'image':

                        const predefinedStylesimage = {
                          width: '100%',
                          margin: '10px auto',
                          display: 'block',
                        };

                        const combinedStylesImage = {
                          ...predefinedStylesimage,
                          ...el.style, // Merge predefined and dynamic styles
                        };
                        const styleImage = Object.entries(combinedStylesImage)
                        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                        .join('; ');

                        const imageElement = `<img src="${el.src}" alt="Image" style="${styleImage}" />`;
                        return el.href ? `<a href="${el.href}" target="_blank" style="${styleImage}">${imageElement}</a>` : imageElement;  
                      case 'button':
                        return `<button style="${elementStyle}">${el.content}</button>`;
                      
                      case 'link':
                        
                        const predefinedStyles = {
                          backgroundColor: '#0075e4',
                          color: '#fff',
                          width: '100px',
                          fontSize: '14px',
                          margin: '10px 0px',
                          padding: '8px 16px',
                          display: 'block',
                          textAlign: 'center',
                          borderRadius: '5px',
                          textDecoration: 'none',
                        };

                        const combinedStyles = {
                          ...predefinedStyles,
                          ...el.style, // Merge predefined and dynamic styles
                        };
                        const styleLink = Object.entries(combinedStyles)
                        .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value}`)
                        .join('; ');
                        
                        return `<a href="${el.href}" style="${styleLink}">${el.content}</a>`;

                      case 'social-media-links':
                      const socialMediaHtml = Object.entries(socialLinks)
                        .filter(([_, link]) => link) // Only include platforms with valid links
                        .map(([platform, link]) => {
                          return `<a href="${link}" target="_blank" style="text-decoration:'none'" rel="noopener noreferrer">
                                    <img src="https://app.aadow.com/social-media-icons/${platform}.png" alt="${platform}" style="width: 40px; height: 40px; margin-right: 10px;" />
                                  </a>`;
                        })
                        .join('');
                      return `<social style="text-align: center;">${socialMediaHtml}</social>`;
                      default:
                        return '';
                    }
                  })
                  .join('')}</div>`
            )
            .join('')}</div>`;
        }
        return '';
      })
      .join('');
  };
  
  const handleSocialLinkChange = (platform, value) => {
    setSocialLinks((prev) => ({ ...prev, [platform]: value }));
  };
  
  const handleElementLinkChange = (value) => {
    if (selectedElement) {
      const updatedElement = {
        ...selectedElement.element,
        href: value,
      };
  
      setSelectedElement({ ...selectedElement, element: updatedElement });
  
      setDroppedElements((prev) => {
        const updatedElements = [...prev];
        updatedElements[selectedElement.parentIndex].columns[selectedElement.columnIndex] =
          updatedElements[selectedElement.parentIndex].columns[selectedElement.columnIndex].map((el) =>
            el.id === selectedElement.element.id ? updatedElement : el
          );
        return updatedElements;
      });
    }
  };

  const handleElementSrcChange = (value) => {
    if (selectedElement) {
      const updatedElement = {
        ...selectedElement.element,
        src: value,
      };
  
      setSelectedElement({ ...selectedElement, element: updatedElement });
  
      setDroppedElements((prev) => {
        const updatedElements = [...prev];
        updatedElements[selectedElement.parentIndex].columns[selectedElement.columnIndex] =
          updatedElements[selectedElement.parentIndex].columns[selectedElement.columnIndex].map((el) =>
            el.id === selectedElement.element.id ? updatedElement : el
          );
        return updatedElements;
      });
    }
  };
  const [activeTab, setActiveTab] = useState('layouts'); // Default is 'elements'

  const [hovered, setHovered] = useState(false);
  const [elementhovered, setElementHovered] = useState(false);
  
  return (
    <>
    <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
		</Backdrop>
      {Error === 'TRUE' && (
            <center>
                <div className="empty-error-area">
                    <img className="empty-error-image w-25" src="../../../iconss/other/no-results.png"  />
                    <h2 className="empty-error-heading">No Template Found</h2>
                    <h6 className="empty-error-subheading" >It looks like there are no template associated with this id.</h6>
                </div>
            </center>
      )}
{Error !== 'TRUE' && (
      <div className="page-content">
        <div className="create-drag-drop-template-body">
          <header>
            <div className="row">
              <div className="col-4">
                <button onClick={handleGoBack} className="back-button secondary-button ms-2 me-2">
                  <i className="bi bi-arrow-left me-2"></i> Back to Templates
                </button>
              </div>
              <div className="col-4">
              <input type="text" className="template-title" value={TemplateName} onChange={(e) => {
								  setTemplateName(e.target.value)
                }} />
              </div>
              <div className="col-4 text-end">
                <button onClick={(e) => handleUpdate(e)} className="publish-button mt-0 primary-button ms-2">
                  <i className="bi bi-megaphone me-2"></i> Save Template
                </button>
              </div>
            </div>
          </header>

          <div className="semi-header">
            <div className="row">
              <div className="col-6">
              <a
                  href="#"
                  className={activeTab === 'layouts' ? 'active-tab' : 'tab'}
                  onClick={() => setActiveTab('layouts')}
                >
                  Layouts
                </a>
                <a
                  href="#"
                  className={activeTab === 'elements' ? 'active-tab' : 'tab'}
                  onClick={() => setActiveTab('elements')}
                >
                  Elements
                </a>
                
                <a href="" className="tab d-none"><i class="bi bi-lightning"></i> AMP</a>
              </div>
              <div className="col-6 text-end">
                <button className="secondary-outline-button me-2 d-none undo-redo">
                  <i className="bi bi-arrow-counterclockwise"></i>
                </button>
                <button className="secondary-outline-button ms-2 d-none me-2 undo-redo">
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
                <button onClick={handleUpdatePreview} className="secondary-outline-button ms-2 me-2">
                  <i className="bi bi-eye me-2"></i> Preview
                </button>
                <button onClick={handleUpdateTest} className="secondary-button ms-2 me-2">
                  <i className="bi bi-send me-2"></i> Send Test
                </button>
              </div>
            </div>
          </div>

          <div className="editor-area">
            <div className="editor-container">
              <div className="left-section">
              {activeTab === 'elements' && (
                <div className="element-area">
                  <div className="row">
                    {[
                      { type: 'heading', logoUrl: 'https://app.aadow.com/svg/title.svg', label: 'Title' },
                      { type: 'text', logoUrl: 'https://app.aadow.com/svg/text.svg', label: 'TEXT' },
                      { type: 'link', logoUrl: 'https://app.aadow.com/svg/button.svg', label: 'BUTTON' },
                      { type: 'logo', logoUrl: 'https://app.aadow.com/svg/logo.svg', label: 'LOGO' },
                      { type: 'image', logoUrl: 'https://app.aadow.com/svg/image.svg', label: 'IMAGE' },
                      { type: 'social-media-links', logoUrl: 'https://app.aadow.com/svg/social.svg', label: 'SOCIAL' },
                      { type: 'footer', logoUrl: 'https://app.aadow.com/svg/footer.svg', label: 'FOOTER' },
                      { type: 'divider', logoUrl: 'https://app.aadow.com/svg/divider.svg', label: 'DIVIDER' },
                      { type: 'spacer', logoUrl: 'https://app.aadow.com/svg/spacer.svg', label: 'SPACER' },
                    ].map(({ type, logoUrl, label }) => (
                      <div className="col-4" key={type}>
                        <div
                          className="element"
                          draggable
                          onDragStart={(event) => handleDragStart(event, type)}
                        >
                          <img className="element-icon" src={logoUrl} alt={label} />
                          <h6 className="element-title">{label}</h6>
                          <i className="bi bi-grip-horizontal"></i>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                )}

                {activeTab === 'layouts' && (
                  <div className="layout-area">
                    <div className="row">
                      {[
                        { type: '1-column', logoUrl: '/iconss/single.jpg', label: '1 Column' },
                        { type: '2-column', logoUrl: '/iconss/double.jpg', label: '2 Column' },
                        { type: '3-column', logoUrl: '/iconss/triple.jpg', label: '3 Column' },
                        { type: '4-column', logoUrl: '/iconss/four.jpg', label: '4 Column' },
                      ].map(({ type, logoUrl, label }) => (
                        <div className="col-12" key={type}>
                          <div
                            className="element"
                            draggable
                            onDragStart={(event) => handleDragStart(event, type)}
                          >
                            <img className="layout-icon" src={logoUrl} alt={label} />
                            <h6 className="element-title">{label}</h6>
                            <i className="bi bi-grip-horizontal"></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="right-section">
              <div
                className="droppable-area"
                onDrop={(event) => handleDrop(event)}
                onDragOver={allowDrop}
                style={{ padding: '5px', border: '2px dashed rgba(245, 245, 245)', background: 'rgba(255, 255, 255)', width: '800px', minHeight: '500px' }}
              >
                {droppedElements.length === 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '35%',
                      left: '49%',
                      transform: 'translate(-50%, -50%)',
                      textAlign: 'center',
                      color: '#aaa', // Light grey color
                      fontSize: '16px',
                    }}
                  >
                    Drag and drop a layout into this area
                  </div>
                )}
                {droppedElements.map((item, parentIndex) => {
                  if (item.type === 'layout') {
                    return (
                      <div
                        key={parentIndex}
                        draggable
                        onDragStart={(event) => handleDragStartLayout(event, parentIndex)}
                        onDragOver={handleDragOverLayout}
                        onDrop={(event) => handleDropLayout(event, parentIndex)}
                        onMouseEnter={() => setHovered(true)} // Set hover state
                        onMouseLeave={() => setHovered(false)} // Reset hover state
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          marginBottom: '10px',
                          position: 'relative',
                          ...item.style,
                        }}
                      >
                        {hovered && ( // Conditionally render the buttons div
                          <div
                            style={{
                              position: 'absolute', // Make it overlap without affecting layout
                                                display: 'flex', // Adjust as per your layout
                                                justifyContent: 'space-between',
                                                top: '-25px',
                                                right: '-50px',
                                                border: '1px solid rgb(202, 202, 202)',
                                                borderRadius: '5px',
                                                backgroundColor: 'rgb(255, 255, 255)', // Optional background
                                                padding: '5px', // Adjust as needed
                                                textAlign: 'right',
                                                zIndex: 1,
                            }}
                          >
                            <i onClick={() => handleEditLayout(parentIndex)}  className='EditLayoutAreaButton'><i className='bi bi-pencil'></i></i>
                            <i onClick={() => removeLayout(parentIndex)}  className='EditLayoutAreaButton bg-danger text-white border-danger'>
                              <i className='bi bi-trash'></i>
                            </i>
                          </div>
                        )}
                        
                        <div class="draggable-area-of-page" style={hovered ? { display: 'flex', gap: '10px' } : { display: 'flex', gap: '10px' }}>
                          {item.columns.map((column, columnIndex) => (
                            <div
                              key={columnIndex}
                              onDrop={(event) => handleDrop(event, parentIndex, columnIndex)}
                              onDragOver={allowDrop}
                              style={{
                                flex: 1,
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                borderStyle: 'solid',
                                bprderWidth: '1px',
                                minHeight: '80px',
                                position: 'relative',
                              }}
                            >
                              {column.map((el) => {
                                switch (el.type) {
                                  case 'heading':
                                    return (
                                      <div
                                          onMouseEnter={() => setElementHovered(true)}
                                          onMouseLeave={() => setElementHovered(false)}
                                          key={el.id}
                                          style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                        >
                                          {elementhovered && ( // Conditionally render the buttons div
                                            <div
                                              style={{
                                                position: 'absolute', // Make it overlap without affecting layout
                                                display: 'flex', // Adjust as per your layout
                                                justifyContent: 'space-between',
                                                top: 0,
                                                right: 0,
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                                padding: '5px', // Adjust as needed
                                                textAlign: 'right',
                                                zIndex: 1,
                                              }}
                                            >
                                              <i
                                                onClick={() => {
                                                  handleEditElement(parentIndex, columnIndex, el.id);
                                                }}
                                                className="EditLayoutAreaButton"
                                              >
                                                <i className="bi bi-pencil"></i>
                                              </i>
                                              <i
                                                onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                                className="EditLayoutAreaButton bg-danger text-white border-danger"
                                              >
                                                <i className="bi bi-trash"></i>
                                              </i>
                                            </div>
                                          )}
                                          <h1 
                                          style={{
                                            fontSize: '24px',
                                            marginTop:'10px',
                                            marginBottom:'10px',
                                            display: 'block',
                                            ...el.style, // Spread el.style to include additional or overriding styles
                                          }}
                                          >{el.content}</h1>
                                        </div>
                                    );
                                    case 'text':
                                    return (
                                      <div
                                          onMouseEnter={() => setElementHovered(true)}
                                          onMouseLeave={() => setElementHovered(false)}
                                          key={el.id}
                                          style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                        >
                                          {elementhovered && ( // Conditionally render the buttons div
                                            <div
                                              style={{
                                                position: 'absolute', // Make it overlap without affecting layout
                                                display: 'flex', // Adjust as per your layout
                                                justifyContent: 'space-between',
                                                top: 0,
                                                right: 0,
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                                padding: '5px', // Adjust as needed
                                                textAlign: 'right',
                                                zIndex: 1,
                                              }}
                                            >
                                              <i
                                                onClick={() => {
                                                  handleEditElement(parentIndex, columnIndex, el.id);
                                                }}
                                                className="EditLayoutAreaButton"
                                              >
                                                <i className="bi bi-pencil"></i>
                                              </i>
                                              <i
                                                onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                                className="EditLayoutAreaButton bg-danger text-white border-danger"
                                              >
                                                <i className="bi bi-trash"></i>
                                              </i>
                                            </div>
                                          )}
                                          <p 
                                          style={{
                                            fontSize: '14px',
                                            marginTop:'10px',
                                            marginBottom:'10px',
                                            display: 'block',
                                            ...el.style, // Spread el.style to include additional or overriding styles
                                          }}
                                          >{el.content}</p>
                                        </div>
                                    );


                                    case 'divider':
                                    return (
                                      <div
                                          onMouseEnter={() => setElementHovered(true)}
                                          onMouseLeave={() => setElementHovered(false)}
                                          key={el.id}
                                          style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                        >
                                          {elementhovered && ( // Conditionally render the buttons div
                                            <div
                                              style={{
                                                position: 'absolute', // Make it overlap without affecting layout
                                                display: 'flex', // Adjust as per your layout
                                                justifyContent: 'space-between',
                                                top: 0,
                                                right: 0,
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                                padding: '5px', // Adjust as needed
                                                textAlign: 'right',
                                                zIndex: 1,
                                              }}
                                            >
                                              <i
                                                onClick={() => {
                                                  handleEditElement(parentIndex, columnIndex, el.id);
                                                }}
                                                className="EditLayoutAreaButton"
                                              >
                                                <i className="bi bi-pencil"></i>
                                              </i>
                                              <i
                                                onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                                className="EditLayoutAreaButton bg-danger text-white border-danger"
                                              >
                                                <i className="bi bi-trash"></i>
                                              </i>
                                            </div>
                                          )}
                                         <hr
                                            style={{
                                              border: 'none',
                                              borderTop: '10px solid #ddd',
                                              margin: '20px 0',
                                              ...(el?.style || {}), // Safely spread el.style if it exists, or use an empty object
                                            }}
                                          />

                                        </div>
                                    );

                                    case 'spacer':
                                    return (
                                      <div
                                          onMouseEnter={() => setElementHovered(true)}
                                          onMouseLeave={() => setElementHovered(false)}
                                          key={el.id}
                                          style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                        >
                                          {elementhovered && ( // Conditionally render the buttons div
                                            <div
                                              style={{
                                                position: 'absolute', // Make it overlap without affecting layout
                                                display: 'flex', // Adjust as per your layout
                                                justifyContent: 'space-between',
                                                top: 0,
                                                right: 0,
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                                padding: '5px', // Adjust as needed
                                                textAlign: 'right',
                                                zIndex: 1,
                                              }}
                                            >
                                              <i
                                                onClick={() => {
                                                  handleEditElement(parentIndex, columnIndex, el.id);
                                                }}
                                                className="EditLayoutAreaButton"
                                              >
                                                <i className="bi bi-pencil"></i>
                                              </i>
                                              <i
                                                onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                                className="EditLayoutAreaButton bg-danger text-white border-danger"
                                              >
                                                <i className="bi bi-trash"></i>
                                              </i>
                                            </div>
                                          )}
                                         <div
                                            style={{
                                              height: '40px',
                                              ...(el?.style || {}), // Safely spread el.style if it exists, or use an empty object
                                            }}
                                          ></div>

                                        </div>
                                    );

                                    case 'footer':
                                    return (
                                      <div
                                          onMouseEnter={() => setElementHovered(true)}
                                          onMouseLeave={() => setElementHovered(false)}
                                          key={el.id}
                                          style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                        >
                                          {elementhovered && ( // Conditionally render the buttons div
                                            <div
                                              style={{
                                                position: 'absolute', // Make it overlap without affecting layout
                                                display: 'flex', // Adjust as per your layout
                                                justifyContent: 'space-between',
                                                top: 0,
                                                right: 0,
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                                padding: '5px', // Adjust as needed
                                                textAlign: 'right',
                                                zIndex: 1,
                                              }}
                                            >
                                              <i
                                                onClick={() => {
                                                  handleEditElement(parentIndex, columnIndex, el.id);
                                                }}
                                                className="EditLayoutAreaButton"
                                              >
                                                <i className="bi bi-pencil"></i>
                                              </i>
                                              <i
                                                onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                                className="EditLayoutAreaButton bg-danger text-white border-danger"
                                              >
                                                <i className="bi bi-trash"></i>
                                              </i>
                                            </div>
                                          )}
                                          <p 
                                          style={{
                                            fontSize: '14px',
                                            marginTop:'10px',
                                            marginBottom:'10px',
                                            textAlign: 'center',
                                            display: 'block',
                                            ...el.style, // Spread el.style to include additional or overriding styles
                                          }}
                                          >{el.content}</p>
                                        </div>
                                    );

                                    case 'image':
                                    return (
                                      
                                      <div
                                      onMouseEnter={() => setElementHovered(true)}
                                      onMouseLeave={() => setElementHovered(false)}
                                      key={el.id}
                                      style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                    >
                                      {elementhovered && ( // Conditionally render the buttons div
                                        <div
                                          style={{
                                            position: 'absolute', // Make it overlap without affecting layout
                                            display: 'flex', // Adjust as per your layout
                                            justifyContent: 'space-between',
                                            top: 0,
                                            right: 0,
                                            backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                            padding: '5px', // Adjust as needed
                                            textAlign: 'right',
                                            zIndex: 1,
                                          }}
                                        >
                                          <i
                                            onClick={() => {
                                              handleEditElement(parentIndex, columnIndex, el.id);
                                            }}
                                            className="EditLayoutAreaButton"
                                          >
                                            <i className="bi bi-pencil"></i>
                                          </i>
                                          <i
                                            onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                            className="EditLayoutAreaButton bg-danger text-white border-danger"
                                          >
                                            <i className="bi bi-trash"></i>
                                          </i>
                                        </div>
                                      )}
                                      {el.href ? (
                                          <a href={el.href} target="_blank" rel="noopener noreferrer">
                                            <img src={el.src} alt="Image" 
                                              style={{
                                                width: '100%',
                                                display:'block',
                                                margin: '10px auto',
                                                ...el.style, // Spread el.style to include additional or overriding styles
                                              }}
                                            />
                                          </a>
                                        ) : (
                                          <img src={el.src} alt="Image" 
                                                style={{
                                                  width: '100%',
                                                  display:'block',
                                                  margin: '10px auto',
                                                  ...el.style, // Spread el.style to include additional or overriding styles
                                                }}
                                          />
                                        )}
                                    </div>
                                    );
                                    case 'logo':
                                    return (
                                      
                                      <div
                                      onMouseEnter={() => setElementHovered(true)}
                                      onMouseLeave={() => setElementHovered(false)}
                                      key={el.id}
                                      style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                    >
                                      {elementhovered && ( // Conditionally render the buttons div
                                        <div
                                          style={{
                                            position: 'absolute', // Make it overlap without affecting layout
                                            display: 'flex', // Adjust as per your layout
                                            justifyContent: 'space-between',
                                            top: 0,
                                            right: 0,
                                            backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                            padding: '5px', // Adjust as needed
                                            textAlign: 'right',
                                            zIndex: 1,
                                          }}
                                        >
                                          <i
                                            onClick={() => {
                                              handleEditElement(parentIndex, columnIndex, el.id);
                                            }}
                                            className="EditLayoutAreaButton"
                                          >
                                            <i className="bi bi-pencil"></i>
                                          </i>
                                          <i
                                            onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                            className="EditLayoutAreaButton bg-danger text-white border-danger"
                                          >
                                            <i className="bi bi-trash"></i>
                                          </i>
                                        </div>
                                      )}
                                      {el.href ? (
                                          <a href={el.href} target="_blank" rel="noopener noreferrer">
                                            <img src={el.src} alt="Logo" style={{
                                                width: '25%',
                                                margin: '10px auto',
                                                display: 'block',
                                                ...el.style, // Spread el.style to include additional or overriding styles
                                              }} />
                                          </a>
                                        ) : (
                                          <img src={el.src} alt="Logo" style={{
                                            width: '25%',
                                            display: 'block',
                                            margin: '10px auto',
                                            ...el.style, // Spread el.style to include additional or overriding styles
                                          }} />
                                        )}
                                    </div>
                                    );
                                    case 'link':
                                      return (
                                        <div
                                      onMouseEnter={() => setElementHovered(true)}
                                      onMouseLeave={() => setElementHovered(false)}
                                      key={el.id}
                                      style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                    >
                                      {elementhovered && ( // Conditionally render the buttons div
                                        <div
                                          style={{
                                            position: 'absolute', // Make it overlap without affecting layout
                                            display: 'flex', // Adjust as per your layout
                                            justifyContent: 'space-between',
                                            top: 0,
                                            right: 0,
                                            textAlign: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                            padding: '5px', // Adjust as needed
                                            zIndex: 1,
                                          }}
                                        >
                                          <i
                                            onClick={() => {
                                              handleEditElement(parentIndex, columnIndex, el.id);
                                            }}
                                            className="EditLayoutAreaButton"
                                          >
                                            <i className="bi bi-pencil"></i>
                                          </i>
                                          <i
                                            onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                            className="EditLayoutAreaButton bg-danger text-white border-danger"
                                          >
                                            <i className="bi bi-trash"></i>
                                          </i>
                                        </div>
                                      )}
                                          <a href={el.href} 
                                            style={{
                                              backgroundColor: '#0075e4',
                                              color: '#fff',
                                              fontSize: '14px',
                                              margin: '10px 0px',
                                              padding: '8px 16px',
                                              display: 'block',
                                              width: '100px',
                                              textAlign: 'center',
                                              borderRadius: '5px',
                                              textDecoration: 'none',
                                              ...el.style, // Spread el.style to include additional or overriding styles
                                            }}
                                          >
                                            {el.content}
                                          </a>
                                    </div>
                                      );
                                      case 'social-media-links':
                                        return (
                                          <div
                                          onMouseEnter={() => setElementHovered(true)}
                                          onMouseLeave={() => setElementHovered(false)}
                                          key={el.id}
                                          style={{ position: 'relative' }} // Ensure the parent is positioned for absolute children
                                        >
                                            {elementhovered && ( // Conditionally render the buttons div
                                              <div
                                                style={{
                                                  position: 'absolute', // Make it overlap without affecting layout
                                                  display: 'flex', // Adjust as per your layout
                                                  justifyContent: 'space-between',
                                                  top: 0,
                                                  right: 0,
                                                  backgroundColor: 'rgba(255, 255, 255, 0.9)', // Optional background
                                                  padding: '5px', // Adjust as needed
                                                  textAlign: 'right',
                                                  zIndex: 1,
                                                }}
                                              >
                                                <i
                                                  onClick={() => {
                                                    handleEditElement(parentIndex, columnIndex, el.id);
                                                  }}
                                                  className="EditLayoutAreaButton"
                                                >
                                                  <i className="bi bi-pencil"></i>
                                                </i>
                                                <i
                                                  onClick={() => removeElement(parentIndex, columnIndex, el.id)}
                                                  className="EditLayoutAreaButton bg-danger text-white border-danger"
                                                >
                                                  <i className="bi bi-trash"></i>
                                                </i>
                                              </div>
                                            )}
                                            <social style={{padding: '20px', display: 'block', textAlign: 'center'}}>
                                              {Object.entries(socialLinks).map(([platform, link]) => {
                                                if (link) {
                                                  return (
                                                    <a key={platform} href={link} target="_blank" style={{textDecoration:'none'}} rel="noopener noreferrer">
                                                      <img src={`https://app.aadow.com/social-media-icons/${platform}.png`} style={{width: '40px', height: '40px', marginRight: '10px'}} alt={platform} />
                                                    </a>
                                                  );
                                                }
                                                return null;
                                              })}
                                            </social>
                                            
                                          </div>
                                        );                              
                                  default:
                                    return null;
                                }
                              })}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>  
              {selectedLayoutIndex !== null && (
                <div className="styling-area">
                <div className="style-editor">
                  <div className='row align-items-center border-1 border-bottom'>
                    <div className='col-6'>
                      <h3 className='style-editor-heading border-0'>Edit Layout</h3>
                    </div>
                    <div className='col-6 text-end  pe-4'>
                          <i onClick={() => moveLayout(selectedLayoutIndex, 'up')} className='EditLayoutAreaButton' >
                              <i className='bi bi-arrow-up'></i>
                            </i>
                            <i onClick={() => moveLayout(selectedLayoutIndex, 'down')} className='EditLayoutAreaButton' >
                            <i className='bi bi-arrow-down'></i>
                            </i>
                            <i onClick={() => removeLayout(selectedLayoutIndex)}  className='EditLayoutAreaButton bg-danger text-white border-danger'>
                              <i className='bi bi-trash'></i>
                            </i>
                    </div>
                  </div> 
                            

                    <div className='ediging-input-area'>
                        <div className="row">
                          <div className='col-12'>
                            <label style={{display: 'block', marginBottom: '10px'}}>Background Color:</label>
                          </div>
                          <div className='col-12'>
                            <input
                              type="color"
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.backgroundColor}
                              onChange={(e) => handleStyleChange('backgroundColor', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-12'>
                            <label style={{display: 'block', marginBottom: '10px'}}>Border:</label>
                          </div>
                          <div className='col-4'>
                            <input
                              type="color"
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.borderColor}
                              onChange={(e) => handleStyleChange('borderColor', e.target.value)}
                            />
                          </div>
                          <div className='col-4'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.borderWidth}
                              onChange={(e) => handleStyleChange('borderWidth', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="2px">2</option>
                              <option value="10px">3</option>
                              <option value="15px">4</option>
                              <option value="25px">5</option>
                            </select>
                          </div>
                          <div className='col-4'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.borderStyle}
                              onChange={(e) => handleStyleChange('borderStyle', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="solid">Solid</option>
                              <option value="dotted">Dotted</option>
                              <option value="dashed">Dashed</option>
                              <option value="double">Double</option>
                              <option value="none">None</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-12'>
                            <label style={{display: 'block', marginBottom: '10px'}}>Padding:</label>
                          </div>
                          <div className='col-3'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.paddingLeft}
                              onChange={(e) => handleStyleChange('paddingLeft', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="5px">2</option>
                              <option value="10px">3</option>
                              <option value="25px">4</option>
                              <option value="50px">5</option>
                            </select>
                          </div>
                          <div className='col-3'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.paddingRight}
                              onChange={(e) => handleStyleChange('paddingRight', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="5px">2</option>
                              <option value="10px">3</option>
                              <option value="25px">4</option>
                              <option value="50px">5</option>
                            </select>
                          </div>
                          <div className='col-3'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.paddingTop}
                              onChange={(e) => handleStyleChange('paddingTop', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="5px">2</option>
                              <option value="10px">3</option>
                              <option value="25px">4</option>
                              <option value="50px">5</option>
                            </select>
                          </div>
                          <div className='col-3'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.paddingBottom}
                              onChange={(e) => handleStyleChange('paddingBottom', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="5px">2</option>
                              <option value="10px">3</option>
                              <option value="25px">4</option>
                              <option value="50px">5</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-12'>
                            <label style={{display: 'block', marginBottom: '10px'}}>margin:</label>
                          </div>
                          <div className='col-3'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.marginLeft}
                              onChange={(e) => handleStyleChange('marginLeft', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="5px">2</option>
                              <option value="10px">3</option>
                              <option value="25px">4</option>
                              <option value="50px">5</option>
                            </select>
                          </div>
                          <div className='col-3'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.marginRight}
                              onChange={(e) => handleStyleChange('marginRight', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="5px">2</option>
                              <option value="10px">3</option>
                              <option value="25px">4</option>
                              <option value="50px">5</option>
                            </select>
                          </div>
                          <div className='col-3'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.marginTop}
                              onChange={(e) => handleStyleChange('marginTop', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="5px">2</option>
                              <option value="10px">3</option>
                              <option value="25px">4</option>
                              <option value="50px">5</option>
                            </select>
                          </div>
                          <div className='col-3'>
                            <select
                              style={{width: '100%', height: '1.8vw'}}
                              value={styleProperties.marginBottom}
                              onChange={(e) => handleStyleChange('marginBottom', e.target.value)}
                              >
                              <option value=""></option>
                              <option value="1px">1</option>
                              <option value="5px">2</option>
                              <option value="10px">3</option>
                              <option value="25px">4</option>
                              <option value="50px">5</option>
                            </select>
                          </div>
                        </div>
                      
                      <button style={{fontSize: '14px'}} onClick={applyStylesClosed}>Apply Changes</button>
                    </div>
                  </div>
                </div>
                )}

              {selectedElement && (
                        
                        <div style={{overflowX: 'hidden'}} className="styling-area">
                          <div className='row align-items-center border-1 border-bottom'>
                            <div className='col-6'>
                              <h3 className='style-editor-heading border-0'>Edit Element</h3>
                            </div>
                            
                          </div> 
                           <div className='ediging-input-area'>
                        <div className="row">
                          {(selectedElement.element.type === 'link' || selectedElement.element.type === 'heading' || selectedElement.element.type === 'footer'  || selectedElement.element.type === 'text') && (
                            <>
                            <div className='col-12'>
                              <label style={{display: 'block', marginBottom: '10px'}}>Content</label>
                            </div>
                            <div className='col-12'>
                              <input
                                type="text"
                                style={{width: '100%', height: '1.8vw'}}
                                value={selectedElement.element.content}
                                onChange={handleElementContentChange}
                              />
                            </div>
                            </>
                          )}
                            {selectedElement.element.type === 'link' && (
                              <>
                              <div className='col-12'>
                                <label style={{display: 'block', marginBottom: '10px'}}>Link</label>
                              </div>
                              <div className='col-12'>
                                <input
                                  type="text"
                                    value={selectedElement.element.href || ''}
                                    onChange={(event) => handleElementLinkChange(event.target.value)}
                                />
                              </div>
                              </>
                            )}
                            {(selectedElement.element.type === 'image' || selectedElement.element.type === 'logo') && (
                               <>
                               <div className='col-12 d-none'>
                                 <label style={{display: 'block', marginBottom: '10px'}}>Image Src</label>
                               </div>
                               <div className='col-12 d-none'>
                                 <input
                                   type="text"
                                  value={selectedElement.element.src || ''}
                                  onChange={(event) => handleElementSrcChange(event.target.value)}
                                 />
                               </div>
                               </>
                            )}
                            {selectedElement.element.type === 'social-media-links' && (
                              
                                <>
                                {['facebook', 'x', 'instagram', 'linkedin', 'youtube', 'pinterest'].map((platform) => (
                                  
                                  <div key={platform} className='col-12'>
                                    <div className='col-12'>
                                      <label className='text-capitalize' style={{display: 'block', marginBottom: '10px'}}>{platform}</label>
                                    </div>
                                    <input
                                      type="text"
                                      value={socialLinks[platform]}
                                      onChange={(e) => handleSocialLinkChange(platform, e.target.value)}
                                      placeholder={`Enter ${platform} URL`}
                                    />
                                  </div>
                                ))}
                                
                                </>
                            )}
                            {selectedElement && (selectedElement.element.type === 'image' || selectedElement.element.type === 'logo') && (
                              <>
                              <div className='col-12'>
                                {(selectedElement.element.type === 'image' || selectedElement.element.type === 'logo') && (
                                  <>
                                    <label style={{ display: 'block', marginBottom: '10px' }}>Upload Image</label>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={async (event) => {
                                        const file = event.target.files[0];
                                        if (file) {
                                          setFullPageloading(true);
                                          const formData = new FormData();
                                          formData.append('file', file);

                                          try {
                                            const response = await axios.post(
                                              `${ServerApiDomain}/api/files/upload`,
                                              formData,
                                              {
                                                headers: {
                                                  'Content-Type': 'multipart/form-data',
                                                },
                                              }
                                            );
                                            const uploadedFileName = response.data.fileName;
                                            const imageUrl = `https://www.aadow.com/uploads/${uploadedFileName}`;
                                            
                                            // Update the element's src
                                            handleElementSrcChange(imageUrl);
                                            
                                          setFullPageloading(false);
                                            showSnackbar('Image uploaded successfully!', 'success');
                                          } catch (error) {
                                            setFullPageloading(false);
                                            alert('Error uploading image:'+ error);
                                            showSnackbar('Failed to upload image. Please try again.', 'error');
                                          }
                                        }
                                      }}
                                    />
                                  </>
                                )}
                              </div>

                              <div className='col-12'>
                                 <label style={{display: 'block', marginBottom: '10px'}}>Image Link</label>
                               </div>
                               <div className='col-12'>
                                 <input
                                   type="text"
                                    value={selectedElement.element.href || ''}
                                    onChange={(event) => handleElementLinkChange(event.target.value)}
                                 />
                               </div>



                              </>
                            )}
                           {(() => {
  const editableProperties = {
    button: ['padding', 'backgroundColor', 'borderStyle', 'borderRadius'],
    image: ['width', 'borderWidth', 'borderRadius', 'borderColor', 'borderStyle', 'padding', 'margin'],
    logo: ['width', 'borderWidth', 'borderRadius', 'borderColor', 'borderStyle', 'padding', 'margin'],
    text: ['color', 'fontSize', 'fontWeight', 'lineHeight', 'textAlign', 'fontStyle'],
    footer: ['color', 'fontSize', 'fontWeight', 'lineHeight', 'textAlign', 'fontStyle'],
    heading: ['color', 'fontSize', 'fontWeight', 'lineHeight', 'textAlign', 'fontStyle'],
    link: ['color', 'width', 'fontSize', 'borderRadius', 'backgroundColor', 'textDecoration', 'padding', 'textAlign', 'margin'],
    spacer: ['height'],
    divider: ['borderWidth', 'borderColor'],
  };

  const selectOptions = {
    borderStyle: ['none', 'solid', 'dashed', 'dotted'],
    fontSize: ['0', '10px', '14px', '18px', '22px', '28px', '34px', '40px', '48px', '54px', '60px', '72px'],
    lineHeight: ['0', '5px', '10px', '15px', '20px', '25px', '30px', '35px', '40px', '45px', '50px'],
    height: ['0', '10px', '20px', '30px', '40px', '50px', '60px', '70px', '80px', '90px', '100px'],
    borderWidth: ['0', '5px', '10px', '15px', '20px', '25px', '30px', '35px', '40px', '45px', '50px'],
    borderRadius: ['0', '5px', '10px', '15px', '20px', '25px', '30px', '35px', '40px', '45px', '50px'],
    padding: ['0', '4px 8px', '8px 16px', '10px 20px', '12px 24px'],
    width: ['auto', '25%', '50%', '75%', '100%'],
    fontWeight: ['normal', 'bold', 'lighter'],
    fontStyle: ['normal', 'italic', 'lighter'],
    textAlign: ['left', 'center', 'right', 'justify'],
    textDecoration: ['none', 'underline', 'overline', 'line-through'],
    margin: [
      { label: '', value: '' },
      { label: 'Left', value: '0' },
      { label: 'Center', value: '0 auto' },
      { label: 'Right', value: '0 0 0 auto' },
    ],
  };

  const currentType = selectedElement.element.type;
  const properties = editableProperties[currentType] || [];

  return properties.map((property) => {
    const isSelect = selectOptions.hasOwnProperty(property);

    return (
      <div className="col-6" key={property}>
        <div className="col-12">
          <label style={{ display: 'block', marginBottom: '10px' }}>
            {property === 'margin' ? 'Alignment' : property.charAt(0).toUpperCase() + property.slice(1)}
          </label>
        </div>
        <div className="col-12">
          {property.toLowerCase().includes('color') ? (
            // Render color input for properties like backgroundColor or textColor
            <input
              type="color"
              value={selectedElement.element.style?.[property] || ''}
              onChange={(e) => handleElementStyleChange(property, e.target.value)}
            />
          ) : isSelect ? (
            // Render select input for properties with predefined options
            property === 'margin' ? (
              <select
              value={
                selectOptions.margin.find(
                  (option) => option.value === selectedElement.element.style?.margin
                )?.label || ''
              }
              onChange={(e) => {
                const selectedValue = selectOptions.margin.find(
                  (option) => option.label === e.target.value
                )?.value;
                handleElementStyleChange('margin', selectedValue);
              }}
            >
              {selectOptions.margin.map((option) => (
                <option key={option.label} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>
            ) : (
              <select
                value={selectedElement.element.style?.[property] || ''}
                onChange={(e) => handleElementStyleChange(property, e.target.value)}
              >
                {selectOptions[property].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            )
          ) : (
            // Render text input for other properties
            <input
              type="text"
              value={selectedElement.element.style?.[property] || ''}
              onChange={(e) => handleElementStyleChange(property, e.target.value)}
            />
          )}
        </div>
      </div>
    );
  });
})()}

                            
                            <div className='col-12'>
                            <button style={{fontSize: '14px'}} onClick={applyElementStylesClosed}>Apply Changes</button>
                            </div>
                          </div>
                          </div>
                        </div>


                      )}
              
              </div>
            </div>
          </div>
          
          
        </div>
        <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
          </Snackbar>
      

      
    

    <div>
      <textarea
        readOnly
        value={generateHTML()}
        style={{
          width: '100%',
          height: '200px',
          marginTop: '10px',
          display: 'none',
          border: '1px solid black',
          padding: '10px',
        }}
      />
    </div>
      </div>
      
)}


      
    {show && (
          <>
            <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
            <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
              <div className="modal-header w-100">
                <div className="row w-100">
                  <div className="col-11">
                  <h5 className="modal-title">Send Test Email</h5>
                  </div>
                  <div className="col-1">
                    <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
                    <span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
                    </a>
                  </div>	  
                </div>  
              </div>
              <div className="modal-body pb-4 pt-0 px-4">
              {InputApiArea === 'true' && (
              <div className="row">
                
                
                <div className="col-12">
                  <label  className='label-modal-form'>Sender Email<b className="text-danger">*</b></label>
                  <input type="text" ref={EmailRef} className={`input-forms-modal w-100 ${EmailError ? 'input-error' : ''}`} value={Email} onChange={(e) => {
                      setEmail(e.target.value);
                    }} />
                  {ErrorEmail && <div className="error-message">{ErrorEmail}</div>}
                </div>
                <div className="col-12">
                  <label className='label-modal-form'>Subject</label>
                  <input type="text" className={`input-forms-modal w-100`} value={MailSubject} onChange={(e) => {
                      setMailSubject(e.target.value);
                    }} />
                </div>
                <div className="col-12">
                  <label  className='label-modal-form'>Preview Text</label>
                  <input type="text" className={`input-forms-modal w-100`} value={MailPreview} onChange={(e) => {
                      setMailPreview(e.target.value);
                    }} />
                </div>
    
              </div>	
              )}
              {InputApiArea === 'false' && (	
                <div className="col-12">
                  <div id="step_three" className="right-content">
                    <img src="../../iconss/other/approved.png" className="success-icon" alt="Success" />
                    <h2 className="secondary-heading-modal text-center">Test Mail Sent!</h2>
                    <h2 className="secondary-description-modal text-center">Test mail sent to <b>{EmailSentTo}</b></h2>
                    <center><button type="button" className="primary-button float-center" onClick={handleClose} >Okay</button></center>
                  </div>
                </div>
              )}
                
              </div>
              
              {InputApiArea === 'true' && (
              <div className="modal-footer ">
                <button type="button" style={{fontSize: '0.8vw'}} className="secondary-button" onClick={handleClose} data-bs-dismiss="modal">Cancel</button>
                <button type="button" style={{fontSize: '0.8vw'}}  className="primary-button" disabled={loading} onClick={handleAddSender}>{loading ? 'Sending...' : 'Send Test Email'}</button>
              </div>
              )}
              
              </div>
            </div>
            </div>
          </>
          )}
          
    
    </>
  );
};

export default CreateEmailTemplateDragDropEditor;
