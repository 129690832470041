import React from 'react';
import { FormControlLabel, Switch, Box } from '@mui/material';
import '../../css/components/RadioSlider.css';

const NotificationRadioSlider = ({ checked, onChange }) => {
  return (
    <Box className="custom-switch">
      <FormControlLabel
        control={<Switch checked={checked} onChange={onChange} />}
        label=""
      />
    </Box>
  );
};

export default NotificationRadioSlider;
