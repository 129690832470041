import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/dashboard.css';
import SideBar from './../components/SideBar';
import { format } from 'date-fns';
import axios from 'axios';

import Cookies from 'js-cookie';

const Dashboard = () => {

  // Close dropdown when clicking outside of it
  const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountId = Cookies.get('current_accountId');
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [campaigns, setCampaigns] = useState([]);
  
  const [UsedCredits, setUsedCredits] = useState("-----");
  const [TotalCredits, setTotalCredits] = useState("-----");
  const [PlanName, setPlanName] = useState("-----");
  
  const [ActiveUserName, setActiveUserName] = useState("Eshan");
  const [TotalDelivered, setTotalDelivered] = useState("-----");
  const [TotalOpened, setTotalOpened] = useState("-----");
  const [TotalClicked, setTotalClicked] = useState("-----");
  const [TotalUnsubscribed, setTotalUnsubscribed] = useState("-----");
  const [responseData, setResponseData] = useState(null);

  const [steps, setSteps] = useState({
    addSender: false,
    importContacts: false,
    designTemplate: false,
    createCampaign: false,
  });

  useEffect(() => {
    // Fetch the status of steps from your API or server
    const fetchStepStatus = async () => {
      try {
        const response = await axios.get(`${ServerApiDomain}/check-username`, {
          params: { username: CookieAccountId },
        });

        // Example of how you might map API response to steps
        setSteps({
            addSender: response.data.senders_domain === '1',
			importContacts: response.data.email_contacts === '1',
			designTemplate: response.data.email_templates === '1',
			createCampaign: response.data.email_campaigns === '1',
        });
		
      } catch (error) {
        console.error('Error fetching step data:', error);
      }
    };

    fetchStepStatus();
  }, []);
  
  

useEffect(() => {
  document.title = 'Dashboard | Aadow';

  const current_username = CookieAccountId;
  const username = 'testuser';
  const password = 'testpassword';
  const encodedCredentials = btoa(`${username}:${password}`);

		const fetchDashboardMetrics = async () => {
			try {
			  const response = await fetch(`${ServerApiDomain}/dashboard/metrics?username=${current_username}`, {
				method: 'GET',
				headers: {
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }

			  const data = await response.json();
			  setTotalDelivered(data.TotalDelivered.toLocaleString());
			  setTotalOpened(data.TotalOpened.toLocaleString());
			  setTotalClicked(data.TotalClicked.toLocaleString());
			  setTotalUnsubscribed(data.TotalUnsubscribed.toLocaleString());
			  setLoading(false);  // Set loading to false once data is fetched
			} catch (error) {
			  setError("Fetch error:", error);
			  setLoading(false);
			}
		};
		
		
		const fetchMyPlanInfo = async () => {
		  try {
			const response_my_plan = await fetch(`${ServerApiDomain}/api/myplan/username/${current_username}`, {
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json',
			  },
			});

			if (!response_my_plan.ok) {
			  throw new Error('Network response was not ok');
			}

			const data_my_plan = await response_my_plan.json();
			
			// Access the first element of the array
			if (data_my_plan.length > 0) {
			  setTotalCredits(data_my_plan[0].totalEmails.toLocaleString()); // Access planName from the first object
			  setUsedCredits(data_my_plan[0].usedEmails.toLocaleString()); // Access planName from the first object
			  setPlanName(data_my_plan[0].planName); // Access planName from the first object
			}
			
			setLoading(false); // Set loading to false once data is fetched
		  } catch (error) {
			setError("Fetch error:", error);
			setLoading(false);
		  }
		};
		
		
		const fetchCampaigns = async () => {
			try {
			  const response = await fetch(`${ServerApiDomain}/api/campaigns/username/${current_username}?page=0`, {
				method: 'GET',
				headers: {
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }

			  const data = await response.json();
			  setCampaigns(data.content); // Set the campaigns array
			} catch (error) {
			  setError("Fetch error: " + error.message);
			} finally {
			  setLoading(false); // Set loading to false once data is fetched
			}
	    };
		

	  fetchCampaigns();
	  fetchDashboardMetrics();
	  fetchMyPlanInfo();
	  
	}, []);

	if (loading) {
	  return <div>Loading...</div>; // Display loading message
	}

	if (error) {
	}



  return (
    <SideBar>
      <div className="dashboard-content ">
        <div className="dashboard-body ">
		{steps.addSender && steps.importContacts && steps.designTemplate && steps.createCampaign ? (

	<div>
<h1 className="greeting">Namastey {CookieAccountName} 🙏</h1>
<div className="top-area">
	<div className="row align-items-center">
		<div className="col-6">
			<h5 className="total-balance-label">Total Credits</h5>
			<h2 className="total-balance">{UsedCredits}/{TotalCredits} <span className=""> {PlanName}</span></h2>
		</div>
		<div className="col-6 text-end">
			<Link to="/settings#my-plan" className="secondary-button text-decoration-none me-2">Buy Credits</Link>
			<Link to="/settings#my-plan" className="primary-button text-decoration-none ms-2">Upgrade</Link>
		</div>
	</div>
</div>
<div className="metric-col-area">
	<div className="row">
	
		<div className="col-3">
			<div className="metric-col">
				<div className="metric-col-top-area">
					<div className="row">
						<div className="col-6">
							<h6 className="metric-col-top-label"><i className="bi bi-people"></i> Delivered</h6>
						</div>
						<div className="col-6">
							<h6 className="metric-col-duration-label">Last 30 Days</h6>
						</div>
					</div>
				</div>
				
				<h2 className="total-metric">{TotalDelivered} <span className="text-success">0.00% <i className="bi bi-arrow-up-right"></i></span></h2>
				
				<span className="total-metric-bottom-label">vs. ------- Last Period</span>
				
			</div>
		</div>
		
		
		
		<div className="col-3">
			<div className="metric-col">
				<div className="metric-col-top-area">
					<div className="row">
						<div className="col-6">
							<h6 className="metric-col-top-label"><i className="bi bi-eye"></i> Opened</h6>
						</div>
						<div className="col-6">
							<h6 className="metric-col-duration-label">Last 30 Days</h6>
						</div>
					</div>
				</div>
				
				<h2 className="total-metric">{TotalOpened} <span className="text-success">0.00% <i className="bi bi-arrow-up-right"></i></span></h2>
				
				<span className="total-metric-bottom-label">vs. ------- Last Period</span>
				
			</div>
		</div>
		
		
		<div className="col-3">
			<div className="metric-col">
				<div className="metric-col-top-area">
					<div className="row">
						<div className="col-6">
							<h6 className="metric-col-top-label"><i className="bi bi-hand-index"></i> Clicked</h6>
						</div>
						<div className="col-6">
							<h6 className="metric-col-duration-label">Last 30 Days</h6>
						</div>
					</div>
				</div>
				
				<h2 className="total-metric">{TotalClicked} <span className="text-success">0.00% <i className="bi bi-arrow-up-right"></i></span></h2>
				
				<span className="total-metric-bottom-label">vs. ------- Last Period</span>
				
			</div>
		</div>
		
		<div className="col-3">
			<div className="metric-col">
				<div className="metric-col-top-area">
					<div className="row">
						<div className="col-7">
							<h6 className="metric-col-top-label"><i className="bi bi-ban"></i> Unsubscribed</h6>
						</div>
						<div className="col-5">
							<h6 className="metric-col-duration-label">Last 30 Days</h6>
						</div>
					</div>
				</div>
				
				<h2 className="total-metric">{TotalUnsubscribed} <span className="text-danger">0.00% <i className="bi bi-arrow-down-right"></i></span></h2>
				
				<span className="total-metric-bottom-label">vs. ------- Last Period</span>
				
			</div>
		</div>
		
		
		
		
	</div>
	</div>
	<div className="other-info-area ">
		<div className="col-12">
			<div className="table-area">
				<h2 className="table-area-heading">Recent Campaigns</h2>
				{campaigns.length === 0 ? (
					<center>
						<div className="empty-error-area">
							<img className="empty-error-image" src="iconss/other/no-activity.svg"  />
							<h2 className="empty-error-heading">No Activity Found</h2>
							<h6 className="empty-error-subheading" >Your dashboard is currently empty as there are no sent, draft, or scheduled campaigns available. Start building your campaigns to see them here.</h6>
						</div>
					</center>
				) : (
				<table>
						<thead>
							<tr>
								<th Style="Width:5%;">ID</th>
								<th Style="Width:30%;">Name</th>
								<th Style="Width:5%;">Recipients</th>
								<th Style="Width:5%;">Status</th>
								<th Style="Width:10%;">Date</th>
							</tr>
						</thead>
						<tbody>
							{campaigns.map(campaign => (
							
							<tr>
								<td className="td-first"><Link to={`/campaigns/email/${campaign.campaignId}`}>#{campaign.campaignId}</Link></td>
								<td className="td-first"><Link to={`/campaigns/email/${campaign.campaignId}`}>{campaign.campaignName}</Link></td>
								<td className="td-first">{campaign.contactLists}</td>
								<td className="td-first"><span className={`campaign-status ${campaign.status}-status text-uppercase`}>{campaign.status}</span></td>
								<td className="td-first">{format(new Date(campaign.scheduledDateTime), 'MMM dd, hh:mm a')}</td>
							</tr>
							))}
						</tbody>
					</table>
				)}	
			</div>
		</div>
	</div>

	</div>
		
			
				
		):(
			
		


		<div className='dashboard-steps-area'>

		<h2 className='dashboard-steps-area-heading'><b>🙏 Namastey {CookieAccountName} </b>, Let's Get you onboard with Aadow</h2>
		<h6 className='dashboard-steps-area-subheading'>Set up your sender info, import your contacts, design eye-catching templates, and launch your first campaign effortlessly</h6>

		<div className={`dashboard-steps-col shadow-sm ${steps.addSender ? 'completed' : ''}`}>
			<div className='row align-items-center'>
			<div className='col-1 text-center'>
				<i className={`bi bi-${steps.addSender ? 'check' : 'exclamation'}-circle-fill dashboard-steps-col-icon`}></i>
			</div>
			<div className='col-7'>
				<h6 className='dashboard-steps-col-heading'>Add Sender Information</h6>
				<h6 className='dashboard-steps-col-subheading'>
				Start by adding your sender information and verifying your domain to ensure seamless email delivery.
				</h6>
			</div>
			<div className='col-4 text-end'>
				{steps.addSender ? (
				<div></div>
				) : (
				<Link to='/sender-authentication' className='primary-button-dashboard-steps text-decoration-none'>
					Add Sender
				</Link>
				)}
			</div>
			</div>
		</div>

		<div className={`dashboard-steps-col shadow-sm ${steps.importContacts ? 'completed' : ''}`}>
			<div className='row align-items-center'>
			<div className='col-1 text-center'>
				<i className={`bi bi-${steps.importContacts ? 'check' : 'exclamation'}-circle-fill dashboard-steps-col-icon`}></i>
			</div>
			<div className='col-7'>
				<h6 className='dashboard-steps-col-heading'>Import Contacts</h6>
				<h6 className='dashboard-steps-col-subheading'>
				Effortlessly upload your contact list by importing contacts in bulk.
				</h6>
			</div>
			<div className='col-4 text-end'>
				{steps.importContacts ? (
				<div></div>
				) : (
				<Link to='/audience-overview' className='primary-button-dashboard-steps text-decoration-none'>
					Import Contacts
				</Link>
				)}
			</div>
			</div>
		</div>

		<div className={`dashboard-steps-col shadow-sm ${steps.designTemplate ? 'completed' : ''}`}>
			<div className='row align-items-center'>
			<div className='col-1 text-center'>
				<i className={`bi bi-${steps.designTemplate ? 'check' : 'exclamation'}-circle-fill dashboard-steps-col-icon`}></i>
			</div>
			<div className='col-7'>
				<h6 className='dashboard-steps-col-heading'>Design Email Template</h6>
				<h6 className='dashboard-steps-col-subheading'>
				Design engaging layouts to captivate your audience and set the tone for your campaigns.
				</h6>
			</div>
			<div className='col-4 text-end'>
				{steps.designTemplate ? (
				<div></div>
				) : (
				<Link to='/email-templates' className='primary-button-dashboard-steps text-decoration-none'>
					Create Template
				</Link>
				)}
			</div>
			</div>
		</div>

		<div className={`dashboard-steps-col shadow-sm ${steps.createCampaign ? 'completed' : ''}`}>
			<div className='row align-items-center'>
			<div className='col-1 text-center'>
				<i className={`bi bi-${steps.createCampaign ? 'check' : 'exclamation'}-circle-fill dashboard-steps-col-icon`}></i>
			</div>
			<div className='col-7'>
				<h6 className='dashboard-steps-col-heading'>Create your first campaign</h6>
				<h6 className='dashboard-steps-col-subheading'>
				Craft personalized messages, select your audience, and start reaching your goals!
				</h6>
			</div>
			<div className='col-4 text-end'>
				{steps.createCampaign ? (
				<div></div>
				) : (
				<Link to='/campaigns/email' className='primary-button-dashboard-steps text-decoration-none'>
					Create Campaign
				</Link>
				)}
			</div>
			</div>
		</div>

		</div>
		)}	
        </div>
      </div>
    </SideBar>
  );
};

export default Dashboard;
