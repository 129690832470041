// Logout.js
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear all cookies
        Cookies.remove('current_Id'); 
        Cookies.remove('current_accountId'); 
        Cookies.remove('current_utm'); 
        Cookies.remove('current_accountName'); 
        Cookies.remove('current_accountEmail'); 
        Cookies.remove('BusinessInfoExist'); 
        Cookies.remove('CurrentPlanId'); 
		

        // Redirect to the login page
        navigate('/login');
    }, [navigate]);

    return null; // or a loading spinner/message
};

export default Logout;
