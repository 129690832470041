import React from 'react';

import '../../css/public/CustomSelect.css';
import { Select, MenuItem, Box, Typography } from '@mui/material';

const CountrySelect = ({ countryselectedOption, countryhandleChange }) => {
  const countryoptions = [
    { value: 'Afghanistan', label: 'Afghanistan', imgSrc: 'iconss/flags/afghanistan.png' },
  { value: 'Albania', label: 'Albania', imgSrc: 'iconss/flags/albania.png' },
  { value: 'Algeria', label: 'Algeria', imgSrc: 'iconss/flags/algeria.png' },
  { value: 'Andorra', label: 'Andorra', imgSrc: 'iconss/flags/andorra.png' },
  { value: 'Angola', label: 'Angola', imgSrc: 'iconss/flags/angola.png' },
  { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda', imgSrc: 'iconss/flags/antigua-and-barbuda.png' },
  { value: 'Argentina', label: 'Argentina', imgSrc: 'iconss/flags/argentina.png' },
  { value: 'Armenia', label: 'Armenia', imgSrc: 'iconss/flags/armenia.png' },
  { value: 'Australia', label: 'Australia', imgSrc: 'iconss/flags/australia.png' },
  { value: 'Austria', label: 'Austria', imgSrc: 'iconss/flags/austria.png' },
  { value: 'Azerbaijan', label: 'Azerbaijan', imgSrc: 'iconss/flags/azerbaijan.png' },
  { value: 'Bahamas', label: 'Bahamas', imgSrc: 'iconss/flags/bahamas.png' },
  { value: 'Bahrain', label: 'Bahrain', imgSrc: 'iconss/flags/bahrain.png' },
  { value: 'Bangladesh', label: 'Bangladesh', imgSrc: 'iconss/flags/bangladesh.png' },
  { value: 'Barbados', label: 'Barbados', imgSrc: 'iconss/flags/barbados.png' },
  { value: 'Belarus', label: 'Belarus', imgSrc: 'iconss/flags/belarus.png' },
  { value: 'Belgium', label: 'Belgium', imgSrc: 'iconss/flags/belgium.png' },
  { value: 'Belize', label: 'Belize', imgSrc: 'iconss/flags/belize.png' },
  { value: 'Benin', label: 'Benin', imgSrc: 'iconss/flags/benin.png' },
  { value: 'Bhutan', label: 'Bhutan', imgSrc: 'iconss/flags/bhutan.png' },
  { value: 'Bolivia', label: 'Bolivia', imgSrc: 'iconss/flags/bolivia.png' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina', imgSrc: 'iconss/flags/bosnia-and-herzegovina.png' },
  { value: 'Botswana', label: 'Botswana', imgSrc: 'iconss/flags/botswana.png' },
  { value: 'Brazil', label: 'Brazil', imgSrc: 'iconss/flags/brazil.png' },
  { value: 'Brunei', label: 'Brunei', imgSrc: 'iconss/flags/brunei.png' },
  { value: 'Bulgaria', label: 'Bulgaria', imgSrc: 'iconss/flags/bulgaria.png' },
  { value: 'Burkina Faso', label: 'Burkina Faso', imgSrc: 'iconss/flags/burkina-faso.png' },
  { value: 'Burundi', label: 'Burundi', imgSrc: 'iconss/flags/burundi.png' },
  { value: 'Cambodia', label: 'Cambodia', imgSrc: 'iconss/flags/cambodia.png' },
  { value: 'Cameroon', label: 'Cameroon', imgSrc: 'iconss/flags/cameroon.png' },
  { value: 'Canada', label: 'Canada', imgSrc: 'iconss/flags/canada.png' },
  { value: 'Chad', label: 'Chad', imgSrc: 'iconss/flags/chad.png' },
  { value: 'Chile', label: 'Chile', imgSrc: 'iconss/flags/chile.png' },
  { value: 'China', label: 'China', imgSrc: 'iconss/flags/china.png' },
  { value: 'Colombia', label: 'Colombia', imgSrc: 'iconss/flags/colombia.png' },
  { value: 'Costa Rica', label: 'Costa Rica', imgSrc: 'iconss/flags/costa-rica.png' },
  { value: 'Croatia', label: 'Croatia', imgSrc: 'iconss/flags/croatia.png' },
  { value: 'Cuba', label: 'Cuba', imgSrc: 'iconss/flags/cuba.png' },
  { value: 'Cyprus', label: 'Cyprus', imgSrc: 'iconss/flags/cyprus.png' },
  { value: 'Denmark', label: 'Denmark', imgSrc: 'iconss/flags/denmark.png' },
  { value: 'Djibouti', label: 'Djibouti', imgSrc: 'iconss/flags/djibouti.png' },
  { value: 'Dominican Republic', label: 'Dominican Republic', imgSrc: 'iconss/flags/dominican-republic.png' },
  { value: 'Ecuador', label: 'Ecuador', imgSrc: 'iconss/flags/ecuador.png' },
  { value: 'Egypt', label: 'Egypt', imgSrc: 'iconss/flags/egypt.png' },
  { value: 'El Salvador', label: 'El Salvador', imgSrc: 'iconss/flags/el-salvador.png' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea', imgSrc: 'iconss/flags/equatorial-guinea.png' },
  { value: 'Eritrea', label: 'Eritrea', imgSrc: 'iconss/flags/eritrea.png' },
  { value: 'Estonia', label: 'Estonia', imgSrc: 'iconss/flags/estonia.png' },
  { value: 'Ethiopia', label: 'Ethiopia', imgSrc: 'iconss/flags/ethiopia.png' },
  { value: 'Finland', label: 'Finland', imgSrc: 'iconss/flags/finland.png' },
  { value: 'France', label: 'France', imgSrc: 'iconss/flags/france.png' },
  { value: 'Gabon', label: 'Gabon', imgSrc: 'iconss/flags/gabon.png' },
  { value: 'Georgia', label: 'Georgia', imgSrc: 'iconss/flags/georgia.png' },
  { value: 'Germany', label: 'Germany', imgSrc: 'iconss/flags/germany.png' },
  { value: 'Ghana', label: 'Ghana', imgSrc: 'iconss/flags/ghana.png' },
  { value: 'Greece', label: 'Greece', imgSrc: 'iconss/flags/greece.png' },
  { value: 'Grenada', label: 'Grenada', imgSrc: 'iconss/flags/grenada.png' },
  { value: 'Guatemala', label: 'Guatemala', imgSrc: 'iconss/flags/guatemala.png' },
  { value: 'Guinea', label: 'Guinea', imgSrc: 'iconss/flags/guinea.png' },
  { value: 'Guinea-Bissau', label: 'Guinea-Bissau', imgSrc: 'iconss/flags/guinea-bissau.png' },
  { value: 'Guyana', label: 'Guyana', imgSrc: 'iconss/flags/guyana.png' },
  { value: 'Haiti', label: 'Haiti', imgSrc: 'iconss/flags/haiti.png' },
  { value: 'Honduras', label: 'Honduras', imgSrc: 'iconss/flags/honduras.png' },
  { value: 'Hungary', label: 'Hungary', imgSrc: 'iconss/flags/hungary.png' },
  { value: 'Iceland', label: 'Iceland', imgSrc: 'iconss/flags/iceland.png' },
  { value: 'India', label: 'India', imgSrc: 'iconss/flags/india.png' },
  { value: 'Indonesia', label: 'Indonesia', imgSrc: 'iconss/flags/indonesia.png' },
  { value: 'Iran', label: 'Iran', imgSrc: 'iconss/flags/iran.png' },
  { value: 'Iraq', label: 'Iraq', imgSrc: 'iconss/flags/iraq.png' },
  { value: 'Ireland', label: 'Ireland', imgSrc: 'iconss/flags/ireland.png' },
  { value: 'Israel', label: 'Israel', imgSrc: 'iconss/flags/israel.png' },
  { value: 'Italy', label: 'Italy', imgSrc: 'iconss/flags/italy.png' },
  { value: 'Jamaica', label: 'Jamaica', imgSrc: 'iconss/flags/jamaica.png' },
  { value: 'Japan', label: 'Japan', imgSrc: 'iconss/flags/japan.png' },
  { value: 'Jordan', label: 'Jordan', imgSrc: 'iconss/flags/jordan.png' },
  { value: 'Kazakhstan', label: 'Kazakhstan', imgSrc: 'iconss/flags/kazakhstan.png' },
  { value: 'Kenya', label: 'Kenya', imgSrc: 'iconss/flags/kenya.png' },
  { value: 'Kiribati', label: 'Kiribati', imgSrc: 'iconss/flags/kiribati.png' },
  { value: 'Kuwait', label: 'Kuwait', imgSrc: 'iconss/flags/kuwait.png' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan', imgSrc: 'iconss/flags/kyrgyzstan.png' },
  { value: 'Laos', label: 'Laos', imgSrc: 'iconss/flags/laos.png' },
  { value: 'Latvia', label: 'Latvia', imgSrc: 'iconss/flags/latvia.png' },
  { value: 'Lebanon', label: 'Lebanon', imgSrc: 'iconss/flags/lebanon.png' },
  { value: 'Lesotho', label: 'Lesotho', imgSrc: 'iconss/flags/lesotho.png' },
  { value: 'Liberia', label: 'Liberia', imgSrc: 'iconss/flags/liberia.png' },
  { value: 'Libya', label: 'Libya', imgSrc: 'iconss/flags/libya.png' },
  { value: 'Liechtenstein', label: 'Liechtenstein', imgSrc: 'iconss/flags/liechtenstein.png' },
  { value: 'Lithuania', label: 'Lithuania', imgSrc: 'iconss/flags/lithuania.png' },
  { value: 'Luxembourg', label: 'Luxembourg', imgSrc: 'iconss/flags/luxembourg.png' },
  { value: 'Madagascar', label: 'Madagascar', imgSrc: 'iconss/flags/madagascar.png' },
  { value: 'Malawi', label: 'Malawi', imgSrc: 'iconss/flags/malawi.png' },
  { value: 'Malaysia', label: 'Malaysia', imgSrc: 'iconss/flags/malaysia.png' },
  { value: 'Maldives', label: 'Maldives', imgSrc: 'iconss/flags/maldives.png' },
  { value: 'Mali', label: 'Mali', imgSrc: 'iconss/flags/mali.png' },
  { value: 'Malta', label: 'Malta', imgSrc: 'iconss/flags/malta.png' },
  { value: 'Mauritania', label: 'Mauritania', imgSrc: 'iconss/flags/mauritania.png' },
  { value: 'Mauritius', label: 'Mauritius', imgSrc: 'iconss/flags/mauritius.png' },
  { value: 'Mexico', label: 'Mexico', imgSrc: 'iconss/flags/mexico.png' },
  { value: 'Micronesia', label: 'Micronesia', imgSrc: 'iconss/flags/micronesia.png' },
  { value: 'Moldova', label: 'Moldova', imgSrc: 'iconss/flags/moldova.png' },
  { value: 'Monaco', label: 'Monaco', imgSrc: 'iconss/flags/monaco.png' },
  { value: 'Mongolia', label: 'Mongolia', imgSrc: 'iconss/flags/mongolia.png' },
  { value: 'Montenegro', label: 'Montenegro', imgSrc: 'iconss/flags/montenegro.png' },
  { value: 'Morocco', label: 'Morocco', imgSrc: 'iconss/flags/morocco.png' },
  { value: 'Mozambique', label: 'Mozambique', imgSrc: 'iconss/flags/mozambique.png' },
  { value: 'Myanmar (Burma)', label: 'Myanmar (Burma)', imgSrc: 'iconss/flags/myanmar.png' },
  { value: 'Namibia', label: 'Namibia', imgSrc: 'iconss/flags/namibia.png' },
  { value: 'Nauru', label: 'Nauru', imgSrc: 'iconss/flags/nauru.png' },
  { value: 'Nepal', label: 'Nepal', imgSrc: 'iconss/flags/nepal.png' },
  { value: 'Netherlands', label: 'Netherlands', imgSrc: 'iconss/flags/netherlands.png' },
  { value: 'New Zealand', label: 'New Zealand', imgSrc: 'iconss/flags/new-zealand.png' },
  { value: 'Nicaragua', label: 'Nicaragua', imgSrc: 'iconss/flags/nicaragua.png' },
  { value: 'Niger', label: 'Niger', imgSrc: 'iconss/flags/niger.png' },
  { value: 'Nigeria', label: 'Nigeria', imgSrc: 'iconss/flags/nigeria.png' },
  { value: 'North Korea', label: 'North Korea', imgSrc: 'iconss/flags/north-korea.png' },
  { value: 'Norway', label: 'Norway', imgSrc: 'iconss/flags/norway.png' },
  { value: 'Oman', label: 'Oman', imgSrc: 'iconss/flags/oman.png' },
  { value: 'Pakistan', label: 'Pakistan', imgSrc: 'iconss/flags/pakistan.png' },
  { value: 'Palau', label: 'Palau', imgSrc: 'iconss/flags/palau.png' },
  { value: 'Palestine State', label: 'Palestine State', imgSrc: 'iconss/flags/palestine.png' },
  { value: 'Panama', label: 'Panama', imgSrc: 'iconss/flags/panama.png' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea', imgSrc: 'iconss/flags/papua-new-guinea.png' },
  { value: 'Paraguay', label: 'Paraguay', imgSrc: 'iconss/flags/paraguay.png' },
  { value: 'Peru', label: 'Peru', imgSrc: 'iconss/flags/peru.png' },
  { value: 'Philippines', label: 'Philippines', imgSrc: 'iconss/flags/philippines.png' },
  { value: 'Poland', label: 'Poland', imgSrc: 'iconss/flags/poland.png' },
  { value: 'Portugal', label: 'Portugal', imgSrc: 'iconss/flags/portugal.png' },
  { value: 'Qatar', label: 'Qatar', imgSrc: 'iconss/flags/qatar.png' },
  { value: 'Romania', label: 'Romania', imgSrc: 'iconss/flags/romania.png' },
  { value: 'Russia', label: 'Russia', imgSrc: 'iconss/flags/russia.png' },
  { value: 'Rwanda', label: 'Rwanda', imgSrc: 'iconss/flags/rwanda.png' },
  { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis', imgSrc: 'iconss/flags/saint-kitts-and-nevis.png' },
  { value: 'Samoa', label: 'Samoa', imgSrc: 'iconss/flags/samoa.png' },
  { value: 'San Marino', label: 'San Marino', imgSrc: 'iconss/flags/san-marino.png' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia', imgSrc: 'iconss/flags/saudi-arabia.png' },
  { value: 'Senegal', label: 'Senegal', imgSrc: 'iconss/flags/senegal.png' },
  { value: 'Serbia', label: 'Serbia', imgSrc: 'iconss/flags/serbia.png' },
  { value: 'Sierra Leone', label: 'Sierra Leone', imgSrc: 'iconss/flags/sierra-leone.png' },
  { value: 'Singapore', label: 'Singapore', imgSrc: 'iconss/flags/singapore.png' },
  { value: 'Slovakia', label: 'Slovakia', imgSrc: 'iconss/flags/slovakia.png' },
  { value: 'Slovenia', label: 'Slovenia', imgSrc: 'iconss/flags/slovenia.png' },
  { value: 'Solomon Islands', label: 'Solomon Islands', imgSrc: 'iconss/flags/solomon-islands.png' },
  { value: 'Somalia', label: 'Somalia', imgSrc: 'iconss/flags/somalia.png' },
  { value: 'South Africa', label: 'South Africa', imgSrc: 'iconss/flags/south-africa.png' },
  { value: 'South Korea', label: 'South Korea', imgSrc: 'iconss/flags/south-korea.png' },
  { value: 'South Sudan', label: 'South Sudan', imgSrc: 'iconss/flags/south-sudan.png' },
  { value: 'Spain', label: 'Spain', imgSrc: 'iconss/flags/spain.png' },
  { value: 'Sri Lanka', label: 'Sri Lanka', imgSrc: 'iconss/flags/sri-lanka.png' },
  { value: 'Sudan', label: 'Sudan', imgSrc: 'iconss/flags/sudan.png' },
  { value: 'Suriname', label: 'Suriname', imgSrc: 'iconss/flags/suriname.png' },
  { value: 'Sweden', label: 'Sweden', imgSrc: 'iconss/flags/sweden.png' },
  { value: 'Switzerland', label: 'Switzerland', imgSrc: 'iconss/flags/switzerland.png' },
  { value: 'Syria', label: 'Syria', imgSrc: 'iconss/flags/syria.png' },
  { value: 'Tajikistan', label: 'Tajikistan', imgSrc: 'iconss/flags/tajikistan.png' },
  { value: 'Tanzania', label: 'Tanzania', imgSrc: 'iconss/flags/tanzania.png' },
  { value: 'Thailand', label: 'Thailand', imgSrc: 'iconss/flags/thailand.png' },
  { value: 'Togo', label: 'Togo', imgSrc: 'iconss/flags/togo.png' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago', imgSrc: 'iconss/flags/trinidad-and-tobago.png' },
  { value: 'Tunisia', label: 'Tunisia', imgSrc: 'iconss/flags/tunisia.png' },
  { value: 'Turkey', label: 'Turkey', imgSrc: 'iconss/flags/turkey.png' },
  { value: 'Turkmenistan', label: 'Turkmenistan', imgSrc: 'iconss/flags/turkmenistan.png' },
  { value: 'Tuvalu', label: 'Tuvalu', imgSrc: 'iconss/flags/tuvalu.png' },
  { value: 'Uganda', label: 'Uganda', imgSrc: 'iconss/flags/uganda.png' },
  { value: 'Ukraine', label: 'Ukraine', imgSrc: 'iconss/flags/ukraine.png' },
  { value: 'United Arab Emirates', label: 'United Arab Emirates', imgSrc: 'iconss/flags/united-arab-emirates.png' },
  { value: 'United Kingdom', label: 'United Kingdom', imgSrc: 'iconss/flags/united-kingdom.png' },
  { value: 'United States', label: 'United States', imgSrc: 'iconss/flags/united-states.png' },
  { value: 'Uruguay', label: 'Uruguay', imgSrc: 'iconss/flags/uruguay.png' },
  { value: 'Uzbekistan', label: 'Uzbekistan', imgSrc: 'iconss/flags/uzbekistan.png' },
  { value: 'Vatican City', label: 'Vatican City', imgSrc: 'iconss/flags/vatican-city.png' },
  { value: 'Venezuela', label: 'Venezuela', imgSrc: 'iconss/flags/venezuela.png' },
  { value: 'Vietnam', label: 'Vietnam', imgSrc: 'iconss/flags/vietnam.png' },
  { value: 'Yemen', label: 'Yemen', imgSrc: 'iconss/flags/yemen.png' },
  { value: 'Zambia', label: 'Zambia', imgSrc: 'iconss/flags/zambia.png' },
  { value: 'Zimbabwe', label: 'Zimbabwe', imgSrc: 'iconss/flags/zimbabwe.png' },
  ];

  return (
	
	<Select
					  labelId="country-select-label"
					  id="country-select"
					  className="select-box  w-100" 
					  value={countryselectedOption} // Use value from props
					  onChange={(event) => countryhandleChange(event.target.value)}
									  >
						{countryoptions.map((option) => (
						<MenuItem key={option.value} className="select-box-item" value={option.value}>
						  <Box className="select-box-box" display="flex" alignItems="center">
							<img className="custom-select-icon" src={option.imgSrc} alt={option.label}  />
							<Typography className="select-box-typography">{option.label}</Typography>
						  </Box>
						</MenuItem>
						))}
				  </Select>
	
	
  );
};

export default CountrySelect;
