import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/public/privacy-policy.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'Privacy Policy | Aadow';
	  }, []);
 

  return (
    <div class="privacy-policy-body">
	<TopBar />
	<div class="top-area">
      <div class="container-top text-center">
        <h1>PRIVACY POLICY</h1>
		<span>Updated on 23 Jul 2023</span>
      </div>
    </div>
    <div class="content-area"> 
	
	<div class="policy-content shadow-sm">
	
	<p>At Aadow, we are committed to protecting your privacy and safeguarding the personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and store your personal information when you engage with our services. By using our services, you consent to the practices described in this policy.</p>
	
	<h2>Email Marketing Data:</h2>
	<p>a. Email Lists and Customer Data: As an email marketing solution company, we understand the importance of your customer's email lists and the data associated with them. We assure you that we will not sell, rent, or share your customer's email lists or any other personal data with any third parties, except as described in this Privacy Policy or with your explicit consent.</p>
	<p>b. Data Processing: We may process your customer's email lists and personal data solely for the purpose of providing our email marketing services and carrying out related activities, such as sending email campaigns and analyzing campaign performance. We will only process the data in accordance with your instructions and as necessary to fulfill our contractual obligations.</p>
	<p>c. Data Security: We implement industry-standard security measures to protect the confidentiality and integrity of your customer's email lists and personal data. We have strict access controls in place and regularly monitor and update our systems to ensure the highest level of data security.</p>
	<p>d. Data Retention: We retain your customer's email lists and personal data only for as long as necessary to provide the email marketing services or as required by law. We will securely delete or anonymize the data when it is no longer needed.</p>
	<p>e. Compliance with Regulations: We comply with applicable data protection laws, including the General Data Protection Regulation (GDPR) and other relevant regulations, regarding the processing and protection of personal data. We are committed to upholding the rights of individuals regarding their personal data.</p>
	<p>f. Data Controller Responsibilities: As the data controller, you are responsible for obtaining any necessary consents and complying with applicable data protection laws when uploading and processing your customer's email lists and personal data. We act as a data processor, processing the data solely on your behalf and in accordance with your instructions.</p>
	<p>g. Subprocessors: In order to provide our email marketing services, we may engage the services of subprocessors (e.g., email delivery providers, data analytics tools) who assist us in processing your customer's email lists and personal data. These subprocessors are carefully selected, and we ensure that they provide an adequate level of data protection.</p>
	<p>h. Changes to Email Marketing Data Practices: Any updates or changes to our practices regarding the handling of email marketing data will be reflected in this Privacy Policy or communicated to you directly.</p>

		 <h2>Information We Collect:</h2>
	  <p>a. Personal Information: We may collect personal information such as your name, contact details (including email address, phone number, and mailing address), and any other information you voluntarily provide to us.</p>
	  <p>b. Payment Information: When you make payments for our services, we may collect payment-related information, such as credit card details or other financial information necessary to process the payment.</p>
	  <p>c. Usage Information: We may collect information about your interactions with our website, including your IP address, browser type, referring/exit pages, and operating system. This information is collected through cookies and similar technologies.</p>

	  <h2>Use of Information:</h2>
	  <p>a. Provision of Services: We use the information collected to deliver and maintain our services, communicate with you, and provide customer support.</p>
	  <p>b. Improving Services: We may use the information to understand how our services are used, analyze trends, and enhance the user experience.</p>
	  <p>c. Marketing and Communication: With your consent, we may send you promotional emails, newsletters, or other communications about our services, special offers, or events. You can opt-out of receiving these communications at any time.</p>
	  <p>d. Legal Compliance: We may use and disclose your personal information to comply with applicable laws, regulations, legal processes, or governmental requests.</p>

	  <h2>Disclosure of Information:</h2>
	  <p>a. Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in delivering our services. These providers are contractually bound to protect the confidentiality and security of your information.</p>
	  <p>b. Legal Requirements: We may disclose your personal information if required to do so by law or if we believe that such disclosure is necessary to protect our rights, comply with a legal obligation, or respond to a legal request.</p>

	  <h2>Data Security:</h2>
	  <p>a. We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>
	  <p>b. While we strive to protect your personal information, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>

	  <h2>Data Retention:</h2>
	  <p>a. We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
	  <p>b. When personal information is no longer needed, we will securely delete or anonymize it.</p>

	  <h2>Third-Party Links:</h2>
	  <p>Our services may contain links to third-party websites or resources. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of these websites before providing any personal information.</p>

	  <h2>Your Rights:</h2>
	  <p>a. You have the right to access, update, correct, and delete your personal information held by us. You may also have the right to restrict or object to certain data processing activities.</p>
	  <p>b. If you wish to exercise any of these rights or have any privacy-related concerns, please contact us using the information provided at the end of this policy.</p>
	  
	  <h2>Changes to the Privacy Policy:</h2>
	  <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated policy on our website. We encourage you to review this Privacy Policy periodically.</p>

	  <h2>Contact Us:</h2>
	  <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at: <a href="mailto:Contact@aadow.com">Contact@aadow.com</a></p>
	
	
	
	</div>
	 
	</div>
	<Footer/>
    </div>
  );
};

export default PrivacyPolicy;
