import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/template-library.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Cookies from 'js-cookie';

import axios from "axios";


const TemplateLibrary = () => {
 
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
	
  const CookietId = Cookies.get('current_Id');
  const CookieAccountId = Cookies.get('current_accountId');
  const CookieAccountName = Cookies.get('current_accountName');
  const CookieAccountEmail = Cookies.get('current_accountEmail');
  
  const { Search } = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [searchBar, setSearchBar] = useState(Search || ''); // Initialize with URL param if present
  
  
  
  useEffect(() => {
    document.title = 'Template Library | Aadow';
  }, []);

  const handleSelect = (item) => {
	const randomTemplateId = Math.floor(10000000 + Math.random() * 90000000).toString();
	const currentDateTime = new Date().toISOString();

    const payload = {
      username: CookieAccountId,
      templateId: randomTemplateId,
      templateName: "Untitled Template",
      subject: "",
      previewText: "",
      content: item.content,
      thumbnail: item.thumbnail,
      status: "active",
      type: item.type,
      score: "",
      datetime: currentDateTime,
      timestamp: currentDateTime,
    };

    axios
      .post(`${ServerApiDomain}/api/emailtemplates/add`, payload)
      .then((response) => {
        console.log("Template added successfully:", response.data);
        console.log("Template added successfully!");
		window.location.href = `/create/email-template/drag-drop/${randomTemplateId}`;
      })
      .catch((error) => {
        console.error("Error adding template:", error);
        console.log("Failed to add the template. Please try again.");
      });
  };

  const createCodeEditorTemplate = () => {
	const randomTemplateId = Math.floor(10000000 + Math.random() * 90000000).toString();
	const currentDateTime = new Date().toISOString();

    const payload = {
      username: CookieAccountId,
      templateId: randomTemplateId,
      templateName: "Untitled Template",
      subject: "",
      previewText: "",
      content: "",
      thumbnail: "https://cdn.dribbble.com/userupload/11622398/file/original-4a6d42d0a68abca0b7f9f0e0ea38d639.png?resize=320x240&vertical=center",
      status: "active",
      type: "code-editor",
      score: "",
      datetime: currentDateTime,
      timestamp: currentDateTime,
    };

    axios
      .post(`${ServerApiDomain}/api/emailtemplates/add`, payload)
      .then((response) => {
        console.log("Template added successfully:", response.data);
        console.log("Template added successfully!");
		window.location.href = `/create/email-template/code-editor/${randomTemplateId}`;
      })
      .catch((error) => {
        console.error("Error adding template:", error);
        console.log("Failed to add the template. Please try again.");
      });
  };

  const createRichTextEditorTemplate = () => {
	const randomTemplateId = Math.floor(10000000 + Math.random() * 90000000).toString();
	const currentDateTime = new Date().toISOString();

    const payload = {
      username: CookieAccountId,
      templateId: randomTemplateId,
      templateName: "Untitled Template",
      subject: "",
      previewText: "",
      content: "",
      thumbnail: "https://cdn.dribbble.com/userupload/11631976/file/original-a335080d8adf1b737fa5134f97167999.png?resize=1024x768",
      status: "active",
      type: "text-editor",
      score: "",
      datetime: currentDateTime,
      timestamp: currentDateTime,
    };

    axios
      .post(`${ServerApiDomain}/api/emailtemplates/add`, payload)
      .then((response) => {
        console.log("Template added successfully:", response.data);
        console.log("Template added successfully!");
		window.location.href = `/create/email-template/text-editor/${randomTemplateId}`;
      })
      .catch((error) => {
        console.error("Error adding template:", error);
        console.log("Failed to add the template. Please try again.");
      });
  };
  
  const [activeTab, setActiveTab] = useState("AllTemplates");

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from doing a full page reload
    window.location.href = `/email-templates/template-library/${searchBar}`;// Navigate to the new URL with the search input
  };
  
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [verifyDomainLink, setVerifyDomainLink] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
	const current_username = 'pachorieshan';
	const username = 'testuser';
	const password = 'testpassword';
	const encodedCredentials = btoa(`${username}:${password}`);
	
	const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
	
	const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
        setSelectedId(null);
    };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorDomain("");
	setDomain("");
    setDomainError(false);
    setInputApiArea("true");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [Domain, setDomain] = useState('');
  const [DomainError, setDomainError] = useState(false);
  const [ErrorDomain, setErrorDomain] = useState('');
  const [DomainAdded, setDomainAdded] = useState('');
  const [DomainSentTo, setDomainSentTo] = useState('');
  const DomainRef = useRef(null);
  
  
  
  const [InputApiArea, setInputApiArea] = useState('true');
  
  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [noData, setNoData] = useState(false);
    const loadedIds = useRef(new Set());

    const fetchData = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    const API_URL = Search
        ? `${ServerApiDomain}/api/emailtemplates/search?username=default&search=${encodeURIComponent(searchBar)}&page=${page}`
        : `${ServerApiDomain}/api/emailtemplates/username/default?page=${page}`;

    const AUTH = 'Basic ' + btoa('testuser:testpassword');
    try {
        const response = await fetch(API_URL, {
            headers: {
                'Authorization': AUTH
            }
        });
        const result = await response.json();

        if (result.content.length > 0) {
            const newData = result.content.filter(item => !loadedIds.current.has(item.id));

            if (newData.length > 0) {
                setData(prevData => [...prevData, ...newData]);
                newData.forEach(item => loadedIds.current.add(item.id));
                setPage(prevPage => prevPage + 1);
            }
        } else {
            setHasMore(false);
        }

        if (page === 0 && result.content.length === 0) {
            setNoData(true);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        showSnackbar('Error fetching data', 'error'); // Show error snackbar
    } finally {
        setLoading(false);
    }
    }, [loading, hasMore, page, searchBar]); // Add searchBar as dependency


    useEffect(() => {
      fetchData();
    }, [fetchData, searchBar]);

    const observer = useRef();

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchData();
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, fetchData]);

  return (
    <SideBar>
       <div className="template-library">
		<div className="template-library-body">
			
			<div className="breadcrumb-area"> 
				 <nav aria-label="breadcrumb">
				  <ol className="breadcrumb">
					<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
					<li className="breadcrumb-item"><Link to="/email-templates">Email Templates</Link></li>
					<li className="breadcrumb-item active" aria-current="page">Template Library</li>
				  </ol>
				</nav>
			</div>
			
			<div className="header-area text-center">
			<form onSubmit={handleSubmit}>
				<input
                type="text"
                name="search"
                autoComplete="off"
                id="search"
                value={searchBar} // Controlled input
                onChange={(e) => setSearchBar(e.target.value)} // Update state when typing
                className="search-bar shadow-sm" placeholder="Search..."
                required
              />
              <button type="submit" className="d-none">Search</button> {/* You can add a search button */}
			</form>
			</div>
			
			
			<div className="search-result-category-area">
				<h2 className="main-heading">Template Library</h2>
				<h6 className="main-subheading">Browse Thousands of  Email Templates for Marketing and Transactional Purpose</h6>
				{Search ? (
				<div></div>
				) : (
				 <div className="category-tabs text-center ">
					<a
					  href="#"
					  className={activeTab === "AllTemplates" ? "active" : ""}
					  onClick={() => handleTabClick("AllTemplates")}
					>
					  All Templates
					</a>
					<a
					  href="#MyTemplates"
					  className={`d-none activeTab === "MyTemplates" ? "active" : ""`}
					  onClick={() => handleTabClick("MyTemplates")}
					>
					  My Templates
					</a>
					<a href="#CodeEditor" className={activeTab === "CodeEditor" ? "active" : ""} onClick={() => handleTabClick("CodeEditor")}>
					  Code Editor
					</a>
					<a href="#TextEditor" className={activeTab === "RichTextEditor" ? "active" : ""} onClick={() => handleTabClick("RichTextEditor")}>
					  Rich Text Editor
					</a>
				  </div>
				)}
			</div>
		
			<div className="template-result-area">
				
				{activeTab === "AllTemplates" && (
				<div>
				{noData ? (
									<center>
										<div className="empty-error-area">
											<img className="empty-error-image w-25 mb-4" src="../../iconss/other/no-results.png"  />
											<h2 className="empty-error-heading">No Email Templates Found</h2>
											<h6 className="empty-error-subheading" >We couldn't find any email templates.</h6>
											
										</div>
									</center>
								) : (
									<>
										<div className="row">
											{data.map((item, index) => {
											const formattedDate = new Intl.DateTimeFormat('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}).format(new Date(item.datetime));

											return (
												<div key={item.id} ref={data.length === index + 1 ? lastElementRef : null} className="col-3">
														<div className="template-preview-area">
															<img className="template-preview shadow-sm" src={`${item.thumbnail}`} alt={`${item.thumbnail}`} />
															<div className="overlay">
															<button
															className="center-button"
															onClick={() => handleSelect(item)}
															>
															Select
															</button>
															</div>
														</div>
												</div>
												
												);
											})}
											
										</div>
									</>
								)}
				</div>
				)}
				{activeTab === "MyTemplates" && (
				<div id="MyTemplates" className="row">
					
					
					<div className="col-3">
						<div className="template-preview-area">
							<img className="template-preview shadow-sm" src="https://cdn.dribbble.com/userupload/11622398/file/original-4a6d42d0a68abca0b7f9f0e0ea38d639.png?resize=320x240&vertical=center" />
							<div className="overlay">
								<button  className="center-button">Select</button>
							</div>
						</div>
					</div>
					
					<div className="col-3">
						<div className="template-preview-area">
							<img className="template-preview shadow-sm" src="https://cdn.dribbble.com/userupload/15973641/file/original-e3335cffe998ee434cd4e66cf71335b3.png?resize=320x240&vertical=center" />
							<div className="overlay">
								<button className="center-button">Select</button>
							</div>
						</div>
					</div>
					
					<div className="col-3">
						<div className="template-preview-area">
							<img className="template-preview shadow-sm" src="https://cdn.dribbble.com/userupload/15312956/file/still-0a67d335063e7da679fc574dbe927057.png?resize=320x240&vertical=center" />
							<div className="overlay">
								<button className="center-button">Select</button>
							</div>
						</div>
					</div>
					
					<div className="col-3">
						<div className="template-preview-area">
							<img className="template-preview shadow-sm" src="https://cdn.dribbble.com/userupload/10494040/file/original-0a9e75be8afa364a377bb5b1e999c69c.png?resize=320x240&vertical=center" />
							<div className="overlay">
								<button className="center-button">Select</button>
							</div>
						</div>
					</div>
					
					
				</div>
				)}
				
				{activeTab === "CodeEditor" && (
				<div id="CodeEditor" className="row">
					
					<div className="col-4"></div>
					
					<div className="col text-center">
						<div className="template-preview-area">
							<img className="template-preview shadow-sm bg-light" src="/icons/CODE-EDITOR.png" />
							<div className="overlay">
								<button type="button" onClick={() => createCodeEditorTemplate()} className="center-button text-decoration-none">Select</button>
							</div>
						</div>
					</div>
					<div className='col-4'></div>
					
				</div>
				)}
				
				{activeTab === "RichTextEditor" && (
				<div id="RichTextEditor" className="row">
					
					<div className="col-4"></div>
					
					<div className="col-4 text-center">
						<div className="template-preview-area">
							<img className="template-preview shadow-sm bg-light" src="/icons/WYSIWYG-EDITOR.png" />
							<div className="overlay">
								<button type="button" onClick={() => createRichTextEditorTemplate()} className="center-button text-decoration-none">Select</button>
							</div>
						</div>
					</div>
					
					
				</div>
				)}
				
				
				
			</div>
		
		</div>
       </div>
    </SideBar>
  );
};

export default TemplateLibrary;
