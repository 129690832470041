import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/public/navbar.css';

const NavBar = () => {
	
  const [isAccordionVisible, setIsAccordionVisible] = useState(false);

  // Function to toggle accordion visibility
  const toggleAccordion = () => {
    setIsAccordionVisible(!isAccordionVisible);
  };	
	
  const [showAccordion, setShowAccordion] = useState(false);
  let hideTimeout; // Declare timeout for delay


  // Function to handle mouse hover
  const handleMouseEnter = () => {
    clearTimeout(hideTimeout); // Clear any hide timeout when hovering
    setShowAccordion(true); // Show accordion immediately
  };

  const handleMouseLeave = () => {
    hideTimeout = setTimeout(() => {
      setShowAccordion(false); // Delay the hide action
    }, 300); // 300ms delay to give users time to move their mouse
  };
  
  const [showSolutions, setShowSolutions] = useState(false);
  const [showCompany, setShowCompany] = useState(false);

  // Handlers to toggle visibility
  const toggleSolutions = () => setShowSolutions(!showSolutions);
  const toggleCompany = () => setShowCompany(!showCompany);

  return (
    <nav>
	<div className="large-nav">
      <div className="navbar-body">
        <div className="top-body">
          <div className="row align-items-center">
            <div className="col-2">
              <img
                className="logo"
                src="/logos/aadow-logo.svg"
              />
            </div>
            <div className="col-6">
              <Link to="/" className="nav-link">
                Home
              </Link>
			  <Link to="/pricing" className="nav-link">
                Pricing
              </Link>
              <Link to="/contact" className="nav-link">
                Contact Support
              </Link>
              <a href="" className="nav-link has-child" onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                Company <i className="bi bi-chevron-down"></i>
              </a>
            </div>
            <div className="col-4 text-end">
              <a href="login" className="nav-link">
                Login
              </a>
              <a href="signup" className="nav-link-signup">
                Create a free account
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Accordion body will be shown when showAccordion is true */}
      {showAccordion && (
        <div
          className="navbar-accordion-body"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="row">
            <div className="col-4">
              <label className="d-none">Resources</label>
              <div className="bullet-links">
			  <Link to="/blogs" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-book bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">Blogs</h4>
                    <p className="bullet-links-subheading">
                      Stay updated with the latest trends and insights in email marketing.
                    </p>
                  </div>
                </div>
			 </Link>
              </div>
              <div className="bullet-links">
			  <Link to="/faqs" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-patch-question bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">FAQs</h4>
                    <p className="bullet-links-subheading">
                      Find answers to commonly asked questions and get quick solutions.
                    </p>
                  </div>
                </div>
				</Link>
              </div>

              <div className="bullet-links">
			  <Link to="/contact" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-life-preserver bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">Contact Support</h4>
                    <p className="bullet-links-subheading">
                      Reach out to our team for assistance with any inquiries or issues.
                    </p>
                  </div>
                </div>
			  </Link>
              </div>
            </div>

            <div className="col-4">
              <label className="d-none">Resources</label>
              <div className="bullet-links">
			  <Link to="/about-us" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-flag bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">About Us</h4>
                    <p className="bullet-links-subheading">
                      Learn more about our mission, values, and how we empower businesses through email marketing
                    </p>
                  </div>
                </div>
				</Link>
              </div>

              <div className="bullet-links">
			  <Link to="/careers" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-briefcase bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">Careers</h4>
                    <p className="bullet-links-subheading">
                      Explore exciting opportunities to join our team and grow your career with us.
                    </p>
                  </div>
                </div>
				</Link>
              </div>

            </div>

            <div className="col-4">
              <div className="schedule-call-area">
                <div className="schedule-image-area">
                  <img
                    className="schedule-image"
                    src="https://images.unsplash.com/photo-1642257859842-c95f9fa8121d?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                  <img
                    className="schedule-image"
                    src="https://images.unsplash.com/photo-1573497490790-9053816a01d4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDYxfHx8ZW58MHx8fHx8"
                    
                  />
                  <img
                    className="schedule-image"
					src="https://images.unsplash.com/photo-1549473448-5d7196c91f48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D"
                  />
                  <img
                    className="schedule-image"
                    src="https://images.unsplash.com/photo-1573496800808-56566a492b63?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDUzfHx8ZW58MHx8fHx8"
                  />
                </div>
                <h4 className="schedule-call-area-heading">
                  Schedule a call with our expert
                </h4>
                <h6 className="schedule-call-area-subheading">
                  Need help getting started? Our team is ready to help.
                </h6>

                <div className="schedule-call-bullets">
                  <h5 className="Schedule-call-bullet">
                    <i className="bi bi-check-circle"></i> Personalized demo and
                    onboarding
                  </h5>
                  <h5 className="Schedule-call-bullet">
                    <i className="bi bi-check-circle"></i> Get assistance with
                    specific issues
                  </h5>
                  <h5 className="Schedule-call-bullet">
                    <i className="bi bi-check-circle"></i> Received guidance in designing
                  </h5>
                  <h5 className="Schedule-call-bullet">
                    <i className="bi bi-check-circle"></i> Get ongoing support
                    for your project
                  </h5>
                </div>

                <Link to="/contact" className="book-call text-decoration-none d-inline-block">
                  Book a free call
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
	</div>
	
	<div className="sm-nav">
      <div className="navbar-body">
        <div className="top-body">
          <div className="row align-items-center">
            <div className="col-6">
              <img
                className="logo"
                src="logos/aadow-logo.svg"
                alt="logo"
              />
            </div>

            <div className="col-6 text-end">
              <a href="login" className="nav-link">
                Login
              </a>
              <a href="#" className="nav-link-signup" onClick={toggleAccordion}>
                <i className="bi bi-list"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Conditionally add/remove 'd-none' based on the state */}
      <div className={`navbar-accordion-body ${isAccordionVisible ? '' : 'd-none'}`}>
        <div className="row">
          <div className="col-12">
            <Link to="/" className="accordion-nav-link text-dark text-decoration-none">Home</Link>
            <Link to="/pricing" className="accordion-nav-link text-dark text-decoration-none">Pricing</Link>
            <Link to="/contact" className="accordion-nav-link text-dark text-decoration-none">Contact Support</Link>

            {/* Accordion for Solutions */}
            <Link className="accordion-nav-link text-decoration-none text-dark" onClick={toggleSolutions}>
              Solutions <i className={`bi ${showSolutions ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
            </Link>
            {showSolutions && (
              <div className="accordion-content">
                <div className="row">
                  <div className="col-12">
                    <label className="d-none">Resources</label>
                    
					<div className="bullet-links">
			  <Link to="/blogs" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-book bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">Blogs</h4>
                    <p className="bullet-links-subheading">
                      Stay updated with the latest trends and insights in email marketing.
                    </p>
                  </div>
                </div>
			 </Link>
              </div>
              <div className="bullet-links">
			  <Link to="/faqs" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-patch-question bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">FAQs</h4>
                    <p className="bullet-links-subheading">
                      Find answers to commonly asked questions and get quick solutions.
                    </p>
                  </div>
                </div>
				</Link>
              </div>

              <div className="bullet-links">
			  <Link to="/contact" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-life-preserver bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">Contact Support</h4>
                    <p className="bullet-links-subheading">
                      Reach out to our team for assistance with any inquiries or issues.
                    </p>
                  </div>
                </div>
			  </Link>
              </div>
			  
			  
              <div className="bullet-links">
			  <Link to="/about-us" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-flag bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">About Us</h4>
                    <p className="bullet-links-subheading">
                      Learn more about our mission, values, and how we empower businesses through email marketing
                    </p>
                  </div>
                </div>
				</Link>
              </div>

              <div className="bullet-links">
			  <Link to="/careers" className="text-decoration-none text-dark">
                <div className="row">
                  <div className="col-2">
                    <i className="bi bi-briefcase bullet-links-icon"></i>
                  </div>
                  <div className="col-10 p-0">
                    <h4 className="bullet-links-heading">Careers</h4>
                    <p className="bullet-links-subheading">
                      Explore exciting opportunities to join our team and grow your career with us.
                    </p>
                  </div>
                </div>
				</Link>
              </div>
					
					
                  </div>
                </div>
              </div>
            )}

            
            <div className="col-12">
              <div className="schedule-call-area">
                <div className="schedule-image-area">
                  <img
                    className="schedule-image"
                    src="https://images.unsplash.com/photo-1642257859842-c95f9fa8121d?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                  <img
                    className="schedule-image"
                    src="https://images.unsplash.com/photo-1573497490790-9053816a01d4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDYxfHx8ZW58MHx8fHx8"
                    
                  />
                  <img
                    className="schedule-image"
					src="https://images.unsplash.com/photo-1549473448-5d7196c91f48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D"
                  />
                  <img
                    className="schedule-image"
                    src="https://images.unsplash.com/photo-1573496800808-56566a492b63?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDUzfHx8ZW58MHx8fHx8"
                  />
                </div>
                <h4 className="schedule-call-area-heading">
                  Schedule a call with our expert
                </h4>
                <h6 className="schedule-call-area-subheading">
                  Need help getting started? Our team is ready to help.
                </h6>

                <div className="schedule-call-bullets">
                  <h5 className="Schedule-call-bullet">
                    <i className="bi bi-check-circle"></i> Personalized demo and
                    onboarding
                  </h5>
                  <h5 className="Schedule-call-bullet">
                    <i className="bi bi-check-circle"></i> Get assistance with
                    specific issues
                  </h5>
                  <h5 className="Schedule-call-bullet">
                    <i className="bi bi-check-circle"></i> Received guidance in designing
                  </h5>
                  <h5 className="Schedule-call-bullet">
                    <i className="bi bi-check-circle"></i> Get ongoing support
                    for your project
                  </h5>
                </div>

                <Link to="/contact" className="book-call text-decoration-none d-inline-block">
                  Book a free call
                </Link>
              </div>
			  
            </div>
          </div>
        </div>
      </div>
    </div>
	
    </nav>
  );
};

export default NavBar;
