import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../../css/private/domain-authentication.css';
import SideBar from './../components/SideBar';


import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from 'js-cookie';

// Import other settings components as needed

const SenderAuthentication = () => {
	
	const ServerApiDomain = process.env.REACT_APP_SPRING_SERVER_API_DOMAIN;
	const CookieAccountName = Cookies.get('current_accountName');
	const CookieAccountId = Cookies.get('current_accountId');
	  
	const [FullPageloading, setFullPageloading] = useState(false);
  const [InputApiArea, setInputApiArea] = useState('true');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
		  showSnackbar('Copied to clipboard!', 'success');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorEmail("");
	setEmail("");
    setEmailError(false);
	setErrorRemarks("");
	setRemarks("");
    setRemarksError(false);
    setInputApiArea("true");
  };
  
  const handleSendMail = () => {
		setErrorRemarks("");
		setRemarksError(false);
		setErrorRemarks("");
		setRemarksError(false);
	if (Email.trim() === '') {
		setErrorEmail("Please enter a coworker's email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}else if (!emailRegex.test(Email)) {
		setErrorEmail("Please enter a valid email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}
	setEmailSentTo(Email);
	setInputApiArea("false");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [Remarks, setRemarks] = useState('');
  const [RemarksError, setRemarksError] = useState(false);
  const [ErrorRemarks, setErrorRemarks] = useState('');
  const RemarksRef = useRef(null);
  
  
  const [Email, setEmail] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const [ErrorEmail, setErrorEmail] = useState('');
  const EmailRef = useRef(null);
  const [EmailSentTo, setEmailSentTo] = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;	
  
  const { Domain } = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [searchBar, setSearchBar] = useState(Domain || ''); // Initialize with URL param if present	

  useEffect(() => {
    document.title = 'Domain Authentication | Aadow';
  }, []);
  
   const [data, setData] = useState([]);
   const [error, setError] = useState(null);

  const [domainname, setDomainname] = useState(''); // Add a state for the domain

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${ServerApiDomain}/api/domainauthenticate/entries`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: CookieAccountId,
            authenticateId: Domain,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        
        // Set the domain from the first entry if data exists
        if (result.length > 0) {
          setDomainname(result[0].domain); // Assuming the domain is the same for all entries
        }

        setData(result);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [domainname]);

    if (error) return <div>Error: {error}</div>;
  
  
  return (
    <SideBar>
		<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={FullPageloading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
      <div className="domain-auth-content">
        <div className="settings-body">
          <div className="breadcrumb-area">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to="/sender-authentication">Sender Authentication</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{domainname}</li>
              </ol>
            </nav>
          </div>

          <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-12">
                  <h1 className="main-heading">{domainname}</h1>

                 
                </div>
              </div>
            </div>
          </div>
		  
		  <div className="main-area">
			  <div className="secondary-heading-area border-bottom-0 pb-2">
					<div className="row align-items-center">
						<div className="col-9">
							<h4 className="secondary-heading">DNS Records</h4>
							<h6 className="secondary-subheading">You will need to install the following records to complete the process.</h6>
						</div>
						<div className="col-3 text-end">
							<button type="button" onClick={HandleShowModal} className="primary-button d-none ms-2">Send To A Coworker</button>
						</div>
					</div>
			  </div>
			  {data.length === 0 ? (
									<center>
										<div className="empty-error-area">
											<img className="empty-error-image w-25 mb-5" src="../../iconss/other/agile.png"  />
											<h2 className="empty-error-heading">We're generating credentials for you.</h2>
											<h6 className="empty-error-subheading" >Usually, it takes a few minutes, but in some cases, it may be generated within 24 hours.</h6>
										</div>
									</center>
			  ) : (
			  <div>
			  <table>
							<thead>
								<tr>
									<th className="th-one">Status</th>
									<th className="th-two">Type</th>
									<th className="th-three">Host</th>
									<th className="th-four">Value</th>
								</tr>
							</thead>
							</table>
							<table>
        
				</table>
	
     
								{data.map(entry => (
									<div key={entry.id}>	
										<table>
											<tbody>
												<tr>
													<td className="td-one"><span className={`verfication-status ${entry.status}-status text-capitalize`}>{entry.status}</span></td>
													<td className="td-two">
														<h6 className="domain-email-name">{entry.type}</h6>
													</td>
													<td className="td-three">
														<div className="row align-items-center">
															<div className="col-9">
																{entry.host}	
															</div>
															<div className="col-3">
																<a 
																	href="#" 
																	className="copy-button text-end"
																	onClick={(e) => {
																		e.preventDefault(); // Prevent default link behavior
																		navigator.clipboard.writeText(entry.host)
																			.then(() => {
																				showSnackbar('Copied to clipboard!', 'success');
																			})
																			.catch(err => {
																				console.error('Failed to copy: ', err);
																			});
																	}}
																>
																	Copy
																</a>
															</div>
														</div>
													</td>
													<td className="td-four">
														<div className="row align-items-center">
															<div className="col-9">
																{entry.value}		
															</div>
															<div className="col-3">
																<a 
																	href="#" 
																	className="copy-button text-end"
																	onClick={(e) => {
																		e.preventDefault(); // Prevent default link behavior
																		navigator.clipboard.writeText(entry.value)
																			.then(() => {
																				showSnackbar('Copied to clipboard!', 'success');
																			})
																			.catch(err => {
																				console.error('Failed to copy: ', err);
																			});
																	}}
																>
																	Copy
																</a>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
										<div className={`error-tr ${entry.status === 'failed' ? '' : 'd-none'}`}>
											<h6 className="error-tr-heading">We received the following error when validating the record.</h6>
											<div className="solution-content">
												{entry.error}
											</div>
										</div>
									</div>
								))}
			  
		  </div>
			  )}
		  </div>
		  

        </div>
      </div>
	  
	  {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Send To A Coworker</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body pb-4 pt-0 px-4">
					{InputApiArea === 'true' && (
					<div className="row">
						<div className="col-12">
							<label>Coworker's Email<b className="text-danger">*</b></label>
							<input type="text" ref={EmailRef} className={`input-forms w-100 ${EmailError ? 'input-error' : ''}`} value={Email} onChange={(e) => {
								  setEmail(e.target.value);
								}} />
							{ErrorEmail && <div className="error-message">{ErrorEmail}</div>}
						</div>
						<div className="col-12">
							<label>Any Remarks</label>
							<textarea type="text" ref={RemarksRef} className={`input-forms w-100 ${RemarksError ? 'input-error' : ''}`} value={Remarks} onChange={(e) => {
								  setRemarks(e.target.value);
								}} ></textarea>
							{ErrorRemarks && <div className="error-message">{ErrorRemarks}</div>}
						</div>
					</div>	
					)}
					{InputApiArea === 'false' && (	
						<div className="col-12">
							<div id="step_three" className="right-content">
							  <img src="../iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">Sent to Coworker!</h2>
							  <h2 className="secondary-description-modal text-center">Mail sent to <b>{EmailSentTo}</b></h2>
							  <center><button type="button" className="primary-button float-center" onClick={handleClose} >Okay</button></center>
							</div>
						</div>
					)}
						
					</div>
					
					{InputApiArea === 'true' && (
					<div className="modal-footer ">
						<button type="button" className="secondary-button" onClick={handleClose} data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="primary-button" onClick={handleSendMail}>Send</button>
					</div>
					)}
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  
		   
		  
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
	  <br/><br/><br/><br/><br/><br/>
    </SideBar>
  );
};

export default SenderAuthentication;